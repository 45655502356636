<template>
  <label class="custom-toggle" :class="wrapperClass">
    <input type="checkbox" v-model="model" />
    <span :class="switchClass" :data-label-off="offText" :data-label-on="onText"> </span>
  </label>
</template>
<script>
  export default {
    name: 'base-switch',
    props: {
      value: [Array, Boolean],
      type: String,
      onText: {
        type: String,
        default: 'Yes',
      },
      offText: {
        type: String,
        default: 'No',
      },
      onColor: {
        type: String,
        default: 'primary',
      },
      offColor: {
        type: String,
        default: 'secondary',
      },
      small: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      wrapperClass() {
        let baseClass = this.small ? 'small' : ''
        if (this.type === 'slider') {
          baseClass += ' '
          baseClass += this.value ? this.onColor : this.offColor
        }
        return baseClass
      },
      switchClass() {
        if (this.type) {
          return 'custom-toggle-' + this.type
        }
        return ''
      },
      model: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        },
      },
    },
    methods: {
      triggerToggle() {
        this.model = !this.model
      },
    },
  }
</script>
<style></style>
