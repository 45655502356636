








import { defineComponent } from '@vue/composition-api'
import IconMagnifierBig from '@/components/Icons/IconMagnifierBig.vue'

export default defineComponent({
  components: {
    IconMagnifierBig,
  },
})
