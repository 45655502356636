<template>
  <div class="header" :class="{ [`bg-${type}`]: type }">
    <div class="container-fluid">
      <div class="header-body">
        <ImpersonationBanner v-if="this.authStore.isImpersonating" />
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapStores } from 'pinia'
  import ImpersonationBanner from '../views/Dashboard/ImpersonationBanner'
  import { useAuthStore } from '@/store/auth'
  export default {
    name: 'base-header',
    components: {
      ImpersonationBanner,
    },
    computed: mapStores(useAuthStore),
    props: {
      type: {
        type: String,
        default: 'primary',
        description: 'Header background type',
      },
    },
  }
</script>
<style></style>
