

















































import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    width: {
      type: [Number, String],
      default: 23,
    },
    height: {
      type: [Number, String],
      default: 23,
    },
    viewBox: {
      type: String,
      default: '0 0 23 23',
    },
  },
})
