import Router, { NavigationGuardNext, RawLocation, Route } from 'vue-router'
import { useAuthStore } from '@/store/auth'
import { useTeamStore } from '@/store/team'
import { TempStorage } from '@/util/tempStorage'
import { Pinia } from 'pinia'
import { SignupType } from '@/util/enums'
import { useOnboardingStore } from '@/store/onboarding'

type AnyFunction<RETURN_T = any> = (...args: any[]) => RETURN_T
type ErrorHandlerFunction<RETURN_T = any> = (e: Error) => RETURN_T
const originalPush = Router.prototype.push

// https://gist.github.com/eyedean/ce6ab6a5108a1bd19ace64382144b5b0
// https://newbedev.com/vue-router-uncaught-in-promise-error-redirected-from-login-to-via-a-navigation-guard
function augmentedPush(location: RawLocation): Promise<Route>
function augmentedPush(
  location: RawLocation,
  onResolve?: AnyFunction,
  onReject?: ErrorHandlerFunction
): void
function augmentedPush(
  this: Router,
  location: RawLocation,
  onResolve?: AnyFunction,
  onReject?: ErrorHandlerFunction
): void | Promise<Route> {
  const boundOriginalPush = originalPush.bind(this)
  if (onResolve || onReject) {
    return boundOriginalPush(location, onResolve, onReject)
  } else {
    return boundOriginalPush(location).catch((err) => {
      if (
        Router.isNavigationFailure(err, Router.NavigationFailureType.redirected) ||
        Router.isNavigationFailure(err, Router.NavigationFailureType.duplicated)
      ) {
        // whatever, we are fine if it's aborted due to navigation redirect
        return Promise.resolve(err.from)
      }
      // rethrow error
      console.log({ err })
      return Promise.reject(err)
    })
  }
}
Router.prototype.push = augmentedPush
Router.prototype.replace = augmentedPush

export default Router

export async function routerBeforeEach(
  to: Route,
  from: Route,
  next: NavigationGuardNext<Vue>,
  pinia: Pinia
) {
  // Remove trailing slashes from the path
  const newPath = to.path.replace(/\/+$/, '')
  if (newPath !== to.path) {
    return next({
      path: newPath,
      query: to.query,
      hash: to.hash,
    })
  }
  document.title =
    to.meta?.title || 'ImageEngine Control Panel - Advanced Image CDN, Real-Time Image Optimization'

  //if user gets to create-account page without starting on /register, route
  //them to /register to start signup process
  if (to.path === '/register/create-account' && from.path !== '/register') {
    //if query passed in then set the site as origin, and skips the input of a
    //website, -if sso signup continue as normal do not redirect to /register
    const queryArr = Object.keys(from.query)
    const signupType = new TempStorage().getSignupType()
    const sso = signupType === SignupType.Google || signupType === SignupType.GitHub
    if (queryArr.length > 0 || sso) {
      next()
      return
    } else if (queryArr.length === 0) {
      next('/register')
      return
    }
  }

  // if impersonation send to login where state is reset
  if (to.path === '/login' && to.query?.impersonation) {
    return next()
  }
  if (to.matched.some((route) => route.meta?.noAuthCheck)) {
    // noAuthCheck routes will perform authn actions themselves
    return next()
  }
  const auth = useAuthStore(pinia)
  if (to.matched.some((route) => route.meta?.registerFlow) && auth.isRegistering) {
    return next()
  }

  if (!auth.isAuthenticated && auth.containsValidRefresh()) {
    await auth.hydrateUser()
  }

  // for routes that should not be accessed when authenticated
  // bring user back to starting page after loggin in
  if (to.matched.some((route) => route.meta?.redirectIfAuth)) {
    if (!auth.isAuthenticated) {
      next()
    } else {
      const team = useTeamStore(pinia)
      team.isReseller ? next('/customers') : next('/subscriptions')
    }
    return
  }

  // checking if user has access to authenticated route
  if (to.matched.some((route) => route.meta?.requiresAuth)) {
    if (auth.isAuthenticated) {
      //comment out until we have new maxio billing ready
      // if (auth.requiredPayment && !to.path.includes('add-payment')) {
      //   next(`/register/add-payment`)
      // } else if (
      //   !auth.hasEngines &&
      //   to.path !== `/getting-started` &&
      //   !to.meta?.doesNotReqEngines
      // ) {
      //   next(`/getting-started`)
      //   return
      // }
      if (!auth.hasEngines && to.path !== `/getting-started` && !to.meta?.doesNotReqEngines) {
        next(`/getting-started`)
        return
      }
      next()
    } else {
      next('/login')
      //store deep link
      new TempStorage().storeDeepLink(to.fullPath)
    }
    return
  }

  // make sure next is called once and only once
  next()
}
