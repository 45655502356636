






















import { defineComponent, onMounted, PropType, ref, watch } from '@vue/composition-api'
import IconStarActive from '@/components/Icons/IconStarActive.vue'
import IconStarInactive from '@/components/Icons/IconStarInactive.vue'
import { DetailedSubscription, Origin } from '@/util/decorators'
import { useSidebarStore } from '@/store/sidebar'
import { scrollIntoViewIfNeeded } from '@/util'

export default defineComponent({
  components: {
    IconStarActive,
    IconStarInactive,
  },
  props: {
    subscription: {
      type: Object as PropType<DetailedSubscription>,
      required: true,
    },
    origin: {
      type: Object as PropType<Origin>,
      required: true,
    },
  },
  setup(props) {
    const sidebarStore = useSidebarStore()
    const originLink = ref<HTMLElement>()

    const handleOriginChange = () => {
      if (!sidebarStore.requested.origin) {
        return
      }

      if (sidebarStore.requested.origin !== props.origin.id) {
        return
      }

      if (originLink.value) {
        scrollIntoViewIfNeeded(originLink.value, { behavior: 'smooth', block: 'center' })
      }
    }

    onMounted(handleOriginChange)
    watch(() => sidebarStore.requested.origin, handleOriginChange)

    return {
      sidebarStore: useSidebarStore(),
      favorite: useSidebarStore().updateOriginFavoriteState,
      originLink,
    }
  },
})
