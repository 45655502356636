import { defineStore } from 'pinia'
import * as api from '../services/ie-microservice-openapi'
import { useAuthStore } from './auth'
interface ITeam {
  id?: number | null
  name?: string | null
  partner?: boolean | null
  reseller?: boolean | null
}

interface ITeamStore {
  team?: ITeam
}

export enum UserType {
  PARTNER = 'partner',
  RESELLER = 'customer-of-a-partner',
  REGULAR_USER = 'end-user',
  IMPERSONATE_USER = 'impersonate-user',
  INTERNAL = 'internal',
}

export const useTeamStore = defineStore({
  id: 'team',
  state: (): ITeamStore => ({
    team: undefined,
  }),
  actions: {
    async fetchTeam(teamId: number) {
      return await new api.TeamsApi()
        .getTeamByID(teamId)
        .then((res) => {
          this.team = res.data
          return { success: true, team: res.data }
        })
        .catch((e) => ({ success: false }))
    },
  },
  getters: {
    userType: ({ team }): UserType => {
      const auth = useAuthStore()

      if (auth.isImpersonating) {
        return UserType.IMPERSONATE_USER
      }

      if (team?.partner) {
        return UserType.PARTNER
      }

      if (team?.reseller) {
        return UserType.RESELLER
      }

      if (auth.user.email?.includes('@scientiamobile.com')) {
        return UserType.INTERNAL
      }

      return UserType.REGULAR_USER
    },
    isReseller: ({ team }): boolean => {
      if (!team) return false
      return !!team.reseller
    },
    isPartner: ({ team }): boolean => {
      if (!team) return false
      return !!team.partner
    },
  },
})
