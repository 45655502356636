


















import { defineComponent, onMounted, onBeforeUnmount } from '@vue/composition-api'
import DashboardNavbar from '@/layout/DashboardNavbar.vue'
import ContentFooter from '@/layout/ContentFooter.vue'
import Onboarding from '@/components/Onboarding.vue'
import EngineVerification from '@/components/EngineVerification.vue'
import Sidebar from '@/components/SidebarPlugin/Sidebar.vue'
import FadeTransition from 'vue2-transitions/src/Fade/FadeTransition.vue'
import { useSidebarStore } from '@/store/sidebar'
import { useAuthStore } from '@/store/auth'
import { TempStorage } from '@/util/tempStorage'
import swal from 'sweetalert2'

export default defineComponent({
  components: {
    DashboardNavbar,
    ContentFooter,
    Sidebar,
    FadeTransition,
    Onboarding,
    EngineVerification,
  },
  setup(_, context) {
    const sidebarStore = useSidebarStore()
    const authStore = useAuthStore()
    let intervalId: number | null = null

    onBeforeUnmount(() => {
      if (intervalId !== null) clearInterval(intervalId)
    })

    onMounted(() => {
      const action = () => {
        // expired time minus 2 minutes
        let time = parseInt(localStorage.getItem('refreshTokenExpiration') || '0') - 120
        const unixSec = Date.now() / 10000
        if (unixSec > time) {
          swal.fire({
            allowOutsideClick: false, // We want user to acknowledge
            title: 'Expired Authorization',
            text: 'Sorry...please login again to resume!',
            willClose: () => {
              new TempStorage().storeDeepLink(context.root.$route.fullPath)
              authStore.logout()
              context.root.$router.push('/login')
            },
          })
        }
      }
      action()
      intervalId = setInterval(action, 60 * 1000) as unknown as number
    })

    const toggleSidebar = () => {
      if (sidebarStore.showSidebar) {
        sidebarStore.displaySidebar(false)
      }
    }

    return {
      sidebarStore,
      toggleSidebar,
    }
  },
})
