import { SetupContext } from '@vue/composition-api'

export function alertSuccess(context: SetupContext, message: string, timeout: number = 5000) {
  // @ts-ignore
  context.root.$notify({
    type: 'primary',
    verticalAlign: 'top',
    horizontalAlign: 'center',
    message: message,
    timeout: timeout,
  })
}

export function alertFailure(context: SetupContext, message: string, timeout: number = 5000) {
  // @ts-ignore
  context.root.$notify({
    type: 'danger',
    verticalAlign: 'top',
    horizontalAlign: 'center',
    message: message,
    timeout: timeout,
  })
}
