




















































































































































































import { computed, defineComponent } from '@vue/composition-api'
import { useEngineVerificationStore } from '@/store/engineVerification'
import { useSidebarStore } from '@/store/sidebar'
import { UserScope } from '@/util/enums'
import Modal from '@/components/Modal.vue'
import IconArrowRight from '@/components/Icons/IconArrowRight.vue'
import IconPicture from '@/components/Icons/IconPicture.vue'
import IconSandWatch from '@/components/Icons/IconSandWatch.vue'
import IconThumbsUp from '@/components/Icons/IconThumbsUp.vue'
import { Bytes } from '@/modules/formatter'
import { Engine } from '@/util/decorators'
import { EnginesApi } from '@/services/ie-microservice-future-api'

export default defineComponent({
  components: {
    Modal,
    IconArrowRight,
    IconPicture,
    IconSandWatch,
    IconThumbsUp,
  },
  setup(_, context) {
    const sidebarStore = useSidebarStore()
    const engineVerificationStore = useEngineVerificationStore()

    const latestCompleted = computed(() => {
      return engineVerificationStore.stack.completed[0]
    })

    const userScopeClass = computed(() =>
      sidebarStore.current.userScope === UserScope.Partner ? 'indigo' : 'primary'
    )

    const minimize = () => {
      engineVerificationStore.minimize()
    }

    const dismiss = async (engine: Engine) => {
      engineVerificationStore.dismiss(engine.id)

      const engineUpdatable = engine

      engineUpdatable.setup_instructions_dismissed = true

      await new EnginesApi().updateEngine(engine.id, engineUpdatable)

      sidebarStore.updateEngine(engineUpdatable)
      engineVerificationStore.dismiss(engine.id)

      context.root.$emit('documentation-dismissed')
    }

    const dismissFailed = (engine: Engine) => {
      engineVerificationStore.dismiss(engine.id)
    }

    const dismissSetupAssistance = async () => {
      const engineUpdatable = latestCompleted?.value.engine
      const route = context.root.$route
      const router = context.root.$router

      !engineUpdatable.is_verified &&
        window.dataLayer.push({ event: 'onboarding_skip_implementation' })

      if (route.name?.includes('setup-instructions')) {
        route.params.id
          ? router.push({ name: 'customers-id-subscriptions-id-engines-id' })
          : router.push({ name: 'subscriptions-id-engines-id' })
        engineVerificationStore.dismiss(engineUpdatable.id)
        return
      }

      engineUpdatable.setup_instructions_dismissed = true

      await new EnginesApi().updateEngine(engineUpdatable.id, engineUpdatable)

      sidebarStore.updateEngine(engineUpdatable)

      engineVerificationStore.dismiss(engineUpdatable.id)
    }

    return {
      userScopeClass,
      engineVerificationStore,
      minimize,
      dismiss,
      dismissFailed,
      latestCompleted,
      Bytes,
      dismissSetupAssistance,
    }
  },
})
