import {
  DetailedSubscription as BaseDetailedSubscription,
  Engine as BaseEngine,
  EngineWithSettings,
  Origin as BaseOrigin,
  PaymentTypeEnum,
  TrialLink as BaseTrialLink,
  User as BaseUser,
} from '@/services/ie-microservice-openapi'
import { CmsType, FrontendType, SignupType, SubscriptionStatus, UserScope } from '@/util/enums'
import { TempStorage } from '@/util/tempStorage'
import moment, { Moment } from 'moment-timezone'
import { EngineCname, EngineIEAddress } from '@/modules/formatter'
import { siteUrl } from '@/util/helpers'

export interface DetailedSubscription extends BaseDetailedSubscription {
  date_accessed: string
  is_default: boolean
  engines?: Array<Engine>
  origins?: Array<Origin>
  is_basic_tier: boolean
  is_standard_tier: boolean
  is_pro_tier: boolean
  is_payment_method_missing: boolean
  is_trial: boolean
  is_readonly: boolean
  get_days_lifespan: (perspective: UserScope) => number | null
  get_days_elapsed: (now: Moment) => number
  get_days_remaining: (perspective: UserScope, now: Moment) => number | null
}

export interface Engine extends BaseEngine, EngineWithSettings {
  is_favorite: boolean
  is_verified: boolean
  nickname: string
  setup_instructions_dismissed: boolean
  get_cname: string
  get_ie_address: string
}

export interface Origin extends BaseOrigin {
  is_favorite: boolean
  detected_cms: Array<CmsType>
  detected_frontend: Array<FrontendType>
}

export interface TrialLink extends BaseTrialLink {
  get_full_link: (teamId: number) => string
  get_expires_nice: string
  get_expires_date: string
}

export interface User extends BaseUser {
  date_accessed: string
}

export interface UserOnboarding {
  signup_type: SignupType
  detected_cms: Array<CmsType>
  detected_frontend: Array<FrontendType>
  is_skipped: boolean
  is_completed: boolean
  is_technical: boolean
  is_recently_registered: boolean
  website: string
  suggested_origins: Array<string>
}

export const UserOnboardingDefault = {
  signup_type: SignupType.Password,
  detected_cms: [],
  detected_frontend: [],
  is_skipped: false,
  is_completed: false,
  is_technical: false,
  is_recently_registered: false,
  website: '',
  suggested_origins: [],
}

export const decorateDetailedSubscription = function (s: BaseDetailedSubscription) {
  s.engines = s.engines && s.engines.map((e) => decorateEngine(e))
  s.origins = s.origins && s.origins.map((o) => decorateOrigin(o))

  return <DetailedSubscription>{
    ...s,
    date_accessed: new TempStorage().getSubscriptionDateAccessed(s.id) || s.date_started,
    is_default: new TempStorage().getSubscriptionDefault(s.user_id, s.id),
    get is_basic_tier(): boolean {
      return s.payment_plan.endsWith('_BASIC')
    },
    get is_standard_tier(): boolean {
      return s.payment_plan.endsWith('_STANDARD')
    },
    get is_pro_tier(): boolean {
      return s.payment_plan.endsWith('_PRO')
    },
    get is_payment_method_missing(): boolean {
      if (!s.payment_type) {
        return false
      }

      // External payment is never pending
      if (s.payment_type.includes(PaymentTypeEnum.External)) {
        return false
      }

      // Internal accounts are used by ScientiaMobile staff
      if (s.payment_type.includes(PaymentTypeEnum.Internal)) {
        return false
      }

      return (
        s.status === SubscriptionStatus.PendingPaymentInformation ||
        s.status === SubscriptionStatus.Suspended
      )
    },
    get is_trial(): boolean {
      return s.payment_type === PaymentTypeEnum.Trial
    },
    get is_readonly(): boolean {
      if (!s.status) {
        return false
      }

      // return s.status == SubscriptionStatus.Canceled || s.status ==
      // SubscriptionStatus.Suspended
      return s.status == SubscriptionStatus.Canceled
    },
    get get_days_lifespan() {
      return (): number | null => {
        const expires = moment(this.trial_expiration_date)

        if (!expires) {
          return null
        }

        return Math.round(
          Math.abs(
            moment
              .duration(moment(this.date_started).startOf('day').diff(expires.startOf('day')))
              .asDays()
          )
        )
      }
    },
    get get_days_elapsed() {
      return (now: Moment): number => {
        return Math.round(
          Math.abs(
            moment
              .duration(moment(this.date_started).startOf('day').diff(now.startOf('day')))
              .asDays()
          )
        )
      }
    },
    get get_days_remaining() {
      return (perspective: UserScope, now: Moment): number | null => {
        const lifespan = this.get_days_lifespan(perspective)

        if (!lifespan) {
          return null
        }

        return lifespan - this.get_days_elapsed(now)
      }
    },
  }
}

export const decorateEngine = function (e: BaseEngine) {
  return <Engine>{
    ...e,
    is_favorite: new TempStorage().getEngineFavoriteFlag(e.id),
    is_verified: new TempStorage().getEngineVerifiedFlag(e.id),
    nickname: new TempStorage().getEngineNickname(e.id),
    setup_instructions_dismissed: new TempStorage().getEngineSetupInstructionsDismissed(e.id),
    get get_cname() {
      return EngineCname(e.cname)
    },
    get get_ie_address() {
      return EngineIEAddress(e.cname)
    },
  }
}

export const decorateOrigin = function (o: BaseOrigin) {
  return {
    ...o,
    is_favorite: new TempStorage().getOriginFavoriteFlag(o.id),
    detected_cms: new TempStorage().getOriginDetectedCms(o.id),
    detected_frontend: new TempStorage().getOriginDetectedFrontend(o.id),
  } as Origin
}

export const decorateTrialLink = function (l: BaseTrialLink) {
  return {
    ...l,
    get get_full_link() {
      return (teamId: number) => {
        return `${siteUrl}/team/${teamId}/trial/${l.link}`
      }
    },
    get get_expires_nice() {
      const expires = moment.tz(l.valid_until, 'America/New_York')
      const now = moment()

      let seconds = expires.diff(now, 'seconds')

      // Compute the time in the past or future in the positive sense so we can
      // use it like "expires in 4 days" and "expired 4 days ago"
      seconds = Math.abs(seconds)

      let niceDiff = ''
      if (seconds > 90 * 86400) {
        niceDiff = Math.floor(Math.abs(now.diff(expires, 'months'))) + ' months'
      } else if (seconds > 2 * 86400) {
        niceDiff = Math.floor(seconds / 86400) + ' days'
      } else if (seconds >= 7200) {
        niceDiff = Math.floor(seconds / 3600) + ' hours'
      } else if (seconds >= 120) {
        niceDiff = Math.floor(seconds / 60) + ' minutes'
      } else if (seconds >= 60) {
        niceDiff = Math.floor(seconds / 60) + ' minute'
      } else {
        niceDiff = Math.floor(seconds) + ' seconds'
      }

      return niceDiff
    },
    get get_expires_date() {
      const expires = moment.tz(l.valid_until, 'America/New_York')

      return expires.tz(moment.tz.guess()).format('YYYY-MM-DD HH:mm z')
    },
  } as TrialLink
}

export const decorateUser = function (u: BaseUser) {
  return { ...u, date_accessed: new TempStorage().getAccountDateAccessed(u.id) } as User
}
