










import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    viewBox: {
      type: String,
      default: '0 0 17 17',
    },
  },
})
