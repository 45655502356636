import axios, { AxiosRequestConfig } from 'axios'
import { useAuthStore } from './store/auth'
import { logError } from './util/sentry'
//@ts-ignore
import router from './router'

export default function register() {
  axios.defaults.headers.common['Accept'] = 'application/json'
  axios.defaults.headers.post['Content-Type'] = 'application/json'
  axios.defaults.headers.put['Content-Type'] = 'application/json'
  axios.defaults.headers.patch['Content-Type'] = 'application/json'

  axios.interceptors.request.use(
    function (config) {
      setAuthHeader(config)
      return config
    }
    // https://github.com/axios/axios/pull/3734
    // https://github.com/axios/axios/issues/3736
    // { runWhen: isControlApiRequest }
  )

  let refreshTokenPromise: Promise<unknown> | null = null
  axios.interceptors.response.use(
    function (response) {
      return response
    },
    function (err) {
      const auth = useAuthStore()
      const { status, config } = err?.response || { status: 418, config: {} }

      if (status === 403 || status === 404) {
        router.push('/not-found')
      }

      if (status === 401) auth.incrementRefreshes()

      if (status === 401 && auth.recentRefreshes > auth.maxRecentRefreshes) {
        return Promise.reject(err)
      }

      if (!err.response || status !== 401) {
        const errMsg = new Error()
        errMsg.name = `Bad Request with status code ${status}`
        logError(errMsg, { extras: err })
        return Promise.reject(err)
      }

      const header = authHeader()
      if (header && header !== config.headers.Authorization) {
        setAuthHeader(config, header)
        return axios(config)
      }

      if (!refreshTokenPromise) {
        const auth = useAuthStore()
        refreshTokenPromise = auth.hydrateUser().finally(function () {
          refreshTokenPromise = null
        })
      }

      return refreshTokenPromise
        .then(function () {
          setAuthHeader(config, header)
          return axios(config)
        })
        .catch(function () {
          const errMsg = new Error()
          errMsg.name = `Bad Request with status code ${status}`
          logError(errMsg, { extras: err })
          return Promise.reject(err)
        })
    }
    // https://github.com/axios/axios/pull/3734
    // https://github.com/axios/axios/issues/3736
    // { runWhen: isControlApiRequest }
  )

  // function isControlApiRequest(config: AxiosRequestConfig) {
  //   return config.url?.indexOf(controlApiUrl) === 0
  // }

  function authHeader() {
    const auth = useAuthStore()
    const token = auth.getToken
    if (!token) {
      return
    }

    return `Bearer ${token}`
  }

  function setAuthHeader(config: AxiosRequestConfig, header?: string) {
    if (!header) {
      header = authHeader()
      if (!header) {
        return
      }
    }
    if (config.headers) {
      config.headers.Authorization = header
    }
  }
}
