/* tslint:disable */
/* eslint-disable */
/**
 * imageengine-microservice
 * imageengine-microservice REST API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration'
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common'
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base'

/**
 *
 * @export
 * @interface Addons
 */
export interface Addons {
  /**
   *
   * @type {number}
   * @memberof Addons
   */
  engines?: number
  /**
   *
   * @type {number}
   * @memberof Addons
   */
  custom_delivery_addresses?: number
}
/**
 *
 * @export
 * @interface Address
 */
export interface Address {
  /**
   *
   * @type {string}
   * @memberof Address
   */
  address: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  address_2?: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  city: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  state: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  country: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  zip: string
}
/**
 *
 * @export
 * @interface Bill
 */
export interface Bill {
  /**
   *
   * @type {string}
   * @memberof Bill
   */
  paid_at?: string
  /**
   *
   * @type {number}
   * @memberof Bill
   */
  transaction_id?: number
  /**
   *
   * @type {Subscription}
   * @memberof Bill
   */
  subscription?: Subscription
  /**
   *
   * @type {string}
   * @memberof Bill
   */
  start_date?: string
  /**
   *
   * @type {string}
   * @memberof Bill
   */
  end_date?: string
  /**
   *
   * @type {number}
   * @memberof Bill
   */
  start_date_timestamp?: number
  /**
   *
   * @type {number}
   * @memberof Bill
   */
  end_date_timestamp?: number
}
/**
 *
 * @export
 * @interface BillingAddress
 */
export interface BillingAddress {
  /**
   *
   * @type {string}
   * @memberof BillingAddress
   */
  street?: string
  /**
   *
   * @type {string}
   * @memberof BillingAddress
   */
  street2?: string
  /**
   *
   * @type {string}
   * @memberof BillingAddress
   */
  city?: string
  /**
   *
   * @type {string}
   * @memberof BillingAddress
   */
  state?: string
  /**
   *
   * @type {string}
   * @memberof BillingAddress
   */
  postal_code?: string
  /**
   *
   * @type {string}
   * @memberof BillingAddress
   */
  country_id?: string
}
/**
 *
 * @export
 * @interface BillingHistory
 */
export interface BillingHistory {
  /**
   *
   * @type {Array<Transaction>}
   * @memberof BillingHistory
   */
  transactions?: Array<Transaction>
  /**
   *
   * @type {Array<Bill>}
   * @memberof BillingHistory
   */
  bills?: Array<Bill>
}
/**
 *
 * @export
 * @interface BillingHistoryV2
 */
export interface BillingHistoryV2 {
  /**
   *
   * @type {string}
   * @memberof BillingHistoryV2
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof BillingHistoryV2
   */
  subscription_name?: string
  /**
   *
   * @type {string}
   * @memberof BillingHistoryV2
   */
  public_url?: string
  /**
   *
   * @type {number}
   * @memberof BillingHistoryV2
   */
  total_amount?: number
  /**
   *
   * @type {string}
   * @memberof BillingHistoryV2
   */
  issued_at?: string
  /**
   *
   * @type {string}
   * @memberof BillingHistoryV2
   */
  status?: string
  /**
   *
   * @type {Array<CostSplit>}
   * @memberof BillingHistoryV2
   */
  cost_split?: Array<CostSplit>
  /**
   *
   * @type {BillingHistoryV2BillingAddress}
   * @memberof BillingHistoryV2
   */
  billing_address?: BillingHistoryV2BillingAddress
  /**
   *
   * @type {BillingHistoryV2CreditCard}
   * @memberof BillingHistoryV2
   */
  credit_card?: BillingHistoryV2CreditCard
}
/**
 *
 * @export
 * @interface BillingHistoryV2BillingAddress
 */
export interface BillingHistoryV2BillingAddress {
  /**
   *
   * @type {string}
   * @memberof BillingHistoryV2BillingAddress
   */
  state?: string
  /**
   *
   * @type {string}
   * @memberof BillingHistoryV2BillingAddress
   */
  country?: string
  /**
   *
   * @type {string}
   * @memberof BillingHistoryV2BillingAddress
   */
  city?: string
  /**
   *
   * @type {string}
   * @memberof BillingHistoryV2BillingAddress
   */
  address?: string
  /**
   *
   * @type {string}
   * @memberof BillingHistoryV2BillingAddress
   */
  address_2?: string
  /**
   *
   * @type {string}
   * @memberof BillingHistoryV2BillingAddress
   */
  zip?: string
}
/**
 *
 * @export
 * @interface BillingHistoryV2CreditCard
 */
export interface BillingHistoryV2CreditCard {
  /**
   *
   * @type {string}
   * @memberof BillingHistoryV2CreditCard
   */
  masked_number?: string
  /**
   *
   * @type {string}
   * @memberof BillingHistoryV2CreditCard
   */
  type?: string
}
/**
 *
 * @export
 * @interface BillingInfoUpdate
 */
export interface BillingInfoUpdate {
  /**
   *
   * @type {string}
   * @memberof BillingInfoUpdate
   */
  street?: string
  /**
   *
   * @type {string}
   * @memberof BillingInfoUpdate
   */
  street2?: string
  /**
   *
   * @type {string}
   * @memberof BillingInfoUpdate
   */
  city?: string
  /**
   *
   * @type {string}
   * @memberof BillingInfoUpdate
   */
  state?: string
  /**
   *
   * @type {string}
   * @memberof BillingInfoUpdate
   */
  postal_code?: string
  /**
   *
   * @type {string}
   * @memberof BillingInfoUpdate
   */
  country_id?: string
  /**
   *
   * @type {string}
   * @memberof BillingInfoUpdate
   */
  nonce?: string
}
/**
 *
 * @export
 * @interface BillingInfoUpdateAllOf
 */
export interface BillingInfoUpdateAllOf {
  /**
   *
   * @type {string}
   * @memberof BillingInfoUpdateAllOf
   */
  nonce?: string
}
/**
 *
 * @export
 * @interface BillingInfoV2
 */
export interface BillingInfoV2 {
  /**
   *
   * @type {string}
   * @memberof BillingInfoV2
   */
  name?: string
  /**
   *
   * @type {BillingInfoV2Address}
   * @memberof BillingInfoV2
   */
  address?: BillingInfoV2Address
  /**
   *
   * @type {BillingInfoV2CreditCard}
   * @memberof BillingInfoV2
   */
  credit_card?: BillingInfoV2CreditCard
}
/**
 *
 * @export
 * @interface BillingInfoV2Address
 */
export interface BillingInfoV2Address {
  /**
   *
   * @type {string}
   * @memberof BillingInfoV2Address
   */
  address: string
  /**
   *
   * @type {string}
   * @memberof BillingInfoV2Address
   */
  country: string
  /**
   *
   * @type {string}
   * @memberof BillingInfoV2Address
   */
  state: string
  /**
   *
   * @type {string}
   * @memberof BillingInfoV2Address
   */
  city: string
  /**
   *
   * @type {string}
   * @memberof BillingInfoV2Address
   */
  zip: string
}
/**
 *
 * @export
 * @interface BillingInfoV2CreditCard
 */
export interface BillingInfoV2CreditCard {
  /**
   *
   * @type {string}
   * @memberof BillingInfoV2CreditCard
   */
  masked_number?: string
  /**
   *
   * @type {string}
   * @memberof BillingInfoV2CreditCard
   */
  type?: string
  /**
   *
   * @type {BillingInfoV2CreditCardHolder}
   * @memberof BillingInfoV2CreditCard
   */
  holder?: BillingInfoV2CreditCardHolder
}
/**
 *
 * @export
 * @interface BillingInfoV2CreditCardHolder
 */
export interface BillingInfoV2CreditCardHolder {
  /**
   *
   * @type {string}
   * @memberof BillingInfoV2CreditCardHolder
   */
  first_name?: string
  /**
   *
   * @type {string}
   * @memberof BillingInfoV2CreditCardHolder
   */
  last_name?: string
}
/**
 *
 * @export
 * @interface BillingInformation
 */
export interface BillingInformation {
  /**
   *
   * @type {string}
   * @memberof BillingInformation
   */
  first_name?: string
  /**
   *
   * @type {string}
   * @memberof BillingInformation
   */
  last_name?: string
  /**
   *
   * @type {string}
   * @memberof BillingInformation
   */
  company?: string
  /**
   *
   * @type {BillingAddress}
   * @memberof BillingInformation
   */
  address?: BillingAddress
  /**
   *
   * @type {string}
   * @memberof BillingInformation
   */
  phone?: string
  /**
   *
   * @type {string}
   * @memberof BillingInformation
   */
  email?: string
  /**
   *
   * @type {string}
   * @memberof BillingInformation
   */
  reference?: string
  /**
   *
   * @type {string}
   * @memberof BillingInformation
   */
  nonce?: string
  /**
   *
   * @type {CreditCardDetails}
   * @memberof BillingInformation
   */
  credit_card_details?: CreditCardDetails
}
/**
 *
 * @export
 * @interface BillingInformationCreatable
 */
export interface BillingInformationCreatable {
  /**
   *
   * @type {string}
   * @memberof BillingInformationCreatable
   */
  first_name: string
  /**
   *
   * @type {string}
   * @memberof BillingInformationCreatable
   */
  last_name: string
  /**
   *
   * @type {string}
   * @memberof BillingInformationCreatable
   */
  email: string
  /**
   *
   * @type {string}
   * @memberof BillingInformationCreatable
   */
  organization?: string
  /**
   *
   * @type {Address}
   * @memberof BillingInformationCreatable
   */
  shipping_address?: Address
  /**
   *
   * @type {PaymentPlanEnum}
   * @memberof BillingInformationCreatable
   */
  payment_plan?: PaymentPlanEnum
  /**
   *
   * @type {string}
   * @memberof BillingInformationCreatable
   */
  frequency: BillingInformationCreatableFrequencyEnum
  /**
   *
   * @type {string}
   * @memberof BillingInformationCreatable
   */
  payment_token: string
}

/**
 * @export
 * @enum {string}
 */
export enum BillingInformationCreatableFrequencyEnum {
  Monthly = 'monthly',
  Annual = 'annual',
}

/**
 *
 * @export
 * @interface BillingInformationV2
 */
export interface BillingInformationV2 {
  /**
   *
   * @type {string}
   * @memberof BillingInformationV2
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof BillingInformationV2
   */
  billing_system?: string
  /**
   *
   * @type {BillingInformationV2Coreapi}
   * @memberof BillingInformationV2
   */
  coreapi?: BillingInformationV2Coreapi
  /**
   *
   * @type {BillingInformationV2Product}
   * @memberof BillingInformationV2
   */
  product?: BillingInformationV2Product
  /**
   *
   * @type {BillingInformationV2Customer}
   * @memberof BillingInformationV2
   */
  customer?: BillingInformationV2Customer
  /**
   *
   * @type {BillingInfoV2}
   * @memberof BillingInformationV2
   */
  billing?: BillingInfoV2
}
/**
 *
 * @export
 * @interface BillingInformationV2Coreapi
 */
export interface BillingInformationV2Coreapi {
  /**
   *
   * @type {number}
   * @memberof BillingInformationV2Coreapi
   */
  reference?: number
  /**
   *
   * @type {string}
   * @memberof BillingInformationV2Coreapi
   */
  reference_type?: string
  /**
   *
   * @type {number}
   * @memberof BillingInformationV2Coreapi
   */
  user_id?: number
}
/**
 *
 * @export
 * @interface BillingInformationV2Customer
 */
export interface BillingInformationV2Customer {
  /**
   *
   * @type {string}
   * @memberof BillingInformationV2Customer
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof BillingInformationV2Customer
   */
  first_name?: string
  /**
   *
   * @type {string}
   * @memberof BillingInformationV2Customer
   */
  last_name?: string
  /**
   *
   * @type {string}
   * @memberof BillingInformationV2Customer
   */
  email?: string
  /**
   *
   * @type {string}
   * @memberof BillingInformationV2Customer
   */
  organization?: string
}
/**
 *
 * @export
 * @interface BillingInformationV2Product
 */
export interface BillingInformationV2Product {
  /**
   *
   * @type {string}
   * @memberof BillingInformationV2Product
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof BillingInformationV2Product
   */
  billing_frequency?: string
  /**
   *
   * @type {string}
   * @memberof BillingInformationV2Product
   */
  tier?: string
}
/**
 *
 * @export
 * @interface BillingPeriod
 */
export interface BillingPeriod {
  /**
   *
   * @type {string}
   * @memberof BillingPeriod
   */
  start_date?: string
  /**
   *
   * @type {string}
   * @memberof BillingPeriod
   */
  end_date?: string
}
/**
 *
 * @export
 * @interface ByteSavings
 */
export interface ByteSavings {
  /**
   *
   * @type {number}
   * @memberof ByteSavings
   */
  original?: number
  /**
   *
   * @type {number}
   * @memberof ByteSavings
   */
  saved?: number
  /**
   *
   * @type {number}
   * @memberof ByteSavings
   */
  final?: number
}
/**
 *
 * @export
 * @interface CacheHitRatio
 */
export interface CacheHitRatio {
  /**
   *
   * @type {number}
   * @memberof CacheHitRatio
   */
  hits?: number
  /**
   *
   * @type {number}
   * @memberof CacheHitRatio
   */
  misses?: number
  /**
   *
   * @type {string}
   * @memberof CacheHitRatio
   */
  hit_percentage?: string
  /**
   *
   * @type {string}
   * @memberof CacheHitRatio
   */
  miss_percentage?: string
}
/**
 *
 * @export
 * @interface CacheHitRatioOverTime
 */
export interface CacheHitRatioOverTime {
  /**
   *
   * @type {string}
   * @memberof CacheHitRatioOverTime
   */
  timestamp?: string
  /**
   *
   * @type {number}
   * @memberof CacheHitRatioOverTime
   */
  hits?: number
  /**
   *
   * @type {number}
   * @memberof CacheHitRatioOverTime
   */
  misses?: number
  /**
   *
   * @type {string}
   * @memberof CacheHitRatioOverTime
   */
  hit_percentage?: string
  /**
   *
   * @type {string}
   * @memberof CacheHitRatioOverTime
   */
  miss_percentage?: string
}
/**
 *
 * @export
 * @interface CachePurgeRequest
 */
export interface CachePurgeRequest {
  /**
   *
   * @type {Array<Engine>}
   * @memberof CachePurgeRequest
   */
  engines?: Array<Engine>
  /**
   *
   * @type {string}
   * @memberof CachePurgeRequest
   */
  origin?: string
  /**
   *
   * @type {string}
   * @memberof CachePurgeRequest
   */
  expression?: string
  /**
   *
   * @type {string}
   * @memberof CachePurgeRequest
   */
  status?: string
  /**
   *
   * @type {number}
   * @memberof CachePurgeRequest
   */
  created?: number
}
/**
 *
 * @export
 * @interface Certificate
 */
export interface Certificate {
  /**
   *
   * @type {string}
   * @memberof Certificate
   */
  arn?: string
  /**
   *
   * @type {string}
   * @memberof Certificate
   */
  region?: string
  /**
   *
   * @type {string}
   * @memberof Certificate
   */
  domain_name?: string
  /**
   *
   * @type {string}
   * @memberof Certificate
   */
  status?: CertificateStatusEnum
  /**
   *
   * @type {string}
   * @memberof Certificate
   */
  load_balancer_arn?: string
  /**
   *
   * @type {number}
   * @memberof Certificate
   */
  subscription_id?: number
  /**
   *
   * @type {Array<DNSRecord>}
   * @memberof Certificate
   */
  validation_records?: Array<DNSRecord>
}

/**
 * @export
 * @enum {string}
 */
export enum CertificateStatusEnum {
  Issued = 'issued',
  PendingValidation = 'pending_validation',
  RestartValidation = 'restart_validation',
  Failed = 'failed',
  Expired = 'expired',
}

/**
 *
 * @export
 * @interface CostSplit
 */
export interface CostSplit {
  /**
   *
   * @type {string}
   * @memberof CostSplit
   */
  description?: string
  /**
   *
   * @type {number}
   * @memberof CostSplit
   */
  amount?: number
}
/**
 *
 * @export
 * @interface Country
 */
export interface Country {
  /**
   *
   * @type {number}
   * @memberof Country
   */
  country_id?: number
  /**
   *
   * @type {string}
   * @memberof Country
   */
  code?: string
  /**
   *
   * @type {string}
   * @memberof Country
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof Country
   */
  full_name?: string
  /**
   *
   * @type {string}
   * @memberof Country
   */
  iso3?: string
  /**
   *
   * @type {number}
   * @memberof Country
   */
  number?: number
  /**
   *
   * @type {string}
   * @memberof Country
   */
  continent_code?: string
  /**
   *
   * @type {number}
   * @memberof Country
   */
  display_order?: number
}
/**
 *
 * @export
 * @interface CreditCardDetails
 */
export interface CreditCardDetails {
  /**
   *
   * @type {string}
   * @memberof CreditCardDetails
   */
  masked_number?: string
  /**
   *
   * @type {string}
   * @memberof CreditCardDetails
   */
  card_type?: string
  /**
   *
   * @type {string}
   * @memberof CreditCardDetails
   */
  cardholder_name?: string
  /**
   *
   * @type {string}
   * @memberof CreditCardDetails
   */
  image_url?: string
}
/**
 *
 * @export
 * @interface CustomerSearchResult
 */
export interface CustomerSearchResult {
  /**
   *
   * @type {Array<CustomerSearchResultUsers>}
   * @memberof CustomerSearchResult
   */
  users?: Array<CustomerSearchResultUsers>
  /**
   *
   * @type {Array<CustomerSearchResultSubscriptions>}
   * @memberof CustomerSearchResult
   */
  subscriptions?: Array<CustomerSearchResultSubscriptions>
  /**
   *
   * @type {Array<CustomerSearchResultEngines>}
   * @memberof CustomerSearchResult
   */
  engines?: Array<CustomerSearchResultEngines>
  /**
   *
   * @type {Array<CustomerSearchResultOrigins>}
   * @memberof CustomerSearchResult
   */
  origins?: Array<CustomerSearchResultOrigins>
}
/**
 *
 * @export
 * @interface CustomerSearchResultEngines
 */
export interface CustomerSearchResultEngines {
  /**
   *
   * @type {number}
   * @memberof CustomerSearchResultEngines
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof CustomerSearchResultEngines
   */
  cname?: string
  /**
   *
   * @type {number}
   * @memberof CustomerSearchResultEngines
   */
  subscription_id?: number
}
/**
 *
 * @export
 * @interface CustomerSearchResultOrigins
 */
export interface CustomerSearchResultOrigins {
  /**
   *
   * @type {number}
   * @memberof CustomerSearchResultOrigins
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof CustomerSearchResultOrigins
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof CustomerSearchResultOrigins
   */
  url?: string
  /**
   *
   * @type {number}
   * @memberof CustomerSearchResultOrigins
   */
  subscription_id?: number
}
/**
 *
 * @export
 * @interface CustomerSearchResultSubscriptions
 */
export interface CustomerSearchResultSubscriptions {
  /**
   *
   * @type {number}
   * @memberof CustomerSearchResultSubscriptions
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof CustomerSearchResultSubscriptions
   */
  account_name?: string
  /**
   *
   * @type {number}
   * @memberof CustomerSearchResultSubscriptions
   */
  user_id?: number
}
/**
 *
 * @export
 * @interface CustomerSearchResultUsers
 */
export interface CustomerSearchResultUsers {
  /**
   *
   * @type {number}
   * @memberof CustomerSearchResultUsers
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof CustomerSearchResultUsers
   */
  first_name?: string
  /**
   *
   * @type {string}
   * @memberof CustomerSearchResultUsers
   */
  middle_name?: string
  /**
   *
   * @type {string}
   * @memberof CustomerSearchResultUsers
   */
  last_name?: string
  /**
   *
   * @type {string}
   * @memberof CustomerSearchResultUsers
   */
  company_name?: string
  /**
   *
   * @type {number}
   * @memberof CustomerSearchResultUsers
   */
  team_id?: number
  /**
   *
   * @type {number}
   * @memberof CustomerSearchResultUsers
   */
  reseller_team_id?: number
}
/**
 *
 * @export
 * @interface DNSRecord
 */
export interface DNSRecord {
  /**
   *
   * @type {string}
   * @memberof DNSRecord
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof DNSRecord
   */
  value?: string
  /**
   *
   * @type {string}
   * @memberof DNSRecord
   */
  type?: string
}
/**
 *
 * @export
 * @interface DNSStatus
 */
export interface DNSStatus {
  /**
   *
   * @type {string}
   * @memberof DNSStatus
   */
  status?: DNSStatusStatusEnum
  /**
   *
   * @type {boolean}
   * @memberof DNSStatus
   */
  global_accelerator?: boolean
  /**
   *
   * @type {DNSRecord}
   * @memberof DNSStatus
   */
  customer_record?: DNSRecord
  /**
   *
   * @type {DNSRecord}
   * @memberof DNSStatus
   */
  customer_required_record?: DNSRecord
  /**
   *
   * @type {Array<DNSRecord>}
   * @memberof DNSStatus
   */
  provider_records?: Array<DNSRecord>
}

/**
 * @export
 * @enum {string}
 */
export enum DNSStatusStatusEnum {
  CustomerRecordMissing = 'customer_record_missing',
  CustomerRecordIncorrect = 'customer_record_incorrect',
  ProviderRecordMissing = 'provider_record_missing',
  ProviderRecordIncorrect = 'provider_record_incorrect',
  TooManyProviderRecords = 'too_many_provider_records',
  Configured = 'configured',
  InternalServerError = 'internal_server_error',
}

/**
 *
 * @export
 * @interface DataBucket
 */
export interface DataBucket {
  /**
   *
   * @type {string}
   * @memberof DataBucket
   */
  timestamp?: string
  /**
   *
   * @type {number}
   * @memberof DataBucket
   */
  non_image_bytes?: number
  /**
   *
   * @type {ByteSavings}
   * @memberof DataBucket
   */
  bytes?: ByteSavings
}
/**
 *
 * @export
 * @interface DetailedSubscription
 */
export interface DetailedSubscription {
  /**
   *
   * @type {number}
   * @memberof DetailedSubscription
   */
  id: number
  /**
   *
   * @type {number}
   * @memberof DetailedSubscription
   */
  user_id: number
  /**
   *
   * @type {string}
   * @memberof DetailedSubscription
   */
  account_name: string
  /**
   *
   * @type {number}
   * @memberof DetailedSubscription
   */
  tier_id: number
  /**
   *
   * @type {string}
   * @memberof DetailedSubscription
   */
  date_started: string
  /**
   *
   * @type {string}
   * @memberof DetailedSubscription
   */
  date_expiration: string
  /**
   *
   * @type {string}
   * @memberof DetailedSubscription
   */
  type: string
  /**
   *
   * @type {string}
   * @memberof DetailedSubscription
   */
  status: string
  /**
   *
   * @type {string}
   * @memberof DetailedSubscription
   */
  date_trial_end: string
  /**
   *
   * @type {string}
   * @memberof DetailedSubscription
   */
  trial_expiration_date?: string
  /**
   *
   * @type {string}
   * @memberof DetailedSubscription
   */
  billing_system?: DetailedSubscriptionBillingSystemEnum
  /**
   *
   * @type {string}
   * @memberof DetailedSubscription
   */
  payment_plan: string
  /**
   *
   * @type {PaymentTypeEnum}
   * @memberof DetailedSubscription
   */
  payment_type: PaymentTypeEnum
  /**
   *
   * @type {Tier}
   * @memberof DetailedSubscription
   */
  tier?: Tier
  /**
   *
   * @type {Array<Engine>}
   * @memberof DetailedSubscription
   */
  engines?: Array<Engine>
  /**
   *
   * @type {Array<Origin>}
   * @memberof DetailedSubscription
   */
  origins?: Array<Origin>
  /**
   *
   * @type {Array<string>}
   * @memberof DetailedSubscription
   */
  wurfl_tokens?: Array<string>
}

/**
 * @export
 * @enum {string}
 */
export enum DetailedSubscriptionBillingSystemEnum {
  Maxio = 'maxio',
  Braintree = 'braintree',
}

/**
 *
 * @export
 * @interface DetailedSubscriptionAllOf
 */
export interface DetailedSubscriptionAllOf {
  /**
   *
   * @type {Tier}
   * @memberof DetailedSubscriptionAllOf
   */
  tier?: Tier
  /**
   *
   * @type {Array<Engine>}
   * @memberof DetailedSubscriptionAllOf
   */
  engines?: Array<Engine>
  /**
   *
   * @type {Array<Origin>}
   * @memberof DetailedSubscriptionAllOf
   */
  origins?: Array<Origin>
  /**
   *
   * @type {Array<string>}
   * @memberof DetailedSubscriptionAllOf
   */
  wurfl_tokens?: Array<string>
}
/**
 *
 * @export
 * @interface Engine
 */
export interface Engine {
  /**
   *
   * @type {number}
   * @memberof Engine
   */
  id: number
  /**
   *
   * @type {number}
   * @memberof Engine
   */
  subscription_id: number
  /**
   *
   * @type {string}
   * @memberof Engine
   */
  cname: string
  /**
   *
   * @type {number}
   * @memberof Engine
   */
  origin_id: number
  /**
   *
   * @type {Origin}
   * @memberof Engine
   */
  origin?: Origin
  /**
   *
   * @type {string}
   * @memberof Engine
   */
  created_at: string
  /**
   *
   * @type {string}
   * @memberof Engine
   */
  updated_at: string
  /**
   *
   * @type {number}
   * @memberof Engine
   */
  iam_flag: number
  /**
   *
   * @type {number}
   * @memberof Engine
   */
  ie_only_flag?: number
  /**
   *
   * @type {number}
   * @memberof Engine
   */
  allow_origin_prefix?: number
  /**
   *
   * @type {number}
   * @memberof Engine
   */
  custom_wildcard_flag?: number
  /**
   *
   * @type {number}
   * @memberof Engine
   */
  transition_time?: number
  /**
   *
   * @type {boolean}
   * @memberof Engine
   */
  cors_enabled?: boolean
  /**
   *
   * @type {number}
   * @memberof Engine
   */
  cors_max_age?: number
  /**
   *
   * @type {string}
   * @memberof Engine
   */
  cors_allowed_methods?: string
  /**
   *
   * @type {Array<string>}
   * @memberof Engine
   */
  cors_allowed_headers?: Array<string>
  /**
   *
   * @type {Array<string>}
   * @memberof Engine
   */
  cors_allowed_origins?: Array<string>
}
/**
 * @type EngineCreatable
 * @export
 */
export type EngineCreatable = any

/**
 *
 * @export
 * @interface EnginePreference
 */
export interface EnginePreference {
  /**
   *
   * @type {number}
   * @memberof EnginePreference
   */
  id: number
  /**
   *
   * @type {number}
   * @memberof EnginePreference
   */
  engine_id: number
  /**
   *
   * @type {string}
   * @memberof EnginePreference
   */
  key: string
  /**
   *
   * @type {string}
   * @memberof EnginePreference
   */
  value: string
}
/**
 *
 * @export
 * @interface EngineSetting
 */
export interface EngineSetting {
  /**
   *
   * @type {number}
   * @memberof EngineSetting
   */
  id?: number
  /**
   *
   * @type {number}
   * @memberof EngineSetting
   */
  engine_id?: number
  /**
   *
   * @type {string}
   * @memberof EngineSetting
   */
  path?: string
  /**
   *
   * @type {string}
   * @memberof EngineSetting
   */
  setting_name?: string
  /**
   *
   * @type {string}
   * @memberof EngineSetting
   */
  created_at?: string
  /**
   *
   * @type {string}
   * @memberof EngineSetting
   */
  updated_at?: string
  /**
   *
   * @type {Array<PathSetting>}
   * @memberof EngineSetting
   */
  path_settings?: Array<PathSetting>
}
/**
 *
 * @export
 * @interface EngineSettingCreatable
 */
export interface EngineSettingCreatable {
  /**
   *
   * @type {string}
   * @memberof EngineSettingCreatable
   */
  path?: string
  /**
   *
   * @type {string}
   * @memberof EngineSettingCreatable
   */
  setting_name?: string
  /**
   *
   * @type {Array<PathSettingCreatable>}
   * @memberof EngineSettingCreatable
   */
  path_settings?: Array<PathSettingCreatable>
}
/**
 *
 * @export
 * @interface EngineSettingUpdatable
 */
export interface EngineSettingUpdatable {
  /**
   *
   * @type {string}
   * @memberof EngineSettingUpdatable
   */
  path?: string
  /**
   *
   * @type {string}
   * @memberof EngineSettingUpdatable
   */
  setting_name?: string
  /**
   *
   * @type {Array<PathSettingUpdatable>}
   * @memberof EngineSettingUpdatable
   */
  path_settings?: Array<PathSettingUpdatable>
}
/**
 *
 * @export
 * @interface EngineStatus
 */
export interface EngineStatus {
  /**
   *
   * @type {SSLStatus}
   * @memberof EngineStatus
   */
  ssl?: SSLStatus
  /**
   *
   * @type {DNSStatus}
   * @memberof EngineStatus
   */
  dns?: DNSStatus
}
/**
 * @type EngineUpdatable
 * @export
 */
export type EngineUpdatable = any

/**
 *
 * @export
 * @interface EngineWithSettings
 */
export interface EngineWithSettings {
  /**
   *
   * @type {number}
   * @memberof EngineWithSettings
   */
  id: number
  /**
   *
   * @type {number}
   * @memberof EngineWithSettings
   */
  subscription_id: number
  /**
   *
   * @type {string}
   * @memberof EngineWithSettings
   */
  cname: string
  /**
   *
   * @type {number}
   * @memberof EngineWithSettings
   */
  origin_id: number
  /**
   *
   * @type {Origin}
   * @memberof EngineWithSettings
   */
  origin?: Origin
  /**
   *
   * @type {string}
   * @memberof EngineWithSettings
   */
  created_at: string
  /**
   *
   * @type {string}
   * @memberof EngineWithSettings
   */
  updated_at: string
  /**
   *
   * @type {number}
   * @memberof EngineWithSettings
   */
  iam_flag: number
  /**
   *
   * @type {number}
   * @memberof EngineWithSettings
   */
  ie_only_flag?: number
  /**
   *
   * @type {number}
   * @memberof EngineWithSettings
   */
  allow_origin_prefix?: number
  /**
   *
   * @type {number}
   * @memberof EngineWithSettings
   */
  custom_wildcard_flag?: number
  /**
   *
   * @type {number}
   * @memberof EngineWithSettings
   */
  transition_time?: number
  /**
   *
   * @type {boolean}
   * @memberof EngineWithSettings
   */
  cors_enabled?: boolean
  /**
   *
   * @type {number}
   * @memberof EngineWithSettings
   */
  cors_max_age?: number
  /**
   *
   * @type {string}
   * @memberof EngineWithSettings
   */
  cors_allowed_methods?: string
  /**
   *
   * @type {Array<string>}
   * @memberof EngineWithSettings
   */
  cors_allowed_headers?: Array<string>
  /**
   *
   * @type {Array<string>}
   * @memberof EngineWithSettings
   */
  cors_allowed_origins?: Array<string>
  /**
   *
   * @type {Array<EngineSetting>}
   * @memberof EngineWithSettings
   */
  settings?: Array<EngineSetting>
  /**
   *
   * @type {Array<string>}
   * @memberof EngineWithSettings
   */
  allowed_prefixes?: Array<string>
}
/**
 *
 * @export
 * @interface EngineWithSettingsAllOf
 */
export interface EngineWithSettingsAllOf {
  /**
   *
   * @type {Array<EngineSetting>}
   * @memberof EngineWithSettingsAllOf
   */
  settings?: Array<EngineSetting>
  /**
   *
   * @type {Array<string>}
   * @memberof EngineWithSettingsAllOf
   */
  allowed_prefixes?: Array<string>
}
/**
 *
 * @export
 * @interface GenericResponse
 */
export interface GenericResponse {
  /**
   *
   * @type {boolean}
   * @memberof GenericResponse
   */
  success?: boolean
  /**
   *
   * @type {string}
   * @memberof GenericResponse
   */
  message?: string
  /**
   *
   * @type {object}
   * @memberof GenericResponse
   */
  errors?: object
}
/**
 *
 * @export
 * @interface GeoIPLookup
 */
export interface GeoIPLookup {
  /**
   *
   * @type {boolean}
   * @memberof GeoIPLookup
   */
  success?: boolean
  /**
   *
   * @type {Country}
   * @memberof GeoIPLookup
   */
  country?: Country
}
/**
 *
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
  /**
   *
   * @type {string}
   * @memberof InlineObject
   */
  reset_hash?: string
  /**
   *
   * @type {string}
   * @memberof InlineObject
   */
  new_password: string
}
/**
 *
 * @export
 * @interface InlineObject1
 */
export interface InlineObject1 {
  /**
   *
   * @type {number}
   * @memberof InlineObject1
   */
  subscription_id: number
  /**
   *
   * @type {number}
   * @memberof InlineObject1
   */
  user_id: number
  /**
   *
   * @type {string}
   * @memberof InlineObject1
   */
  website: string
  /**
   *
   * @type {string}
   * @memberof InlineObject1
   */
  demo_id: string
  /**
   *
   * @type {string}
   * @memberof InlineObject1
   */
  current_cms?: string
  /**
   *
   * @type {string}
   * @memberof InlineObject1
   */
  delivery_address: string
  /**
   *
   * @type {string}
   * @memberof InlineObject1
   */
  country: string
}
/**
 *
 * @export
 * @interface InlineObject2
 */
export interface InlineObject2 {
  /**
   *
   * @type {string}
   * @memberof InlineObject2
   */
  payment_token: string
}
/**
 *
 * @export
 * @interface InlineObject3
 */
export interface InlineObject3 {
  /**
   *
   * @type {string}
   * @memberof InlineObject3
   */
  new_plan?: string
}
/**
 *
 * @export
 * @interface InlineObject4
 */
export interface InlineObject4 {
  /**
   *
   * @type {PaymentPlanEnum}
   * @memberof InlineObject4
   */
  new_payment_plan: PaymentPlanEnum
}
/**
 *
 * @export
 * @interface InlineObject5
 */
export interface InlineObject5 {
  /**
   *
   * @type {string}
   * @memberof InlineObject5
   */
  email: string
}
/**
 *
 * @export
 * @interface InlineObject6
 */
export interface InlineObject6 {
  /**
   *
   * @type {string}
   * @memberof InlineObject6
   */
  email: string
}
/**
 *
 * @export
 * @interface InlineObject7
 */
export interface InlineObject7 {
  /**
   *
   * @type {number}
   * @memberof InlineObject7
   */
  subscription_id: number
}
/**
 *
 * @export
 * @interface InlineObject8
 */
export interface InlineObject8 {
  /**
   *
   * @type {number}
   * @memberof InlineObject8
   */
  engine_id: number
  /**
   *
   * @type {string}
   * @memberof InlineObject8
   */
  expression: string
}
/**
 *
 * @export
 * @interface InlineObject9
 */
export interface InlineObject9 {
  /**
   *
   * @type {string}
   * @memberof InlineObject9
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof InlineObject9
   */
  companyEmail: string
  /**
   *
   * @type {string}
   * @memberof InlineObject9
   */
  phoneNumber: string
  /**
   *
   * @type {string}
   * @memberof InlineObject9
   */
  companySize: string
  /**
   *
   * @type {string}
   * @memberof InlineObject9
   */
  companyHeadquarters: string
  /**
   *
   * @type {string}
   * @memberof InlineObject9
   */
  discuss: string
  /**
   *
   * @type {boolean}
   * @memberof InlineObject9
   */
  isPartner?: boolean
}
/**
 *
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
  /**
   *
   * @type {QueryMetadata}
   * @memberof InlineResponse200
   */
  metadata?: QueryMetadata
  /**
   *
   * @type {CacheHitRatio}
   * @memberof InlineResponse200
   */
  cache_hit_ratio?: CacheHitRatio
}
/**
 *
 * @export
 * @interface InlineResponse2001
 */
export interface InlineResponse2001 {
  /**
   *
   * @type {QueryMetadata}
   * @memberof InlineResponse2001
   */
  metadata?: QueryMetadata
  /**
   *
   * @type {Array<CacheHitRatioOverTime>}
   * @memberof InlineResponse2001
   */
  cache_hit_ratio_over_time?: Array<CacheHitRatioOverTime>
}
/**
 *
 * @export
 * @interface InlineResponse20010
 */
export interface InlineResponse20010 {
  /**
   *
   * @type {QueryMetadata}
   * @memberof InlineResponse20010
   */
  metadata: QueryMetadata
  /**
   *
   * @type {TotalUsage}
   * @memberof InlineResponse20010
   */
  total_usage: TotalUsage
}
/**
 *
 * @export
 * @interface InlineResponse20011
 */
export interface InlineResponse20011 {
  /**
   *
   * @type {QueryMetadata}
   * @memberof InlineResponse20011
   */
  metadata?: QueryMetadata
  /**
   *
   * @type {Array<TotalUsageBucket>}
   * @memberof InlineResponse20011
   */
  total_usage_over_time?: Array<TotalUsageBucket>
}
/**
 *
 * @export
 * @interface InlineResponse20012
 */
export interface InlineResponse20012 {
  /**
   *
   * @type {QueryMetadata}
   * @memberof InlineResponse20012
   */
  metadata?: QueryMetadata
  /**
   *
   * @type {UserTotalUsage}
   * @memberof InlineResponse20012
   */
  total_usage?: UserTotalUsage
}
/**
 *
 * @export
 * @interface InlineResponse20013
 */
export interface InlineResponse20013 {
  /**
   *
   * @type {QueryMetadata}
   * @memberof InlineResponse20013
   */
  metadata?: QueryMetadata
  /**
   *
   * @type {object}
   * @memberof InlineResponse20013
   */
  total_usage_over_time?: object
}
/**
 *
 * @export
 * @interface InlineResponse20014
 */
export interface InlineResponse20014 {
  /**
   *
   * @type {string}
   * @memberof InlineResponse20014
   */
  url?: string
}
/**
 *
 * @export
 * @interface InlineResponse2002
 */
export interface InlineResponse2002 {
  /**
   *
   * @type {QueryMetadata}
   * @memberof InlineResponse2002
   */
  metadata?: QueryMetadata
  /**
   *
   * @type {Array<DataBucket>}
   * @memberof InlineResponse2002
   */
  data_over_time?: Array<DataBucket>
}
/**
 *
 * @export
 * @interface InlineResponse2003
 */
export interface InlineResponse2003 {
  /**
   *
   * @type {QueryMetadata}
   * @memberof InlineResponse2003
   */
  metadata?: QueryMetadata
  /**
   *
   * @type {Array<RequestsBucket>}
   * @memberof InlineResponse2003
   */
  requests_over_time?: Array<RequestsBucket>
}
/**
 *
 * @export
 * @interface InlineResponse2004
 */
export interface InlineResponse2004 {
  /**
   *
   * @type {QueryMetadata}
   * @memberof InlineResponse2004
   */
  metadata?: QueryMetadata
  /**
   *
   * @type {object}
   * @memberof InlineResponse2004
   */
  bytes_by_image_format?: object
}
/**
 *
 * @export
 * @interface InlineResponse2005
 */
export interface InlineResponse2005 {
  /**
   *
   * @type {QueryMetadata}
   * @memberof InlineResponse2005
   */
  metadata?: QueryMetadata
  /**
   *
   * @type {object}
   * @memberof InlineResponse2005
   */
  requests_by_country_code?: object
}
/**
 *
 * @export
 * @interface InlineResponse2006
 */
export interface InlineResponse2006 {
  /**
   *
   * @type {QueryMetadata}
   * @memberof InlineResponse2006
   */
  metadata?: QueryMetadata
  /**
   *
   * @type {Array<RequestedItem>}
   * @memberof InlineResponse2006
   */
  top_missed?: Array<RequestedItem>
}
/**
 *
 * @export
 * @interface InlineResponse2007
 */
export interface InlineResponse2007 {
  /**
   *
   * @type {QueryMetadata}
   * @memberof InlineResponse2007
   */
  metadata?: QueryMetadata
  /**
   *
   * @type {Array<RequestedItem>}
   * @memberof InlineResponse2007
   */
  top_requested?: Array<RequestedItem>
}
/**
 *
 * @export
 * @interface InlineResponse2008
 */
export interface InlineResponse2008 {
  /**
   *
   * @type {QueryMetadata}
   * @memberof InlineResponse2008
   */
  metadata?: QueryMetadata
  /**
   *
   * @type {UsageTrends}
   * @memberof InlineResponse2008
   */
  usage_trends?: UsageTrends
}
/**
 *
 * @export
 * @interface InlineResponse2009
 */
export interface InlineResponse2009 {
  /**
   *
   * @type {QueryMetadata}
   * @memberof InlineResponse2009
   */
  metadata?: QueryMetadata
  /**
   *
   * @type {UsageTrendsOverTime}
   * @memberof InlineResponse2009
   */
  usage_trends_over_time?: UsageTrendsOverTime
}
/**
 *
 * @export
 * @interface LoginRequest
 */
export interface LoginRequest {
  /**
   *
   * @type {string}
   * @memberof LoginRequest
   */
  username?: string
  /**
   *
   * @type {string}
   * @memberof LoginRequest
   */
  password?: string
}
/**
 *
 * @export
 * @interface MapValueItem
 */
export interface MapValueItem {
  /**
   *
   * @type {string}
   * @memberof MapValueItem
   */
  key?: string
  /**
   *
   * @type {string}
   * @memberof MapValueItem
   */
  value?: string
}
/**
 *
 * @export
 * @interface Origin
 */
export interface Origin {
  /**
   *
   * @type {number}
   * @memberof Origin
   */
  id: number
  /**
   *
   * @type {number}
   * @memberof Origin
   */
  subscription_id: number
  /**
   *
   * @type {string}
   * @memberof Origin
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof Origin
   */
  url?: string
  /**
   *
   * @type {string}
   * @memberof Origin
   */
  host_header?: string
  /**
   *
   * @type {string}
   * @memberof Origin
   */
  url_type?: string
  /**
   *
   * @type {string}
   * @memberof Origin
   */
  created_at?: string
  /**
   *
   * @type {string}
   * @memberof Origin
   */
  updated_at?: string
  /**
   *
   * @type {boolean}
   * @memberof Origin
   */
  in_use: boolean
}
/**
 *
 * @export
 * @interface OriginCreatable
 */
export interface OriginCreatable {
  /**
   *
   * @type {string}
   * @memberof OriginCreatable
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof OriginCreatable
   */
  host_header?: string
  /**
   *
   * @type {string}
   * @memberof OriginCreatable
   */
  url_type?: string
  /**
   *
   * @type {string}
   * @memberof OriginCreatable
   */
  hostname?: string
  /**
   *
   * @type {string}
   * @memberof OriginCreatable
   */
  username?: string
  /**
   *
   * @type {string}
   * @memberof OriginCreatable
   */
  password?: string
  /**
   *
   * @type {number}
   * @memberof OriginCreatable
   */
  port?: number
  /**
   *
   * @type {string}
   * @memberof OriginCreatable
   */
  path?: string
  /**
   *
   * @type {string}
   * @memberof OriginCreatable
   */
  bucketname?: string
}
/**
 *
 * @export
 * @interface OriginUpdatable
 */
export interface OriginUpdatable {
  /**
   *
   * @type {string}
   * @memberof OriginUpdatable
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof OriginUpdatable
   */
  host_header?: string
  /**
   *
   * @type {string}
   * @memberof OriginUpdatable
   */
  url_type?: string
  /**
   *
   * @type {string}
   * @memberof OriginUpdatable
   */
  hostname?: string
  /**
   *
   * @type {string}
   * @memberof OriginUpdatable
   */
  username?: string
  /**
   *
   * @type {string}
   * @memberof OriginUpdatable
   */
  password?: string
  /**
   *
   * @type {number}
   * @memberof OriginUpdatable
   */
  port?: number
  /**
   *
   * @type {string}
   * @memberof OriginUpdatable
   */
  path?: string
  /**
   *
   * @type {string}
   * @memberof OriginUpdatable
   */
  bucketname?: string
}
/**
 *
 * @export
 * @interface PathSetting
 */
export interface PathSetting {
  /**
   *
   * @type {number}
   * @memberof PathSetting
   */
  id?: number
  /**
   *
   * @type {number}
   * @memberof PathSetting
   */
  engine_setting_id?: number
  /**
   *
   * @type {number}
   * @memberof PathSetting
   */
  setting_id?: number
  /**
   *
   * @type {string}
   * @memberof PathSetting
   */
  value?: string
  /**
   *
   * @type {Array<MapValueItem>}
   * @memberof PathSetting
   */
  map_value?: Array<MapValueItem>
  /**
   *
   * @type {string}
   * @memberof PathSetting
   */
  created_at?: string
  /**
   *
   * @type {string}
   * @memberof PathSetting
   */
  updated_at?: string
  /**
   *
   * @type {Setting}
   * @memberof PathSetting
   */
  setting?: Setting
}
/**
 *
 * @export
 * @interface PathSettingCreatable
 */
export interface PathSettingCreatable {
  /**
   *
   * @type {string}
   * @memberof PathSettingCreatable
   */
  value?: string
  /**
   *
   * @type {Array<MapValueItem>}
   * @memberof PathSettingCreatable
   */
  map_value?: Array<MapValueItem>
  /**
   *
   * @type {number}
   * @memberof PathSettingCreatable
   */
  setting_id?: number
  /**
   *
   * @type {PathSettingCreatableSetting}
   * @memberof PathSettingCreatable
   */
  setting?: PathSettingCreatableSetting
}
/**
 *
 * @export
 * @interface PathSettingCreatableSetting
 */
export interface PathSettingCreatableSetting {
  /**
   *
   * @type {string}
   * @memberof PathSettingCreatableSetting
   */
  directive?: string
}
/**
 *
 * @export
 * @interface PathSettingUpdatable
 */
export interface PathSettingUpdatable {
  /**
   *
   * @type {number}
   * @memberof PathSettingUpdatable
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof PathSettingUpdatable
   */
  value?: string
  /**
   *
   * @type {Array<MapValueItem>}
   * @memberof PathSettingUpdatable
   */
  map_value?: Array<MapValueItem>
  /**
   *
   * @type {number}
   * @memberof PathSettingUpdatable
   */
  setting_id?: number
}
/**
 *
 * @export
 * @interface PathValidationResult
 */
export interface PathValidationResult {
  /**
   *
   * @type {string}
   * @memberof PathValidationResult
   */
  path?: string
  /**
   *
   * @type {boolean}
   * @memberof PathValidationResult
   */
  valid?: boolean
}
/**
 *
 * @export
 * @enum {string}
 */

export enum PaymentPlanEnum {
  Pro = 'IMAGEENGINE_PRO',
  Basic = 'IMAGEENGINE_BASIC',
  Standard = 'IMAGEENGINE_STANDARD',
  Dev = 'IMAGEENGINE_DEV',
  Appsumo1 = 'IMAGEENGINE_APPSUMO_1',
  Appsumo2 = 'IMAGEENGINE_APPSUMO_2',
  Appsumo3 = 'IMAGEENGINE_APPSUMO_3',
  Appsumo4 = 'IMAGEENGINE_APPSUMO_4',
  Starter = 'IMAGEENGINE_STARTER',
  Growth = 'IMAGEENGINE_GROWTH',
  BeastMode = 'IMAGEENGINE_BEAST_MODE',
  Partner = 'IMAGEENGINE_PARTNER',
  PartnerCustomer = 'IMAGEENGINE_PARTNER_CUSTOMER',
}

/**
 *
 * @export
 * @enum {string}
 */

export enum PaymentTypeEnum {
  Standard = 'STANDARD',
  External = 'EXTERNAL',
  Trial = 'TRIAL',
  Internal = 'INTERNAL',
  Free = 'FREE',
}

/**
 *
 * @export
 * @interface Period
 */
export interface Period {
  /**
   *
   * @type {string}
   * @memberof Period
   */
  start?: string
  /**
   *
   * @type {string}
   * @memberof Period
   */
  end?: string
}
/**
 *
 * @export
 * @interface QueryMetadata
 */
export interface QueryMetadata {
  /**
   *
   * @type {string}
   * @memberof QueryMetadata
   */
  cname?: string
  /**
   *
   * @type {string}
   * @memberof QueryMetadata
   */
  token?: string
  /**
   *
   * @type {number}
   * @memberof QueryMetadata
   */
  subscription_id?: number
  /**
   *
   * @type {Period}
   * @memberof QueryMetadata
   */
  period?: Period
}
/**
 *
 * @export
 * @interface RequestedItem
 */
export interface RequestedItem {
  /**
   *
   * @type {string}
   * @memberof RequestedItem
   */
  name?: string
  /**
   *
   * @type {number}
   * @memberof RequestedItem
   */
  requests?: number
}
/**
 *
 * @export
 * @interface RequestsBucket
 */
export interface RequestsBucket {
  /**
   *
   * @type {string}
   * @memberof RequestsBucket
   */
  timestamp?: string
  /**
   *
   * @type {number}
   * @memberof RequestsBucket
   */
  requests?: number
}
/**
 *
 * @export
 * @interface SSLStatus
 */
export interface SSLStatus {
  /**
   *
   * @type {string}
   * @memberof SSLStatus
   */
  status?: SSLStatusStatusEnum
  /**
   *
   * @type {Array<Certificate>}
   * @memberof SSLStatus
   */
  certificates?: Array<Certificate>
  /**
   *
   * @type {Array<DNSRecord>}
   * @memberof SSLStatus
   */
  validation_records?: Array<DNSRecord>
  /**
   *
   * @type {SSLStatusValidationStatus}
   * @memberof SSLStatus
   */
  validation_status?: SSLStatusValidationStatus
}

/**
 * @export
 * @enum {string}
 */
export enum SSLStatusStatusEnum {
  Disabled = 'disabled',
  CertificatePending = 'certificate_pending',
  CertificateFailed = 'certificate_failed',
  CertificateMissing = 'certificate_missing',
  AttachPending = 'attach_pending',
  Enabled = 'enabled',
}

/**
 *
 * @export
 * @interface SSLStatusValidationStatus
 */
export interface SSLStatusValidationStatus {
  /**
   *
   * @type {boolean}
   * @memberof SSLStatusValidationStatus
   */
  is_valid?: boolean
  /**
   *
   * @type {string}
   * @memberof SSLStatusValidationStatus
   */
  error_message?: string
  /**
   *
   * @type {string}
   * @memberof SSLStatusValidationStatus
   */
  expected_cname?: string
  /**
   *
   * @type {string}
   * @memberof SSLStatusValidationStatus
   */
  actual_cname?: string
}
/**
 *
 * @export
 * @interface Setting
 */
export interface Setting {
  /**
   *
   * @type {number}
   * @memberof Setting
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof Setting
   */
  directive?: string
  /**
   *
   * @type {string}
   * @memberof Setting
   */
  directive_name?: string
}
/**
 *
 * @export
 * @interface Statistics
 */
export interface Statistics {
  /**
   *
   * @type {QueryMetadata}
   * @memberof Statistics
   */
  metadata?: QueryMetadata
  /**
   *
   * @type {CacheHitRatio}
   * @memberof Statistics
   */
  cache_hit_ratio?: CacheHitRatio
  /**
   *
   * @type {Array<DataBucket>}
   * @memberof Statistics
   */
  data_over_time?: Array<DataBucket>
  /**
   *
   * @type {Array<RequestsBucket>}
   * @memberof Statistics
   */
  requests_over_time?: Array<RequestsBucket>
  /**
   *
   * @type {object}
   * @memberof Statistics
   */
  bytes_by_image_format?: object
}
/**
 *
 * @export
 * @interface Subscription
 */
export interface Subscription {
  /**
   *
   * @type {number}
   * @memberof Subscription
   */
  id: number
  /**
   *
   * @type {number}
   * @memberof Subscription
   */
  user_id: number
  /**
   *
   * @type {string}
   * @memberof Subscription
   */
  account_name: string
  /**
   *
   * @type {number}
   * @memberof Subscription
   */
  tier_id: number
  /**
   *
   * @type {string}
   * @memberof Subscription
   */
  date_started: string
  /**
   *
   * @type {string}
   * @memberof Subscription
   */
  date_expiration: string
  /**
   *
   * @type {string}
   * @memberof Subscription
   */
  type: string
  /**
   *
   * @type {string}
   * @memberof Subscription
   */
  status: string
  /**
   *
   * @type {string}
   * @memberof Subscription
   */
  date_trial_end: string
  /**
   *
   * @type {string}
   * @memberof Subscription
   */
  trial_expiration_date?: string
  /**
   *
   * @type {string}
   * @memberof Subscription
   */
  billing_system?: SubscriptionBillingSystemEnum
  /**
   *
   * @type {string}
   * @memberof Subscription
   */
  payment_plan: string
  /**
   *
   * @type {PaymentTypeEnum}
   * @memberof Subscription
   */
  payment_type: PaymentTypeEnum
}

/**
 * @export
 * @enum {string}
 */
export enum SubscriptionBillingSystemEnum {
  Maxio = 'maxio',
  Braintree = 'braintree',
}

/**
 *
 * @export
 * @interface SubscriptionCancelable
 */
export interface SubscriptionCancelable {
  /**
   *
   * @type {string}
   * @memberof SubscriptionCancelable
   */
  reason: string
}
/**
 *
 * @export
 * @interface SubscriptionCreatable
 */
export interface SubscriptionCreatable {
  /**
   *
   * @type {PaymentPlanEnum}
   * @memberof SubscriptionCreatable
   */
  plan_id: PaymentPlanEnum
  /**
   *
   * @type {PaymentTypeEnum}
   * @memberof SubscriptionCreatable
   */
  payment_type: PaymentTypeEnum
  /**
   *
   * @type {string}
   * @memberof SubscriptionCreatable
   */
  account_name: string
  /**
   *
   * @type {boolean}
   * @memberof SubscriptionCreatable
   */
  pro_standard?: boolean
  /**
   *
   * @type {boolean}
   * @memberof SubscriptionCreatable
   */
  use_defaults?: boolean
}
/**
 *
 * @export
 * @interface SubscriptionEditable
 */
export interface SubscriptionEditable {
  /**
   *
   * @type {PaymentPlanEnum}
   * @memberof SubscriptionEditable
   */
  payment_plan: PaymentPlanEnum
  /**
   *
   * @type {PaymentTypeEnum}
   * @memberof SubscriptionEditable
   */
  payment_type: PaymentTypeEnum
  /**
   *
   * @type {string}
   * @memberof SubscriptionEditable
   */
  account_name: string
}
/**
 *
 * @export
 * @interface SubscriptionUpdatable
 */
export interface SubscriptionUpdatable {
  /**
   *
   * @type {string}
   * @memberof SubscriptionUpdatable
   */
  account_name?: string
}
/**
 *
 * @export
 * @interface Team
 */
export interface Team {
  /**
   *
   * @type {number}
   * @memberof Team
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof Team
   */
  name?: string
  /**
   *
   * @type {boolean}
   * @memberof Team
   */
  partner?: boolean
  /**
   *
   * @type {boolean}
   * @memberof Team
   */
  reseller?: boolean
}
/**
 *
 * @export
 * @interface TeamInviteAccepted
 */
export interface TeamInviteAccepted {
  /**
   *
   * @type {number}
   * @memberof TeamInviteAccepted
   */
  id?: number
  /**
   *
   * @type {User}
   * @memberof TeamInviteAccepted
   */
  invitee?: User
  /**
   *
   * @type {User}
   * @memberof TeamInviteAccepted
   */
  inviter?: User
  /**
   *
   * @type {string}
   * @memberof TeamInviteAccepted
   */
  status?: string
  /**
   *
   * @type {number}
   * @memberof TeamInviteAccepted
   */
  team_id?: number
  /**
   *
   * @type {string}
   * @memberof TeamInviteAccepted
   */
  created_at?: string
  /**
   *
   * @type {string}
   * @memberof TeamInviteAccepted
   */
  updated_at?: string
}
/**
 *
 * @export
 * @interface TeamInviteDenied
 */
export interface TeamInviteDenied {
  /**
   *
   * @type {number}
   * @memberof TeamInviteDenied
   */
  id?: number
  /**
   *
   * @type {User}
   * @memberof TeamInviteDenied
   */
  invitee?: User
  /**
   *
   * @type {User}
   * @memberof TeamInviteDenied
   */
  inviter?: User
  /**
   *
   * @type {string}
   * @memberof TeamInviteDenied
   */
  status?: string
  /**
   *
   * @type {number}
   * @memberof TeamInviteDenied
   */
  team_id?: number
  /**
   *
   * @type {string}
   * @memberof TeamInviteDenied
   */
  created_at?: string
  /**
   *
   * @type {string}
   * @memberof TeamInviteDenied
   */
  updated_at?: string
}
/**
 *
 * @export
 * @interface TeamInvitePending
 */
export interface TeamInvitePending {
  /**
   *
   * @type {number}
   * @memberof TeamInvitePending
   */
  id?: number
  /**
   *
   * @type {User}
   * @memberof TeamInvitePending
   */
  invitee?: User
  /**
   *
   * @type {User}
   * @memberof TeamInvitePending
   */
  inviter?: User
  /**
   *
   * @type {string}
   * @memberof TeamInvitePending
   */
  status?: string
  /**
   *
   * @type {number}
   * @memberof TeamInvitePending
   */
  team_id?: number
  /**
   *
   * @type {string}
   * @memberof TeamInvitePending
   */
  created_at?: string
  /**
   *
   * @type {string}
   * @memberof TeamInvitePending
   */
  updated_at?: string
}
/**
 *
 * @export
 * @interface TeamOptions
 */
export interface TeamOptions {
  /**
   *
   * @type {string}
   * @memberof TeamOptions
   */
  name?: string
  /**
   *
   * @type {boolean}
   * @memberof TeamOptions
   */
  partner?: boolean
  /**
   *
   * @type {boolean}
   * @memberof TeamOptions
   */
  reseller?: boolean
  /**
   *
   * @type {number}
   * @memberof TeamOptions
   */
  user_id?: number
}
/**
 *
 * @export
 * @interface Tier
 */
export interface Tier {
  /**
   *
   * @type {number}
   * @memberof Tier
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof Tier
   */
  tier_name?: string
  /**
   *
   * @type {string}
   * @memberof Tier
   */
  payment_plan?: string
  /**
   *
   * @type {number}
   * @memberof Tier
   */
  usage_limit?: number
  /**
   *
   * @type {number}
   * @memberof Tier
   */
  flat_rate?: number
  /**
   *
   * @type {number}
   * @memberof Tier
   */
  overage_fee?: number
  /**
   *
   * @type {boolean}
   * @memberof Tier
   */
  default_tier?: boolean
  /**
   *
   * @type {number}
   * @memberof Tier
   */
  trial_days?: number
  /**
   *
   * @type {number}
   * @memberof Tier
   */
  enforcement?: number
  /**
   *
   * @type {number}
   * @memberof Tier
   */
  bucket_size?: number
  /**
   *
   * @type {string}
   * @memberof Tier
   */
  payment_types?: string
  /**
   *
   * @type {number}
   * @memberof Tier
   */
  engines?: number
  /**
   *
   * @type {number}
   * @memberof Tier
   */
  custom_domains?: number
  /**
   *
   * @type {number}
   * @memberof Tier
   */
  team_members?: number
  /**
   *
   * @type {boolean}
   * @memberof Tier
   */
  referrer_mgmt?: boolean
  /**
   *
   * @type {boolean}
   * @memberof Tier
   */
  log_exports?: boolean
  /**
   *
   * @type {boolean}
   * @memberof Tier
   */
  api_access?: boolean
  /**
   *
   * @type {boolean}
   * @memberof Tier
   */
  allow_cdn?: boolean
}
/**
 *
 * @export
 * @interface TotalUsage
 */
export interface TotalUsage {
  /**
   *
   * @type {number}
   * @memberof TotalUsage
   */
  bytes_sent: number
  /**
   *
   * @type {number}
   * @memberof TotalUsage
   */
  bytes_received: number
  /**
   *
   * @type {number}
   * @memberof TotalUsage
   */
  total_bytes: number
}
/**
 *
 * @export
 * @interface TotalUsageBucket
 */
export interface TotalUsageBucket {
  /**
   *
   * @type {string}
   * @memberof TotalUsageBucket
   */
  timestamp?: string
  /**
   *
   * @type {TotalUsageBucketTotalUsage}
   * @memberof TotalUsageBucket
   */
  total_usage?: TotalUsageBucketTotalUsage
}
/**
 *
 * @export
 * @interface TotalUsageBucketTotalUsage
 */
export interface TotalUsageBucketTotalUsage {
  /**
   *
   * @type {number}
   * @memberof TotalUsageBucketTotalUsage
   */
  bytes_sent?: number
  /**
   *
   * @type {number}
   * @memberof TotalUsageBucketTotalUsage
   */
  bytes_received?: number
  /**
   *
   * @type {number}
   * @memberof TotalUsageBucketTotalUsage
   */
  total_bytes?: number
}
/**
 *
 * @export
 * @interface Transaction
 */
export interface Transaction {
  /**
   *
   * @type {number}
   * @memberof Transaction
   */
  current_billing_cycle?: number
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  balance?: string
  /**
   *
   * @type {number}
   * @memberof Transaction
   */
  billing_dom?: number
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  subscription_status?: string
  /**
   *
   * @type {BillingPeriod}
   * @memberof Transaction
   */
  billing_period?: BillingPeriod
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  status_text?: string
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  created_at?: string
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  amount?: string
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  type?: string
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  processor_response_text?: string
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  processor_response_code?: string
  /**
   *
   * @type {string}
   * @memberof Transaction
   */
  plan_id?: string
  /**
   *
   * @type {BillingInformation}
   * @memberof Transaction
   */
  billing_info?: BillingInformation
  /**
   *
   * @type {boolean}
   * @memberof Transaction
   */
  recurring?: boolean
}
/**
 *
 * @export
 * @interface TrialLink
 */
export interface TrialLink {
  /**
   *
   * @type {number}
   * @memberof TrialLink
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof TrialLink
   */
  email?: string
  /**
   *
   * @type {string}
   * @memberof TrialLink
   */
  link?: string
  /**
   *
   * @type {boolean}
   * @memberof TrialLink
   */
  claimed?: boolean
  /**
   *
   * @type {string}
   * @memberof TrialLink
   */
  valid_until?: string
  /**
   *
   * @type {number}
   * @memberof TrialLink
   */
  subscription_id?: number
  /**
   *
   * @type {number}
   * @memberof TrialLink
   */
  reseller_team_id?: number
  /**
   *
   * @type {string}
   * @memberof TrialLink
   */
  created_at?: string
  /**
   *
   * @type {string}
   * @memberof TrialLink
   */
  updated_at?: string
}
/**
 *
 * @export
 * @interface UsageOverTimeBucket
 */
export interface UsageOverTimeBucket {
  /**
   *
   * @type {string}
   * @memberof UsageOverTimeBucket
   */
  timestamp?: string
  /**
   *
   * @type {object}
   * @memberof UsageOverTimeBucket
   */
  data?: object
}
/**
 *
 * @export
 * @interface UsageTrends
 */
export interface UsageTrends {
  /**
   *
   * @type {object}
   * @memberof UsageTrends
   */
  requests_by_os?: object
  /**
   *
   * @type {object}
   * @memberof UsageTrends
   */
  requests_by_form_factor?: object
  /**
   *
   * @type {object}
   * @memberof UsageTrends
   */
  requests_by_device_name?: object
}
/**
 *
 * @export
 * @interface UsageTrendsOverTime
 */
export interface UsageTrendsOverTime {
  /**
   *
   * @type {Array<UsageOverTimeBucket>}
   * @memberof UsageTrendsOverTime
   */
  requests_by_os?: Array<UsageOverTimeBucket>
  /**
   *
   * @type {Array<UsageOverTimeBucket>}
   * @memberof UsageTrendsOverTime
   */
  requests_by_form_factor?: Array<UsageOverTimeBucket>
  /**
   *
   * @type {Array<UsageOverTimeBucket>}
   * @memberof UsageTrendsOverTime
   */
  requests_by_device_name?: Array<UsageOverTimeBucket>
}
/**
 *
 * @export
 * @interface User
 */
export interface User {
  /**
   *
   * @type {number}
   * @memberof User
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof User
   */
  first_name: string
  /**
   *
   * @type {string}
   * @memberof User
   */
  last_name: string
  /**
   *
   * @type {string}
   * @memberof User
   */
  username: string
  /**
   *
   * @type {string}
   * @memberof User
   */
  email: string
  /**
   *
   * @type {string}
   * @memberof User
   */
  phone: string
  /**
   *
   * @type {string}
   * @memberof User
   */
  company_name: string
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  confirmed: boolean
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  enabled: boolean
  /**
   *
   * @type {number}
   * @memberof User
   */
  team_id?: number
  /**
   *
   * @type {number}
   * @memberof User
   */
  reseller_team_id?: number
}
/**
 *
 * @export
 * @interface UserApiKey
 */
export interface UserApiKey {
  /**
   *
   * @type {number}
   * @memberof UserApiKey
   */
  id: number
  /**
   *
   * @type {number}
   * @memberof UserApiKey
   */
  user_id: number
  /**
   *
   * @type {number}
   * @memberof UserApiKey
   */
  subscription_id?: number
  /**
   *
   * @type {string}
   * @memberof UserApiKey
   */
  key_name?: string
  /**
   *
   * @type {number}
   * @memberof UserApiKey
   */
  api_username?: number
  /**
   *
   * @type {string}
   * @memberof UserApiKey
   */
  api_password?: string
  /**
   *
   * @type {string}
   * @memberof UserApiKey
   */
  key: string
  /**
   *
   * @type {string}
   * @memberof UserApiKey
   */
  expiration?: string
}
/**
 *
 * @export
 * @interface UserApiKeyCreatable
 */
export interface UserApiKeyCreatable {
  /**
   *
   * @type {string}
   * @memberof UserApiKeyCreatable
   */
  key_name: string
}
/**
 *
 * @export
 * @interface UserApiKeyUpdatable
 */
export interface UserApiKeyUpdatable {
  /**
   *
   * @type {string}
   * @memberof UserApiKeyUpdatable
   */
  key_name?: string
  /**
   *
   * @type {string}
   * @memberof UserApiKeyUpdatable
   */
  expiration?: string
}
/**
 *
 * @export
 * @interface UserCreatable
 */
export interface UserCreatable {
  /**
   *
   * @type {string}
   * @memberof UserCreatable
   */
  username: string
  /**
   *
   * @type {string}
   * @memberof UserCreatable
   */
  email: string
  /**
   *
   * @type {string}
   * @memberof UserCreatable
   */
  first_name?: string
  /**
   *
   * @type {string}
   * @memberof UserCreatable
   */
  last_name?: string
  /**
   *
   * @type {string}
   * @memberof UserCreatable
   */
  company_name?: string
  /**
   *
   * @type {number}
   * @memberof UserCreatable
   */
  country_id?: number
  /**
   *
   * @type {boolean}
   * @memberof UserCreatable
   */
  enabled?: boolean
  /**
   *
   * @type {number}
   * @memberof UserCreatable
   * @deprecated
   */
  user_type?: number
  /**
   *
   * @type {string}
   * @memberof UserCreatable
   */
  password: string
  /**
   *
   * @type {boolean}
   * @memberof UserCreatable
   */
  confirmed?: boolean
  /**
   *
   * @type {number}
   * @memberof UserCreatable
   */
  team_id?: number
  /**
   *
   * @type {number}
   * @memberof UserCreatable
   */
  reseller_team_id?: number
  /**
   *
   * @type {string}
   * @memberof UserCreatable
   */
  token?: string
}
/**
 *
 * @export
 * @interface UserPreference
 */
export interface UserPreference {
  /**
   *
   * @type {number}
   * @memberof UserPreference
   */
  id: number
  /**
   *
   * @type {number}
   * @memberof UserPreference
   */
  user_id: number
  /**
   *
   * @type {string}
   * @memberof UserPreference
   */
  key: string
  /**
   *
   * @type {string}
   * @memberof UserPreference
   */
  value: string
}
/**
 *
 * @export
 * @interface UserPreferenceCreatable
 */
export interface UserPreferenceCreatable {
  /**
   *
   * @type {string}
   * @memberof UserPreferenceCreatable
   */
  key: string
  /**
   *
   * @type {string}
   * @memberof UserPreferenceCreatable
   */
  value: string
}
/**
 *
 * @export
 * @interface UserTotalUsage
 */
export interface UserTotalUsage {
  /**
   *
   * @type {TotalUsage}
   * @memberof UserTotalUsage
   */
  total_usage?: TotalUsage
  /**
   *
   * @type {object}
   * @memberof UserTotalUsage
   */
  total_usage_by_subscription?: object
}
/**
 *
 * @export
 * @interface UserUpdatable
 */
export interface UserUpdatable {
  /**
   *
   * @type {string}
   * @memberof UserUpdatable
   */
  first_name?: string
  /**
   *
   * @type {string}
   * @memberof UserUpdatable
   */
  last_name?: string
  /**
   *
   * @type {string}
   * @memberof UserUpdatable
   */
  phone?: string
  /**
   *
   * @type {string}
   * @memberof UserUpdatable
   */
  company_name?: string
  /**
   *
   * @type {number}
   * @memberof UserUpdatable
   */
  team_id?: number
  /**
   *
   * @type {number}
   * @memberof UserUpdatable
   */
  reseller_team_id?: number
  /**
   *
   * @type {boolean}
   * @memberof UserUpdatable
   */
  confirmed?: boolean
}
/**
 *
 * @export
 * @interface ValidatePathsRequest
 */
export interface ValidatePathsRequest {
  /**
   *
   * @type {string}
   * @memberof ValidatePathsRequest
   */
  pattern?: string
  /**
   *
   * @type {Array<string>}
   * @memberof ValidatePathsRequest
   */
  paths?: Array<string>
}
/**
 *
 * @export
 * @interface VerifyTrialLink
 */
export interface VerifyTrialLink {
  /**
   *
   * @type {boolean}
   * @memberof VerifyTrialLink
   */
  claimed?: boolean
  /**
   *
   * @type {boolean}
   * @memberof VerifyTrialLink
   */
  expired?: boolean
  /**
   *
   * @type {string}
   * @memberof VerifyTrialLink
   */
  email?: string
  /**
   *
   * @type {string}
   * @memberof VerifyTrialLink
   */
  team_name?: string
}

/**
 * CachePurgingApi - axios parameter creator
 * @export
 */
export const CachePurgingApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} subscriptionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCachePurgesForSubscription: async (
      subscriptionId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'subscriptionId' is not null or undefined
      assertParamExists('getCachePurgesForSubscription', 'subscriptionId', subscriptionId)
      const localVarPath = `/v1/subscriptions/{subscription_id}/cache_purges`.replace(
        `{${'subscription_id'}}`,
        encodeURIComponent(String(subscriptionId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} subscriptionId
     * @param {InlineObject8} [inlineObject8]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    submitCachePurgeRequest: async (
      subscriptionId: number,
      inlineObject8?: InlineObject8,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'subscriptionId' is not null or undefined
      assertParamExists('submitCachePurgeRequest', 'subscriptionId', subscriptionId)
      const localVarPath = `/v1/subscriptions/{subscription_id}/cache_purges`.replace(
        `{${'subscription_id'}}`,
        encodeURIComponent(String(subscriptionId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        inlineObject8,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * CachePurgingApi - functional programming interface
 * @export
 */
export const CachePurgingApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CachePurgingApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {number} subscriptionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCachePurgesForSubscription(
      subscriptionId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CachePurgeRequest>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCachePurgesForSubscription(
        subscriptionId,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} subscriptionId
     * @param {InlineObject8} [inlineObject8]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async submitCachePurgeRequest(
      subscriptionId: number,
      inlineObject8?: InlineObject8,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CachePurgeRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.submitCachePurgeRequest(
        subscriptionId,
        inlineObject8,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * CachePurgingApi - factory interface
 * @export
 */
export const CachePurgingApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = CachePurgingApiFp(configuration)
  return {
    /**
     *
     * @param {number} subscriptionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCachePurgesForSubscription(
      subscriptionId: number,
      options?: any
    ): AxiosPromise<Array<CachePurgeRequest>> {
      return localVarFp
        .getCachePurgesForSubscription(subscriptionId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} subscriptionId
     * @param {InlineObject8} [inlineObject8]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    submitCachePurgeRequest(
      subscriptionId: number,
      inlineObject8?: InlineObject8,
      options?: any
    ): AxiosPromise<CachePurgeRequest> {
      return localVarFp
        .submitCachePurgeRequest(subscriptionId, inlineObject8, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * CachePurgingApi - object-oriented interface
 * @export
 * @class CachePurgingApi
 * @extends {BaseAPI}
 */
export class CachePurgingApi extends BaseAPI {
  /**
   *
   * @param {number} subscriptionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CachePurgingApi
   */
  public getCachePurgesForSubscription(subscriptionId: number, options?: AxiosRequestConfig) {
    return CachePurgingApiFp(this.configuration)
      .getCachePurgesForSubscription(subscriptionId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} subscriptionId
   * @param {InlineObject8} [inlineObject8]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CachePurgingApi
   */
  public submitCachePurgeRequest(
    subscriptionId: number,
    inlineObject8?: InlineObject8,
    options?: AxiosRequestConfig
  ) {
    return CachePurgingApiFp(this.configuration)
      .submitCachePurgeRequest(subscriptionId, inlineObject8, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * ContactApi - axios parameter creator
 * @export
 */
export const ContactApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {InlineObject9} [inlineObject9]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contactSupport: async (
      inlineObject9?: InlineObject9,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/contact`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        inlineObject9,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ContactApi - functional programming interface
 * @export
 */
export const ContactApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ContactApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {InlineObject9} [inlineObject9]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contactSupport(
      inlineObject9?: InlineObject9,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.contactSupport(
        inlineObject9,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * ContactApi - factory interface
 * @export
 */
export const ContactApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ContactApiFp(configuration)
  return {
    /**
     *
     * @param {InlineObject9} [inlineObject9]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contactSupport(inlineObject9?: InlineObject9, options?: any): AxiosPromise<void> {
      return localVarFp
        .contactSupport(inlineObject9, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * ContactApi - object-oriented interface
 * @export
 * @class ContactApi
 * @extends {BaseAPI}
 */
export class ContactApi extends BaseAPI {
  /**
   *
   * @param {InlineObject9} [inlineObject9]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContactApi
   */
  public contactSupport(inlineObject9?: InlineObject9, options?: AxiosRequestConfig) {
    return ContactApiFp(this.configuration)
      .contactSupport(inlineObject9, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * EnginesApi - axios parameter creator
 * @export
 */
export const EnginesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * This endpoint adds a region to an engine. If SSL is enabled for the engine, a certificate will be requested and attached before a DNS record is created. If the region is already completely provisioned, the only mutation performed will be a DNS record update to ensure it is pointed at the correct load balancer. The region will not be added to the engine\'s region list if SSL is enabled but the certificate can\'t/won\'t be attached.
     * @param {number} engineId
     * @param {string} region
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addRegionToEngine: async (
      engineId: number,
      region: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'engineId' is not null or undefined
      assertParamExists('addRegionToEngine', 'engineId', engineId)
      // verify required parameter 'region' is not null or undefined
      assertParamExists('addRegionToEngine', 'region', region)
      const localVarPath = `/v1/engines/{engine_id}/regions/{region}`
        .replace(`{${'engine_id'}}`, encodeURIComponent(String(engineId)))
        .replace(`{${'region'}}`, encodeURIComponent(String(region)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} subscriptionId
     * @param {EngineCreatable} [engineCreatable]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createEngine: async (
      subscriptionId: number,
      engineCreatable?: EngineCreatable,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'subscriptionId' is not null or undefined
      assertParamExists('createEngine', 'subscriptionId', subscriptionId)
      const localVarPath = `/v1/subscriptions/{subscription_id}/engines`.replace(
        `{${'subscription_id'}}`,
        encodeURIComponent(String(subscriptionId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        engineCreatable,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} engineId
     * @param {EnginePreference} [enginePreference]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createEnginePreference: async (
      engineId: number,
      enginePreference?: EnginePreference,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'engineId' is not null or undefined
      assertParamExists('createEnginePreference', 'engineId', engineId)
      const localVarPath = `/v1/engines/{engine_id}/preferences`.replace(
        `{${'engine_id'}}`,
        encodeURIComponent(String(engineId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        enginePreference,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} engineId
     * @param {EngineSettingCreatable} [engineSettingCreatable]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createEngineSetting: async (
      engineId: number,
      engineSettingCreatable?: EngineSettingCreatable,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'engineId' is not null or undefined
      assertParamExists('createEngineSetting', 'engineId', engineId)
      const localVarPath = `/v1/engines/{engine_id}/settings`.replace(
        `{${'engine_id'}}`,
        encodeURIComponent(String(engineId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        engineSettingCreatable,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} engineId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteEngine: async (
      engineId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'engineId' is not null or undefined
      assertParamExists('deleteEngine', 'engineId', engineId)
      const localVarPath = `/v1/engines/{engine_id}`.replace(
        `{${'engine_id'}}`,
        encodeURIComponent(String(engineId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} engineId
     * @param {number} preferenceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteEnginePreference: async (
      engineId: number,
      preferenceId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'engineId' is not null or undefined
      assertParamExists('deleteEnginePreference', 'engineId', engineId)
      // verify required parameter 'preferenceId' is not null or undefined
      assertParamExists('deleteEnginePreference', 'preferenceId', preferenceId)
      const localVarPath = `/v1/engines/{engine_id}/preferences/{preference_id}`
        .replace(`{${'engine_id'}}`, encodeURIComponent(String(engineId)))
        .replace(`{${'preference_id'}}`, encodeURIComponent(String(preferenceId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} engineId
     * @param {number} settingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteEngineSetting: async (
      engineId: number,
      settingId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'engineId' is not null or undefined
      assertParamExists('deleteEngineSetting', 'engineId', engineId)
      // verify required parameter 'settingId' is not null or undefined
      assertParamExists('deleteEngineSetting', 'settingId', settingId)
      const localVarPath = `/v1/engines/{engine_id}/settings/{setting_id}`
        .replace(`{${'engine_id'}}`, encodeURIComponent(String(engineId)))
        .replace(`{${'setting_id'}}`, encodeURIComponent(String(settingId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * This endpoint enables SSL for an engine in all the regions the engine already exists in. If the engine does not exist in any regions, the subscription\'s CRB will be used for the available regions. If a CRB is also not available, all regions with at least one applicable load balancer will be used. When determining the list of regions for the engine, only those regions that can/will have a certificate attached will be included. If SSL is already provisioned for the engine, the only mutations perfomed will be DNS record updates to ensure they are pointed to the correct load balancers.
     * @param {number} engineId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    enableSSLForEngine: async (
      engineId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'engineId' is not null or undefined
      assertParamExists('enableSSLForEngine', 'engineId', engineId)
      const localVarPath = `/v1/engines/{engine_id}/ssl`.replace(
        `{${'engine_id'}}`,
        encodeURIComponent(String(engineId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} engineId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEngine: async (engineId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'engineId' is not null or undefined
      assertParamExists('getEngine', 'engineId', engineId)
      const localVarPath = `/v1/engines/{engine_id}`.replace(
        `{${'engine_id'}}`,
        encodeURIComponent(String(engineId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} engineId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEnginePreferences: async (
      engineId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'engineId' is not null or undefined
      assertParamExists('getEnginePreferences', 'engineId', engineId)
      const localVarPath = `/v1/engines/{engine_id}/preferences`.replace(
        `{${'engine_id'}}`,
        encodeURIComponent(String(engineId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * This endpoint retrieves the status for an engine, which includes the regions in which the engine should exist, the SSL configuration and status, and the DNS configuration and status.
     * @param {number} engineId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEngineStatus: async (
      engineId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'engineId' is not null or undefined
      assertParamExists('getEngineStatus', 'engineId', engineId)
      const localVarPath = `/v1/engines/{engine_id}/status`.replace(
        `{${'engine_id'}}`,
        encodeURIComponent(String(engineId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} cname Search by cname
     * @param {number} [originId] Search by origin id
     * @param {number} [subscriptionId] Search by subscription id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEngines: async (
      cname: string,
      originId?: number,
      subscriptionId?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cname' is not null or undefined
      assertParamExists('getEngines', 'cname', cname)
      const localVarPath = `/v1/engines`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (cname !== undefined) {
        localVarQueryParameter['cname'] = cname
      }

      if (originId !== undefined) {
        localVarQueryParameter['origin_id'] = originId
      }

      if (subscriptionId !== undefined) {
        localVarQueryParameter['subscription_id'] = subscriptionId
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * This endpoint removes a region from an engine. If one exists, the DNS record will be deleted. Then the certificate, if it exists, will be removed from the load balancer and tagged for deletion.
     * @param {number} engineId
     * @param {string} region
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeRegionFromEngine: async (
      engineId: number,
      region: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'engineId' is not null or undefined
      assertParamExists('removeRegionFromEngine', 'engineId', engineId)
      // verify required parameter 'region' is not null or undefined
      assertParamExists('removeRegionFromEngine', 'region', region)
      const localVarPath = `/v1/engines/{engine_id}/regions/{region}`
        .replace(`{${'engine_id'}}`, encodeURIComponent(String(engineId)))
        .replace(`{${'region'}}`, encodeURIComponent(String(region)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Update an engine. Either an origin_id or origin must be supplied.
     * @param {number} engineId
     * @param {EngineUpdatable} [engineUpdatable]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEngine: async (
      engineId: number,
      engineUpdatable?: EngineUpdatable,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'engineId' is not null or undefined
      assertParamExists('updateEngine', 'engineId', engineId)
      const localVarPath = `/v1/engines/{engine_id}`.replace(
        `{${'engine_id'}}`,
        encodeURIComponent(String(engineId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        engineUpdatable,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} engineId
     * @param {number} preferenceId
     * @param {EnginePreference} [enginePreference]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEnginePreference: async (
      engineId: number,
      preferenceId: number,
      enginePreference?: EnginePreference,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'engineId' is not null or undefined
      assertParamExists('updateEnginePreference', 'engineId', engineId)
      // verify required parameter 'preferenceId' is not null or undefined
      assertParamExists('updateEnginePreference', 'preferenceId', preferenceId)
      const localVarPath = `/v1/engines/{engine_id}/preferences/{preference_id}`
        .replace(`{${'engine_id'}}`, encodeURIComponent(String(engineId)))
        .replace(`{${'preference_id'}}`, encodeURIComponent(String(preferenceId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        enginePreference,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Update an engine setting in its entirety. New path settings will be created, existing path settings will be updated, and omitted path settings will be deleted.
     * @param {number} engineId
     * @param {number} settingId
     * @param {EngineSettingUpdatable} [engineSettingUpdatable]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEngineSetting: async (
      engineId: number,
      settingId: number,
      engineSettingUpdatable?: EngineSettingUpdatable,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'engineId' is not null or undefined
      assertParamExists('updateEngineSetting', 'engineId', engineId)
      // verify required parameter 'settingId' is not null or undefined
      assertParamExists('updateEngineSetting', 'settingId', settingId)
      const localVarPath = `/v1/engines/{engine_id}/settings/{setting_id}`
        .replace(`{${'engine_id'}}`, encodeURIComponent(String(engineId)))
        .replace(`{${'setting_id'}}`, encodeURIComponent(String(settingId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        engineSettingUpdatable,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {ValidatePathsRequest} [validatePathsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateEngineSettingPaths: async (
      validatePathsRequest?: ValidatePathsRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/engines/settings/validate/paths`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        validatePathsRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * EnginesApi - functional programming interface
 * @export
 */
export const EnginesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = EnginesApiAxiosParamCreator(configuration)
  return {
    /**
     * This endpoint adds a region to an engine. If SSL is enabled for the engine, a certificate will be requested and attached before a DNS record is created. If the region is already completely provisioned, the only mutation performed will be a DNS record update to ensure it is pointed at the correct load balancer. The region will not be added to the engine\'s region list if SSL is enabled but the certificate can\'t/won\'t be attached.
     * @param {number} engineId
     * @param {string} region
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addRegionToEngine(
      engineId: number,
      region: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addRegionToEngine(
        engineId,
        region,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} subscriptionId
     * @param {EngineCreatable} [engineCreatable]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createEngine(
      subscriptionId: number,
      engineCreatable?: EngineCreatable,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Engine>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createEngine(
        subscriptionId,
        engineCreatable,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} engineId
     * @param {EnginePreference} [enginePreference]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createEnginePreference(
      engineId: number,
      enginePreference?: EnginePreference,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnginePreference>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createEnginePreference(
        engineId,
        enginePreference,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} engineId
     * @param {EngineSettingCreatable} [engineSettingCreatable]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createEngineSetting(
      engineId: number,
      engineSettingCreatable?: EngineSettingCreatable,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EngineSetting>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createEngineSetting(
        engineId,
        engineSettingCreatable,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} engineId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteEngine(
      engineId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEngine(engineId, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} engineId
     * @param {number} preferenceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteEnginePreference(
      engineId: number,
      preferenceId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEnginePreference(
        engineId,
        preferenceId,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} engineId
     * @param {number} settingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteEngineSetting(
      engineId: number,
      settingId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEngineSetting(
        engineId,
        settingId,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * This endpoint enables SSL for an engine in all the regions the engine already exists in. If the engine does not exist in any regions, the subscription\'s CRB will be used for the available regions. If a CRB is also not available, all regions with at least one applicable load balancer will be used. When determining the list of regions for the engine, only those regions that can/will have a certificate attached will be included. If SSL is already provisioned for the engine, the only mutations perfomed will be DNS record updates to ensure they are pointed to the correct load balancers.
     * @param {number} engineId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async enableSSLForEngine(
      engineId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.enableSSLForEngine(
        engineId,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} engineId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEngine(
      engineId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EngineWithSettings>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getEngine(engineId, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} engineId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEnginePreferences(
      engineId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EnginePreference>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getEnginePreferences(
        engineId,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * This endpoint retrieves the status for an engine, which includes the regions in which the engine should exist, the SSL configuration and status, and the DNS configuration and status.
     * @param {number} engineId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEngineStatus(
      engineId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EngineStatus>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getEngineStatus(engineId, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} cname Search by cname
     * @param {number} [originId] Search by origin id
     * @param {number} [subscriptionId] Search by subscription id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEngines(
      cname: string,
      originId?: number,
      subscriptionId?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EngineWithSettings>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getEngines(
        cname,
        originId,
        subscriptionId,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * This endpoint removes a region from an engine. If one exists, the DNS record will be deleted. Then the certificate, if it exists, will be removed from the load balancer and tagged for deletion.
     * @param {number} engineId
     * @param {string} region
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async removeRegionFromEngine(
      engineId: number,
      region: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.removeRegionFromEngine(
        engineId,
        region,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Update an engine. Either an origin_id or origin must be supplied.
     * @param {number} engineId
     * @param {EngineUpdatable} [engineUpdatable]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateEngine(
      engineId: number,
      engineUpdatable?: EngineUpdatable,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Engine>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateEngine(
        engineId,
        engineUpdatable,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} engineId
     * @param {number} preferenceId
     * @param {EnginePreference} [enginePreference]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateEnginePreference(
      engineId: number,
      preferenceId: number,
      enginePreference?: EnginePreference,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnginePreference>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateEnginePreference(
        engineId,
        preferenceId,
        enginePreference,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Update an engine setting in its entirety. New path settings will be created, existing path settings will be updated, and omitted path settings will be deleted.
     * @param {number} engineId
     * @param {number} settingId
     * @param {EngineSettingUpdatable} [engineSettingUpdatable]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateEngineSetting(
      engineId: number,
      settingId: number,
      engineSettingUpdatable?: EngineSettingUpdatable,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EngineSetting>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateEngineSetting(
        engineId,
        settingId,
        engineSettingUpdatable,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {ValidatePathsRequest} [validatePathsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async validateEngineSettingPaths(
      validatePathsRequest?: ValidatePathsRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PathValidationResult>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.validateEngineSettingPaths(
        validatePathsRequest,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * EnginesApi - factory interface
 * @export
 */
export const EnginesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = EnginesApiFp(configuration)
  return {
    /**
     * This endpoint adds a region to an engine. If SSL is enabled for the engine, a certificate will be requested and attached before a DNS record is created. If the region is already completely provisioned, the only mutation performed will be a DNS record update to ensure it is pointed at the correct load balancer. The region will not be added to the engine\'s region list if SSL is enabled but the certificate can\'t/won\'t be attached.
     * @param {number} engineId
     * @param {string} region
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addRegionToEngine(
      engineId: number,
      region: string,
      options?: any
    ): AxiosPromise<GenericResponse> {
      return localVarFp
        .addRegionToEngine(engineId, region, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} subscriptionId
     * @param {EngineCreatable} [engineCreatable]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createEngine(
      subscriptionId: number,
      engineCreatable?: EngineCreatable,
      options?: any
    ): AxiosPromise<Engine> {
      return localVarFp
        .createEngine(subscriptionId, engineCreatable, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} engineId
     * @param {EnginePreference} [enginePreference]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createEnginePreference(
      engineId: number,
      enginePreference?: EnginePreference,
      options?: any
    ): AxiosPromise<EnginePreference> {
      return localVarFp
        .createEnginePreference(engineId, enginePreference, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} engineId
     * @param {EngineSettingCreatable} [engineSettingCreatable]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createEngineSetting(
      engineId: number,
      engineSettingCreatable?: EngineSettingCreatable,
      options?: any
    ): AxiosPromise<EngineSetting> {
      return localVarFp
        .createEngineSetting(engineId, engineSettingCreatable, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} engineId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteEngine(engineId: number, options?: any): AxiosPromise<GenericResponse> {
      return localVarFp.deleteEngine(engineId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} engineId
     * @param {number} preferenceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteEnginePreference(
      engineId: number,
      preferenceId: number,
      options?: any
    ): AxiosPromise<GenericResponse> {
      return localVarFp
        .deleteEnginePreference(engineId, preferenceId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} engineId
     * @param {number} settingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteEngineSetting(
      engineId: number,
      settingId: number,
      options?: any
    ): AxiosPromise<GenericResponse> {
      return localVarFp
        .deleteEngineSetting(engineId, settingId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * This endpoint enables SSL for an engine in all the regions the engine already exists in. If the engine does not exist in any regions, the subscription\'s CRB will be used for the available regions. If a CRB is also not available, all regions with at least one applicable load balancer will be used. When determining the list of regions for the engine, only those regions that can/will have a certificate attached will be included. If SSL is already provisioned for the engine, the only mutations perfomed will be DNS record updates to ensure they are pointed to the correct load balancers.
     * @param {number} engineId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    enableSSLForEngine(engineId: number, options?: any): AxiosPromise<GenericResponse> {
      return localVarFp
        .enableSSLForEngine(engineId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} engineId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEngine(engineId: number, options?: any): AxiosPromise<EngineWithSettings> {
      return localVarFp.getEngine(engineId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} engineId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEnginePreferences(engineId: number, options?: any): AxiosPromise<Array<EnginePreference>> {
      return localVarFp
        .getEnginePreferences(engineId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * This endpoint retrieves the status for an engine, which includes the regions in which the engine should exist, the SSL configuration and status, and the DNS configuration and status.
     * @param {number} engineId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEngineStatus(engineId: number, options?: any): AxiosPromise<EngineStatus> {
      return localVarFp
        .getEngineStatus(engineId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} cname Search by cname
     * @param {number} [originId] Search by origin id
     * @param {number} [subscriptionId] Search by subscription id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEngines(
      cname: string,
      originId?: number,
      subscriptionId?: number,
      options?: any
    ): AxiosPromise<Array<EngineWithSettings>> {
      return localVarFp
        .getEngines(cname, originId, subscriptionId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * This endpoint removes a region from an engine. If one exists, the DNS record will be deleted. Then the certificate, if it exists, will be removed from the load balancer and tagged for deletion.
     * @param {number} engineId
     * @param {string} region
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeRegionFromEngine(
      engineId: number,
      region: string,
      options?: any
    ): AxiosPromise<GenericResponse> {
      return localVarFp
        .removeRegionFromEngine(engineId, region, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Update an engine. Either an origin_id or origin must be supplied.
     * @param {number} engineId
     * @param {EngineUpdatable} [engineUpdatable]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEngine(
      engineId: number,
      engineUpdatable?: EngineUpdatable,
      options?: any
    ): AxiosPromise<Engine> {
      return localVarFp
        .updateEngine(engineId, engineUpdatable, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} engineId
     * @param {number} preferenceId
     * @param {EnginePreference} [enginePreference]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEnginePreference(
      engineId: number,
      preferenceId: number,
      enginePreference?: EnginePreference,
      options?: any
    ): AxiosPromise<EnginePreference> {
      return localVarFp
        .updateEnginePreference(engineId, preferenceId, enginePreference, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Update an engine setting in its entirety. New path settings will be created, existing path settings will be updated, and omitted path settings will be deleted.
     * @param {number} engineId
     * @param {number} settingId
     * @param {EngineSettingUpdatable} [engineSettingUpdatable]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEngineSetting(
      engineId: number,
      settingId: number,
      engineSettingUpdatable?: EngineSettingUpdatable,
      options?: any
    ): AxiosPromise<EngineSetting> {
      return localVarFp
        .updateEngineSetting(engineId, settingId, engineSettingUpdatable, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {ValidatePathsRequest} [validatePathsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateEngineSettingPaths(
      validatePathsRequest?: ValidatePathsRequest,
      options?: any
    ): AxiosPromise<Array<PathValidationResult>> {
      return localVarFp
        .validateEngineSettingPaths(validatePathsRequest, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * EnginesApi - object-oriented interface
 * @export
 * @class EnginesApi
 * @extends {BaseAPI}
 */
export class EnginesApi extends BaseAPI {
  /**
   * This endpoint adds a region to an engine. If SSL is enabled for the engine, a certificate will be requested and attached before a DNS record is created. If the region is already completely provisioned, the only mutation performed will be a DNS record update to ensure it is pointed at the correct load balancer. The region will not be added to the engine\'s region list if SSL is enabled but the certificate can\'t/won\'t be attached.
   * @param {number} engineId
   * @param {string} region
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EnginesApi
   */
  public addRegionToEngine(engineId: number, region: string, options?: AxiosRequestConfig) {
    return EnginesApiFp(this.configuration)
      .addRegionToEngine(engineId, region, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} subscriptionId
   * @param {EngineCreatable} [engineCreatable]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EnginesApi
   */
  public createEngine(
    subscriptionId: number,
    engineCreatable?: EngineCreatable,
    options?: AxiosRequestConfig
  ) {
    return EnginesApiFp(this.configuration)
      .createEngine(subscriptionId, engineCreatable, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} engineId
   * @param {EnginePreference} [enginePreference]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EnginesApi
   */
  public createEnginePreference(
    engineId: number,
    enginePreference?: EnginePreference,
    options?: AxiosRequestConfig
  ) {
    return EnginesApiFp(this.configuration)
      .createEnginePreference(engineId, enginePreference, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} engineId
   * @param {EngineSettingCreatable} [engineSettingCreatable]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EnginesApi
   */
  public createEngineSetting(
    engineId: number,
    engineSettingCreatable?: EngineSettingCreatable,
    options?: AxiosRequestConfig
  ) {
    return EnginesApiFp(this.configuration)
      .createEngineSetting(engineId, engineSettingCreatable, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} engineId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EnginesApi
   */
  public deleteEngine(engineId: number, options?: AxiosRequestConfig) {
    return EnginesApiFp(this.configuration)
      .deleteEngine(engineId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} engineId
   * @param {number} preferenceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EnginesApi
   */
  public deleteEnginePreference(
    engineId: number,
    preferenceId: number,
    options?: AxiosRequestConfig
  ) {
    return EnginesApiFp(this.configuration)
      .deleteEnginePreference(engineId, preferenceId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} engineId
   * @param {number} settingId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EnginesApi
   */
  public deleteEngineSetting(engineId: number, settingId: number, options?: AxiosRequestConfig) {
    return EnginesApiFp(this.configuration)
      .deleteEngineSetting(engineId, settingId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * This endpoint enables SSL for an engine in all the regions the engine already exists in. If the engine does not exist in any regions, the subscription\'s CRB will be used for the available regions. If a CRB is also not available, all regions with at least one applicable load balancer will be used. When determining the list of regions for the engine, only those regions that can/will have a certificate attached will be included. If SSL is already provisioned for the engine, the only mutations perfomed will be DNS record updates to ensure they are pointed to the correct load balancers.
   * @param {number} engineId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EnginesApi
   */
  public enableSSLForEngine(engineId: number, options?: AxiosRequestConfig) {
    return EnginesApiFp(this.configuration)
      .enableSSLForEngine(engineId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} engineId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EnginesApi
   */
  public getEngine(engineId: number, options?: AxiosRequestConfig) {
    return EnginesApiFp(this.configuration)
      .getEngine(engineId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} engineId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EnginesApi
   */
  public getEnginePreferences(engineId: number, options?: AxiosRequestConfig) {
    return EnginesApiFp(this.configuration)
      .getEnginePreferences(engineId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * This endpoint retrieves the status for an engine, which includes the regions in which the engine should exist, the SSL configuration and status, and the DNS configuration and status.
   * @param {number} engineId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EnginesApi
   */
  public getEngineStatus(engineId: number, options?: AxiosRequestConfig) {
    return EnginesApiFp(this.configuration)
      .getEngineStatus(engineId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} cname Search by cname
   * @param {number} [originId] Search by origin id
   * @param {number} [subscriptionId] Search by subscription id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EnginesApi
   */
  public getEngines(
    cname: string,
    originId?: number,
    subscriptionId?: number,
    options?: AxiosRequestConfig
  ) {
    return EnginesApiFp(this.configuration)
      .getEngines(cname, originId, subscriptionId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * This endpoint removes a region from an engine. If one exists, the DNS record will be deleted. Then the certificate, if it exists, will be removed from the load balancer and tagged for deletion.
   * @param {number} engineId
   * @param {string} region
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EnginesApi
   */
  public removeRegionFromEngine(engineId: number, region: string, options?: AxiosRequestConfig) {
    return EnginesApiFp(this.configuration)
      .removeRegionFromEngine(engineId, region, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Update an engine. Either an origin_id or origin must be supplied.
   * @param {number} engineId
   * @param {EngineUpdatable} [engineUpdatable]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EnginesApi
   */
  public updateEngine(
    engineId: number,
    engineUpdatable?: EngineUpdatable,
    options?: AxiosRequestConfig
  ) {
    return EnginesApiFp(this.configuration)
      .updateEngine(engineId, engineUpdatable, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} engineId
   * @param {number} preferenceId
   * @param {EnginePreference} [enginePreference]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EnginesApi
   */
  public updateEnginePreference(
    engineId: number,
    preferenceId: number,
    enginePreference?: EnginePreference,
    options?: AxiosRequestConfig
  ) {
    return EnginesApiFp(this.configuration)
      .updateEnginePreference(engineId, preferenceId, enginePreference, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Update an engine setting in its entirety. New path settings will be created, existing path settings will be updated, and omitted path settings will be deleted.
   * @param {number} engineId
   * @param {number} settingId
   * @param {EngineSettingUpdatable} [engineSettingUpdatable]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EnginesApi
   */
  public updateEngineSetting(
    engineId: number,
    settingId: number,
    engineSettingUpdatable?: EngineSettingUpdatable,
    options?: AxiosRequestConfig
  ) {
    return EnginesApiFp(this.configuration)
      .updateEngineSetting(engineId, settingId, engineSettingUpdatable, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {ValidatePathsRequest} [validatePathsRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EnginesApi
   */
  public validateEngineSettingPaths(
    validatePathsRequest?: ValidatePathsRequest,
    options?: AxiosRequestConfig
  ) {
    return EnginesApiFp(this.configuration)
      .validateEngineSettingPaths(validatePathsRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * LocationApi - axios parameter creator
 * @export
 */
export const LocationApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} ipAddress
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLocationFromIPAddress: async (
      ipAddress: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'ipAddress' is not null or undefined
      assertParamExists('getLocationFromIPAddress', 'ipAddress', ipAddress)
      const localVarPath = `/v1/location/geoip/{ip_address}`.replace(
        `{${'ip_address'}}`,
        encodeURIComponent(String(ipAddress))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLocationFromRequest: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v1/location/geoip`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * LocationApi - functional programming interface
 * @export
 */
export const LocationApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = LocationApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {string} ipAddress
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLocationFromIPAddress(
      ipAddress: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GeoIPLookup>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getLocationFromIPAddress(
        ipAddress,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLocationFromRequest(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GeoIPLookup>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getLocationFromRequest(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * LocationApi - factory interface
 * @export
 */
export const LocationApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = LocationApiFp(configuration)
  return {
    /**
     *
     * @param {string} ipAddress
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLocationFromIPAddress(ipAddress: string, options?: any): AxiosPromise<Array<GeoIPLookup>> {
      return localVarFp
        .getLocationFromIPAddress(ipAddress, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLocationFromRequest(options?: any): AxiosPromise<Array<GeoIPLookup>> {
      return localVarFp.getLocationFromRequest(options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * LocationApi - object-oriented interface
 * @export
 * @class LocationApi
 * @extends {BaseAPI}
 */
export class LocationApi extends BaseAPI {
  /**
   *
   * @param {string} ipAddress
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LocationApi
   */
  public getLocationFromIPAddress(ipAddress: string, options?: AxiosRequestConfig) {
    return LocationApiFp(this.configuration)
      .getLocationFromIPAddress(ipAddress, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LocationApi
   */
  public getLocationFromRequest(options?: AxiosRequestConfig) {
    return LocationApiFp(this.configuration)
      .getLocationFromRequest(options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * OriginsApi - axios parameter creator
 * @export
 */
export const OriginsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} subscriptionId
     * @param {OriginCreatable} [originCreatable]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOrigin: async (
      subscriptionId: number,
      originCreatable?: OriginCreatable,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'subscriptionId' is not null or undefined
      assertParamExists('createOrigin', 'subscriptionId', subscriptionId)
      const localVarPath = `/v1/subscriptions/{subscription_id}/origins`.replace(
        `{${'subscription_id'}}`,
        encodeURIComponent(String(subscriptionId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        originCreatable,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} originId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteOrigin: async (
      originId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'originId' is not null or undefined
      assertParamExists('deleteOrigin', 'originId', originId)
      const localVarPath = `/v1/origins/{origin_id}`.replace(
        `{${'origin_id'}}`,
        encodeURIComponent(String(originId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} originId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrigin: async (originId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'originId' is not null or undefined
      assertParamExists('getOrigin', 'originId', originId)
      const localVarPath = `/v1/origins/{origin_id}`.replace(
        `{${'origin_id'}}`,
        encodeURIComponent(String(originId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get all the possible origins for the site. Both the \'http\' and \'https\' versions are tested, and the \'https\' results are preferred for the response.
     * @param {string} siteUrl
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPossibleOriginsForSite: async (
      siteUrl: string,
      token: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'siteUrl' is not null or undefined
      assertParamExists('getPossibleOriginsForSite', 'siteUrl', siteUrl)
      // verify required parameter 'token' is not null or undefined
      assertParamExists('getPossibleOriginsForSite', 'token', token)
      const localVarPath = `/v1/possible-origins`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (siteUrl !== undefined) {
        localVarQueryParameter['site_url'] = siteUrl
      }

      if (token !== undefined) {
        localVarQueryParameter['token'] = token
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} originId
     * @param {OriginUpdatable} [originUpdatable]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateOrigin: async (
      originId: number,
      originUpdatable?: OriginUpdatable,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'originId' is not null or undefined
      assertParamExists('updateOrigin', 'originId', originId)
      const localVarPath = `/v1/origins/{origin_id}`.replace(
        `{${'origin_id'}}`,
        encodeURIComponent(String(originId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        originUpdatable,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * OriginsApi - functional programming interface
 * @export
 */
export const OriginsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = OriginsApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {number} subscriptionId
     * @param {OriginCreatable} [originCreatable]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createOrigin(
      subscriptionId: number,
      originCreatable?: OriginCreatable,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Origin>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createOrigin(
        subscriptionId,
        originCreatable,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} originId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteOrigin(
      originId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOrigin(originId, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} originId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrigin(
      originId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Origin>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getOrigin(originId, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Get all the possible origins for the site. Both the \'http\' and \'https\' versions are tested, and the \'https\' results are preferred for the response.
     * @param {string} siteUrl
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPossibleOriginsForSite(
      siteUrl: string,
      token: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPossibleOriginsForSite(
        siteUrl,
        token,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} originId
     * @param {OriginUpdatable} [originUpdatable]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateOrigin(
      originId: number,
      originUpdatable?: OriginUpdatable,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Origin>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrigin(
        originId,
        originUpdatable,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * OriginsApi - factory interface
 * @export
 */
export const OriginsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = OriginsApiFp(configuration)
  return {
    /**
     *
     * @param {number} subscriptionId
     * @param {OriginCreatable} [originCreatable]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOrigin(
      subscriptionId: number,
      originCreatable?: OriginCreatable,
      options?: any
    ): AxiosPromise<Origin> {
      return localVarFp
        .createOrigin(subscriptionId, originCreatable, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} originId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteOrigin(originId: number, options?: any): AxiosPromise<GenericResponse> {
      return localVarFp.deleteOrigin(originId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} originId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrigin(originId: number, options?: any): AxiosPromise<Origin> {
      return localVarFp.getOrigin(originId, options).then((request) => request(axios, basePath))
    },
    /**
     * Get all the possible origins for the site. Both the \'http\' and \'https\' versions are tested, and the \'https\' results are preferred for the response.
     * @param {string} siteUrl
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPossibleOriginsForSite(siteUrl: string, token: string, options?: any): AxiosPromise<object> {
      return localVarFp
        .getPossibleOriginsForSite(siteUrl, token, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} originId
     * @param {OriginUpdatable} [originUpdatable]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateOrigin(
      originId: number,
      originUpdatable?: OriginUpdatable,
      options?: any
    ): AxiosPromise<Origin> {
      return localVarFp
        .updateOrigin(originId, originUpdatable, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * OriginsApi - object-oriented interface
 * @export
 * @class OriginsApi
 * @extends {BaseAPI}
 */
export class OriginsApi extends BaseAPI {
  /**
   *
   * @param {number} subscriptionId
   * @param {OriginCreatable} [originCreatable]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OriginsApi
   */
  public createOrigin(
    subscriptionId: number,
    originCreatable?: OriginCreatable,
    options?: AxiosRequestConfig
  ) {
    return OriginsApiFp(this.configuration)
      .createOrigin(subscriptionId, originCreatable, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} originId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OriginsApi
   */
  public deleteOrigin(originId: number, options?: AxiosRequestConfig) {
    return OriginsApiFp(this.configuration)
      .deleteOrigin(originId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} originId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OriginsApi
   */
  public getOrigin(originId: number, options?: AxiosRequestConfig) {
    return OriginsApiFp(this.configuration)
      .getOrigin(originId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get all the possible origins for the site. Both the \'http\' and \'https\' versions are tested, and the \'https\' results are preferred for the response.
   * @param {string} siteUrl
   * @param {string} token
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OriginsApi
   */
  public getPossibleOriginsForSite(siteUrl: string, token: string, options?: AxiosRequestConfig) {
    return OriginsApiFp(this.configuration)
      .getPossibleOriginsForSite(siteUrl, token, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} originId
   * @param {OriginUpdatable} [originUpdatable]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OriginsApi
   */
  public updateOrigin(
    originId: number,
    originUpdatable?: OriginUpdatable,
    options?: AxiosRequestConfig
  ) {
    return OriginsApiFp(this.configuration)
      .updateOrigin(originId, originUpdatable, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * SearchApi - axios parameter creator
 * @export
 */
export const SearchApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Search for all of the resources available to the specified user, filtered by an optional term and with optional related records. This includes items that are available to teammates and subordinate users, if applicable.
     * @param {number} userId
     * @param {string} [term] Search term
     * @param {Array<'subscriptions' | 'tokens' | 'engines' | 'origins'>} [related] Fetch related records.  All related records are fetched by default.  May be specified multiple times like &#x60;?related&#x3D;engines&amp;related&#x3D;tokens&#x60;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchResourcesForUser: async (
      userId: number,
      term?: string,
      related?: Array<'subscriptions' | 'tokens' | 'engines' | 'origins'>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('searchResourcesForUser', 'userId', userId)
      const localVarPath = `/v1/users/{user_id}/search`.replace(
        `{${'user_id'}}`,
        encodeURIComponent(String(userId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (term !== undefined) {
        localVarQueryParameter['term'] = term
      }

      if (related) {
        localVarQueryParameter['related'] = related
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * SearchApi - functional programming interface
 * @export
 */
export const SearchApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SearchApiAxiosParamCreator(configuration)
  return {
    /**
     * Search for all of the resources available to the specified user, filtered by an optional term and with optional related records. This includes items that are available to teammates and subordinate users, if applicable.
     * @param {number} userId
     * @param {string} [term] Search term
     * @param {Array<'subscriptions' | 'tokens' | 'engines' | 'origins'>} [related] Fetch related records.  All related records are fetched by default.  May be specified multiple times like &#x60;?related&#x3D;engines&amp;related&#x3D;tokens&#x60;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchResourcesForUser(
      userId: number,
      term?: string,
      related?: Array<'subscriptions' | 'tokens' | 'engines' | 'origins'>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerSearchResult>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchResourcesForUser(
        userId,
        term,
        related,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * SearchApi - factory interface
 * @export
 */
export const SearchApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = SearchApiFp(configuration)
  return {
    /**
     * Search for all of the resources available to the specified user, filtered by an optional term and with optional related records. This includes items that are available to teammates and subordinate users, if applicable.
     * @param {number} userId
     * @param {string} [term] Search term
     * @param {Array<'subscriptions' | 'tokens' | 'engines' | 'origins'>} [related] Fetch related records.  All related records are fetched by default.  May be specified multiple times like &#x60;?related&#x3D;engines&amp;related&#x3D;tokens&#x60;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchResourcesForUser(
      userId: number,
      term?: string,
      related?: Array<'subscriptions' | 'tokens' | 'engines' | 'origins'>,
      options?: any
    ): AxiosPromise<CustomerSearchResult> {
      return localVarFp
        .searchResourcesForUser(userId, term, related, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * SearchApi - object-oriented interface
 * @export
 * @class SearchApi
 * @extends {BaseAPI}
 */
export class SearchApi extends BaseAPI {
  /**
   * Search for all of the resources available to the specified user, filtered by an optional term and with optional related records. This includes items that are available to teammates and subordinate users, if applicable.
   * @param {number} userId
   * @param {string} [term] Search term
   * @param {Array<'subscriptions' | 'tokens' | 'engines' | 'origins'>} [related] Fetch related records.  All related records are fetched by default.  May be specified multiple times like &#x60;?related&#x3D;engines&amp;related&#x3D;tokens&#x60;
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchApi
   */
  public searchResourcesForUser(
    userId: number,
    term?: string,
    related?: Array<'subscriptions' | 'tokens' | 'engines' | 'origins'>,
    options?: AxiosRequestConfig
  ) {
    return SearchApiFp(this.configuration)
      .searchResourcesForUser(userId, term, related, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * StatisticsApi - axios parameter creator
 * @export
 */
export const StatisticsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get the bytes by image format for an engine or subscription.
     * @param {number} start A unix timestamp.
     * @param {string} [cname] The cname from an engine. Required if token is not present. If provided along with a token, only the cname will be used.
     * @param {string} [token] A wurfl_token from the wurfl_tokens array on a subscrpition. Required if cname is not present. If provided along with a cname, only the cname will be used.
     * @param {number} [end] A unix timestamp. If omitted, the current unix timestamp will be used.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBytesByImageFormat: async (
      start: number,
      cname?: string,
      token?: string,
      end?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'start' is not null or undefined
      assertParamExists('getBytesByImageFormat', 'start', start)
      const localVarPath = `/v1/statistics/bytes_by_image_format`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (cname !== undefined) {
        localVarQueryParameter['cname'] = cname
      }

      if (token !== undefined) {
        localVarQueryParameter['token'] = token
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start
      }

      if (end !== undefined) {
        localVarQueryParameter['end'] = end
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get the cache hit ratio for an engine or subscription.
     * @param {number} start A unix timestamp.
     * @param {string} [cname] The cname from an engine. Required if token is not present. If provided along with a token, only the cname will be used.
     * @param {string} [token] A wurfl_token from the wurfl_tokens array on a subscrpition. Required if cname is not present. If provided along with a cname, only the cname will be used.
     * @param {number} [end] A unix timestamp. If omitted, the current unix timestamp will be used.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCacheHitRatio: async (
      start: number,
      cname?: string,
      token?: string,
      end?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'start' is not null or undefined
      assertParamExists('getCacheHitRatio', 'start', start)
      const localVarPath = `/v1/statistics/cache_hit_ratio`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (cname !== undefined) {
        localVarQueryParameter['cname'] = cname
      }

      if (token !== undefined) {
        localVarQueryParameter['token'] = token
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start
      }

      if (end !== undefined) {
        localVarQueryParameter['end'] = end
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get the cache hit ratio for an engine or subscription.
     * @param {number} start A unix timestamp.
     * @param {string} [cname] The cname from an engine. Required if token is not present. If provided along with a token, only the cname will be used.
     * @param {string} [token] A wurfl_token from the wurfl_tokens array on a subscrpition. Required if cname is not present. If provided along with a cname, only the cname will be used.
     * @param {number} [end] A unix timestamp. If omitted, the current unix timestamp will be used.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCacheHitRatioOverTime: async (
      start: number,
      cname?: string,
      token?: string,
      end?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'start' is not null or undefined
      assertParamExists('getCacheHitRatioOverTime', 'start', start)
      const localVarPath = `/v1/statistics/cache_hit_ratio_over_time`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (cname !== undefined) {
        localVarQueryParameter['cname'] = cname
      }

      if (token !== undefined) {
        localVarQueryParameter['token'] = token
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start
      }

      if (end !== undefined) {
        localVarQueryParameter['end'] = end
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get the data over time for an engine or subscription.
     * @param {number} start A unix timestamp.
     * @param {string} [cname] The cname from an engine. Required if token is not present. If provided along with a token, only the cname will be used.
     * @param {string} [token] A wurfl_token from the wurfl_tokens array on a subscrpition. Required if cname is not present. If provided along with a cname, only the cname will be used.
     * @param {number} [end] A unix timestamp. If omitted, the current unix timestamp will be used.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDataOverTime: async (
      start: number,
      cname?: string,
      token?: string,
      end?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'start' is not null or undefined
      assertParamExists('getDataOverTime', 'start', start)
      const localVarPath = `/v1/statistics/data_over_time`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (cname !== undefined) {
        localVarQueryParameter['cname'] = cname
      }

      if (token !== undefined) {
        localVarQueryParameter['token'] = token
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start
      }

      if (end !== undefined) {
        localVarQueryParameter['end'] = end
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get the requests by country code for an engine or subscription.
     * @param {number} start A unix timestamp.
     * @param {string} [cname] The cname from an engine. Required if token is not present. If provided along with a token, only the cname will be used.
     * @param {string} [token] A wurfl_token from the wurfl_tokens array on a subscrpition. Required if cname is not present. If provided along with a cname, only the cname will be used.
     * @param {number} [end] A unix timestamp. If omitted, the current unix timestamp will be used.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRequestsByCountryCode: async (
      start: number,
      cname?: string,
      token?: string,
      end?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'start' is not null or undefined
      assertParamExists('getRequestsByCountryCode', 'start', start)
      const localVarPath = `/v1/statistics/requests_by_country_code`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (cname !== undefined) {
        localVarQueryParameter['cname'] = cname
      }

      if (token !== undefined) {
        localVarQueryParameter['token'] = token
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start
      }

      if (end !== undefined) {
        localVarQueryParameter['end'] = end
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get the requests over time for an engine or subscription.
     * @param {number} start A unix timestamp.
     * @param {string} [cname] The cname from an engine. Required if token is not present. If provided along with a token, only the cname will be used.
     * @param {string} [token] A wurfl_token from the wurfl_tokens array on a subscrpition. Required if cname is not present. If provided along with a cname, only the cname will be used.
     * @param {number} [end] A unix timestamp. If omitted, the current unix timestamp will be used.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRequestsOverTime: async (
      start: number,
      cname?: string,
      token?: string,
      end?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'start' is not null or undefined
      assertParamExists('getRequestsOverTime', 'start', start)
      const localVarPath = `/v1/statistics/requests_over_time`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (cname !== undefined) {
        localVarQueryParameter['cname'] = cname
      }

      if (token !== undefined) {
        localVarQueryParameter['token'] = token
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start
      }

      if (end !== undefined) {
        localVarQueryParameter['end'] = end
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get the statistics for for an engine or subscription. This endpoint collects all the stat types at once and can be used when all the statistics are needed vs. using an endpoint for one specific stat.
     * @param {number} start A unix timestamp.
     * @param {string} [cname] The cname from an engine. Required if token is not present. If provided along with a token, only the cname will be used.
     * @param {string} [token] A wurfl_token from the wurfl_tokens array on a subscrpition. Required if cname is not present. If provided along with a cname, only the cname will be used.
     * @param {number} [end] A unix timestamp. If omitted, the current unix timestamp will be used.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStatistics: async (
      start: number,
      cname?: string,
      token?: string,
      end?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'start' is not null or undefined
      assertParamExists('getStatistics', 'start', start)
      const localVarPath = `/v1/statistics`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (cname !== undefined) {
        localVarQueryParameter['cname'] = cname
      }

      if (token !== undefined) {
        localVarQueryParameter['token'] = token
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start
      }

      if (end !== undefined) {
        localVarQueryParameter['end'] = end
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get the top missed items for an engine or subscription.
     * @param {number} start A unix timestamp.
     * @param {string} [cname] The cname from an engine. Required if token is not present. If provided along with a token, only the cname will be used.
     * @param {string} [token] A wurfl_token from the wurfl_tokens array on a subscrpition. Required if cname is not present. If provided along with a cname, only the cname will be used.
     * @param {number} [end] A unix timestamp. If omitted, the current unix timestamp will be used.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTopMissedItems: async (
      start: number,
      cname?: string,
      token?: string,
      end?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'start' is not null or undefined
      assertParamExists('getTopMissedItems', 'start', start)
      const localVarPath = `/v1/statistics/top_missed`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (cname !== undefined) {
        localVarQueryParameter['cname'] = cname
      }

      if (token !== undefined) {
        localVarQueryParameter['token'] = token
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start
      }

      if (end !== undefined) {
        localVarQueryParameter['end'] = end
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get the top requested items for an engine or subscription.
     * @param {number} start A unix timestamp.
     * @param {string} [cname] The cname from an engine. Required if token is not present. If provided along with a token, only the cname will be used.
     * @param {string} [token] A wurfl_token from the wurfl_tokens array on a subscrpition. Required if cname is not present. If provided along with a cname, only the cname will be used.
     * @param {number} [end] A unix timestamp. If omitted, the current unix timestamp will be used.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTopRequestedItems: async (
      start: number,
      cname?: string,
      token?: string,
      end?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'start' is not null or undefined
      assertParamExists('getTopRequestedItems', 'start', start)
      const localVarPath = `/v1/statistics/top_requested`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (cname !== undefined) {
        localVarQueryParameter['cname'] = cname
      }

      if (token !== undefined) {
        localVarQueryParameter['token'] = token
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start
      }

      if (end !== undefined) {
        localVarQueryParameter['end'] = end
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get the total usage for a subscription.
     * @param {number} subscriptionId
     * @param {number} start A unix timestamp.
     * @param {number} [end] A unix timestamp. If omitted, the current unix timestamp will be used.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTotalUsageForSubscription: async (
      subscriptionId: number,
      start: number,
      end?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'subscriptionId' is not null or undefined
      assertParamExists('getTotalUsageForSubscription', 'subscriptionId', subscriptionId)
      // verify required parameter 'start' is not null or undefined
      assertParamExists('getTotalUsageForSubscription', 'start', start)
      const localVarPath = `/v1/statistics/total_usage`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (subscriptionId !== undefined) {
        localVarQueryParameter['subscription_id'] = subscriptionId
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start
      }

      if (end !== undefined) {
        localVarQueryParameter['end'] = end
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get the total usage for all a user\'s subscriptions.
     * @param {number} userId
     * @param {number} start A unix timestamp.
     * @param {number} [end] A unix timestamp. If omitted, the current unix timestamp will be used.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTotalUsageForUser: async (
      userId: number,
      start: number,
      end?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('getTotalUsageForUser', 'userId', userId)
      // verify required parameter 'start' is not null or undefined
      assertParamExists('getTotalUsageForUser', 'start', start)
      const localVarPath = `/v1/statistics/users/{user_id}/total_usage`.replace(
        `{${'user_id'}}`,
        encodeURIComponent(String(userId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (start !== undefined) {
        localVarQueryParameter['start'] = start
      }

      if (end !== undefined) {
        localVarQueryParameter['end'] = end
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get the total usage over a time interval for a subscription.
     * @param {number} subscriptionId
     * @param {number} start A unix timestamp.
     * @param {number} [end] A unix timestamp. If omitted, the current unix timestamp will be used.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTotalUsageOverTimeForSubscription: async (
      subscriptionId: number,
      start: number,
      end?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'subscriptionId' is not null or undefined
      assertParamExists('getTotalUsageOverTimeForSubscription', 'subscriptionId', subscriptionId)
      // verify required parameter 'start' is not null or undefined
      assertParamExists('getTotalUsageOverTimeForSubscription', 'start', start)
      const localVarPath = `/v1/statistics/total_usage_over_time`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (subscriptionId !== undefined) {
        localVarQueryParameter['subscription_id'] = subscriptionId
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start
      }

      if (end !== undefined) {
        localVarQueryParameter['end'] = end
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get the total usage over time for all a user\'s subscriptions.
     * @param {number} userId
     * @param {number} start A unix timestamp.
     * @param {number} [end] A unix timestamp. If omitted, the current unix timestamp will be used.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTotalUsageOverTimeForUser: async (
      userId: number,
      start: number,
      end?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('getTotalUsageOverTimeForUser', 'userId', userId)
      // verify required parameter 'start' is not null or undefined
      assertParamExists('getTotalUsageOverTimeForUser', 'start', start)
      const localVarPath = `/v1/statistics/users/{user_id}/total_usage_over_time`.replace(
        `{${'user_id'}}`,
        encodeURIComponent(String(userId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (start !== undefined) {
        localVarQueryParameter['start'] = start
      }

      if (end !== undefined) {
        localVarQueryParameter['end'] = end
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get the usage trends for an engine or subscription.
     * @param {number} start A unix timestamp.
     * @param {string} [cname] The cname from an engine. Required if token is not present. If provided along with a token, only the cname will be used.
     * @param {string} [token] A wurfl_token from the wurfl_tokens array on a subscrpition. Required if cname is not present. If provided along with a cname, only the cname will be used.
     * @param {number} [end] A unix timestamp. If omitted, the current unix timestamp will be used.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUsageTrends: async (
      start: number,
      cname?: string,
      token?: string,
      end?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'start' is not null or undefined
      assertParamExists('getUsageTrends', 'start', start)
      const localVarPath = `/v1/statistics/usage_trends`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (cname !== undefined) {
        localVarQueryParameter['cname'] = cname
      }

      if (token !== undefined) {
        localVarQueryParameter['token'] = token
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start
      }

      if (end !== undefined) {
        localVarQueryParameter['end'] = end
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get the usage trends over time for an engine or subscription.
     * @param {number} start A unix timestamp.
     * @param {string} [cname] The cname from an engine. Required if token is not present. If provided along with a token, only the cname will be used.
     * @param {string} [token] A wurfl_token from the wurfl_tokens array on a subscrpition. Required if cname is not present. If provided along with a cname, only the cname will be used.
     * @param {number} [end] A unix timestamp. If omitted, the current unix timestamp will be used.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUsageTrendsOverTime: async (
      start: number,
      cname?: string,
      token?: string,
      end?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'start' is not null or undefined
      assertParamExists('getUsageTrendsOverTime', 'start', start)
      const localVarPath = `/v1/statistics/usage_trends_over_time`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (cname !== undefined) {
        localVarQueryParameter['cname'] = cname
      }

      if (token !== undefined) {
        localVarQueryParameter['token'] = token
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start
      }

      if (end !== undefined) {
        localVarQueryParameter['end'] = end
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * StatisticsApi - functional programming interface
 * @export
 */
export const StatisticsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = StatisticsApiAxiosParamCreator(configuration)
  return {
    /**
     * Get the bytes by image format for an engine or subscription.
     * @param {number} start A unix timestamp.
     * @param {string} [cname] The cname from an engine. Required if token is not present. If provided along with a token, only the cname will be used.
     * @param {string} [token] A wurfl_token from the wurfl_tokens array on a subscrpition. Required if cname is not present. If provided along with a cname, only the cname will be used.
     * @param {number} [end] A unix timestamp. If omitted, the current unix timestamp will be used.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBytesByImageFormat(
      start: number,
      cname?: string,
      token?: string,
      end?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2004>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBytesByImageFormat(
        start,
        cname,
        token,
        end,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Get the cache hit ratio for an engine or subscription.
     * @param {number} start A unix timestamp.
     * @param {string} [cname] The cname from an engine. Required if token is not present. If provided along with a token, only the cname will be used.
     * @param {string} [token] A wurfl_token from the wurfl_tokens array on a subscrpition. Required if cname is not present. If provided along with a cname, only the cname will be used.
     * @param {number} [end] A unix timestamp. If omitted, the current unix timestamp will be used.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCacheHitRatio(
      start: number,
      cname?: string,
      token?: string,
      end?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCacheHitRatio(
        start,
        cname,
        token,
        end,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Get the cache hit ratio for an engine or subscription.
     * @param {number} start A unix timestamp.
     * @param {string} [cname] The cname from an engine. Required if token is not present. If provided along with a token, only the cname will be used.
     * @param {string} [token] A wurfl_token from the wurfl_tokens array on a subscrpition. Required if cname is not present. If provided along with a cname, only the cname will be used.
     * @param {number} [end] A unix timestamp. If omitted, the current unix timestamp will be used.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCacheHitRatioOverTime(
      start: number,
      cname?: string,
      token?: string,
      end?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCacheHitRatioOverTime(
        start,
        cname,
        token,
        end,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Get the data over time for an engine or subscription.
     * @param {number} start A unix timestamp.
     * @param {string} [cname] The cname from an engine. Required if token is not present. If provided along with a token, only the cname will be used.
     * @param {string} [token] A wurfl_token from the wurfl_tokens array on a subscrpition. Required if cname is not present. If provided along with a cname, only the cname will be used.
     * @param {number} [end] A unix timestamp. If omitted, the current unix timestamp will be used.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDataOverTime(
      start: number,
      cname?: string,
      token?: string,
      end?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2002>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDataOverTime(
        start,
        cname,
        token,
        end,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Get the requests by country code for an engine or subscription.
     * @param {number} start A unix timestamp.
     * @param {string} [cname] The cname from an engine. Required if token is not present. If provided along with a token, only the cname will be used.
     * @param {string} [token] A wurfl_token from the wurfl_tokens array on a subscrpition. Required if cname is not present. If provided along with a cname, only the cname will be used.
     * @param {number} [end] A unix timestamp. If omitted, the current unix timestamp will be used.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRequestsByCountryCode(
      start: number,
      cname?: string,
      token?: string,
      end?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2005>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getRequestsByCountryCode(
        start,
        cname,
        token,
        end,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Get the requests over time for an engine or subscription.
     * @param {number} start A unix timestamp.
     * @param {string} [cname] The cname from an engine. Required if token is not present. If provided along with a token, only the cname will be used.
     * @param {string} [token] A wurfl_token from the wurfl_tokens array on a subscrpition. Required if cname is not present. If provided along with a cname, only the cname will be used.
     * @param {number} [end] A unix timestamp. If omitted, the current unix timestamp will be used.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRequestsOverTime(
      start: number,
      cname?: string,
      token?: string,
      end?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2003>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getRequestsOverTime(
        start,
        cname,
        token,
        end,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Get the statistics for for an engine or subscription. This endpoint collects all the stat types at once and can be used when all the statistics are needed vs. using an endpoint for one specific stat.
     * @param {number} start A unix timestamp.
     * @param {string} [cname] The cname from an engine. Required if token is not present. If provided along with a token, only the cname will be used.
     * @param {string} [token] A wurfl_token from the wurfl_tokens array on a subscrpition. Required if cname is not present. If provided along with a cname, only the cname will be used.
     * @param {number} [end] A unix timestamp. If omitted, the current unix timestamp will be used.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getStatistics(
      start: number,
      cname?: string,
      token?: string,
      end?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Statistics>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getStatistics(
        start,
        cname,
        token,
        end,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Get the top missed items for an engine or subscription.
     * @param {number} start A unix timestamp.
     * @param {string} [cname] The cname from an engine. Required if token is not present. If provided along with a token, only the cname will be used.
     * @param {string} [token] A wurfl_token from the wurfl_tokens array on a subscrpition. Required if cname is not present. If provided along with a cname, only the cname will be used.
     * @param {number} [end] A unix timestamp. If omitted, the current unix timestamp will be used.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTopMissedItems(
      start: number,
      cname?: string,
      token?: string,
      end?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2006>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTopMissedItems(
        start,
        cname,
        token,
        end,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Get the top requested items for an engine or subscription.
     * @param {number} start A unix timestamp.
     * @param {string} [cname] The cname from an engine. Required if token is not present. If provided along with a token, only the cname will be used.
     * @param {string} [token] A wurfl_token from the wurfl_tokens array on a subscrpition. Required if cname is not present. If provided along with a cname, only the cname will be used.
     * @param {number} [end] A unix timestamp. If omitted, the current unix timestamp will be used.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTopRequestedItems(
      start: number,
      cname?: string,
      token?: string,
      end?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2007>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTopRequestedItems(
        start,
        cname,
        token,
        end,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Get the total usage for a subscription.
     * @param {number} subscriptionId
     * @param {number} start A unix timestamp.
     * @param {number} [end] A unix timestamp. If omitted, the current unix timestamp will be used.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTotalUsageForSubscription(
      subscriptionId: number,
      start: number,
      end?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20010>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTotalUsageForSubscription(
        subscriptionId,
        start,
        end,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Get the total usage for all a user\'s subscriptions.
     * @param {number} userId
     * @param {number} start A unix timestamp.
     * @param {number} [end] A unix timestamp. If omitted, the current unix timestamp will be used.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTotalUsageForUser(
      userId: number,
      start: number,
      end?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20012>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTotalUsageForUser(
        userId,
        start,
        end,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Get the total usage over a time interval for a subscription.
     * @param {number} subscriptionId
     * @param {number} start A unix timestamp.
     * @param {number} [end] A unix timestamp. If omitted, the current unix timestamp will be used.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTotalUsageOverTimeForSubscription(
      subscriptionId: number,
      start: number,
      end?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20011>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getTotalUsageOverTimeForSubscription(
          subscriptionId,
          start,
          end,
          options
        )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Get the total usage over time for all a user\'s subscriptions.
     * @param {number} userId
     * @param {number} start A unix timestamp.
     * @param {number} [end] A unix timestamp. If omitted, the current unix timestamp will be used.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTotalUsageOverTimeForUser(
      userId: number,
      start: number,
      end?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20013>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTotalUsageOverTimeForUser(
        userId,
        start,
        end,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Get the usage trends for an engine or subscription.
     * @param {number} start A unix timestamp.
     * @param {string} [cname] The cname from an engine. Required if token is not present. If provided along with a token, only the cname will be used.
     * @param {string} [token] A wurfl_token from the wurfl_tokens array on a subscrpition. Required if cname is not present. If provided along with a cname, only the cname will be used.
     * @param {number} [end] A unix timestamp. If omitted, the current unix timestamp will be used.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUsageTrends(
      start: number,
      cname?: string,
      token?: string,
      end?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2008>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUsageTrends(
        start,
        cname,
        token,
        end,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Get the usage trends over time for an engine or subscription.
     * @param {number} start A unix timestamp.
     * @param {string} [cname] The cname from an engine. Required if token is not present. If provided along with a token, only the cname will be used.
     * @param {string} [token] A wurfl_token from the wurfl_tokens array on a subscrpition. Required if cname is not present. If provided along with a cname, only the cname will be used.
     * @param {number} [end] A unix timestamp. If omitted, the current unix timestamp will be used.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUsageTrendsOverTime(
      start: number,
      cname?: string,
      token?: string,
      end?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2009>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUsageTrendsOverTime(
        start,
        cname,
        token,
        end,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * StatisticsApi - factory interface
 * @export
 */
export const StatisticsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = StatisticsApiFp(configuration)
  return {
    /**
     * Get the bytes by image format for an engine or subscription.
     * @param {number} start A unix timestamp.
     * @param {string} [cname] The cname from an engine. Required if token is not present. If provided along with a token, only the cname will be used.
     * @param {string} [token] A wurfl_token from the wurfl_tokens array on a subscrpition. Required if cname is not present. If provided along with a cname, only the cname will be used.
     * @param {number} [end] A unix timestamp. If omitted, the current unix timestamp will be used.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBytesByImageFormat(
      start: number,
      cname?: string,
      token?: string,
      end?: number,
      options?: any
    ): AxiosPromise<InlineResponse2004> {
      return localVarFp
        .getBytesByImageFormat(start, cname, token, end, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Get the cache hit ratio for an engine or subscription.
     * @param {number} start A unix timestamp.
     * @param {string} [cname] The cname from an engine. Required if token is not present. If provided along with a token, only the cname will be used.
     * @param {string} [token] A wurfl_token from the wurfl_tokens array on a subscrpition. Required if cname is not present. If provided along with a cname, only the cname will be used.
     * @param {number} [end] A unix timestamp. If omitted, the current unix timestamp will be used.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCacheHitRatio(
      start: number,
      cname?: string,
      token?: string,
      end?: number,
      options?: any
    ): AxiosPromise<InlineResponse200> {
      return localVarFp
        .getCacheHitRatio(start, cname, token, end, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Get the cache hit ratio for an engine or subscription.
     * @param {number} start A unix timestamp.
     * @param {string} [cname] The cname from an engine. Required if token is not present. If provided along with a token, only the cname will be used.
     * @param {string} [token] A wurfl_token from the wurfl_tokens array on a subscrpition. Required if cname is not present. If provided along with a cname, only the cname will be used.
     * @param {number} [end] A unix timestamp. If omitted, the current unix timestamp will be used.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCacheHitRatioOverTime(
      start: number,
      cname?: string,
      token?: string,
      end?: number,
      options?: any
    ): AxiosPromise<InlineResponse2001> {
      return localVarFp
        .getCacheHitRatioOverTime(start, cname, token, end, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Get the data over time for an engine or subscription.
     * @param {number} start A unix timestamp.
     * @param {string} [cname] The cname from an engine. Required if token is not present. If provided along with a token, only the cname will be used.
     * @param {string} [token] A wurfl_token from the wurfl_tokens array on a subscrpition. Required if cname is not present. If provided along with a cname, only the cname will be used.
     * @param {number} [end] A unix timestamp. If omitted, the current unix timestamp will be used.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDataOverTime(
      start: number,
      cname?: string,
      token?: string,
      end?: number,
      options?: any
    ): AxiosPromise<InlineResponse2002> {
      return localVarFp
        .getDataOverTime(start, cname, token, end, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Get the requests by country code for an engine or subscription.
     * @param {number} start A unix timestamp.
     * @param {string} [cname] The cname from an engine. Required if token is not present. If provided along with a token, only the cname will be used.
     * @param {string} [token] A wurfl_token from the wurfl_tokens array on a subscrpition. Required if cname is not present. If provided along with a cname, only the cname will be used.
     * @param {number} [end] A unix timestamp. If omitted, the current unix timestamp will be used.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRequestsByCountryCode(
      start: number,
      cname?: string,
      token?: string,
      end?: number,
      options?: any
    ): AxiosPromise<InlineResponse2005> {
      return localVarFp
        .getRequestsByCountryCode(start, cname, token, end, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Get the requests over time for an engine or subscription.
     * @param {number} start A unix timestamp.
     * @param {string} [cname] The cname from an engine. Required if token is not present. If provided along with a token, only the cname will be used.
     * @param {string} [token] A wurfl_token from the wurfl_tokens array on a subscrpition. Required if cname is not present. If provided along with a cname, only the cname will be used.
     * @param {number} [end] A unix timestamp. If omitted, the current unix timestamp will be used.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRequestsOverTime(
      start: number,
      cname?: string,
      token?: string,
      end?: number,
      options?: any
    ): AxiosPromise<InlineResponse2003> {
      return localVarFp
        .getRequestsOverTime(start, cname, token, end, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Get the statistics for for an engine or subscription. This endpoint collects all the stat types at once and can be used when all the statistics are needed vs. using an endpoint for one specific stat.
     * @param {number} start A unix timestamp.
     * @param {string} [cname] The cname from an engine. Required if token is not present. If provided along with a token, only the cname will be used.
     * @param {string} [token] A wurfl_token from the wurfl_tokens array on a subscrpition. Required if cname is not present. If provided along with a cname, only the cname will be used.
     * @param {number} [end] A unix timestamp. If omitted, the current unix timestamp will be used.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStatistics(
      start: number,
      cname?: string,
      token?: string,
      end?: number,
      options?: any
    ): AxiosPromise<Statistics> {
      return localVarFp
        .getStatistics(start, cname, token, end, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Get the top missed items for an engine or subscription.
     * @param {number} start A unix timestamp.
     * @param {string} [cname] The cname from an engine. Required if token is not present. If provided along with a token, only the cname will be used.
     * @param {string} [token] A wurfl_token from the wurfl_tokens array on a subscrpition. Required if cname is not present. If provided along with a cname, only the cname will be used.
     * @param {number} [end] A unix timestamp. If omitted, the current unix timestamp will be used.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTopMissedItems(
      start: number,
      cname?: string,
      token?: string,
      end?: number,
      options?: any
    ): AxiosPromise<InlineResponse2006> {
      return localVarFp
        .getTopMissedItems(start, cname, token, end, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Get the top requested items for an engine or subscription.
     * @param {number} start A unix timestamp.
     * @param {string} [cname] The cname from an engine. Required if token is not present. If provided along with a token, only the cname will be used.
     * @param {string} [token] A wurfl_token from the wurfl_tokens array on a subscrpition. Required if cname is not present. If provided along with a cname, only the cname will be used.
     * @param {number} [end] A unix timestamp. If omitted, the current unix timestamp will be used.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTopRequestedItems(
      start: number,
      cname?: string,
      token?: string,
      end?: number,
      options?: any
    ): AxiosPromise<InlineResponse2007> {
      return localVarFp
        .getTopRequestedItems(start, cname, token, end, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Get the total usage for a subscription.
     * @param {number} subscriptionId
     * @param {number} start A unix timestamp.
     * @param {number} [end] A unix timestamp. If omitted, the current unix timestamp will be used.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTotalUsageForSubscription(
      subscriptionId: number,
      start: number,
      end?: number,
      options?: any
    ): AxiosPromise<InlineResponse20010> {
      return localVarFp
        .getTotalUsageForSubscription(subscriptionId, start, end, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Get the total usage for all a user\'s subscriptions.
     * @param {number} userId
     * @param {number} start A unix timestamp.
     * @param {number} [end] A unix timestamp. If omitted, the current unix timestamp will be used.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTotalUsageForUser(
      userId: number,
      start: number,
      end?: number,
      options?: any
    ): AxiosPromise<InlineResponse20012> {
      return localVarFp
        .getTotalUsageForUser(userId, start, end, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Get the total usage over a time interval for a subscription.
     * @param {number} subscriptionId
     * @param {number} start A unix timestamp.
     * @param {number} [end] A unix timestamp. If omitted, the current unix timestamp will be used.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTotalUsageOverTimeForSubscription(
      subscriptionId: number,
      start: number,
      end?: number,
      options?: any
    ): AxiosPromise<InlineResponse20011> {
      return localVarFp
        .getTotalUsageOverTimeForSubscription(subscriptionId, start, end, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Get the total usage over time for all a user\'s subscriptions.
     * @param {number} userId
     * @param {number} start A unix timestamp.
     * @param {number} [end] A unix timestamp. If omitted, the current unix timestamp will be used.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTotalUsageOverTimeForUser(
      userId: number,
      start: number,
      end?: number,
      options?: any
    ): AxiosPromise<InlineResponse20013> {
      return localVarFp
        .getTotalUsageOverTimeForUser(userId, start, end, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Get the usage trends for an engine or subscription.
     * @param {number} start A unix timestamp.
     * @param {string} [cname] The cname from an engine. Required if token is not present. If provided along with a token, only the cname will be used.
     * @param {string} [token] A wurfl_token from the wurfl_tokens array on a subscrpition. Required if cname is not present. If provided along with a cname, only the cname will be used.
     * @param {number} [end] A unix timestamp. If omitted, the current unix timestamp will be used.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUsageTrends(
      start: number,
      cname?: string,
      token?: string,
      end?: number,
      options?: any
    ): AxiosPromise<InlineResponse2008> {
      return localVarFp
        .getUsageTrends(start, cname, token, end, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Get the usage trends over time for an engine or subscription.
     * @param {number} start A unix timestamp.
     * @param {string} [cname] The cname from an engine. Required if token is not present. If provided along with a token, only the cname will be used.
     * @param {string} [token] A wurfl_token from the wurfl_tokens array on a subscrpition. Required if cname is not present. If provided along with a cname, only the cname will be used.
     * @param {number} [end] A unix timestamp. If omitted, the current unix timestamp will be used.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUsageTrendsOverTime(
      start: number,
      cname?: string,
      token?: string,
      end?: number,
      options?: any
    ): AxiosPromise<InlineResponse2009> {
      return localVarFp
        .getUsageTrendsOverTime(start, cname, token, end, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * StatisticsApi - object-oriented interface
 * @export
 * @class StatisticsApi
 * @extends {BaseAPI}
 */
export class StatisticsApi extends BaseAPI {
  /**
   * Get the bytes by image format for an engine or subscription.
   * @param {number} start A unix timestamp.
   * @param {string} [cname] The cname from an engine. Required if token is not present. If provided along with a token, only the cname will be used.
   * @param {string} [token] A wurfl_token from the wurfl_tokens array on a subscrpition. Required if cname is not present. If provided along with a cname, only the cname will be used.
   * @param {number} [end] A unix timestamp. If omitted, the current unix timestamp will be used.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatisticsApi
   */
  public getBytesByImageFormat(
    start: number,
    cname?: string,
    token?: string,
    end?: number,
    options?: AxiosRequestConfig
  ) {
    return StatisticsApiFp(this.configuration)
      .getBytesByImageFormat(start, cname, token, end, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get the cache hit ratio for an engine or subscription.
   * @param {number} start A unix timestamp.
   * @param {string} [cname] The cname from an engine. Required if token is not present. If provided along with a token, only the cname will be used.
   * @param {string} [token] A wurfl_token from the wurfl_tokens array on a subscrpition. Required if cname is not present. If provided along with a cname, only the cname will be used.
   * @param {number} [end] A unix timestamp. If omitted, the current unix timestamp will be used.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatisticsApi
   */
  public getCacheHitRatio(
    start: number,
    cname?: string,
    token?: string,
    end?: number,
    options?: AxiosRequestConfig
  ) {
    return StatisticsApiFp(this.configuration)
      .getCacheHitRatio(start, cname, token, end, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get the cache hit ratio for an engine or subscription.
   * @param {number} start A unix timestamp.
   * @param {string} [cname] The cname from an engine. Required if token is not present. If provided along with a token, only the cname will be used.
   * @param {string} [token] A wurfl_token from the wurfl_tokens array on a subscrpition. Required if cname is not present. If provided along with a cname, only the cname will be used.
   * @param {number} [end] A unix timestamp. If omitted, the current unix timestamp will be used.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatisticsApi
   */
  public getCacheHitRatioOverTime(
    start: number,
    cname?: string,
    token?: string,
    end?: number,
    options?: AxiosRequestConfig
  ) {
    return StatisticsApiFp(this.configuration)
      .getCacheHitRatioOverTime(start, cname, token, end, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get the data over time for an engine or subscription.
   * @param {number} start A unix timestamp.
   * @param {string} [cname] The cname from an engine. Required if token is not present. If provided along with a token, only the cname will be used.
   * @param {string} [token] A wurfl_token from the wurfl_tokens array on a subscrpition. Required if cname is not present. If provided along with a cname, only the cname will be used.
   * @param {number} [end] A unix timestamp. If omitted, the current unix timestamp will be used.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatisticsApi
   */
  public getDataOverTime(
    start: number,
    cname?: string,
    token?: string,
    end?: number,
    options?: AxiosRequestConfig
  ) {
    return StatisticsApiFp(this.configuration)
      .getDataOverTime(start, cname, token, end, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get the requests by country code for an engine or subscription.
   * @param {number} start A unix timestamp.
   * @param {string} [cname] The cname from an engine. Required if token is not present. If provided along with a token, only the cname will be used.
   * @param {string} [token] A wurfl_token from the wurfl_tokens array on a subscrpition. Required if cname is not present. If provided along with a cname, only the cname will be used.
   * @param {number} [end] A unix timestamp. If omitted, the current unix timestamp will be used.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatisticsApi
   */
  public getRequestsByCountryCode(
    start: number,
    cname?: string,
    token?: string,
    end?: number,
    options?: AxiosRequestConfig
  ) {
    return StatisticsApiFp(this.configuration)
      .getRequestsByCountryCode(start, cname, token, end, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get the requests over time for an engine or subscription.
   * @param {number} start A unix timestamp.
   * @param {string} [cname] The cname from an engine. Required if token is not present. If provided along with a token, only the cname will be used.
   * @param {string} [token] A wurfl_token from the wurfl_tokens array on a subscrpition. Required if cname is not present. If provided along with a cname, only the cname will be used.
   * @param {number} [end] A unix timestamp. If omitted, the current unix timestamp will be used.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatisticsApi
   */
  public getRequestsOverTime(
    start: number,
    cname?: string,
    token?: string,
    end?: number,
    options?: AxiosRequestConfig
  ) {
    return StatisticsApiFp(this.configuration)
      .getRequestsOverTime(start, cname, token, end, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get the statistics for for an engine or subscription. This endpoint collects all the stat types at once and can be used when all the statistics are needed vs. using an endpoint for one specific stat.
   * @param {number} start A unix timestamp.
   * @param {string} [cname] The cname from an engine. Required if token is not present. If provided along with a token, only the cname will be used.
   * @param {string} [token] A wurfl_token from the wurfl_tokens array on a subscrpition. Required if cname is not present. If provided along with a cname, only the cname will be used.
   * @param {number} [end] A unix timestamp. If omitted, the current unix timestamp will be used.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatisticsApi
   */
  public getStatistics(
    start: number,
    cname?: string,
    token?: string,
    end?: number,
    options?: AxiosRequestConfig
  ) {
    return StatisticsApiFp(this.configuration)
      .getStatistics(start, cname, token, end, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get the top missed items for an engine or subscription.
   * @param {number} start A unix timestamp.
   * @param {string} [cname] The cname from an engine. Required if token is not present. If provided along with a token, only the cname will be used.
   * @param {string} [token] A wurfl_token from the wurfl_tokens array on a subscrpition. Required if cname is not present. If provided along with a cname, only the cname will be used.
   * @param {number} [end] A unix timestamp. If omitted, the current unix timestamp will be used.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatisticsApi
   */
  public getTopMissedItems(
    start: number,
    cname?: string,
    token?: string,
    end?: number,
    options?: AxiosRequestConfig
  ) {
    return StatisticsApiFp(this.configuration)
      .getTopMissedItems(start, cname, token, end, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get the top requested items for an engine or subscription.
   * @param {number} start A unix timestamp.
   * @param {string} [cname] The cname from an engine. Required if token is not present. If provided along with a token, only the cname will be used.
   * @param {string} [token] A wurfl_token from the wurfl_tokens array on a subscrpition. Required if cname is not present. If provided along with a cname, only the cname will be used.
   * @param {number} [end] A unix timestamp. If omitted, the current unix timestamp will be used.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatisticsApi
   */
  public getTopRequestedItems(
    start: number,
    cname?: string,
    token?: string,
    end?: number,
    options?: AxiosRequestConfig
  ) {
    return StatisticsApiFp(this.configuration)
      .getTopRequestedItems(start, cname, token, end, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get the total usage for a subscription.
   * @param {number} subscriptionId
   * @param {number} start A unix timestamp.
   * @param {number} [end] A unix timestamp. If omitted, the current unix timestamp will be used.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatisticsApi
   */
  public getTotalUsageForSubscription(
    subscriptionId: number,
    start: number,
    end?: number,
    options?: AxiosRequestConfig
  ) {
    return StatisticsApiFp(this.configuration)
      .getTotalUsageForSubscription(subscriptionId, start, end, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get the total usage for all a user\'s subscriptions.
   * @param {number} userId
   * @param {number} start A unix timestamp.
   * @param {number} [end] A unix timestamp. If omitted, the current unix timestamp will be used.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatisticsApi
   */
  public getTotalUsageForUser(
    userId: number,
    start: number,
    end?: number,
    options?: AxiosRequestConfig
  ) {
    return StatisticsApiFp(this.configuration)
      .getTotalUsageForUser(userId, start, end, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get the total usage over a time interval for a subscription.
   * @param {number} subscriptionId
   * @param {number} start A unix timestamp.
   * @param {number} [end] A unix timestamp. If omitted, the current unix timestamp will be used.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatisticsApi
   */
  public getTotalUsageOverTimeForSubscription(
    subscriptionId: number,
    start: number,
    end?: number,
    options?: AxiosRequestConfig
  ) {
    return StatisticsApiFp(this.configuration)
      .getTotalUsageOverTimeForSubscription(subscriptionId, start, end, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get the total usage over time for all a user\'s subscriptions.
   * @param {number} userId
   * @param {number} start A unix timestamp.
   * @param {number} [end] A unix timestamp. If omitted, the current unix timestamp will be used.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatisticsApi
   */
  public getTotalUsageOverTimeForUser(
    userId: number,
    start: number,
    end?: number,
    options?: AxiosRequestConfig
  ) {
    return StatisticsApiFp(this.configuration)
      .getTotalUsageOverTimeForUser(userId, start, end, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get the usage trends for an engine or subscription.
   * @param {number} start A unix timestamp.
   * @param {string} [cname] The cname from an engine. Required if token is not present. If provided along with a token, only the cname will be used.
   * @param {string} [token] A wurfl_token from the wurfl_tokens array on a subscrpition. Required if cname is not present. If provided along with a cname, only the cname will be used.
   * @param {number} [end] A unix timestamp. If omitted, the current unix timestamp will be used.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatisticsApi
   */
  public getUsageTrends(
    start: number,
    cname?: string,
    token?: string,
    end?: number,
    options?: AxiosRequestConfig
  ) {
    return StatisticsApiFp(this.configuration)
      .getUsageTrends(start, cname, token, end, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get the usage trends over time for an engine or subscription.
   * @param {number} start A unix timestamp.
   * @param {string} [cname] The cname from an engine. Required if token is not present. If provided along with a token, only the cname will be used.
   * @param {string} [token] A wurfl_token from the wurfl_tokens array on a subscrpition. Required if cname is not present. If provided along with a cname, only the cname will be used.
   * @param {number} [end] A unix timestamp. If omitted, the current unix timestamp will be used.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatisticsApi
   */
  public getUsageTrendsOverTime(
    start: number,
    cname?: string,
    token?: string,
    end?: number,
    options?: AxiosRequestConfig
  ) {
    return StatisticsApiFp(this.configuration)
      .getUsageTrendsOverTime(start, cname, token, end, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * SubscriptionsApi - axios parameter creator
 * @export
 */
export const SubscriptionsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} subscriptionId
     * @param {SubscriptionCancelable} [subscriptionCancelable]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelSubscription: async (
      subscriptionId: number,
      subscriptionCancelable?: SubscriptionCancelable,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'subscriptionId' is not null or undefined
      assertParamExists('cancelSubscription', 'subscriptionId', subscriptionId)
      const localVarPath = `/v1/subscriptions/{subscription_id}/cancel`.replace(
        `{${'subscription_id'}}`,
        encodeURIComponent(String(subscriptionId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        subscriptionCancelable,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} subscriptionId
     * @param {SubscriptionCancelable} [subscriptionCancelable]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelSubscriptionV2: async (
      subscriptionId: number,
      subscriptionCancelable?: SubscriptionCancelable,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'subscriptionId' is not null or undefined
      assertParamExists('cancelSubscriptionV2', 'subscriptionId', subscriptionId)
      const localVarPath = `/v2/subscriptions/{subscription_id}/cancel`.replace(
        `{${'subscription_id'}}`,
        encodeURIComponent(String(subscriptionId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        subscriptionCancelable,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} userId
     * @param {SubscriptionCreatable} [subscriptionCreatable]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSubscriptionForUser: async (
      userId: number,
      subscriptionCreatable?: SubscriptionCreatable,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('createSubscriptionForUser', 'userId', userId)
      const localVarPath = `/v1/users/{user_id}/subscriptions`.replace(
        `{${'user_id'}}`,
        encodeURIComponent(String(userId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        subscriptionCreatable,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} userId
     * @param {SubscriptionEditable} [subscriptionEditable]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSubscriptionForUserV2: async (
      userId: number,
      subscriptionEditable?: SubscriptionEditable,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('createSubscriptionForUserV2', 'userId', userId)
      const localVarPath = `/v2/users/{user_id}/subscriptions`.replace(
        `{${'user_id'}}`,
        encodeURIComponent(String(userId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        subscriptionEditable,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} subscriptionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSubscription: async (
      subscriptionId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'subscriptionId' is not null or undefined
      assertParamExists('getSubscription', 'subscriptionId', subscriptionId)
      const localVarPath = `/v1/subscriptions/{subscription_id}`.replace(
        `{${'subscription_id'}}`,
        encodeURIComponent(String(subscriptionId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} subscriptionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSubscriptionBillingHistory: async (
      subscriptionId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'subscriptionId' is not null or undefined
      assertParamExists('getSubscriptionBillingHistory', 'subscriptionId', subscriptionId)
      const localVarPath = `/v1/subscriptions/{subscription_id}/billing/history`.replace(
        `{${'subscription_id'}}`,
        encodeURIComponent(String(subscriptionId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} subscriptionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSubscriptionBillingHistoryV2: async (
      subscriptionId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'subscriptionId' is not null or undefined
      assertParamExists('getSubscriptionBillingHistoryV2', 'subscriptionId', subscriptionId)
      const localVarPath = `/v2/subscriptions/{subscription_id}/billing/history`.replace(
        `{${'subscription_id'}}`,
        encodeURIComponent(String(subscriptionId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} subscriptionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSubscriptionBillingInfo: async (
      subscriptionId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'subscriptionId' is not null or undefined
      assertParamExists('getSubscriptionBillingInfo', 'subscriptionId', subscriptionId)
      const localVarPath = `/v1/subscriptions/{subscription_id}/billing/info`.replace(
        `{${'subscription_id'}}`,
        encodeURIComponent(String(subscriptionId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} subscriptionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSubscriptionBillingInfoV2: async (
      subscriptionId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'subscriptionId' is not null or undefined
      assertParamExists('getSubscriptionBillingInfoV2', 'subscriptionId', subscriptionId)
      const localVarPath = `/v2/subscriptions/{subscription_id}/billing/info`.replace(
        `{${'subscription_id'}}`,
        encodeURIComponent(String(subscriptionId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} subscriptionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSubscriptionBillingPeriods: async (
      subscriptionId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'subscriptionId' is not null or undefined
      assertParamExists('getSubscriptionBillingPeriods', 'subscriptionId', subscriptionId)
      const localVarPath = `/v1/subscriptions/{subscription_id}/billing/periods`.replace(
        `{${'subscription_id'}}`,
        encodeURIComponent(String(subscriptionId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} subscriptionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSubscriptionRegions: async (
      subscriptionId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'subscriptionId' is not null or undefined
      assertParamExists('getSubscriptionRegions', 'subscriptionId', subscriptionId)
      const localVarPath = `/v1/subscriptions/{subscription_id}/regions`.replace(
        `{${'subscription_id'}}`,
        encodeURIComponent(String(subscriptionId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSubscriptionsByUserId: async (
      userId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('getSubscriptionsByUserId', 'userId', userId)
      const localVarPath = `/v1/users/{user_id}/subscriptions`.replace(
        `{${'user_id'}}`,
        encodeURIComponent(String(userId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Set billing information for a subscription. This should only be used when the subscription doesn\'t already have billing information.
     * @param {number} subscriptionId
     * @param {BillingInformation} [billingInformation]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setSubscriptionBillingInfo: async (
      subscriptionId: number,
      billingInformation?: BillingInformation,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'subscriptionId' is not null or undefined
      assertParamExists('setSubscriptionBillingInfo', 'subscriptionId', subscriptionId)
      const localVarPath = `/v1/subscriptions/{subscription_id}/billing/info`.replace(
        `{${'subscription_id'}}`,
        encodeURIComponent(String(subscriptionId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        billingInformation,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Set billing information for a subscription. This should only be used when the subscription doesn\'t already have billing information.
     * @param {number} subscriptionId
     * @param {BillingInformationCreatable} [billingInformationCreatable]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setSubscriptionBillingInfoV2: async (
      subscriptionId: number,
      billingInformationCreatable?: BillingInformationCreatable,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'subscriptionId' is not null or undefined
      assertParamExists('setSubscriptionBillingInfoV2', 'subscriptionId', subscriptionId)
      const localVarPath = `/v2/subscriptions/{subscription_id}/billing/info`.replace(
        `{${'subscription_id'}}`,
        encodeURIComponent(String(subscriptionId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        billingInformationCreatable,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} subscriptionId
     * @param {SubscriptionUpdatable} [subscriptionUpdatable]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSubscription: async (
      subscriptionId: number,
      subscriptionUpdatable?: SubscriptionUpdatable,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'subscriptionId' is not null or undefined
      assertParamExists('updateSubscription', 'subscriptionId', subscriptionId)
      const localVarPath = `/v1/subscriptions/{subscription_id}`.replace(
        `{${'subscription_id'}}`,
        encodeURIComponent(String(subscriptionId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        subscriptionUpdatable,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} subscriptionId
     * @param {Addons} [addons]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSubscriptionAddon: async (
      subscriptionId: number,
      addons?: Addons,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'subscriptionId' is not null or undefined
      assertParamExists('updateSubscriptionAddon', 'subscriptionId', subscriptionId)
      const localVarPath = `/v2/subscriptions/{subscription_id}/plan/addon`.replace(
        `{${'subscription_id'}}`,
        encodeURIComponent(String(subscriptionId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        addons,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Update billing information for a subscription. This should only be used after the user has submitted initial billing information. Only the address is available for updating.
     * @param {number} subscriptionId
     * @param {BillingInfoUpdate} [billingInfoUpdate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSubscriptionBillingInfo: async (
      subscriptionId: number,
      billingInfoUpdate?: BillingInfoUpdate,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'subscriptionId' is not null or undefined
      assertParamExists('updateSubscriptionBillingInfo', 'subscriptionId', subscriptionId)
      const localVarPath = `/v1/subscriptions/{subscription_id}/billing/info`.replace(
        `{${'subscription_id'}}`,
        encodeURIComponent(String(subscriptionId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        billingInfoUpdate,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Update billing information for a subscription. This should only be used after the user has submitted initial billing information. This will take a maxio token and apply the associated credit card as the default payment method
     * @param {number} subscriptionId
     * @param {InlineObject2} [inlineObject2]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSubscriptionBillingInfoV2: async (
      subscriptionId: number,
      inlineObject2?: InlineObject2,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'subscriptionId' is not null or undefined
      assertParamExists('updateSubscriptionBillingInfoV2', 'subscriptionId', subscriptionId)
      const localVarPath = `/v2/subscriptions/{subscription_id}/billing/info`.replace(
        `{${'subscription_id'}}`,
        encodeURIComponent(String(subscriptionId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        inlineObject2,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} subscriptionId
     * @param {BillingInformationCreatable} [billingInformationCreatable]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    upgradeLegacySubscriptionPlan: async (
      subscriptionId: number,
      billingInformationCreatable?: BillingInformationCreatable,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'subscriptionId' is not null or undefined
      assertParamExists('upgradeLegacySubscriptionPlan', 'subscriptionId', subscriptionId)
      const localVarPath = `/v2/subscriptions/{subscription_id}/plan/upgrade-legacy`.replace(
        `{${'subscription_id'}}`,
        encodeURIComponent(String(subscriptionId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        billingInformationCreatable,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} subscriptionId
     * @param {InlineObject3} [inlineObject3]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    upgradeSubscriptionPlan: async (
      subscriptionId: number,
      inlineObject3?: InlineObject3,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'subscriptionId' is not null or undefined
      assertParamExists('upgradeSubscriptionPlan', 'subscriptionId', subscriptionId)
      const localVarPath = `/v1/subscriptions/{subscription_id}/plan/upgrade`.replace(
        `{${'subscription_id'}}`,
        encodeURIComponent(String(subscriptionId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        inlineObject3,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} subscriptionId
     * @param {InlineObject4} [inlineObject4]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    upgradeSubscriptionPlanV2: async (
      subscriptionId: number,
      inlineObject4?: InlineObject4,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'subscriptionId' is not null or undefined
      assertParamExists('upgradeSubscriptionPlanV2', 'subscriptionId', subscriptionId)
      const localVarPath = `/v2/subscriptions/{subscription_id}/plan/upgrade`.replace(
        `{${'subscription_id'}}`,
        encodeURIComponent(String(subscriptionId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        inlineObject4,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * SubscriptionsApi - functional programming interface
 * @export
 */
export const SubscriptionsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SubscriptionsApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {number} subscriptionId
     * @param {SubscriptionCancelable} [subscriptionCancelable]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cancelSubscription(
      subscriptionId: number,
      subscriptionCancelable?: SubscriptionCancelable,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.cancelSubscription(
        subscriptionId,
        subscriptionCancelable,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} subscriptionId
     * @param {SubscriptionCancelable} [subscriptionCancelable]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cancelSubscriptionV2(
      subscriptionId: number,
      subscriptionCancelable?: SubscriptionCancelable,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.cancelSubscriptionV2(
        subscriptionId,
        subscriptionCancelable,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} userId
     * @param {SubscriptionCreatable} [subscriptionCreatable]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createSubscriptionForUser(
      userId: number,
      subscriptionCreatable?: SubscriptionCreatable,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Subscription>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createSubscriptionForUser(
        userId,
        subscriptionCreatable,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} userId
     * @param {SubscriptionEditable} [subscriptionEditable]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createSubscriptionForUserV2(
      userId: number,
      subscriptionEditable?: SubscriptionEditable,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Subscription>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createSubscriptionForUserV2(
        userId,
        subscriptionEditable,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} subscriptionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSubscription(
      subscriptionId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedSubscription>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSubscription(
        subscriptionId,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} subscriptionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSubscriptionBillingHistory(
      subscriptionId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingHistory>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSubscriptionBillingHistory(
        subscriptionId,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} subscriptionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSubscriptionBillingHistoryV2(
      subscriptionId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BillingHistoryV2>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSubscriptionBillingHistoryV2(
        subscriptionId,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} subscriptionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSubscriptionBillingInfo(
      subscriptionId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingInformation>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSubscriptionBillingInfo(
        subscriptionId,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} subscriptionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSubscriptionBillingInfoV2(
      subscriptionId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingInformationV2>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSubscriptionBillingInfoV2(
        subscriptionId,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} subscriptionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSubscriptionBillingPeriods(
      subscriptionId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BillingPeriod>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSubscriptionBillingPeriods(
        subscriptionId,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} subscriptionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSubscriptionRegions(
      subscriptionId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSubscriptionRegions(
        subscriptionId,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSubscriptionsByUserId(
      userId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DetailedSubscription>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSubscriptionsByUserId(
        userId,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Set billing information for a subscription. This should only be used when the subscription doesn\'t already have billing information.
     * @param {number} subscriptionId
     * @param {BillingInformation} [billingInformation]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async setSubscriptionBillingInfo(
      subscriptionId: number,
      billingInformation?: BillingInformation,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.setSubscriptionBillingInfo(
        subscriptionId,
        billingInformation,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Set billing information for a subscription. This should only be used when the subscription doesn\'t already have billing information.
     * @param {number} subscriptionId
     * @param {BillingInformationCreatable} [billingInformationCreatable]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async setSubscriptionBillingInfoV2(
      subscriptionId: number,
      billingInformationCreatable?: BillingInformationCreatable,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingInformationV2>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.setSubscriptionBillingInfoV2(
        subscriptionId,
        billingInformationCreatable,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} subscriptionId
     * @param {SubscriptionUpdatable} [subscriptionUpdatable]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateSubscription(
      subscriptionId: number,
      subscriptionUpdatable?: SubscriptionUpdatable,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedSubscription>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateSubscription(
        subscriptionId,
        subscriptionUpdatable,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} subscriptionId
     * @param {Addons} [addons]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateSubscriptionAddon(
      subscriptionId: number,
      addons?: Addons,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateSubscriptionAddon(
        subscriptionId,
        addons,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Update billing information for a subscription. This should only be used after the user has submitted initial billing information. Only the address is available for updating.
     * @param {number} subscriptionId
     * @param {BillingInfoUpdate} [billingInfoUpdate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateSubscriptionBillingInfo(
      subscriptionId: number,
      billingInfoUpdate?: BillingInfoUpdate,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateSubscriptionBillingInfo(
        subscriptionId,
        billingInfoUpdate,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Update billing information for a subscription. This should only be used after the user has submitted initial billing information. This will take a maxio token and apply the associated credit card as the default payment method
     * @param {number} subscriptionId
     * @param {InlineObject2} [inlineObject2]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateSubscriptionBillingInfoV2(
      subscriptionId: number,
      inlineObject2?: InlineObject2,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateSubscriptionBillingInfoV2(
        subscriptionId,
        inlineObject2,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} subscriptionId
     * @param {BillingInformationCreatable} [billingInformationCreatable]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async upgradeLegacySubscriptionPlan(
      subscriptionId: number,
      billingInformationCreatable?: BillingInformationCreatable,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingInformationV2>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.upgradeLegacySubscriptionPlan(
        subscriptionId,
        billingInformationCreatable,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} subscriptionId
     * @param {InlineObject3} [inlineObject3]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async upgradeSubscriptionPlan(
      subscriptionId: number,
      inlineObject3?: InlineObject3,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.upgradeSubscriptionPlan(
        subscriptionId,
        inlineObject3,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} subscriptionId
     * @param {InlineObject4} [inlineObject4]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async upgradeSubscriptionPlanV2(
      subscriptionId: number,
      inlineObject4?: InlineObject4,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingInformationV2>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.upgradeSubscriptionPlanV2(
        subscriptionId,
        inlineObject4,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * SubscriptionsApi - factory interface
 * @export
 */
export const SubscriptionsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = SubscriptionsApiFp(configuration)
  return {
    /**
     *
     * @param {number} subscriptionId
     * @param {SubscriptionCancelable} [subscriptionCancelable]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelSubscription(
      subscriptionId: number,
      subscriptionCancelable?: SubscriptionCancelable,
      options?: any
    ): AxiosPromise<GenericResponse> {
      return localVarFp
        .cancelSubscription(subscriptionId, subscriptionCancelable, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} subscriptionId
     * @param {SubscriptionCancelable} [subscriptionCancelable]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelSubscriptionV2(
      subscriptionId: number,
      subscriptionCancelable?: SubscriptionCancelable,
      options?: any
    ): AxiosPromise<GenericResponse> {
      return localVarFp
        .cancelSubscriptionV2(subscriptionId, subscriptionCancelable, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} userId
     * @param {SubscriptionCreatable} [subscriptionCreatable]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSubscriptionForUser(
      userId: number,
      subscriptionCreatable?: SubscriptionCreatable,
      options?: any
    ): AxiosPromise<Subscription> {
      return localVarFp
        .createSubscriptionForUser(userId, subscriptionCreatable, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} userId
     * @param {SubscriptionEditable} [subscriptionEditable]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSubscriptionForUserV2(
      userId: number,
      subscriptionEditable?: SubscriptionEditable,
      options?: any
    ): AxiosPromise<Subscription> {
      return localVarFp
        .createSubscriptionForUserV2(userId, subscriptionEditable, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} subscriptionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSubscription(subscriptionId: number, options?: any): AxiosPromise<DetailedSubscription> {
      return localVarFp
        .getSubscription(subscriptionId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} subscriptionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSubscriptionBillingHistory(
      subscriptionId: number,
      options?: any
    ): AxiosPromise<BillingHistory> {
      return localVarFp
        .getSubscriptionBillingHistory(subscriptionId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} subscriptionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSubscriptionBillingHistoryV2(
      subscriptionId: number,
      options?: any
    ): AxiosPromise<Array<BillingHistoryV2>> {
      return localVarFp
        .getSubscriptionBillingHistoryV2(subscriptionId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} subscriptionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSubscriptionBillingInfo(
      subscriptionId: number,
      options?: any
    ): AxiosPromise<BillingInformation> {
      return localVarFp
        .getSubscriptionBillingInfo(subscriptionId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} subscriptionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSubscriptionBillingInfoV2(
      subscriptionId: number,
      options?: any
    ): AxiosPromise<BillingInformationV2> {
      return localVarFp
        .getSubscriptionBillingInfoV2(subscriptionId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} subscriptionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSubscriptionBillingPeriods(
      subscriptionId: number,
      options?: any
    ): AxiosPromise<Array<BillingPeriod>> {
      return localVarFp
        .getSubscriptionBillingPeriods(subscriptionId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} subscriptionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSubscriptionRegions(subscriptionId: number, options?: any): AxiosPromise<Array<string>> {
      return localVarFp
        .getSubscriptionRegions(subscriptionId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSubscriptionsByUserId(
      userId: number,
      options?: any
    ): AxiosPromise<Array<DetailedSubscription>> {
      return localVarFp
        .getSubscriptionsByUserId(userId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Set billing information for a subscription. This should only be used when the subscription doesn\'t already have billing information.
     * @param {number} subscriptionId
     * @param {BillingInformation} [billingInformation]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setSubscriptionBillingInfo(
      subscriptionId: number,
      billingInformation?: BillingInformation,
      options?: any
    ): AxiosPromise<GenericResponse> {
      return localVarFp
        .setSubscriptionBillingInfo(subscriptionId, billingInformation, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Set billing information for a subscription. This should only be used when the subscription doesn\'t already have billing information.
     * @param {number} subscriptionId
     * @param {BillingInformationCreatable} [billingInformationCreatable]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setSubscriptionBillingInfoV2(
      subscriptionId: number,
      billingInformationCreatable?: BillingInformationCreatable,
      options?: any
    ): AxiosPromise<BillingInformationV2> {
      return localVarFp
        .setSubscriptionBillingInfoV2(subscriptionId, billingInformationCreatable, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} subscriptionId
     * @param {SubscriptionUpdatable} [subscriptionUpdatable]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSubscription(
      subscriptionId: number,
      subscriptionUpdatable?: SubscriptionUpdatable,
      options?: any
    ): AxiosPromise<DetailedSubscription> {
      return localVarFp
        .updateSubscription(subscriptionId, subscriptionUpdatable, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} subscriptionId
     * @param {Addons} [addons]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSubscriptionAddon(
      subscriptionId: number,
      addons?: Addons,
      options?: any
    ): AxiosPromise<GenericResponse> {
      return localVarFp
        .updateSubscriptionAddon(subscriptionId, addons, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Update billing information for a subscription. This should only be used after the user has submitted initial billing information. Only the address is available for updating.
     * @param {number} subscriptionId
     * @param {BillingInfoUpdate} [billingInfoUpdate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSubscriptionBillingInfo(
      subscriptionId: number,
      billingInfoUpdate?: BillingInfoUpdate,
      options?: any
    ): AxiosPromise<GenericResponse> {
      return localVarFp
        .updateSubscriptionBillingInfo(subscriptionId, billingInfoUpdate, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Update billing information for a subscription. This should only be used after the user has submitted initial billing information. This will take a maxio token and apply the associated credit card as the default payment method
     * @param {number} subscriptionId
     * @param {InlineObject2} [inlineObject2]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSubscriptionBillingInfoV2(
      subscriptionId: number,
      inlineObject2?: InlineObject2,
      options?: any
    ): AxiosPromise<GenericResponse> {
      return localVarFp
        .updateSubscriptionBillingInfoV2(subscriptionId, inlineObject2, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} subscriptionId
     * @param {BillingInformationCreatable} [billingInformationCreatable]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    upgradeLegacySubscriptionPlan(
      subscriptionId: number,
      billingInformationCreatable?: BillingInformationCreatable,
      options?: any
    ): AxiosPromise<BillingInformationV2> {
      return localVarFp
        .upgradeLegacySubscriptionPlan(subscriptionId, billingInformationCreatable, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} subscriptionId
     * @param {InlineObject3} [inlineObject3]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    upgradeSubscriptionPlan(
      subscriptionId: number,
      inlineObject3?: InlineObject3,
      options?: any
    ): AxiosPromise<GenericResponse> {
      return localVarFp
        .upgradeSubscriptionPlan(subscriptionId, inlineObject3, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} subscriptionId
     * @param {InlineObject4} [inlineObject4]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    upgradeSubscriptionPlanV2(
      subscriptionId: number,
      inlineObject4?: InlineObject4,
      options?: any
    ): AxiosPromise<BillingInformationV2> {
      return localVarFp
        .upgradeSubscriptionPlanV2(subscriptionId, inlineObject4, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * SubscriptionsApi - object-oriented interface
 * @export
 * @class SubscriptionsApi
 * @extends {BaseAPI}
 */
export class SubscriptionsApi extends BaseAPI {
  /**
   *
   * @param {number} subscriptionId
   * @param {SubscriptionCancelable} [subscriptionCancelable]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SubscriptionsApi
   */
  public cancelSubscription(
    subscriptionId: number,
    subscriptionCancelable?: SubscriptionCancelable,
    options?: AxiosRequestConfig
  ) {
    return SubscriptionsApiFp(this.configuration)
      .cancelSubscription(subscriptionId, subscriptionCancelable, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} subscriptionId
   * @param {SubscriptionCancelable} [subscriptionCancelable]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SubscriptionsApi
   */
  public cancelSubscriptionV2(
    subscriptionId: number,
    subscriptionCancelable?: SubscriptionCancelable,
    options?: AxiosRequestConfig
  ) {
    return SubscriptionsApiFp(this.configuration)
      .cancelSubscriptionV2(subscriptionId, subscriptionCancelable, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} userId
   * @param {SubscriptionCreatable} [subscriptionCreatable]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SubscriptionsApi
   */
  public createSubscriptionForUser(
    userId: number,
    subscriptionCreatable?: SubscriptionCreatable,
    options?: AxiosRequestConfig
  ) {
    return SubscriptionsApiFp(this.configuration)
      .createSubscriptionForUser(userId, subscriptionCreatable, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} userId
   * @param {SubscriptionEditable} [subscriptionEditable]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SubscriptionsApi
   */
  public createSubscriptionForUserV2(
    userId: number,
    subscriptionEditable?: SubscriptionEditable,
    options?: AxiosRequestConfig
  ) {
    return SubscriptionsApiFp(this.configuration)
      .createSubscriptionForUserV2(userId, subscriptionEditable, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} subscriptionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SubscriptionsApi
   */
  public getSubscription(subscriptionId: number, options?: AxiosRequestConfig) {
    return SubscriptionsApiFp(this.configuration)
      .getSubscription(subscriptionId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} subscriptionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SubscriptionsApi
   */
  public getSubscriptionBillingHistory(subscriptionId: number, options?: AxiosRequestConfig) {
    return SubscriptionsApiFp(this.configuration)
      .getSubscriptionBillingHistory(subscriptionId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} subscriptionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SubscriptionsApi
   */
  public getSubscriptionBillingHistoryV2(subscriptionId: number, options?: AxiosRequestConfig) {
    return SubscriptionsApiFp(this.configuration)
      .getSubscriptionBillingHistoryV2(subscriptionId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} subscriptionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SubscriptionsApi
   */
  public getSubscriptionBillingInfo(subscriptionId: number, options?: AxiosRequestConfig) {
    return SubscriptionsApiFp(this.configuration)
      .getSubscriptionBillingInfo(subscriptionId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} subscriptionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SubscriptionsApi
   */
  public getSubscriptionBillingInfoV2(subscriptionId: number, options?: AxiosRequestConfig) {
    return SubscriptionsApiFp(this.configuration)
      .getSubscriptionBillingInfoV2(subscriptionId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} subscriptionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SubscriptionsApi
   */
  public getSubscriptionBillingPeriods(subscriptionId: number, options?: AxiosRequestConfig) {
    return SubscriptionsApiFp(this.configuration)
      .getSubscriptionBillingPeriods(subscriptionId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} subscriptionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SubscriptionsApi
   */
  public getSubscriptionRegions(subscriptionId: number, options?: AxiosRequestConfig) {
    return SubscriptionsApiFp(this.configuration)
      .getSubscriptionRegions(subscriptionId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SubscriptionsApi
   */
  public getSubscriptionsByUserId(userId: number, options?: AxiosRequestConfig) {
    return SubscriptionsApiFp(this.configuration)
      .getSubscriptionsByUserId(userId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Set billing information for a subscription. This should only be used when the subscription doesn\'t already have billing information.
   * @param {number} subscriptionId
   * @param {BillingInformation} [billingInformation]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SubscriptionsApi
   */
  public setSubscriptionBillingInfo(
    subscriptionId: number,
    billingInformation?: BillingInformation,
    options?: AxiosRequestConfig
  ) {
    return SubscriptionsApiFp(this.configuration)
      .setSubscriptionBillingInfo(subscriptionId, billingInformation, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Set billing information for a subscription. This should only be used when the subscription doesn\'t already have billing information.
   * @param {number} subscriptionId
   * @param {BillingInformationCreatable} [billingInformationCreatable]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SubscriptionsApi
   */
  public setSubscriptionBillingInfoV2(
    subscriptionId: number,
    billingInformationCreatable?: BillingInformationCreatable,
    options?: AxiosRequestConfig
  ) {
    return SubscriptionsApiFp(this.configuration)
      .setSubscriptionBillingInfoV2(subscriptionId, billingInformationCreatable, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} subscriptionId
   * @param {SubscriptionUpdatable} [subscriptionUpdatable]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SubscriptionsApi
   */
  public updateSubscription(
    subscriptionId: number,
    subscriptionUpdatable?: SubscriptionUpdatable,
    options?: AxiosRequestConfig
  ) {
    return SubscriptionsApiFp(this.configuration)
      .updateSubscription(subscriptionId, subscriptionUpdatable, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} subscriptionId
   * @param {Addons} [addons]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SubscriptionsApi
   */
  public updateSubscriptionAddon(
    subscriptionId: number,
    addons?: Addons,
    options?: AxiosRequestConfig
  ) {
    return SubscriptionsApiFp(this.configuration)
      .updateSubscriptionAddon(subscriptionId, addons, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Update billing information for a subscription. This should only be used after the user has submitted initial billing information. Only the address is available for updating.
   * @param {number} subscriptionId
   * @param {BillingInfoUpdate} [billingInfoUpdate]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SubscriptionsApi
   */
  public updateSubscriptionBillingInfo(
    subscriptionId: number,
    billingInfoUpdate?: BillingInfoUpdate,
    options?: AxiosRequestConfig
  ) {
    return SubscriptionsApiFp(this.configuration)
      .updateSubscriptionBillingInfo(subscriptionId, billingInfoUpdate, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Update billing information for a subscription. This should only be used after the user has submitted initial billing information. This will take a maxio token and apply the associated credit card as the default payment method
   * @param {number} subscriptionId
   * @param {InlineObject2} [inlineObject2]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SubscriptionsApi
   */
  public updateSubscriptionBillingInfoV2(
    subscriptionId: number,
    inlineObject2?: InlineObject2,
    options?: AxiosRequestConfig
  ) {
    return SubscriptionsApiFp(this.configuration)
      .updateSubscriptionBillingInfoV2(subscriptionId, inlineObject2, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} subscriptionId
   * @param {BillingInformationCreatable} [billingInformationCreatable]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SubscriptionsApi
   */
  public upgradeLegacySubscriptionPlan(
    subscriptionId: number,
    billingInformationCreatable?: BillingInformationCreatable,
    options?: AxiosRequestConfig
  ) {
    return SubscriptionsApiFp(this.configuration)
      .upgradeLegacySubscriptionPlan(subscriptionId, billingInformationCreatable, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} subscriptionId
   * @param {InlineObject3} [inlineObject3]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SubscriptionsApi
   */
  public upgradeSubscriptionPlan(
    subscriptionId: number,
    inlineObject3?: InlineObject3,
    options?: AxiosRequestConfig
  ) {
    return SubscriptionsApiFp(this.configuration)
      .upgradeSubscriptionPlan(subscriptionId, inlineObject3, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} subscriptionId
   * @param {InlineObject4} [inlineObject4]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SubscriptionsApi
   */
  public upgradeSubscriptionPlanV2(
    subscriptionId: number,
    inlineObject4?: InlineObject4,
    options?: AxiosRequestConfig
  ) {
    return SubscriptionsApiFp(this.configuration)
      .upgradeSubscriptionPlanV2(subscriptionId, inlineObject4, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * SupportApi - axios parameter creator
 * @export
 */
export const SupportApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} [returnTo] If provided, the user is redirected to this URL after Zendesk successfully validates their JWT authentication
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getZendeskLogin: async (
      returnTo?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/support/zendesk/login`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (returnTo !== undefined) {
        localVarQueryParameter['returnTo'] = returnTo
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * SupportApi - functional programming interface
 * @export
 */
export const SupportApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SupportApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {string} [returnTo] If provided, the user is redirected to this URL after Zendesk successfully validates their JWT authentication
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getZendeskLogin(
      returnTo?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20014>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getZendeskLogin(returnTo, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * SupportApi - factory interface
 * @export
 */
export const SupportApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = SupportApiFp(configuration)
  return {
    /**
     *
     * @param {string} [returnTo] If provided, the user is redirected to this URL after Zendesk successfully validates their JWT authentication
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getZendeskLogin(returnTo?: string, options?: any): AxiosPromise<InlineResponse20014> {
      return localVarFp
        .getZendeskLogin(returnTo, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * SupportApi - object-oriented interface
 * @export
 * @class SupportApi
 * @extends {BaseAPI}
 */
export class SupportApi extends BaseAPI {
  /**
   *
   * @param {string} [returnTo] If provided, the user is redirected to this URL after Zendesk successfully validates their JWT authentication
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SupportApi
   */
  public getZendeskLogin(returnTo?: string, options?: AxiosRequestConfig) {
    return SupportApiFp(this.configuration)
      .getZendeskLogin(returnTo, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * TeamsApi - axios parameter creator
 * @export
 */
export const TeamsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} teamId
     * @param {number} teamInviteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    acceptTeamInvite: async (
      teamId: number,
      teamInviteId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'teamId' is not null or undefined
      assertParamExists('acceptTeamInvite', 'teamId', teamId)
      // verify required parameter 'teamInviteId' is not null or undefined
      assertParamExists('acceptTeamInvite', 'teamInviteId', teamInviteId)
      const localVarPath = `/v1/teams/{team_id}/team_invites/{team_invite_id}/accepted`
        .replace(`{${'team_id'}}`, encodeURIComponent(String(teamId)))
        .replace(`{${'team_invite_id'}}`, encodeURIComponent(String(teamInviteId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} teamId
     * @param {string} trialLink
     * @param {InlineObject7} [inlineObject7]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    claimTeamTrialLink: async (
      teamId: number,
      trialLink: string,
      inlineObject7?: InlineObject7,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'teamId' is not null or undefined
      assertParamExists('claimTeamTrialLink', 'teamId', teamId)
      // verify required parameter 'trialLink' is not null or undefined
      assertParamExists('claimTeamTrialLink', 'trialLink', trialLink)
      const localVarPath = `/v1/teams/{team_id}/trial_links/{trial_link}/claim`
        .replace(`{${'team_id'}}`, encodeURIComponent(String(teamId)))
        .replace(`{${'trial_link'}}`, encodeURIComponent(String(trialLink)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        inlineObject7,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {TeamOptions} [teamOptions]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTeam: async (
      teamOptions?: TeamOptions,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/teams`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        teamOptions,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} teamId
     * @param {InlineObject6} [inlineObject6]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTeamTrialLink: async (
      teamId: number,
      inlineObject6?: InlineObject6,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'teamId' is not null or undefined
      assertParamExists('createTeamTrialLink', 'teamId', teamId)
      const localVarPath = `/v1/teams/{team_id}/trial_links`.replace(
        `{${'team_id'}}`,
        encodeURIComponent(String(teamId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        inlineObject6,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} teamId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteTeamByID: async (
      teamId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'teamId' is not null or undefined
      assertParamExists('deleteTeamByID', 'teamId', teamId)
      const localVarPath = `/v1/teams/{team_id}`.replace(
        `{${'team_id'}}`,
        encodeURIComponent(String(teamId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} teamId
     * @param {number} teamInviteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    denyTeamInvite: async (
      teamId: number,
      teamInviteId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'teamId' is not null or undefined
      assertParamExists('denyTeamInvite', 'teamId', teamId)
      // verify required parameter 'teamInviteId' is not null or undefined
      assertParamExists('denyTeamInvite', 'teamInviteId', teamInviteId)
      const localVarPath = `/v1/teams/{team_id}/team_invites/{team_invite_id}/denied`
        .replace(`{${'team_id'}}`, encodeURIComponent(String(teamId)))
        .replace(`{${'team_invite_id'}}`, encodeURIComponent(String(teamInviteId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} teamId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTeamByID: async (teamId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'teamId' is not null or undefined
      assertParamExists('getTeamByID', 'teamId', teamId)
      const localVarPath = `/v1/teams/{team_id}`.replace(
        `{${'team_id'}}`,
        encodeURIComponent(String(teamId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} teamId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTeamCustomers: async (
      teamId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'teamId' is not null or undefined
      assertParamExists('getTeamCustomers', 'teamId', teamId)
      const localVarPath = `/v1/teams/{team_id}/customers`.replace(
        `{${'team_id'}}`,
        encodeURIComponent(String(teamId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} teamId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTeamInvites: async (
      teamId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'teamId' is not null or undefined
      assertParamExists('getTeamInvites', 'teamId', teamId)
      const localVarPath = `/v1/teams/{team_id}/team_invites`.replace(
        `{${'team_id'}}`,
        encodeURIComponent(String(teamId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} teamId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTeamMembers: async (
      teamId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'teamId' is not null or undefined
      assertParamExists('getTeamMembers', 'teamId', teamId)
      const localVarPath = `/v1/teams/{team_id}/members`.replace(
        `{${'team_id'}}`,
        encodeURIComponent(String(teamId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} teamId
     * @param {string} trialLink
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTeamTrialLink: async (
      teamId: number,
      trialLink: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'teamId' is not null or undefined
      assertParamExists('getTeamTrialLink', 'teamId', teamId)
      // verify required parameter 'trialLink' is not null or undefined
      assertParamExists('getTeamTrialLink', 'trialLink', trialLink)
      const localVarPath = `/v1/teams/{team_id}/trial_links/{trial_link}`
        .replace(`{${'team_id'}}`, encodeURIComponent(String(teamId)))
        .replace(`{${'trial_link'}}`, encodeURIComponent(String(trialLink)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} teamId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTeamTrialLinks: async (
      teamId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'teamId' is not null or undefined
      assertParamExists('getTeamTrialLinks', 'teamId', teamId)
      const localVarPath = `/v1/teams/{team_id}/trial_links`.replace(
        `{${'team_id'}}`,
        encodeURIComponent(String(teamId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} teamId
     * @param {InlineObject5} [inlineObject5]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    inviteTeamMember: async (
      teamId: number,
      inlineObject5?: InlineObject5,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'teamId' is not null or undefined
      assertParamExists('inviteTeamMember', 'teamId', teamId)
      const localVarPath = `/v1/teams/{team_id}/team_invites`.replace(
        `{${'team_id'}}`,
        encodeURIComponent(String(teamId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        inlineObject5,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} teamId
     * @param {TeamOptions} [teamOptions]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTeam: async (
      teamId: number,
      teamOptions?: TeamOptions,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'teamId' is not null or undefined
      assertParamExists('updateTeam', 'teamId', teamId)
      const localVarPath = `/v1/teams/{team_id}`.replace(
        `{${'team_id'}}`,
        encodeURIComponent(String(teamId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        teamOptions,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} teamId
     * @param {number} teamInviteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyTeamInvite: async (
      teamId: number,
      teamInviteId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'teamId' is not null or undefined
      assertParamExists('verifyTeamInvite', 'teamId', teamId)
      // verify required parameter 'teamInviteId' is not null or undefined
      assertParamExists('verifyTeamInvite', 'teamInviteId', teamInviteId)
      const localVarPath = `/v1/teams/{team_id}/team_invites/{team_invite_id}/verify`
        .replace(`{${'team_id'}}`, encodeURIComponent(String(teamId)))
        .replace(`{${'team_invite_id'}}`, encodeURIComponent(String(teamInviteId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Verify that a trial link can be used.  This will return HTTP 200 if the trial link was found for the given team ID. If the link is **expired** or was already **claimed**, it returns `success: false`.  If `success: true`, the `email` and `team_name` are also included, otherwise they are omitted to avoid leaking this data.  **This endpoint does not require authentication since it is used by unauthenticated users to verify that their trial link is valid**
     * @param {number} teamId
     * @param {string} trialLink
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyTeamTrialLink: async (
      teamId: number,
      trialLink: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'teamId' is not null or undefined
      assertParamExists('verifyTeamTrialLink', 'teamId', teamId)
      // verify required parameter 'trialLink' is not null or undefined
      assertParamExists('verifyTeamTrialLink', 'trialLink', trialLink)
      const localVarPath = `/v1/teams/{team_id}/trial_links/{trial_link}/verify`
        .replace(`{${'team_id'}}`, encodeURIComponent(String(teamId)))
        .replace(`{${'trial_link'}}`, encodeURIComponent(String(trialLink)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * TeamsApi - functional programming interface
 * @export
 */
export const TeamsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TeamsApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {number} teamId
     * @param {number} teamInviteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async acceptTeamInvite(
      teamId: number,
      teamInviteId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamInviteAccepted>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.acceptTeamInvite(
        teamId,
        teamInviteId,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} teamId
     * @param {string} trialLink
     * @param {InlineObject7} [inlineObject7]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async claimTeamTrialLink(
      teamId: number,
      trialLink: string,
      inlineObject7?: InlineObject7,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrialLink>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.claimTeamTrialLink(
        teamId,
        trialLink,
        inlineObject7,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {TeamOptions} [teamOptions]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createTeam(
      teamOptions?: TeamOptions,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Team>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createTeam(teamOptions, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} teamId
     * @param {InlineObject6} [inlineObject6]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createTeamTrialLink(
      teamId: number,
      inlineObject6?: InlineObject6,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TrialLink>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createTeamTrialLink(
        teamId,
        inlineObject6,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} teamId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteTeamByID(
      teamId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTeamByID(teamId, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} teamId
     * @param {number} teamInviteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async denyTeamInvite(
      teamId: number,
      teamInviteId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamInviteDenied>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.denyTeamInvite(
        teamId,
        teamInviteId,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} teamId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTeamByID(
      teamId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Team>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTeamByID(teamId, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} teamId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTeamCustomers(
      teamId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTeamCustomers(teamId, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} teamId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTeamInvites(
      teamId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TeamInvitePending>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTeamInvites(teamId, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} teamId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTeamMembers(
      teamId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTeamMembers(teamId, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} teamId
     * @param {string} trialLink
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTeamTrialLink(
      teamId: number,
      trialLink: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrialLink>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTeamTrialLink(
        teamId,
        trialLink,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} teamId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTeamTrialLinks(
      teamId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TrialLink>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTeamTrialLinks(teamId, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} teamId
     * @param {InlineObject5} [inlineObject5]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async inviteTeamMember(
      teamId: number,
      inlineObject5?: InlineObject5,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamInvitePending>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.inviteTeamMember(
        teamId,
        inlineObject5,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} teamId
     * @param {TeamOptions} [teamOptions]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateTeam(
      teamId: number,
      teamOptions?: TeamOptions,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Team>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateTeam(
        teamId,
        teamOptions,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} teamId
     * @param {number} teamInviteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async verifyTeamInvite(
      teamId: number,
      teamInviteId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TeamInvitePending>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.verifyTeamInvite(
        teamId,
        teamInviteId,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Verify that a trial link can be used.  This will return HTTP 200 if the trial link was found for the given team ID. If the link is **expired** or was already **claimed**, it returns `success: false`.  If `success: true`, the `email` and `team_name` are also included, otherwise they are omitted to avoid leaking this data.  **This endpoint does not require authentication since it is used by unauthenticated users to verify that their trial link is valid**
     * @param {number} teamId
     * @param {string} trialLink
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async verifyTeamTrialLink(
      teamId: number,
      trialLink: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifyTrialLink>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.verifyTeamTrialLink(
        teamId,
        trialLink,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * TeamsApi - factory interface
 * @export
 */
export const TeamsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = TeamsApiFp(configuration)
  return {
    /**
     *
     * @param {number} teamId
     * @param {number} teamInviteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    acceptTeamInvite(
      teamId: number,
      teamInviteId: number,
      options?: any
    ): AxiosPromise<TeamInviteAccepted> {
      return localVarFp
        .acceptTeamInvite(teamId, teamInviteId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} teamId
     * @param {string} trialLink
     * @param {InlineObject7} [inlineObject7]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    claimTeamTrialLink(
      teamId: number,
      trialLink: string,
      inlineObject7?: InlineObject7,
      options?: any
    ): AxiosPromise<TrialLink> {
      return localVarFp
        .claimTeamTrialLink(teamId, trialLink, inlineObject7, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {TeamOptions} [teamOptions]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTeam(teamOptions?: TeamOptions, options?: any): AxiosPromise<Team> {
      return localVarFp.createTeam(teamOptions, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} teamId
     * @param {InlineObject6} [inlineObject6]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTeamTrialLink(
      teamId: number,
      inlineObject6?: InlineObject6,
      options?: any
    ): AxiosPromise<Array<TrialLink>> {
      return localVarFp
        .createTeamTrialLink(teamId, inlineObject6, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} teamId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteTeamByID(teamId: number, options?: any): AxiosPromise<GenericResponse> {
      return localVarFp.deleteTeamByID(teamId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} teamId
     * @param {number} teamInviteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    denyTeamInvite(
      teamId: number,
      teamInviteId: number,
      options?: any
    ): AxiosPromise<TeamInviteDenied> {
      return localVarFp
        .denyTeamInvite(teamId, teamInviteId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} teamId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTeamByID(teamId: number, options?: any): AxiosPromise<Team> {
      return localVarFp.getTeamByID(teamId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} teamId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTeamCustomers(teamId: number, options?: any): AxiosPromise<Array<User>> {
      return localVarFp
        .getTeamCustomers(teamId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} teamId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTeamInvites(teamId: number, options?: any): AxiosPromise<Array<TeamInvitePending>> {
      return localVarFp.getTeamInvites(teamId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} teamId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTeamMembers(teamId: number, options?: any): AxiosPromise<Array<User>> {
      return localVarFp.getTeamMembers(teamId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} teamId
     * @param {string} trialLink
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTeamTrialLink(teamId: number, trialLink: string, options?: any): AxiosPromise<TrialLink> {
      return localVarFp
        .getTeamTrialLink(teamId, trialLink, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} teamId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTeamTrialLinks(teamId: number, options?: any): AxiosPromise<Array<TrialLink>> {
      return localVarFp
        .getTeamTrialLinks(teamId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} teamId
     * @param {InlineObject5} [inlineObject5]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    inviteTeamMember(
      teamId: number,
      inlineObject5?: InlineObject5,
      options?: any
    ): AxiosPromise<TeamInvitePending> {
      return localVarFp
        .inviteTeamMember(teamId, inlineObject5, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} teamId
     * @param {TeamOptions} [teamOptions]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTeam(teamId: number, teamOptions?: TeamOptions, options?: any): AxiosPromise<Team> {
      return localVarFp
        .updateTeam(teamId, teamOptions, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} teamId
     * @param {number} teamInviteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyTeamInvite(
      teamId: number,
      teamInviteId: number,
      options?: any
    ): AxiosPromise<Array<TeamInvitePending>> {
      return localVarFp
        .verifyTeamInvite(teamId, teamInviteId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Verify that a trial link can be used.  This will return HTTP 200 if the trial link was found for the given team ID. If the link is **expired** or was already **claimed**, it returns `success: false`.  If `success: true`, the `email` and `team_name` are also included, otherwise they are omitted to avoid leaking this data.  **This endpoint does not require authentication since it is used by unauthenticated users to verify that their trial link is valid**
     * @param {number} teamId
     * @param {string} trialLink
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyTeamTrialLink(
      teamId: number,
      trialLink: string,
      options?: any
    ): AxiosPromise<VerifyTrialLink> {
      return localVarFp
        .verifyTeamTrialLink(teamId, trialLink, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * TeamsApi - object-oriented interface
 * @export
 * @class TeamsApi
 * @extends {BaseAPI}
 */
export class TeamsApi extends BaseAPI {
  /**
   *
   * @param {number} teamId
   * @param {number} teamInviteId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TeamsApi
   */
  public acceptTeamInvite(teamId: number, teamInviteId: number, options?: AxiosRequestConfig) {
    return TeamsApiFp(this.configuration)
      .acceptTeamInvite(teamId, teamInviteId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} teamId
   * @param {string} trialLink
   * @param {InlineObject7} [inlineObject7]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TeamsApi
   */
  public claimTeamTrialLink(
    teamId: number,
    trialLink: string,
    inlineObject7?: InlineObject7,
    options?: AxiosRequestConfig
  ) {
    return TeamsApiFp(this.configuration)
      .claimTeamTrialLink(teamId, trialLink, inlineObject7, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {TeamOptions} [teamOptions]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TeamsApi
   */
  public createTeam(teamOptions?: TeamOptions, options?: AxiosRequestConfig) {
    return TeamsApiFp(this.configuration)
      .createTeam(teamOptions, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} teamId
   * @param {InlineObject6} [inlineObject6]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TeamsApi
   */
  public createTeamTrialLink(
    teamId: number,
    inlineObject6?: InlineObject6,
    options?: AxiosRequestConfig
  ) {
    return TeamsApiFp(this.configuration)
      .createTeamTrialLink(teamId, inlineObject6, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} teamId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TeamsApi
   */
  public deleteTeamByID(teamId: number, options?: AxiosRequestConfig) {
    return TeamsApiFp(this.configuration)
      .deleteTeamByID(teamId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} teamId
   * @param {number} teamInviteId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TeamsApi
   */
  public denyTeamInvite(teamId: number, teamInviteId: number, options?: AxiosRequestConfig) {
    return TeamsApiFp(this.configuration)
      .denyTeamInvite(teamId, teamInviteId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} teamId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TeamsApi
   */
  public getTeamByID(teamId: number, options?: AxiosRequestConfig) {
    return TeamsApiFp(this.configuration)
      .getTeamByID(teamId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} teamId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TeamsApi
   */
  public getTeamCustomers(teamId: number, options?: AxiosRequestConfig) {
    return TeamsApiFp(this.configuration)
      .getTeamCustomers(teamId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} teamId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TeamsApi
   */
  public getTeamInvites(teamId: number, options?: AxiosRequestConfig) {
    return TeamsApiFp(this.configuration)
      .getTeamInvites(teamId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} teamId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TeamsApi
   */
  public getTeamMembers(teamId: number, options?: AxiosRequestConfig) {
    return TeamsApiFp(this.configuration)
      .getTeamMembers(teamId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} teamId
   * @param {string} trialLink
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TeamsApi
   */
  public getTeamTrialLink(teamId: number, trialLink: string, options?: AxiosRequestConfig) {
    return TeamsApiFp(this.configuration)
      .getTeamTrialLink(teamId, trialLink, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} teamId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TeamsApi
   */
  public getTeamTrialLinks(teamId: number, options?: AxiosRequestConfig) {
    return TeamsApiFp(this.configuration)
      .getTeamTrialLinks(teamId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} teamId
   * @param {InlineObject5} [inlineObject5]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TeamsApi
   */
  public inviteTeamMember(
    teamId: number,
    inlineObject5?: InlineObject5,
    options?: AxiosRequestConfig
  ) {
    return TeamsApiFp(this.configuration)
      .inviteTeamMember(teamId, inlineObject5, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} teamId
   * @param {TeamOptions} [teamOptions]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TeamsApi
   */
  public updateTeam(teamId: number, teamOptions?: TeamOptions, options?: AxiosRequestConfig) {
    return TeamsApiFp(this.configuration)
      .updateTeam(teamId, teamOptions, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} teamId
   * @param {number} teamInviteId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TeamsApi
   */
  public verifyTeamInvite(teamId: number, teamInviteId: number, options?: AxiosRequestConfig) {
    return TeamsApiFp(this.configuration)
      .verifyTeamInvite(teamId, teamInviteId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Verify that a trial link can be used.  This will return HTTP 200 if the trial link was found for the given team ID. If the link is **expired** or was already **claimed**, it returns `success: false`.  If `success: true`, the `email` and `team_name` are also included, otherwise they are omitted to avoid leaking this data.  **This endpoint does not require authentication since it is used by unauthenticated users to verify that their trial link is valid**
   * @param {number} teamId
   * @param {string} trialLink
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TeamsApi
   */
  public verifyTeamTrialLink(teamId: number, trialLink: string, options?: AxiosRequestConfig) {
    return TeamsApiFp(this.configuration)
      .verifyTeamTrialLink(teamId, trialLink, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * TiersApi - axios parameter creator
 * @export
 */
export const TiersApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDefaultTiers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v1/tiers`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * TiersApi - functional programming interface
 * @export
 */
export const TiersApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TiersApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDefaultTiers(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Tier>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDefaultTiers(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * TiersApi - factory interface
 * @export
 */
export const TiersApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = TiersApiFp(configuration)
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDefaultTiers(options?: any): AxiosPromise<Array<Tier>> {
      return localVarFp.getDefaultTiers(options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * TiersApi - object-oriented interface
 * @export
 * @class TiersApi
 * @extends {BaseAPI}
 */
export class TiersApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TiersApi
   */
  public getDefaultTiers(options?: AxiosRequestConfig) {
    return TiersApiFp(this.configuration)
      .getDefaultTiers(options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {UserCreatable} [userCreatable]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUser: async (
      userCreatable?: UserCreatable,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/users`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        userCreatable,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} userId
     * @param {UserApiKeyCreatable} [userApiKeyCreatable]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUserApiKey: async (
      userId: number,
      userApiKeyCreatable?: UserApiKeyCreatable,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('createUserApiKey', 'userId', userId)
      const localVarPath = `/v1/users/{user_id}/api_keys`.replace(
        `{${'user_id'}}`,
        encodeURIComponent(String(userId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        userApiKeyCreatable,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} userId
     * @param {UserPreferenceCreatable} [userPreferenceCreatable]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUserPreference: async (
      userId: number,
      userPreferenceCreatable?: UserPreferenceCreatable,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('createUserPreference', 'userId', userId)
      const localVarPath = `/v1/users/{user_id}/preferences`.replace(
        `{${'user_id'}}`,
        encodeURIComponent(String(userId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        userPreferenceCreatable,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} userId
     * @param {number} apiKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteUserApiKey: async (
      userId: number,
      apiKey: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('deleteUserApiKey', 'userId', userId)
      // verify required parameter 'apiKey' is not null or undefined
      assertParamExists('deleteUserApiKey', 'apiKey', apiKey)
      const localVarPath = `/v1/users/{user_id}/api_keys/{api_key}`
        .replace(`{${'user_id'}}`, encodeURIComponent(String(userId)))
        .replace(`{${'api_key'}}`, encodeURIComponent(String(apiKey)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} userId
     * @param {number} preferenceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteUserPreference: async (
      userId: number,
      preferenceId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('deleteUserPreference', 'userId', userId)
      // verify required parameter 'preferenceId' is not null or undefined
      assertParamExists('deleteUserPreference', 'preferenceId', preferenceId)
      const localVarPath = `/v1/users/{user_id}/preferences/{preference_id}`
        .replace(`{${'user_id'}}`, encodeURIComponent(String(userId)))
        .replace(`{${'preference_id'}}`, encodeURIComponent(String(preferenceId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUser: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('getUser', 'userId', userId)
      const localVarPath = `/v1/users/{user_id}`.replace(
        `{${'user_id'}}`,
        encodeURIComponent(String(userId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserApiKeys: async (
      userId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('getUserApiKeys', 'userId', userId)
      const localVarPath = `/v1/users/{user_id}/api_keys`.replace(
        `{${'user_id'}}`,
        encodeURIComponent(String(userId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserPreferences: async (
      userId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('getUserPreferences', 'userId', userId)
      const localVarPath = `/v1/users/{user_id}/preferences`.replace(
        `{${'user_id'}}`,
        encodeURIComponent(String(userId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Sends a welcome email to a new user
     * @param {number} userId
     * @param {InlineObject1} [inlineObject1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendWelcomeEmail: async (
      userId: number,
      inlineObject1?: InlineObject1,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('sendWelcomeEmail', 'userId', userId)
      const localVarPath = `/v1/users/{user_id}/send-welcome-email`.replace(
        `{${'user_id'}}`,
        encodeURIComponent(String(userId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        inlineObject1,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Update a user\'s password.
     * @param {number} userId
     * @param {InlineObject} [inlineObject]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePassword: async (
      userId: number,
      inlineObject?: InlineObject,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('updatePassword', 'userId', userId)
      const localVarPath = `/v1/users/{user_id}/password`.replace(
        `{${'user_id'}}`,
        encodeURIComponent(String(userId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        inlineObject,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} userId
     * @param {UserUpdatable} [userUpdatable]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUser: async (
      userId: number,
      userUpdatable?: UserUpdatable,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('updateUser', 'userId', userId)
      const localVarPath = `/v1/users/{user_id}`.replace(
        `{${'user_id'}}`,
        encodeURIComponent(String(userId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        userUpdatable,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} userId
     * @param {number} apiKey
     * @param {UserApiKeyUpdatable} [userApiKeyUpdatable]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserApiKey: async (
      userId: number,
      apiKey: number,
      userApiKeyUpdatable?: UserApiKeyUpdatable,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('updateUserApiKey', 'userId', userId)
      // verify required parameter 'apiKey' is not null or undefined
      assertParamExists('updateUserApiKey', 'apiKey', apiKey)
      const localVarPath = `/v1/users/{user_id}/api_keys/{api_key}`
        .replace(`{${'user_id'}}`, encodeURIComponent(String(userId)))
        .replace(`{${'api_key'}}`, encodeURIComponent(String(apiKey)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        userApiKeyUpdatable,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} userId
     * @param {number} preferenceId
     * @param {UserPreference} [userPreference]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserPreference: async (
      userId: number,
      preferenceId: number,
      userPreference?: UserPreference,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('updateUserPreference', 'userId', userId)
      // verify required parameter 'preferenceId' is not null or undefined
      assertParamExists('updateUserPreference', 'preferenceId', preferenceId)
      const localVarPath = `/v1/users/{user_id}/preferences/{preference_id}`
        .replace(`{${'user_id'}}`, encodeURIComponent(String(userId)))
        .replace(`{${'preference_id'}}`, encodeURIComponent(String(preferenceId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        userPreference,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {UserCreatable} [userCreatable]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createUser(
      userCreatable?: UserCreatable,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(userCreatable, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} userId
     * @param {UserApiKeyCreatable} [userApiKeyCreatable]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createUserApiKey(
      userId: number,
      userApiKeyCreatable?: UserApiKeyCreatable,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserApiKey>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createUserApiKey(
        userId,
        userApiKeyCreatable,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} userId
     * @param {UserPreferenceCreatable} [userPreferenceCreatable]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createUserPreference(
      userId: number,
      userPreferenceCreatable?: UserPreferenceCreatable,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserPreference>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createUserPreference(
        userId,
        userPreferenceCreatable,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} userId
     * @param {number} apiKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteUserApiKey(
      userId: number,
      apiKey: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GenericResponse>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserApiKey(
        userId,
        apiKey,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} userId
     * @param {number} preferenceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteUserPreference(
      userId: number,
      preferenceId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserPreference(
        userId,
        preferenceId,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUser(
      userId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(userId, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUserApiKeys(
      userId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserApiKey>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUserApiKeys(userId, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUserPreferences(
      userId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserPreference>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUserPreferences(userId, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Sends a welcome email to a new user
     * @param {number} userId
     * @param {InlineObject1} [inlineObject1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async sendWelcomeEmail(
      userId: number,
      inlineObject1?: InlineObject1,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.sendWelcomeEmail(
        userId,
        inlineObject1,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Update a user\'s password.
     * @param {number} userId
     * @param {InlineObject} [inlineObject]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updatePassword(
      userId: number,
      inlineObject?: InlineObject,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updatePassword(
        userId,
        inlineObject,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} userId
     * @param {UserUpdatable} [userUpdatable]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateUser(
      userId: number,
      userUpdatable?: UserUpdatable,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(
        userId,
        userUpdatable,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} userId
     * @param {number} apiKey
     * @param {UserApiKeyUpdatable} [userApiKeyUpdatable]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateUserApiKey(
      userId: number,
      apiKey: number,
      userApiKeyUpdatable?: UserApiKeyUpdatable,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserApiKey>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserApiKey(
        userId,
        apiKey,
        userApiKeyUpdatable,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} userId
     * @param {number} preferenceId
     * @param {UserPreference} [userPreference]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateUserPreference(
      userId: number,
      preferenceId: number,
      userPreference?: UserPreference,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserPreference>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserPreference(
        userId,
        preferenceId,
        userPreference,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = UsersApiFp(configuration)
  return {
    /**
     *
     * @param {UserCreatable} [userCreatable]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUser(userCreatable?: UserCreatable, options?: any): AxiosPromise<User> {
      return localVarFp
        .createUser(userCreatable, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} userId
     * @param {UserApiKeyCreatable} [userApiKeyCreatable]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUserApiKey(
      userId: number,
      userApiKeyCreatable?: UserApiKeyCreatable,
      options?: any
    ): AxiosPromise<UserApiKey> {
      return localVarFp
        .createUserApiKey(userId, userApiKeyCreatable, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} userId
     * @param {UserPreferenceCreatable} [userPreferenceCreatable]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUserPreference(
      userId: number,
      userPreferenceCreatable?: UserPreferenceCreatable,
      options?: any
    ): AxiosPromise<UserPreference> {
      return localVarFp
        .createUserPreference(userId, userPreferenceCreatable, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} userId
     * @param {number} apiKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteUserApiKey(
      userId: number,
      apiKey: number,
      options?: any
    ): AxiosPromise<Array<GenericResponse>> {
      return localVarFp
        .deleteUserApiKey(userId, apiKey, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} userId
     * @param {number} preferenceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteUserPreference(
      userId: number,
      preferenceId: number,
      options?: any
    ): AxiosPromise<GenericResponse> {
      return localVarFp
        .deleteUserPreference(userId, preferenceId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUser(userId: number, options?: any): AxiosPromise<User> {
      return localVarFp.getUser(userId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserApiKeys(userId: number, options?: any): AxiosPromise<Array<UserApiKey>> {
      return localVarFp.getUserApiKeys(userId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserPreferences(userId: number, options?: any): AxiosPromise<Array<UserPreference>> {
      return localVarFp
        .getUserPreferences(userId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Sends a welcome email to a new user
     * @param {number} userId
     * @param {InlineObject1} [inlineObject1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendWelcomeEmail(
      userId: number,
      inlineObject1?: InlineObject1,
      options?: any
    ): AxiosPromise<GenericResponse> {
      return localVarFp
        .sendWelcomeEmail(userId, inlineObject1, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Update a user\'s password.
     * @param {number} userId
     * @param {InlineObject} [inlineObject]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePassword(
      userId: number,
      inlineObject?: InlineObject,
      options?: any
    ): AxiosPromise<GenericResponse> {
      return localVarFp
        .updatePassword(userId, inlineObject, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} userId
     * @param {UserUpdatable} [userUpdatable]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUser(userId: number, userUpdatable?: UserUpdatable, options?: any): AxiosPromise<User> {
      return localVarFp
        .updateUser(userId, userUpdatable, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} userId
     * @param {number} apiKey
     * @param {UserApiKeyUpdatable} [userApiKeyUpdatable]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserApiKey(
      userId: number,
      apiKey: number,
      userApiKeyUpdatable?: UserApiKeyUpdatable,
      options?: any
    ): AxiosPromise<UserApiKey> {
      return localVarFp
        .updateUserApiKey(userId, apiKey, userApiKeyUpdatable, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} userId
     * @param {number} preferenceId
     * @param {UserPreference} [userPreference]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserPreference(
      userId: number,
      preferenceId: number,
      userPreference?: UserPreference,
      options?: any
    ): AxiosPromise<UserPreference> {
      return localVarFp
        .updateUserPreference(userId, preferenceId, userPreference, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
  /**
   *
   * @param {UserCreatable} [userCreatable]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public createUser(userCreatable?: UserCreatable, options?: AxiosRequestConfig) {
    return UsersApiFp(this.configuration)
      .createUser(userCreatable, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} userId
   * @param {UserApiKeyCreatable} [userApiKeyCreatable]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public createUserApiKey(
    userId: number,
    userApiKeyCreatable?: UserApiKeyCreatable,
    options?: AxiosRequestConfig
  ) {
    return UsersApiFp(this.configuration)
      .createUserApiKey(userId, userApiKeyCreatable, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} userId
   * @param {UserPreferenceCreatable} [userPreferenceCreatable]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public createUserPreference(
    userId: number,
    userPreferenceCreatable?: UserPreferenceCreatable,
    options?: AxiosRequestConfig
  ) {
    return UsersApiFp(this.configuration)
      .createUserPreference(userId, userPreferenceCreatable, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} userId
   * @param {number} apiKey
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public deleteUserApiKey(userId: number, apiKey: number, options?: AxiosRequestConfig) {
    return UsersApiFp(this.configuration)
      .deleteUserApiKey(userId, apiKey, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} userId
   * @param {number} preferenceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public deleteUserPreference(userId: number, preferenceId: number, options?: AxiosRequestConfig) {
    return UsersApiFp(this.configuration)
      .deleteUserPreference(userId, preferenceId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public getUser(userId: number, options?: AxiosRequestConfig) {
    return UsersApiFp(this.configuration)
      .getUser(userId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public getUserApiKeys(userId: number, options?: AxiosRequestConfig) {
    return UsersApiFp(this.configuration)
      .getUserApiKeys(userId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public getUserPreferences(userId: number, options?: AxiosRequestConfig) {
    return UsersApiFp(this.configuration)
      .getUserPreferences(userId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Sends a welcome email to a new user
   * @param {number} userId
   * @param {InlineObject1} [inlineObject1]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public sendWelcomeEmail(
    userId: number,
    inlineObject1?: InlineObject1,
    options?: AxiosRequestConfig
  ) {
    return UsersApiFp(this.configuration)
      .sendWelcomeEmail(userId, inlineObject1, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Update a user\'s password.
   * @param {number} userId
   * @param {InlineObject} [inlineObject]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public updatePassword(userId: number, inlineObject?: InlineObject, options?: AxiosRequestConfig) {
    return UsersApiFp(this.configuration)
      .updatePassword(userId, inlineObject, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} userId
   * @param {UserUpdatable} [userUpdatable]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public updateUser(userId: number, userUpdatable?: UserUpdatable, options?: AxiosRequestConfig) {
    return UsersApiFp(this.configuration)
      .updateUser(userId, userUpdatable, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} userId
   * @param {number} apiKey
   * @param {UserApiKeyUpdatable} [userApiKeyUpdatable]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public updateUserApiKey(
    userId: number,
    apiKey: number,
    userApiKeyUpdatable?: UserApiKeyUpdatable,
    options?: AxiosRequestConfig
  ) {
    return UsersApiFp(this.configuration)
      .updateUserApiKey(userId, apiKey, userApiKeyUpdatable, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} userId
   * @param {number} preferenceId
   * @param {UserPreference} [userPreference]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public updateUserPreference(
    userId: number,
    preferenceId: number,
    userPreference?: UserPreference,
    options?: AxiosRequestConfig
  ) {
    return UsersApiFp(this.configuration)
      .updateUserPreference(userId, preferenceId, userPreference, options)
      .then((request) => request(this.axios, this.basePath))
  }
}
