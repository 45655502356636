















import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    width: {
      type: [Number, String],
      default: 52,
    },
    height: {
      type: [Number, String],
      default: 50,
    },
    viewBox: {
      type: String,
      default: '0 0 52 50',
    },
  },
})
