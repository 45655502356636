<template>
  <div class="main-content bg-default" style="height: 100vh; min-height: 1200px">
    <!-- Navbar -->
    <base-nav
      class="navbar-top navbar-horizontal navbar-dark"
      containerClasses="px-4 container"
      expand
    >
      <router-link slot="brand" to="/">
        <img src="/img/brand/Logo-White.svg" alt="white-logo" />
      </router-link>

      <template v-slot="{ closeMenu }">
        <!-- Collapse header -->
        <div class="navbar-collapse-header d-md-none">
          <div class="row">
            <div class="col-6 collapse-brand" @click="closeMenu">
              <router-link to="/">
                <img src="/img/brand/Logo-Main.svg" />
              </router-link>
            </div>
            <div class="col-6 collapse-close">
              <button
                type="button"
                @click="closeMenu"
                class="navbar-toggler"
                aria-label="Toggle sidenav"
              >
                <span></span>
                <span></span>
              </button>
            </div>
          </div>
        </div>
        <!-- Navbar items -->
        <ul class="navbar-nav ml-auto">
          <li class="nav-item" @click="closeMenu">
            <router-link class="nav-link nav-link-icon" to="/register/starter">
              <i class="ni ni-circle-08"></i>
              <span class="nav-link-inner--text">Sign Up</span>
            </router-link>
          </li>
          <li class="nav-item" @click="closeMenu">
            <router-link class="nav-link nav-link-icon" to="/login">
              <i class="ni ni-key-25"></i>
              <span class="nav-link-inner--text">Login</span>
            </router-link>
          </li>
        </ul>
      </template>
    </base-nav>
    <!-- Header -->
    <div class="header py-7 py-lg-8">
      <div class="container"></div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">
      <slide-y-up-transition mode="out-in" origin="center top">
        <router-view></router-view>
      </slide-y-up-transition>
    </div>
    <footer class="py-5 bg-default">
      <div class="container">
        <div class="row align-items-center justify-content-xl-between">
          <div class="col-xl-6">
            <div class="copyright text-center text-xl-left text-light">
              &copy; {{ year }}
              <a
                href="https://imageengine.io/"
                class="font-weight-bold ml-1 text-light"
                target="_blank"
                >ImageEngine {{ isDevTier ? 'for Developers (beta)' : '' }}</a
              >
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
  import { SlideYUpTransition } from 'vue2-transitions'

  export default {
    name: 'auth-layout',
    components: {
      SlideYUpTransition,
    },
    data() {
      return {
        year: new Date().getFullYear(),
        showMenu: false,
        isDevTier: false,
      }
    },
    created() {
      this.isDevTier = this.$route.path.includes('dev')
    },
  }
</script>
<style scoped></style>
