import { DetailedSubscription, Engine, Origin, User } from '@/util/decorators'
import { PaymentTypeEnum } from '@/services/ie-microservice-openapi'
import { SubscriptionType } from '@/util/enums'

export class Engines {
  public engines: Array<Engine> = []

  public constructor(engines: Array<Engine>) {
    this.engines = engines
  }

  public sortByCname(): this {
    this.engines = this.engines.sort((a: Engine, b: Engine) => {
      return a.cname.toLocaleLowerCase().localeCompare(b.cname.toLowerCase())
    })

    return this
  }

  public sortByCnameOrNickname(): this {
    this.engines = this.engines.sort((a: Engine, b: Engine) => {
      const termA = a.nickname || a.cname
      const termB = b.nickname || b.cname

      return termA.toLocaleLowerCase().localeCompare(termB.toLowerCase())
    })

    return this
  }

  public sortByFavoriteStateAndCname(): this {
    this.engines = this.engines.sort((a: Engine, b: Engine) => {
      if (a.is_favorite === b.is_favorite) {
        return a.cname.toLocaleLowerCase().localeCompare(b.cname.toLowerCase())
      }
      return a.is_favorite ? -1 : 1
    })

    return this
  }

  public search(term: string): this {
    this.engines = this.engines.filter((engine: Engine) => {
      return (
        engine.cname.toLowerCase().includes(term.toLowerCase()) ||
        engine.nickname.toLowerCase().includes(term.toLowerCase())
      )
    })

    return this
  }

  public filterByFavoriteStateEnabled(): this {
    this.engines = this.engines.filter((engine: Engine) => {
      return engine.is_favorite
    })

    return this
  }

  public find(id: number): Engine | null {
    return this.engines.find((e) => e.id === id) || null
  }

  public take(amount: number): Array<Engine> {
    return this.engines.slice(0, amount)
  }

  public all(): Array<Engine> {
    return this.engines
  }

  public count(): number {
    return this.engines.length
  }
}

export class Origins {
  public origins: Array<Origin> = []

  public constructor(origins: Array<Origin>) {
    this.origins = origins
  }

  public sortByFavoriteStateAndName(): this {
    this.origins = this.origins.sort((a: Origin, b: Origin) => {
      if (!a.name || !b.name) {
        return -1
      }

      if (a.is_favorite === b.is_favorite) {
        return a.name.toLocaleLowerCase().localeCompare(b.name.toLowerCase())
      }
      return a.is_favorite ? -1 : 1
    })

    return this
  }

  public search(term: string): this {
    this.origins = this.origins.filter((origin: Origin) => {
      return origin.name && origin.name.toLowerCase().includes(term.toLowerCase())
    })

    return this
  }

  public filterByFavoriteStateEnabled(): this {
    this.origins = this.origins.filter((origin: Origin) => {
      return origin.is_favorite
    })

    return this
  }

  public find(id: number): Origin | null {
    return this.origins.find((o) => o.id === id) || null
  }

  public take(amount: number): Array<Origin> {
    return this.origins.slice(0, amount)
  }

  public all(): Array<Origin> {
    return this.origins
  }

  public count(): number {
    return this.origins.length
  }
}

export class DetailedSubscriptions {
  public subscriptions: Array<DetailedSubscription> = []

  public constructor(subscriptions: Array<DetailedSubscription>) {
    this.subscriptions = subscriptions
  }

  public sortByAccessedOrStartedDate(): this {
    this.subscriptions = this.subscriptions.sort(
      (a: DetailedSubscription, b: DetailedSubscription) =>
        b.date_accessed.localeCompare(a.date_accessed)
    )

    return this
  }

  public sortByDefaultState(): this {
    this.subscriptions = this.subscriptions.sort(
      (a: DetailedSubscription, b: DetailedSubscription) => {
        if (a.is_default === b.is_default) return 0

        return a.is_default ? -1 : 1
      }
    )

    return this
  }

  public sortByAccountName(): this {
    this.subscriptions = this.subscriptions.sort(
      (a: DetailedSubscription, b: DetailedSubscription) =>
        a.account_name.trim().toLowerCase().localeCompare(b.account_name.trim().toLowerCase())
    )

    return this
  }

  public filterByType(type: SubscriptionType): this {
    this.subscriptions = this.subscriptions.filter((s: DetailedSubscription) =>
      type === SubscriptionType.Trial
        ? s.payment_type === PaymentTypeEnum.Trial
        : s.payment_type !== PaymentTypeEnum.Trial
    )

    return this
  }

  public search(term: string): this {
    this.subscriptions = this.subscriptions.filter((s: DetailedSubscription) =>
      s.account_name.toLowerCase().includes(term.toLowerCase())
    )

    return this
  }

  public findMostRecent(type: SubscriptionType): DetailedSubscription | null {
    return (
      this.filterByType(type).sortByAccessedOrStartedDate().sortByDefaultState().first() || null
    )
  }

  public findDefault(): DetailedSubscription | null {
    return this.subscriptions.find((s) => s.is_default) || null
  }

  public find(id: number): DetailedSubscription | null {
    return this.subscriptions.find((s) => s.id === id) || null
  }

  public first(): DetailedSubscription | null {
    return this.subscriptions[0] || null
  }

  public all(): Array<DetailedSubscription> {
    return this.subscriptions
  }

  public take(amount: number): Array<DetailedSubscription> {
    return this.subscriptions.slice(0, amount)
  }
}

export class Users {
  public users: Array<User> = []

  public constructor(users: Array<User>) {
    this.users = users
  }

  public sortByAccessedDate(): this {
    this.users = this.users.sort((a: User, b: User) =>
      b.date_accessed.localeCompare(a.date_accessed)
    )

    return this
  }

  public sortByCompanyName(): this {
    this.users = this.users.sort((a: User, b: User) =>
      a.company_name.trim().toLowerCase().localeCompare(b.company_name.trim().toLowerCase())
    )

    return this
  }

  public search(term: string): this {
    this.users = this.users.filter(
      (u: User) =>
        u.first_name.toLowerCase().includes(term.toLowerCase()) ||
        u.last_name.toLowerCase().includes(term.toLowerCase()) ||
        u.email.toLowerCase().includes(term.toLowerCase()) ||
        u.company_name.toLowerCase().includes(term.toLowerCase()) ||
        u.username.toLowerCase().includes(term.toLowerCase())
    )

    return this
  }

  public findMostRecent(): User | null {
    return this.sortByAccessedDate().first() || null
  }

  public find(id: number): User | null {
    return this.users.find((u) => u.id === id) || null
  }

  public first(): User | null {
    return this.users[0] || null
  }

  public all(): Array<User> {
    return this.users
  }

  public take(amount: number): Array<User> {
    return this.users.slice(0, amount)
  }
}
