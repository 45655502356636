import * as api from '@/services/ie-microservice-openapi'
import { SubscriptionCreatable, SubscriptionEditable } from '@/services/ie-microservice-openapi'

export async function getSubscriptionsByUserId(userID: number) {
  return await new api.SubscriptionsApi()
    .getSubscriptionsByUserId(userID)
    .then(({ data }) => ({
      data,
    }))
    .catch((err) => ({
      error: err as string,
    }))
}

type nullError = Error | null

export async function getSubscriptionById(
  subID: number
): Promise<[api.DetailedSubscription, nullError]> {
  return await new api.SubscriptionsApi()
    .getSubscription(subID)
    .then(({ data }) => {
      return [data, null] as [api.DetailedSubscription, nullError]
    })
    .catch((err) => [{}, err] as [api.DetailedSubscription, nullError])
}

export async function createBasicSubscription(userID: number, accountName: string) {
  const { PaymentPlanEnum, PaymentTypeEnum } = api
  const payload: SubscriptionCreatable = {
    plan_id: PaymentPlanEnum.Basic,
    payment_type: PaymentTypeEnum.Trial,
    account_name: accountName,
    pro_standard: false,
    use_defaults: true,
  }
  return await new api.SubscriptionsApi()
    .createSubscriptionForUser(userID, payload)
    .then(({ data }) => {
      return {
        success: true,
        data,
      }
    })
    .catch((e) => {
      return {
        success: false,
        message: e,
      }
    })
}

export async function createSubscriptionWithTier(
  userID: number,
  accountName: string,
  tier: keyof typeof api.PaymentPlanEnum
) {
  const { PaymentTypeEnum, PaymentPlanEnum } = api
  const payload: SubscriptionCreatable = {
    plan_id: PaymentPlanEnum[tier],
    payment_type: PaymentTypeEnum.Trial,
    account_name: accountName,
    pro_standard: false,
    use_defaults: true,
  }
  return await new api.SubscriptionsApi()
    .createSubscriptionForUser(userID, payload)
    .then(({ data }) => {
      return {
        success: true,
        data,
      }
    })
    .catch((e) => {
      return {
        success: false,
        message: e,
      }
    })
}

export async function createSubscriptionV2(
  userID: number,
  accountName: string,
  tier: keyof typeof api.PaymentPlanEnum,
  standardPayment: boolean
) {
  let devTier = false

  if (tier === 'Dev') {
    devTier = true
  }

  const { PaymentTypeEnum, PaymentPlanEnum } = api

  let paymentType = PaymentTypeEnum.Trial

  if (standardPayment) {
    paymentType = PaymentTypeEnum.Standard
  }
  if (devTier) {
    paymentType = PaymentTypeEnum.Free
  }

  const payload: SubscriptionEditable = {
    payment_plan: PaymentPlanEnum[tier],
    payment_type: paymentType,
    account_name: accountName,
  }
  return await new api.SubscriptionsApi()
    .createSubscriptionForUserV2(userID, payload)
    .then(({ data }) => {
      return {
        success: true,
        data,
      }
    })
    .catch((e) => {
      return {
        success: false,
        message: e,
      }
    })
}

export async function createProSubscription(
  userID: number,
  accountName: string,
  paymentType: api.PaymentTypeEnum
) {
  const { PaymentPlanEnum } = api
  const payload: SubscriptionCreatable = {
    plan_id: PaymentPlanEnum.Pro,
    payment_type: paymentType,
    account_name: accountName,
    pro_standard: false,
    use_defaults: true,
  }

  return await new api.SubscriptionsApi()
    .createSubscriptionForUser(userID, payload)
    .then(({ data }) => {
      return {
        success: true,
        data,
      }
    })
    .catch((e) => {
      return {
        success: false,
        message: e,
      }
    })
}

export interface OriginCreatable {
  name?: string
  url_type: string
  hostname: string
  host_header: string
  username: string
  password: string
  port: string
  path: string
  bucketname: string
}

export interface EngineCreatable {
  origin?: OriginCreatable
  origin_id?: number
  cname?: string
}

export async function createEngine(
  subID: number,
  originData?: OriginCreatable,
  engineData?: EngineCreatable
) {
  const payload = {
    cors_enabled: true,
    // This should be true for all account types (basic, standard, pro)
    // See the comments in #IECP-200 for more details
    ssl: true,
    // this should be default #IECP-289
    cors_allowed_origins: ['*'],
    cors_allowed_methods: 'GET,POST,OPTIONS',
    cors_allowed_headers: ['*'],
    cors_max_age: 7200,
  } as any

  let origin = null as OriginCreatable | null

  if (originData) {
    origin = originData
  }

  if (engineData) {
    if (engineData.origin) {
      origin = engineData.origin
    }

    if (engineData.origin_id) {
      payload.origin_id = engineData.origin_id
    }

    if (engineData.cname) {
      payload.cname = engineData.cname
    }
  }

  if (origin) {
    payload.origin = {
      name: origin.name ?? 'default',
      url_type: origin.url_type,
      hostname: origin.hostname,
      host_header: origin.host_header,
      username: origin.username,
      password: origin.password,
      port: parseInt(origin.port),
      path: origin.path,
      bucketname: origin.bucketname,
    }
  }

  return await new api.EnginesApi()
    .createEngine(subID, payload)
    .then(({ data }) => {
      return {
        success: true,
        engine: data,
        message: '',
      }
    })
    .catch((err) => {
      // console.log(extractError(err.response.data))
      return {
        success: false,
        message:
          'An engine could not be created. Please check that you have entered the correct URL.',
        engine: null,
      }
    })
}

interface ErrorData {
  message?: string
  errors: { [key: string]: string }
  success: false
}

interface Error {
  response: {
    data: ErrorData
  }
}

export function extractError({ response: { data } }: Error): Array<string> {
  if (data.message) {
    return [data.message]
  }
  return Object.keys(data.errors).map((key) => {
    return data.errors[key]
  })
}
