<template>
  <b-alert show dismissible variant="primary">
    <span class="alert-text"
      ><strong>Note:</strong> You are viewing this account in impersonation mode.</span
    >
  </b-alert>
</template>

<script>
  export default {
    name: 'impersonation-banner',
  }
</script>
