import {
  CmsType,
  CmsTypes,
  FrontedTypes,
  FrontendType,
  SubscriptionStatus,
  UserScope,
} from '@/util/enums'
import moment from 'moment'
import { DetailedSubscription } from '@/util/decorators'
import * as api from '@/services/ie-microservice-openapi'
import { SubscriptionsApi } from '@/services/ie-microservice-openapi'

export async function getBaseCost(subscription: DetailedSubscription) {
  try {
    const { data } = await new SubscriptionsApi().getSubscriptionBillingInfoV2(subscription.id)

    if (data.product?.billing_frequency === 'monthly') {
      return subscription.tier?.flat_rate
    } else {
      if (data.product?.tier === 'starter') {
        return 39
      }
      if (data.product?.tier === 'growth') {
        return 225
      }
      if (data.product?.tier === 'beast-mode') {
        return 449
      }
    }
  } catch (err) {
    return subscription.tier?.flat_rate || 0
  }
}

//Shorten payment plan names. example: "IMAGEENGINE_BEAST_MODE" -> "Beast"
export function shortenPlanName(subscription: DetailedSubscription) {
  switch (subscription.payment_plan) {
    case api.PaymentPlanEnum.Starter:
      return 'Starter'
    case api.PaymentPlanEnum.Growth:
      return 'Growth'
    case api.PaymentPlanEnum.BeastMode:
      return 'Beast'
    case api.PaymentPlanEnum.Pro:
      return 'Pro'
    case api.PaymentPlanEnum.Dev:
      return 'Developer'
    case api.PaymentPlanEnum.Basic:
      return 'Basic'
    case api.PaymentPlanEnum.Standard:
      return 'Standard'
    case api.PaymentPlanEnum.Partner:
      return 'Partner'
    default:
      return subscription.payment_plan
  }
}

//Get Maxio billing periods by invoice date
export async function getMaxioPeriods(subID: number) {
  try {
    const { data } = await new SubscriptionsApi().getSubscriptionBillingHistoryV2(subID)
    const recentInvoice = data[0]
    //can test by manually adding a start date like '2024-01-10T00:00:00Z'
    //instead of recentInvoice.issued_at
    let invoiceStart = moment.utc(recentInvoice.issued_at)
    const currentDate = moment.utc()

    const isAnnual = recentInvoice.subscription_name?.includes('Annual')

    if (isAnnual) {
      // initial end date from the initial invoice date
      const initialEndDate = invoiceStart.clone().add(1, 'month')

      if (currentDate.isBetween(invoiceStart, initialEndDate, null, '[]')) {
        // If current date is within 1st month set start to initial invoice
        // start and end date to intial 1 month from then
        return {
          start: invoiceStart.clone(),
          end: initialEndDate.clone(),
        }
      }

      // subsequent start and end dates
      while (initialEndDate.isBefore(invoiceStart.clone().add(1, 'year'))) {
        // Move start date to the next month
        invoiceStart = invoiceStart.clone().add(1, 'month')

        // new end date 1 month from the new start date
        const endDate = invoiceStart.clone().add(1, 'month')

        // Check if current date falls within the calculated period
        if (currentDate.isBetween(invoiceStart, endDate, null, '[]')) {
          return {
            start: invoiceStart.clone(),
            end: endDate.clone(),
          }
        }

        // Update initial end date for next iteration
        initialEndDate.add(1, 'month')
      }
    } else {
      // Monthly plan
      const invoiceEnd = invoiceStart.clone().add(1, 'month')
      return { start: invoiceStart, end: invoiceEnd }
    }
  } catch (err) {
    return { start: moment().startOf('month'), end: moment() }
  }
  return { start: moment().startOf('month'), end: moment() }
}

// Get the highest Tier user from team to set team limits
export async function getTeamHighestTier(teamID: number) {
  try {
    const highestMember = { tier: '', limit: '', memberCount: 0 }
    const { data } = await new api.TeamsApi().getTeamMembers(teamID)
    const members = data
    highestMember.memberCount = members.length
    const allSubscriptions = [] as string[]

    for (const member of members) {
      const response = await new api.SubscriptionsApi().getSubscriptionsByUserId(member.id)
      const subscriptions = response.data
      const memberSubscriptions = subscriptions.map((subscription) => subscription.payment_plan)
      memberSubscriptions.forEach((paymentPlan) => allSubscriptions.push(paymentPlan))
    }

    if (allSubscriptions.includes('IMAGEENGINE_PRO')) {
      highestMember.tier = 'pro'
      highestMember.limit = 'unlimited'
    } else if (allSubscriptions.includes('IMAGEENGINE_BEAST_MODE')) {
      highestMember.tier = 'beast'
      highestMember.limit = 'unlimited'
    } else if (
      allSubscriptions.includes('IMAGEENGINE_GROWTH') &&
      !allSubscriptions.includes('IMAGEENGINE_BEAST_MODE')
    ) {
      highestMember.tier = 'growth'
      highestMember.limit = '5'
    } else {
      highestMember.tier = 'starter'
      highestMember.limit = '2'
    }

    return highestMember
  } catch (err) {
    return false
  }
}

// Check if the URL includes a path
export const hasPath = (origin: string) => {
  try {
    const url = new URL(origin)
    return url.pathname !== '/'
  } catch (err) {
    return false
  }
}

//Check if new tiers - Starter, Growth, Beast
export const isNewTier = (subscription: DetailedSubscription) => {
  return (
    subscription?.payment_plan === 'IMAGEENGINE_STARTER' ||
    subscription?.payment_plan === 'IMAGEENGINE_GROWTH' ||
    subscription?.payment_plan === 'IMAGEENGINE_BEAST_MODE'
  )
}

//Check if subscription is Developer Tier
export const isDeveloperTier = (subscription: DetailedSubscription) => {
  return subscription?.payment_plan === 'IMAGEENGINE_DEV'
}

//Check if subscription is Starter Tier
export const isStarterTier = (subscription: DetailedSubscription) => {
  return subscription?.payment_plan === 'IMAGEENGINE_STARTER'
}

//Check if subscription is Growth Tier
export const isGrowthTier = (subscription: DetailedSubscription) => {
  return subscription?.payment_plan === 'IMAGEENGINE_GROWTH'
}

//Check if subscription is Beast Tier
export const isBeastTier = (subscription: DetailedSubscription) => {
  return subscription?.payment_plan === 'IMAGEENGINE_BEAST_MODE'
}

export const removeSchemeTrailingSlash = (url: string) => {
  //remove any extra added scheme
  const urlWithoutScheme = url.toLowerCase().replace(/^(https?:\/\/)?/, '')
  //remove any number of trailing slashes from URL
  return urlWithoutScheme.replace(/\/+$/, '')
}

const emailRegex =
  // eslint-disable-next-line
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const emailMatcher = new RegExp(emailRegex)

export const siteUrl = process.env.VUE_APP_SITE_URL as string

export const isEmailValid = (value: string) => {
  return emailMatcher.test(value)
}

export const isProd = (): boolean => {
  const hostname = window.location.hostname.split('.')[0]
  return !(hostname.includes('staging') || hostname.includes('local'))
}

export const isStagingOrLocal = (): boolean => {
  const hostname = window.location.hostname.split('.')[0]
  return hostname.includes('staging') || hostname.includes('local')
}

export const getOrElse = (obj: Record<string, string>, attr: string, value: string): string => {
  if (value === undefined) {
    console.error(`Undefined fallback value when looking up ${attr} on object:`, obj)
    value = ''
  }

  if (!obj) {
    return value
  }

  return attr in obj ? obj[attr] : value
}

const checksum = (str: string) => {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  return hash
}

export const toColor = (input: string) => {
  const code = checksum(input.toString())
  const c = (code & 0x00ffffff).toString(16).toUpperCase()
  return '00000'.substring(0, 6 - c.length) + c
}

export const getSubscriptionStatusClass = (status: string) => {
  return {
    'text-success': status === SubscriptionStatus.Active,
    'text-danger': status === SubscriptionStatus.Canceled || status == SubscriptionStatus.Suspended,
    'text-warning':
      status !== SubscriptionStatus.Canceled &&
      status !== SubscriptionStatus.Suspended &&
      status !== SubscriptionStatus.Active,
  }
}

export const getUserScopeClass = (scope: UserScope | null) => {
  return scope === UserScope.Partner ? 'indigo' : 'primary'
}

/**
 * Method to scroll into view port, if it's outside the viewport
 *
 * @param {HTMLElement} target - DOM Element
 * @param {ScrollIntoViewOptions} options
 * @returns {undefined}
 * @see https://gist.github.com/makkBit/d5733f5ec82d7816b2277bebcdbec0c5#file-jsx
 */
export const scrollIntoViewIfNeeded = (target: HTMLElement, options?: ScrollIntoViewOptions) => {
  // Target is outside the viewport from the bottom
  if (target.getBoundingClientRect().bottom > window.innerHeight) {
    //  The bottom of the target will be aligned to the bottom of the visible area of the scrollable ancestor.
    target.scrollIntoView(options || false)
  }

  // Target is outside the view from the top
  if (target.getBoundingClientRect().top < 0) {
    // The top of the target will be aligned to the top of the visible area of the scrollable ancestor
    target.scrollIntoView(options || false)
  }
}

export const getTechnologyData = (technology: CmsType | FrontendType) => {
  return (
    CmsTypes.find((t) => t.type === technology) ||
    FrontedTypes.find((t) => t.type === technology) || {
      type: '',
      name: '',
      integrationGuideUrl: '',
    }
  )
}

export const downloadCSV = (
  header: Array<string>,
  columnData: Array<Array<string | number>>,
  filename: string
) => {
  const csv =
    [header, ...columnData[0].map((_, i) => columnData.map((column) => column[i]).join(','))].join(
      '\r\n'
    ) + '\r\n'

  const anchor = document.createElement('a')
  anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv)
  anchor.target = '_blank'
  anchor.download = filename.replaceAll('-', '_')
  anchor.click()
}
