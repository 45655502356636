import { Severity, withScope, captureException, captureMessage } from '@sentry/browser'

interface Context {
  tags?: { [key: string]: string | number }
  extras?: { [key: string]: unknown }
}

export const logWarning = (err: string | Error, context?: Context) => {
  process.env.NODE_ENV !== 'production'
    ? console.warn(err, context)
    : log(err, Severity.Warning, context)
}

export const logError = (err: string | Error, context?: Context) => {
  process.env.NODE_ENV !== 'production'
    ? console.error(err, context)
    : log(err, Severity.Error, context)
}

export const logInfo = (err: string | Error, context?: Context) => {
  process.env.NODE_ENV !== 'production'
    ? console.info(err, context)
    : log(err, Severity.Info, context)
}

const log = (err: string | Error, level: Severity, context?: Context) => {
  const pathname = window.location.pathname
  withScope((scope) => {
    scope.setLevel(level)
    if (context) {
      const { extras, tags } = context
      extras && scope.setExtras(extras)
      tags && scope.setTags(tags)
    }
    scope.setTag('pathname', pathname)
    typeof err === 'string' ? captureMessage(err) : captureException(err)
  })
}
