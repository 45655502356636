import {
  EnginesApi as BaseEnginesApi,
  EngineUpdatable,
  OriginsApi as BaseOriginsApi,
  SubscriptionsApi as BaseSubscriptionsApi,
  TeamsApi as BaseTeamsApi,
} from '@/services/ie-microservice-openapi/api'
import {
  decorateDetailedSubscription,
  decorateEngine,
  decorateUser,
  DetailedSubscription,
  Engine,
  User,
  UserOnboarding,
} from '@/util/decorators'
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { TempStorage } from '@/util'
import { logError } from '@/util/sentry'

export class SubscriptionsApi extends BaseSubscriptionsApi {
  public getSubscriptionsByUserId(userId: number, options?: AxiosRequestConfig) {
    return (async () => {
      const res = await super.getSubscriptionsByUserId(userId, options)

      res.data = res.data.map((s) => decorateDetailedSubscription(s))

      return res as AxiosResponse<Array<DetailedSubscription>>
    })()
  }

  public getSubscription(subscriptionId: number, options?: AxiosRequestConfig) {
    return (async () => {
      const res = await super.getSubscription(subscriptionId, options)

      res.data = decorateDetailedSubscription(res.data)

      return res as AxiosResponse<DetailedSubscription>
    })()
  }
}

export class SubscriptionsApiWrapper {
  public loadSubscriptionsForAccount(account: User) {
    return (async () => {
      let subscriptions: Array<DetailedSubscription> = []

      try {
        if (account.team_id) {
          const { data } = await new TeamsApi().getTeamMembers(account.team_id)
          const users = data.filter((u) => u.team_id === account.team_id)

          const promises = users.map((u) => new SubscriptionsApi().getSubscriptionsByUserId(u.id))
          const responses = await Promise.allSettled(promises)

          for (const resp of responses) {
            if (resp.status !== 'fulfilled') continue
            const { data } = resp.value
            subscriptions.push(...data)
          }
        } else {
          await new SubscriptionsApi()
            .getSubscriptionsByUserId(account.id)
            .then((res) => (subscriptions = res.data || []))
        }
      } catch (err) {
        logError('failed to load subscriptions', { extras: { error: err } })
      }

      if (!subscriptions) {
        return []
      }

      return subscriptions
    })()
  }
}

export class EnginesApi extends BaseEnginesApi {
  public getEngine(engineId: number, options?: AxiosRequestConfig) {
    return (async () => {
      const res = await super.getEngine(engineId, options)

      res.data = decorateEngine(res.data)

      return res as AxiosResponse<Engine>
    })()
  }

  public updateEngine(
    engineId: number,
    engineUpdatable?: EngineUpdatable,
    options?: AxiosRequestConfig
  ) {
    return (async () => {
      if (engineUpdatable.nickname) {
        new TempStorage().storeEngineNickname(engineId, engineUpdatable.nickname)
      }
      if (engineUpdatable.is_favorite !== undefined) {
        new TempStorage().storeEngineFavoriteFlag(engineId, engineUpdatable.is_favorite)
      }
      if (engineUpdatable.is_verified !== undefined) {
        new TempStorage().storeEngineVerifiedFlag(engineId, engineUpdatable.is_verified)
      }
      if (engineUpdatable.setup_instructions_dismissed) {
        new TempStorage().storeEngineSetupInstructionsDismissed(
          engineId,
          engineUpdatable.setup_instructions_dismissed
        )
      }

      return {
        data: {},
        status: 200,
        statusText: '',
        headers: {},
        config: {},
      } as AxiosResponse<Engine>
    })()
  }
}

export class OriginsApi extends BaseOriginsApi {
  public updateOriginFavoriteState(originId: number, state: boolean, options?: AxiosRequestConfig) {
    return (async () => {
      new TempStorage().storeOriginFavoriteFlag(originId, state)

      return {
        data: true,
        status: 200,
        statusText: '',
        headers: {},
        config: {},
      } as AxiosResponse<boolean>
    })()
  }
}

export class TeamsApi extends BaseTeamsApi {
  public getTeamCustomers(teamId: number, options?: AxiosRequestConfig) {
    return (async () => {
      const res = await super.getTeamCustomers(teamId, options)

      res.data = res.data.map((u) => decorateUser(u))

      return res as AxiosResponse<Array<User>>
    })()
  }
}

export class TeamsApiWrapper {
  public loadAccountsForUser(user: User) {
    return (async () => {
      let accounts: Array<User> = []

      if (!user.team_id) {
        return []
      }

      try {
        const { data } = await new TeamsApi().getTeamCustomers(user.team_id)

        if (!data) {
          return []
        }

        accounts = data
      } catch (err) {
        logError('failed to load accounts', { extras: { error: err } })
      }

      if (!accounts) {
        return []
      }

      return accounts
    })()
  }
}

export class UserOnboardingApi {
  public updateOnboardingByUserId(
    userId: number,
    state: UserOnboarding,
    options?: AxiosRequestConfig
  ) {
    return (async () => {
      new TempStorage().storeUserOnboarding(userId, state)

      return {
        data: true,
        status: 200,
        statusText: '',
        headers: {},
        config: {},
      } as AxiosResponse<boolean>
    })()
  }
  public getOnboardingByUserId(userId: number, options?: AxiosRequestConfig) {
    return (async () => {
      return {
        data: new TempStorage().getUserOnboarding(userId),
        status: 200,
        statusText: '',
        headers: {},
        config: {},
      } as AxiosResponse<UserOnboarding>
    })()
  }
}
