<template>
  <table class="table tablesorter" :class="tableClass">
    <thead :class="theadClasses">
      <tr>
        <slot name="columns" :columns="columns">
          <th v-for="column in columns" :key="column">{{ column }}</th>
        </slot>
      </tr>
    </thead>
    <tbody :class="tbodyClasses">
      <tr v-for="(item, index) in data" :key="index">
        <slot :row="item" :index="index">
          <td v-for="(column, index) in colsWithValue(item)" :key="index">
            {{ itemValue(item, column) }}
          </td>
        </slot>
      </tr>
    </tbody>
  </table>
</template>
<script>
  import { defineComponent, computed } from '@vue/composition-api'
  export default defineComponent({
    name: 'BaseTable',
    props: {
      columns: {
        type: Array,
        default: () => [],
        description: 'Table columns',
      },
      data: {
        type: Array,
        default: () => [],
        description: 'Table data',
      },
      type: {
        type: String, // striped | hover
        default: '',
        description: 'Whether table is striped or hover type',
      },
      theadClasses: {
        type: String,
        default: '',
        description: '<thead> css classes',
      },
      tbodyClasses: {
        type: String,
        default: '',
        description: '<tbody> css classes',
      },
    },
    setup(props) {
      const hasValue = (item, column) => {
        return item[column.toLowerCase()] !== 'undefined'
      }

      const itemValue = (item, column) => {
        return item[column.toLowerCase()]
      }

      const tableClass = computed(() => {
        return props.type && `table-${props.type}`
      })

      const colsWithValue = computed(() => (row) => {
        return props.columns.filter((column) => hasValue(row, column))
      })

      return {
        tableClass,
        itemValue,
        colsWithValue,
      }
    },
  })
</script>
