












































import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
  toRefs,
  watch,
} from '@vue/composition-api'
import IconMagnifierSmall from '@/components/Icons/IconMagnifierSmall.vue'
import SidebarEngine from '@/components/SidebarPlugin/SidebarEngine.vue'
import { DetailedSubscription, Engine } from '@/util/decorators'
import debounce from 'lodash/debounce'
import { Engines } from '@/util/collections'
import { useSidebarStore } from '@/store/sidebar'
import { UserScope } from '@/util/enums'

export default defineComponent({
  components: {
    IconMagnifierSmall,
    SidebarEngine,
  },
  props: {
    show: {
      type: Number,
      default: 3,
      description: 'Amount of items to show',
    },
    subscription: {
      type: Object as PropType<DetailedSubscription>,
      required: true,
    },
    engines: {
      type: Array as PropType<Array<Engine>>,
      required: true,
    },
  },
  setup(props) {
    const sidebarStore = useSidebarStore()
    const searchTerm = ref<string>('')
    const searchResults = ref<Array<Engine> | null>(null)
    const expanded = ref<boolean>(false)
    const { engines } = toRefs(props)

    const onSearch = debounce(() => {
      if (!searchTerm.value.length) {
        expand(false)
        searchResults.value = props.engines
        return
      }

      searchResults.value = new Engines(props.engines).search(searchTerm.value).all()
    }, 50)

    const show = computed(() => {
      const favorites = new Engines(searchResults.value || engines.value)
        .filterByFavoriteStateEnabled()
        .count()

      return favorites === 0 ? props.show : favorites < props.show ? props.show : favorites
    })

    const expand = (expand: boolean) => (expanded.value = expand)
    const expandable = computed(() => !searchTerm.value && show.value < props.engines.length)

    const visible = computed(() => {
      const sorted = new Engines(
        searchResults.value || engines.value
      ).sortByFavoriteStateAndCname()

      return expanded.value ? sorted.all() : sorted.take(show.value)
    })

    const handleEngineChange = () => {
      if (!sidebarStore.requested.engine) {
        return
      }

      if (!new Engines(engines.value).find(sidebarStore.requested.engine)) {
        return
      }

      if (new Engines(visible.value).find(sidebarStore.requested.engine)) {
        return
      }

      expanded.value = true
    }

    onMounted(handleEngineChange)
    watch(() => sidebarStore.requested.engine, handleEngineChange)

    return {
      sidebarStore,
      searchTerm,
      onSearch,
      visible,
      expanded,
      expandable,
      expand,
      UserScope,
    }
  },
})
