import { defineStore } from 'pinia'
import { UserOnboarding, UserOnboardingDefault } from '@/util/decorators'
import { UserOnboardingApi } from '@/services/ie-microservice-future-api'
import { useAuthStore } from '@/store/auth'
import { detectAndStoreCMSForEngine } from '@/util/cmsdetector'
import { CmsType, FrontendType } from '@/util/enums'

interface IOnboardingStore {
  onboarding: UserOnboarding
}

export const useOnboardingStore = defineStore({
  id: 'onboarding',
  state: (): IOnboardingStore => ({
    onboarding: UserOnboardingDefault,
  }),
  actions: {
    async pushState(userId?: number) {
      const id = userId || this.getUserId

      if (id) {
        await new UserOnboardingApi().updateOnboardingByUserId(id, this.onboarding)
      }
    },
    async loadState() {
      if (this.getUserId) {
        const { data } = await new UserOnboardingApi().getOnboardingByUserId(this.getUserId)

        this.onboarding = data
      }
    },
    async detectCmsAndFrontend(url: string) {
      const data = await detectAndStoreCMSForEngine(url)
      if (!data) return
      this.onboarding.detected_cms = (data.cms ?? []) as Array<CmsType>
      this.onboarding.detected_frontend = (data.frontend ?? []) as Array<FrontendType>
    },
    async skip() {
      this.onboarding.is_skipped = true

      await this.pushState()
    },
    async complete() {
      this.onboarding.is_completed = true

      await this.pushState()
    },
  },
  getters: {
    getUserId: (): number | null => {
      return useAuthStore().user?.id || null
    },
  },
})
