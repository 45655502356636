





import { PropType, defineComponent, computed } from '@vue/composition-api'

export default defineComponent({
  props: {
    type: {
      required: true,
      type: String as PropType<
        'customerPurple' | 'partnerBlue' | 'whitePartner' | 'whiteCustomer'
      >,
    },
    title: {
      type: String,
      default: null,
    },
    to: {
      type: String,
      default: null,
    },
  },
  setup(props, context) {
    const wrapperClass = computed(() => {
      let baseClass = 'sidebar-btn'
      if (props.type === 'partnerBlue') {
        baseClass += ' partner-blue bg-gradient-blue-bright'
      } else if (props.type === 'customerPurple') {
        baseClass += ' customer-purple bg-gradient-pink-bright'
      } else if (props.type === 'whitePartner') {
        baseClass += ' white-partner'
      } else if (props.type === 'whiteCustomer') {
        baseClass += ' white-customer'
      }
      return baseClass
    })

    const redirectToLink = () => {
      if (props.to) {
        context.root.$router.push({ name: props.to })
      }
    }

    return { wrapperClass, redirectToLink }
  },
})
