/**
 * General helpers
 *
 * Functions in this file should be looking for a new home!
 */

export const charsAlpha = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
export const charsNumeric = '0123456789'
export const charsSymbols = 'a-zA-Z0-9_-+.$%*&@!'
export const charsAlphaNumeric = charsAlpha + charsNumeric
export const charsAll = charsAlphaNumeric + charsSymbols

/**
 * Generate a random string.  This is NOT cryptographically-safe.
 * TODO: test
 * @see cryptoRandomString
 * @param length length of the generated string
 * @param chars characters to use when generating
 * @returns random string
 */
export function randomString(length: number, chars: string = charsAlphaNumeric): string {
  let result = ''
  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length))
  }
  return result
}

/**
 * Generate a cryptographically-safe random string.
 * TODO: test
 * @see randomString
 * @param length length of the generated string
 * @param chars characters to use when generating
 * @returns random string
 */
export function cryptoRandomString(length: number, chars: string = charsAlphaNumeric): string {
  // Build a map of the characters codes since we have to do a lot of
  // lookups to weed out chars outside of the charset.
  const charCodesMap: { [key: number]: boolean } = {}
  for (let i = 0; i < chars.length; i++) {
    charCodesMap[chars.charCodeAt(i)] = true
  }

  let result = ''
  let remaining = length
  while (remaining > 0) {
    const bytes = new Uint8Array(32)
    crypto.getRandomValues(bytes)

    const block: number[] = []
    bytes
      .filter((code) => code in charCodesMap)
      .forEach((code) => {
        if (remaining === 0) {
          return
        }

        block.push(Number(code))
        remaining--
      })

    result += String.fromCharCode(...block)
  }

  return result
}
