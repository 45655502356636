


















import { computed, defineComponent, PropType } from '@vue/composition-api'
import SidebarEngines from '@/components/SidebarPlugin/SidebarEngines.vue'
import SidebarOrigins from '@/components/SidebarPlugin/SidebarOrigins.vue'
import SidebarSelectSubscription from '@/components/SidebarPlugin/SidebarSelectSubscription.vue'
import { DetailedSubscription } from '@/util/decorators'
import { SubscriptionType } from '@/util/enums'
import { useSidebarStore } from '@/store/sidebar'

export default defineComponent({
  components: {
    SidebarEngines,
    SidebarOrigins,
    SidebarSelectSubscription,
  },
  props: {
    subscription: {
      type: Object as PropType<DetailedSubscription>,
      required: true,
    },
    type: {
      type: String as PropType<SubscriptionType>,
      required: true,
    },
  },
  setup() {
    return {
      userScope: computed(() => useSidebarStore().current.userScope?.toLowerCase()),
    }
  },
})
