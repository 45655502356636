


























































































































import { computed, defineComponent, PropType, ref } from '@vue/composition-api'
import IconDots from '@/components/Icons/IconDots.vue'
import IconMagnifierSmall from '@/components/Icons/IconMagnifierSmall.vue'
import SubscriptionStatusBullet from '@/components/SubscriptionStatusBullet.vue'
import debounce from 'lodash/debounce'
import { useSidebarStore } from '@/store/sidebar'
import { SubscriptionType, UserScope } from '@/util/enums'
import { DetailedSubscription } from '@/util/decorators'
import { DetailedSubscriptions } from '@/util/collections'
import { getUserScopeClass } from '@/util'
import { isDeveloperTier } from '@/util/helpers'
import { TempStorage } from '@/util'

export default defineComponent({
  components: {
    IconDots,
    IconMagnifierSmall,
    SubscriptionStatusBullet,
  },
  props: {
    type: {
      type: String as PropType<SubscriptionType>,
      required: true,
    },
    subscription: {
      type: Object as PropType<DetailedSubscription>,
      required: true,
    },
  },
  setup(props, context) {
    const sidebarStore = useSidebarStore()
    const searchTerm = ref<string>('')
    const searchResults = ref<Array<DetailedSubscription> | null>(null)
    const subscriptions = computed(() => sidebarStore.getRecentlyViewedSubscriptions(props.type))
    const visible = computed(() => searchResults.value || subscriptions.value)
    const userScopeClass = computed(() => getUserScopeClass(sidebarStore.current.userScope))

    const isDevTier = computed(() => {
      return props.type === 'SUBSCRIPTION' && isDeveloperTier(props.subscription)
    })

    const setActiveSubscription = (subscription: DetailedSubscription) => {
      context.root.$router.push({
        path: `${sidebarStore.getAccountRoutePrefix}/subscriptions/${subscription.id}/engines`,
      })
    }

    const updateDefaultState = (subscription: DetailedSubscription) => {
      sidebarStore.updateDefaultSubscription(subscription)

      if (subscription.is_default) {
        setActiveSubscription(subscription)
      }
    }

    if (props.subscription.is_payment_method_missing) {
      new TempStorage().storePaymentAddedStatus(false)
    }

    const onSearch = debounce(() => {
      if (!searchTerm.value.length) {
        searchResults.value = null
        return
      }

      searchResults.value = new DetailedSubscriptions(sidebarStore.subscriptions)
        .filterByType(props.type)
        .search(searchTerm.value)
        .all()
    }, 50)

    const dropdownToggled = (isOpen: boolean) => {
      if (!isOpen) {
        searchTerm.value = ''
        searchResults.value = null
      }
    }

    const menuClasses = computed(() => {
      if (isDevTier.value) {
        return `dev-dropdown-menu sidebar-dropdown-links-menu p-0 ${userScopeClass}`
      } else {
        return `sidebar-dropdown-menu sidebar-dropdown-links-menu p-0 ${userScopeClass}`
      }
    })

    const links = computed((): Array<{ url: string; text: string }> => {
      let links = [
        {
          url: `${sidebarStore.getAccountRoutePrefix}/subscriptions/${props.subscription.id}/analytics`,
          text: 'Analytics',
        },
        {
          url: `${sidebarStore.getAccountRoutePrefix}/subscriptions/${props.subscription.id}/details`,
          text: 'See details',
        },
      ]

      if (
        sidebarStore.current.userScope === UserScope.Customer &&
        !props.subscription.is_pro_tier
      ) {
        if (isDevTier.value) {
          links.push({
            url: `/subscriptions/${props.subscription.id}/details/devTier`,
            text: 'Create Subscription for Commerical use',
          })
        } else {
          let query = props.subscription.is_payment_method_missing
            ? '?addPayment=true'
            : '?updateBilling=true'

          links.push({
            url: `/subscriptions/${props.subscription.id}/details?${query}=true`,
            text: props.subscription.is_payment_method_missing
              ? 'Add Payment Method'
              : 'Update payment details',
          })

          if (!props.subscription.is_payment_method_missing) {
            links.unshift({
              url: `/subscriptions/${props.subscription.id}/billing/upgrade`,
              text: 'Upgrade',
            })

            links.push({
              url: `/subscriptions/${props.subscription.id}/billing/history`,
              text: 'Billing history',
            })
          }
        }
      }

      return links
    })

    return {
      sidebarStore,
      searchTerm,
      onSearch,
      visible,
      links,
      dropdownToggled,
      userScopeClass,
      setActiveSubscription,
      updateDefaultState,
      UserScope,
      isDevTier,
      menuClasses,
    }
  },
})
