import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Badge from '@/components/Badge'
import BaseAlert from '@/components/BaseAlert'
import BaseButton from '@/components/BaseButton'
import BaseCheckbox from '@/components/Inputs/BaseCheckbox'
import BaseDropdown from '@/components/BaseDropdown'
import BaseHeader from '@/components/BaseHeader'
import BaseInput from '@/components/Inputs/BaseInput'
import BaseNav from '@/components/Navbar/BaseNav'
import BasePagination from '@/components/BasePagination'
import BaseProgress from '@/components/BaseProgress'
import BaseRadio from '@/components/Inputs/BaseRadio'
import BaseSlider from '@/components/BaseSlider'
import BaseSwitch from '@/components/BaseSwitch'
import BaseTable from '@/components/BaseTable'
import Card from '@/components/Cards/Card'
import Modal from '@/components/Modal'
import RouteBreadcrumb from '@/components/Breadcrumb/RouteBreadcrumb'
import StatsCard from '@/components/Cards/StatsCard'
import TabPane from '@/components/Tabs/TabPane'
import Tabs from '@/components/Tabs/Tabs'
/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
  install(Vue) {
    Vue.component(Badge.name, Badge)
    Vue.component(BaseAlert.name, BaseAlert)
    Vue.component(BaseButton.name, BaseButton)
    Vue.component(BaseCheckbox.name, BaseCheckbox)
    Vue.component(BaseDropdown.name, BaseDropdown)
    Vue.component(BaseHeader.name, BaseHeader)
    Vue.component(BaseInput.name, BaseInput)
    Vue.component(BaseNav.name, BaseNav)
    Vue.component(BasePagination.name, BasePagination)
    Vue.component(BaseProgress.name, BaseProgress)
    Vue.component(BaseRadio.name, BaseRadio)
    Vue.component(BaseSlider.name, BaseSlider)
    Vue.component(BaseSwitch.name, BaseSwitch)
    Vue.component(BaseTable.name, BaseTable)
    Vue.component(Card.name, Card)
    Vue.component(Modal.name, Modal)
    Vue.component(RouteBreadcrumb.name, RouteBreadcrumb)
    Vue.component(StatsCard.name, StatsCard)
    Vue.component(TabPane.name, TabPane)
    Vue.component(Tabs.name, Tabs)
    Vue.component('validation-provider', ValidationProvider)
    Vue.component('validation-observer', ValidationObserver)
  },
}

export default GlobalComponents
