
















import { defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
  setup() {
    const year = ref(new Date().getFullYear())

    return { year }
  },
})
