










import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    width: {
      type: [Number, String],
      default: 14,
    },
    height: {
      type: [Number, String],
      default: 12,
    },
    viewBox: {
      type: String,
      default: '0 0 14 12',
    },
  },
})
