




















































































import { computed, defineComponent, onMounted, ref, watch } from '@vue/composition-api'
import { useSidebarStore } from '@/store/sidebar'
import { useOnboardingStore } from '@/store/onboarding'
import { useAuthStore } from '@/store/auth'
import { UserPreference, UsersApi } from '@/services/ie-microservice-openapi'
import { CmsType, FrontendType, SignupType, UserRole, UserScope } from '@/util/enums'
import Modal from '@/components/Modal.vue'
import { logError } from '@/util/sentry'
import { SubscriptionsApi } from '@/services/ie-microservice-future-api'
import { DetailedSubscription } from '@/util/decorators'
import { isDeveloperTier } from '@/util/helpers'
import { TempStorage } from '@/util/tempStorage'
import onboardState from '@/store/onboardingTechnology'

export default defineComponent({
  components: {
    Modal,
  },
  setup() {
    onboardState.manualSelect = false
    const authStore = useAuthStore()
    const sidebarStore = useSidebarStore()
    const onboardingStore = useOnboardingStore()
    const subscription = ref<DetailedSubscription | null>()

    const show = ref(false)

    onMounted(async () => {
      if (authStore.user?.id) {
        const { data } = await new SubscriptionsApi().getSubscriptionsByUserId(authStore.user?.id)
        subscription.value = data[0]
      }
      const compute = () => {
        if (onboardingStore.onboarding.is_completed) {
          return false
        }

        if (onboardingStore.onboarding.is_skipped) {
          return false
        }

        if (onboardingStore.onboarding.signup_type === SignupType.GitHub) {
          try {
            new UsersApi().createUserPreference(authStore.user.id, {
              key: 'company_role',
              value: UserRole.Engineering,
            } as UserPreference)
          } catch (e: any) {
            logError('Failed to save preference', { extras: e })
          }
        }

        if (
          onboardingStore.onboarding.signup_type === SignupType.GitHub &&
          onboardingStore.onboarding.website
        ) {
          return false
        }

        return true
      }

      show.value = compute()
    })

    watch(subscription, () => {
      if (subscription.value && isDeveloperTier(subscription.value)) {
        model.value.non_technical = false
      }
    })

    const technologies = Object.values({ ...CmsType, ...FrontendType }).sort((a, b) =>
      a.toLocaleLowerCase().localeCompare(b.toLowerCase())
    )
    const roles = Object.values(UserRole).filter((v) => v !== UserRole.Engineering)

    const isWebsitePresent = ref(!!onboardingStore.onboarding.website)

    const model = ref({
      non_technical: onboardingStore.onboarding.signup_type !== SignupType.GitHub,
      role: null as UserRole | null,
      roleOther: '',
      technology: [
        ...onboardingStore.onboarding.detected_cms,
        ...onboardingStore.onboarding.detected_frontend,
        new TempStorage().getOnboardingTech(),
      ],
    })

    const userScopeClass = computed(() =>
      sidebarStore.current.userScope === UserScope.Partner ? 'indigo' : 'primary'
    )

    const technologyChangeTrigger = ref(0)

    const next = () => {
      show.value = false
      window.dataLayer.push({ event: 'onboarding_customize_setup_next' })
      let role

      if (model.value.non_technical && model.value.role) {
        role = model.value.role === UserRole.Other ? model.value.roleOther : model.value.role
      } else if (!model.value.non_technical) {
        role = UserRole.Engineering
      }

      if (role) {
        new UsersApi().createUserPreference(authStore.user.id, {
          key: 'company_role',
          value: role,
        } as UserPreference)
      }

      onboardingStore.onboarding.is_technical = !model.value.non_technical
      onboardingStore.complete()
    }

    const skip = () => {
      show.value = false
      window.dataLayer.push({ event: 'onboarding_customize_setup_skip' })
      onboardingStore.skip()
    }

    const modelTechnology = computed(() => model.value.technology)

    //when user manually selects - store tech in localstorage and set the
    //onboardState.onboardingTech to it to have that selection reflect on
    //the integration guide watched in Documentation.vue
    watch(modelTechnology, () => {
      onboardState.manualSelect = true
      new TempStorage().storeOnboardingTech('')
      new TempStorage().storeOnboardingTech(model.value.technology[0])

      onboardState.onboardingTech = new TempStorage().getOnboardingTech()
    })

    //This watches the onboarState.onboardingTech that can be changed by
    //detectAndStoreCMSForEngine() when it detects a technology (this happens
    //only if a user does not manually select a technology)
    watch(
      () => onboardState.onboardingTech,
      () => {
        model.value.technology[0] = onboardState.onboardingTech
        // Increment the trigger to force a re-render for dropdown preselect if tech detected
        technologyChangeTrigger.value += 1
      }
    )

    return {
      userScopeClass,
      show,
      next,
      skip,
      model,
      isWebsitePresent,
      roles,
      technologies,
      UserRole,
      subscription,
      technologyChangeTrigger,
    }
  },
})
