




















import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'IconDiscord',
  props: {
    width: {
      type: [Number, String],
      default: 20,
    },
    height: {
      type: [Number, String],
      default: 26,
    },
    viewBox: {
      type: String,
      default: '0 0 20 26',
    },
    fill: {
      type: String,
    },
  },
})
