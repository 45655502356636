import { SiteTestApi } from '@/services/demo-tool-v4-openapi'
import { UsersApi } from '@/services/ie-microservice-openapi'
import { UserType, useTeamStore } from '@/store/team'
import { getGeoLocation, isProd, TempStorage } from '.'
import { logError } from './sentry'

export const runDemo = async (url: string, subId: number, email: string) => {
  const teamStore = useTeamStore()
  let notifierType = ''
  notifierType = teamStore.userType !== UserType.PARTNER ? 'signup' : teamStore.userType
  const country = await getGeoLocation()
  const tempStorage = new TempStorage()
  let siteTestId = ''
  try {
    const callback = isProd()
      ? 'https://external-sync.scientiamobile.com/api/v1/event/imageengine/demo-results'
      : ''
    const result = await new SiteTestApi().postV2Analyze({
      desktop: true,
      mobile: true,
      url,
      country: country.code,
      referrer: `https://control.imageengine.io/#notify:${notifierType}`,
      callback_url: callback,
      params: {
        email,
        subscription_id: subId.toString(),
      },
    })
    siteTestId = result.data.site_test_id
    tempStorage.storeDemoResults(result.data.site_test_id)
  } catch (error) {
    logError('Error with demo v2', { extras: { error } })
  }
  return siteTestId
}

interface IWelcomeEmail {
  delivery_address: string
  demo_id: string
  subscription_id: number
  user_id: number
  website: string
  current_cms: string
}

export const sendWelcomeEmail = async (payload: IWelcomeEmail) => {
  const country = await getGeoLocation()

  try {
    await new UsersApi().sendWelcomeEmail(payload.user_id, {
      country: country.name ?? '',
      ...payload,
    })
  } catch (error) {
    logError('Could not send welcome email', {
      extras: error as any,
      tags: payload as { [key: string]: any },
    })
  }
}

// appends https to urls that dont have any ex.
// http://www.google.com -> http://www.google.com
// ftp://google.com      -> ftp://google.com
// www.google.com        -> http://www.google.com
// google.com            -> http://google.com
export const sanitizeToHttps = (website: string) => {
  return website.indexOf('://') === -1 ? 'https://' + website : website
}
