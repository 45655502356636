












































import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
  toRefs,
  watch,
} from '@vue/composition-api'
import IconMagnifierSmall from '@/components/Icons/IconMagnifierSmall.vue'
import SidebarOrigin from '@/components/SidebarPlugin/SidebarOrigin.vue'
import { DetailedSubscription, Origin } from '@/util/decorators'
import debounce from 'lodash/debounce'
import { Origins } from '@/util/collections'
import { useSidebarStore } from '@/store/sidebar'
import { UserScope } from '@/util/enums'

export default defineComponent({
  components: {
    IconMagnifierSmall,
    SidebarOrigin,
  },
  props: {
    show: {
      type: Number,
      default: 3,
      description: 'Amount of items to show',
    },
    subscription: {
      type: Object as PropType<DetailedSubscription>,
      required: true,
    },
    origins: {
      type: Array as PropType<Array<Origin>>,
      required: true,
    },
  },
  setup(props) {
    const sidebarStore = useSidebarStore()
    const searchTerm = ref<string>('')
    const searchResults = ref<Array<Origin> | null>(null)
    const expanded = ref<boolean>(false)
    const { origins } = toRefs(props)

    const onSearch = debounce(() => {
      if (!searchTerm.value.length) {
        expand(false)
        searchResults.value = props.origins
        return
      }

      searchResults.value = new Origins(props.origins).search(searchTerm.value).all()
    }, 50)

    const show = computed(() => {
      const favorites = new Origins(searchResults.value || origins.value)
        .filterByFavoriteStateEnabled()
        .count()

      return favorites === 0 ? props.show : favorites < props.show ? props.show : favorites
    })

    const expand = (expand: boolean) => (expanded.value = expand)
    const expandable = computed(() => !searchTerm.value && show.value < props.origins.length)

    const visible = computed(() => {
      const sorted = new Origins(
        searchResults.value || origins.value
      ).sortByFavoriteStateAndName()

      return expanded.value ? sorted.all() : sorted.take(show.value)
    })

    const handleOriginChange = () => {
      if (!sidebarStore.requested.origin) {
        return
      }

      if (!new Origins(origins.value).find(sidebarStore.requested.origin)) {
        return
      }

      if (new Origins(visible.value).find(sidebarStore.requested.origin)) {
        return
      }

      expanded.value = true
    }

    onMounted(handleOriginChange)
    watch(() => sidebarStore.requested.origin, handleOriginChange)

    return {
      sidebarStore,
      searchTerm,
      onSearch,
      visible,
      expanded,
      expandable,
      expand,
      UserScope,
    }
  },
})
