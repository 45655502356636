import { defineStore } from 'pinia'
import { SidebarWizardStep, SubscriptionType, UserScope } from '@/util/enums'
import { SubscriptionsApiWrapper } from '@/services/ie-microservice-future-api'
import { DetailedSubscription, Engine, User } from '@/util/decorators'
import { DetailedSubscriptions, Engines, Users } from '@/util/collections'
import { useSidebarStore } from '@/store/sidebar'

interface IWizardStore {
  wizard: {
    step: SidebarWizardStep
    isLoading: boolean
    accounts: Array<User>
    subscriptions: Array<DetailedSubscription>
    trials: Array<DetailedSubscription>
    engines: Array<Engine>
    selected: {
      subscriptionType: SubscriptionType | null
      account: User | null
      subscription: DetailedSubscription | null
      engine: Engine | null
    }
    form: {
      trial: {
        name: string
      }
      subscription: {
        name: string
      }
      account: {
        invite: {
          email: string
        }
        create: {
          firstName: string
          lastName: string
          company: string
          email: string
        }
      }
    }
    tap: {
      engine: boolean
    }
  }
}

export const useWizardStore = defineStore({
  id: 'wizard',
  state: (): IWizardStore => ({
    wizard: {
      step: SidebarWizardStep.Idle,
      isLoading: false,
      accounts: [],
      subscriptions: [],
      trials: [],
      engines: [],
      selected: {
        subscriptionType: null,
        account: null,
        subscription: null,
        engine: null,
      },
      form: {
        trial: {
          name: '',
        },
        subscription: {
          name: '',
        },
        account: {
          invite: {
            email: '',
          },
          create: {
            firstName: '',
            lastName: '',
            company: '',
            email: '',
          },
        },
      },
      tap: {
        engine: false,
      },
    },
  }),
  actions: {
    async showWizard() {
      const sidebarStore = useSidebarStore()

      this.wizard.accounts = []
      this.wizard.subscriptions = []
      this.wizard.trials = []
      this.wizard.selected.account = null
      this.wizard.selected.subscriptionType = null
      this.wizard.selected.subscription = null
      this.wizard.selected.engine = null
      this.wizard.tap.engine = false
      this.wizard.form.trial.name = ''
      this.wizard.form.account.invite.email = ''
      this.wizard.form.account.create.firstName = ''
      this.wizard.form.account.create.lastName = ''
      this.wizard.form.account.create.company = ''
      this.wizard.form.account.create.email = ''

      this.wizard.step = SidebarWizardStep.ResourceSelection

      if (sidebarStore.current.userScope === UserScope.Partner) {
        this.wizard.accounts = new Users(sidebarStore.accounts.slice()).sortByCompanyName().all()
      } else {
        if (sidebarStore.current.account) {
          await this.selectWizardAccount(sidebarStore.current.account)
        }
      }
    },
    async hideWizard() {
      this.wizard.step = SidebarWizardStep.Idle
    },
    async selectWizardAccount(account: User) {
      this.wizard.selected.subscription = null
      this.wizard.subscriptions = []
      this.wizard.trials = []
      this.wizard.selected.account = account

      if (this.wizard.step === SidebarWizardStep.Trial) {
        return
      }

      this.wizard.isLoading = true

      const subscriptions = await new SubscriptionsApiWrapper().loadSubscriptionsForAccount(
        this.wizard.selected.account
      )

      this.wizard.subscriptions = new DetailedSubscriptions(subscriptions.slice())
        .filterByType(SubscriptionType.Subscription)
        .sortByAccountName()
        .all()
      this.wizard.trials = new DetailedSubscriptions(subscriptions.slice())
        .filterByType(SubscriptionType.Trial)
        .sortByAccountName()
        .all()

      this.wizard.isLoading = false
    },
    selectWizardSubscriptionType(type: SubscriptionType | null) {
      this.wizard.engines = []
      this.wizard.selected.engine = null
      this.wizard.selected.subscriptionType = type
    },
    selectWizardSubscription(subscription: DetailedSubscription | null) {
      this.wizard.engines = []
      this.wizard.selected.engine = null
      this.wizard.selected.subscription = subscription

      if (this.wizard.selected.subscription) {
        this.wizard.engines = new Engines(this.wizard.selected.subscription.engines?.slice() || [])
          .sortByCnameOrNickname()
          .all()
      }
    },
    selectWizardEngine(engine: Engine | null) {
      this.wizard.selected.engine = engine
      this.wizard.tap.engine = true
    },
  },
  getters: {
    isWizardNextStepAvailable(): boolean {
      const sidebarStore = useSidebarStore()

      const stepsRequiringAccount = [
        SidebarWizardStep.Engine,
        SidebarWizardStep.Origin,
        SidebarWizardStep.Trial,
      ]
      const stepsRequiringSubscription = [SidebarWizardStep.Engine, SidebarWizardStep.Origin]

      if (
        sidebarStore.current.userScope === UserScope.Partner &&
        stepsRequiringAccount.includes(this.wizard.step) &&
        !this.wizard.selected.account
      ) {
        return false
      }

      if (
        stepsRequiringSubscription.includes(this.wizard.step) &&
        !this.wizard.selected.subscription
      ) {
        return false
      }

      if (this.wizard.step === SidebarWizardStep.Trial && !this.wizard.form.trial.name) {
        return false
      }

      if (this.wizard.step === SidebarWizardStep.AccountCreate) {
        if (!this.wizard.form.account.create.firstName) {
          return false
        }
        if (!this.wizard.form.account.create.lastName) {
          return false
        }
        if (!this.wizard.form.account.create.company) {
          return false
        }
        if (!this.wizard.form.account.create.email) {
          return false
        }
        if (
          !this.wizard.form.account.create.email.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
        ) {
          return false
        }

        return true
      }

      return true
    },
    isWizardNextStepPresent(): boolean {
      const stepsOmittingNext = [
        SidebarWizardStep.Idle,
        SidebarWizardStep.ResourceSelection,
        SidebarWizardStep.AccountInvite,
        SidebarWizardStep.AccountCreationComplete,
      ]

      return !stepsOmittingNext.includes(this.wizard.step)
    },
  },
})
