
































































import { computed, defineComponent } from '@vue/composition-api'
import { useSidebarStore } from '@/store/sidebar'
import IconDocSheet from '@/components/Icons/IconDocSheet.vue'
import IconEnvelope from '@/components/Icons/IconEnvelope.vue'
import IconLifebuoy from '@/components/Icons/IconLifebuoy.vue'
import IconMarkerQuestion from '@/components/Icons/IconMarkerQuestion.vue'
import SidebarButton from '@/components/SidebarPlugin/SidebarButton.vue'
import { UserScope } from '@/util/enums'

export default defineComponent({
  components: {
    IconDocSheet,
    IconEnvelope,
    IconLifebuoy,
    IconMarkerQuestion,
    SidebarButton,
  },
  setup() {
    const sidebarStore = useSidebarStore()
    const buttonType = computed(() =>
      sidebarStore.current.userScope === UserScope.Partner ? 'partnerBlue' : 'customerPurple'
    )

    return {
      buttonType,
      developerDocsUrl: 'https://support.imageengine.io/hc/en-us',
      faqUrl: 'https://support.imageengine.io/hc/en-us/sections/360012969751-FAQ',
      links: [
        {
          title: 'Quick start to ImageEngine',
          url: 'https://support.imageengine.io/hc/en-us/sections/360012891812-Getting-Started',
        },
        {
          title: 'Implement ImageEngine in your stack',
          url: 'https://support.imageengine.io/hc/en-us/sections/360012891852-Integration-Guides',
        },
      ],
    }
  },
})
