import { TempStorage } from '..'
import { logError } from '../sentry'
import PQueue from 'p-queue'
const queue = new PQueue({ concurrency: 2 })

interface ICache {
  expires: number
  data: unknown
}

type ttl = `${number}s` | `${number}m` | `${number}h`
interface IOptions {
  revalidate?: boolean
}

export async function cacheData<T>(
  ttl: ttl, // in seconds
  key: string | Array<string | number>,
  promise: () => Promise<T>,
  opts: IOptions = {}
): Promise<T> {
  const tempStorage = new TempStorage()
  const { revalidate } = opts
  // There's a 25% chance that a call to cache() will trigger a cache cleanup
  if (Math.random() <= 0.25) pruneCache()
  let cacheKey = ''
  if (Array.isArray(key)) {
    cacheKey = key.join('|')
  } else {
    cacheKey = key
  }
  if (cacheKey.trim() === '') throw `Not valid key empty string`

  const cachedData = getCachedData(cacheKey)
  if (cachedData !== null && !revalidate) {
    return cachedData as T
  }

  const data = await queue.add(promise)
  const cache: ICache = {
    expires: Date.now() + getTimeInMs(ttl),
    data,
  }
  try {
    tempStorage.storeCacheKey(cacheKey)
    localStorage.setItem(cacheKey, JSON.stringify(cache))
  } catch (err: any) {
    err.message.includes('exceeded the quota') ? console.log('quota exceeded') : console.log(err)
  }
  return data
}

function getCachedData(key: string): unknown | null {
  const data = localStorage.getItem(key)
  if (data !== null) {
    const cacheData = JSON.parse(data) as ICache
    if (cacheData.expires > Date.now()) {
      return cacheData.data
    }
    pruneCache()
  }
  return null
}

// will remove all cached data that is expired
function pruneCache() {
  const tempStorage = new TempStorage()
  const expiredKeys: Array<string> = []
  const now = Date.now()

  tempStorage.getCacheKeys().forEach((k) => {
    const data = localStorage.getItem(k)
    if (!data) {
      expiredKeys.push(k)
      return
    }

    const cacheData = JSON.parse(data) as ICache
    if (cacheData.expires < now) {
      expiredKeys.push(k)
      localStorage.removeItem(k)
    }
  })

  const cacheKeys = tempStorage.getCacheKeys()
  for (let index = 0; index < cacheKeys.length; index++) {
    const k = cacheKeys[index]
    if (expiredKeys.includes(k)) {
      cacheKeys.splice(index, 1) // remove that element in array
    }
  }
  tempStorage.overrideCacheKeys(cacheKeys)
}

function getTimeInMs(t: ttl): number {
  const c = t.split('').pop() || 's'
  const num = parseInt(t.slice(0, -1), 10)

  switch (c) {
    case 's':
      return num * 1000
    case 'm':
      return num * 60 * 1000
    case 'h':
      return num * 60 * 60 * 1000
    default:
      logError(`getTimeInMs: cannot translate ttl of ${t}`, { extras: { t } })
  }
  return 0
}

// unused but keeping here until we fix the statistic endpoint issue
const retry = <T>(fn: () => Promise<T>, maxRetries = 5): Promise<T> =>
  new Promise((resolve, reject) => {
    const ms = Math.floor(Math.random() * 500)
    let retries = 0
    fn()
      .then(resolve)
      .catch(() => {
        setTimeout(() => {
          ++retries
          if (retries == maxRetries) {
            return reject('maximum retries exceeded')
          }
          retry(fn, retries).then(resolve)
        }, ms)
      })
  })
