import { defineStore } from 'pinia'
import { logError } from '@/util/sentry'
import { Engine } from '@/util/decorators'
import { EnginesApi } from '@/services/ie-microservice-future-api'
import { useSidebarStore } from '@/store/sidebar'
import { CmsType, FrontendType } from '@/util/enums'

interface IEngineVerificationStore {
  displayed: Engine | null
  stack: {
    pending: Array<{ engine: Engine }>
    completed: Array<{
      engine: Engine
      succeeded: boolean
      bytes?: {
        ie: number
        origin: number
        saved: number
      }
      imageUrl?: string
    }>
  }
  selectedTechnology: CmsType | FrontendType | string
  verifyURL: string
}

export const useEngineVerificationStore = defineStore({
  id: 'engineVerification',
  state: (): IEngineVerificationStore => ({
    displayed: null,
    stack: {
      pending: [],
      completed: [],
    },
    selectedTechnology: '',
    verifyURL: '',
  }),
  actions: {
    async initiateVerification(engine: Engine, site: string) {
      this.verifyURL = site
      if (
        this.stack.pending.find((i) => i.engine.id === engine.id) ||
        this.stack.completed.find((i) => i.engine.id === engine.id)
      ) {
        return
      }

      this.displayed = engine
      this.stack.completed = this.stack.completed.filter((i) => i.engine.id !== engine.id)
      this.stack.pending.push({ engine })

      const response = await fetch(
        `https://ty9czrxu47.execute-api.us-west-2.amazonaws.com/prod?site=${site}&ie=https://${engine.get_cname}`
      )

      if (response.ok) {
        const data = await response.json()

        if (
          data.errorMessage ||
          data.status !== 'success' ||
          !data.ie_requests ||
          !data.ie_requests.length
        ) {
          logError('failed to validate engine', { extras: { data } })
          window.dataLayer.push({ event: 'onboarding_verification_fail' })
          this.stack.pending = this.stack.pending.filter((i) => i.engine.id !== engine.id)
          this.stack.completed.push({
            engine,
            succeeded: false,
          })
          return
        }

        const image = data.ie_requests.sort(
          (a: any, b: any) =>
            parseInt(b.wit_headers['x-wit-bytes-origin']) -
            parseInt(a.wit_headers['x-wit-bytes-origin'])
        )[0]

        this.stack.pending = this.stack.pending.filter((i) => i.engine.id !== engine.id)
        this.stack.completed.push({
          engine,
          succeeded: true,
          bytes: {
            ie: parseInt(image.wit_headers['x-wit-bytes']),
            origin: parseInt(image.wit_headers['x-wit-bytes-origin']),
            saved:
              parseInt(image.wit_headers['x-wit-bytes-origin']) -
              parseInt(image.wit_headers['x-wit-bytes']),
          },
          imageUrl: image.full_url,
        })

        engine.is_verified = true
        await new EnginesApi().updateEngine(engine.id, { is_verified: true })
        useSidebarStore().updateEngine(engine)
        window.dataLayer.push({ event: 'onboarding_verification_OK' })
      } else {
        logError('failed to validate engine', { extras: { response } })

        this.stack.pending = this.stack.pending.filter((i) => i.engine.id !== engine.id)
        this.stack.completed.push({
          engine,
          succeeded: false,
        })
      }
    },
    minimize() {
      this.displayed = null
    },
    dismiss(engineId: number) {
      this.displayed = null
      this.stack.completed = this.stack.completed.filter((i) => i.engine.id !== engineId)

      if (this.stack.completed.length) {
        this.displayed = this.stack.completed[0].engine
      }
    },
  },
})
