















































import { computed, defineComponent } from '@vue/composition-api'
import { useSidebarStore } from '@/store/sidebar'
import IconDocStack from '@/components/Icons/IconDocStack.vue'
import IconHeadset from '@/components/Icons/IconHeadset.vue'
import IconLoudspeaker from '@/components/Icons/IconLoudspeaker.vue'
import SidebarButton from '@/components/SidebarPlugin/SidebarButton.vue'
import { UserScope } from '@/util/enums'

export default defineComponent({
  components: {
    IconDocStack,
    IconHeadset,
    IconLoudspeaker,
    SidebarButton,
  },
  setup(props, context) {
    const sidebarStore = useSidebarStore()

    const contactSupport = () => {
      context.root.$router.push(`/support`)
    }
    const viewTickets = () => {
      window.open('https://support.imageengine.io/hc/en-us/requests', '_blank')?.focus()
    }

    const buttonActiveType = computed(() =>
      sidebarStore.current.userScope === UserScope.Partner ? 'partnerBlue' : 'customerPurple'
    )
    const buttonInactiveType = computed(() =>
      sidebarStore.current.userScope === UserScope.Partner ? 'whitePartner' : 'whiteCustomer'
    )

    const buttonGradient = computed(() => ({
      start: sidebarStore.current.userScope === UserScope.Partner ? '#5e72e4' : '#4636b3',
      stop: sidebarStore.current.userScope === UserScope.Partner ? '#0098d5' : '#8965e0',
    }))

    return {
      contactSupport,
      viewTickets,
      buttonActiveType,
      buttonInactiveType,
      buttonGradient,
    }
  },
})
