import { LocationApi, Country } from '@/services/ie-microservice-openapi'
import { TempStorage } from './tempStorage'

export const defaultGeoIP: Country = {
  country_id: 230,
  name: 'United States of America',
  code: 'US',
}

export const getGeoLocation = async (): Promise<Country> => {
  if (process.env.NODE_ENV === 'production') {
    const tempStorage = new TempStorage()
    const storedCountry = tempStorage.getGeoIP()
    if (storedCountry) {
      return Promise.resolve(storedCountry)
    }
    const country = await new LocationApi().getLocationFromRequest().then((res: any) => {
      return res.data.country as any
    })
    country && tempStorage.storeGeoIP(country)
    return country
  }
  return Promise.resolve(defaultGeoIP)
}
