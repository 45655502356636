import { Engine, Subscription, User } from '@/services/ie-microservice-openapi'
import { useTeamStore } from '@/store/team'
import { getGeoLocation, TempStorage } from '.'
import { logInfo, logError } from './sentry'

// see alerts here https://sentry.scientiamobile.com/organizations/scientiamobile/alerts/rules/
export async function trackSignUp(
  user: User,
  sub: Subscription = {} as Subscription,
  signUpType: 'Self Service' | 'Partner Trial'
) {
  const team = useTeamStore().$state.team
  const country = await getGeoLocation()

  const tags: { [key: string]: string | number } = {
    type: 'signUp',
    userID: user.id ?? 0,
    email: user.email ?? '',
    teamID: user.team_id ?? 0,
    teamName: team?.name ?? '',
    subscriptionID: sub.id ?? 0,
    country: country.name ?? '',
    signUpType,
  }

  const extra: { [key: string]: unknown } = {
    subscription: { ...sub },
    team: { ...team },
    user: { ...user },
  }

  logInfo(`New account created for user ${user.email}`, {
    tags: tags,
    extras: extra,
  })
}

export async function trackEngineCreation(user: User, engine: Engine) {
  const team = useTeamStore().$state.team
  const country = await getGeoLocation()
  const tempStorage = new TempStorage()
  const demoRes = tempStorage.getDemoResults()

  const tags: { [key: string]: string | number } = {
    type: 'engineCreate',
    userID: user.id ?? 0,
    email: user.email ?? '',
    teamID: user.team_id ?? 0,
    teamName: team?.name ?? '',
    origin: sanitizeURLCredentials(engine.origin?.url ?? ''),
    engine: engine.cname ?? '',
    country: country.name ?? '',
    ...(demoRes && {
      demoResult: `https://imageengine.io/developers/image-speed-test/results/${demoRes}`,
    }), // only if truthy we send
  }

  const extra: { [key: string]: unknown } = {
    team: { ...team },
    engine: { ...engine },
    user: { ...user },
  }

  logInfo(`User ${user.email} created an engine`, {
    tags: tags,
    extras: extra,
  })
}

const sanitizeURLCredentials = (origin: string): string => {
  try {
    const url = new URL(origin)
    url.password = ''
    url.username = ''
    return url.href
  } catch (error) {
    logError('failed to sanitize URL', {
      extras: {
        origin,
      },
    })
    return ''
  }
}

export async function alertPartnerLogin(email: string) {
  const country = await getGeoLocation()

  const tags: { [key: string]: string | number } = {
    type: 'partnerProgram',
    email: email,
    country: country.name ?? '',
  }

  logInfo(`${email} tried to self sign-up while being registered as a prospective customer`, {
    tags: tags,
  })
}
