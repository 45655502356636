
















































































import {
  computed,
  defineComponent,
  onBeforeMount,
  onBeforeUnmount,
  watch,
} from '@vue/composition-api'

import NavbarToggleButton from '@/components/NavbarToggleButton.vue'
import SidebarAccount from '@/components/SidebarPlugin/SidebarAccount.vue'
import SidebarButtons from '@/components/SidebarPlugin/SidebarButtons.vue'
import SidebarSelectAccount from '@/components/SidebarPlugin/SidebarSelectAccount.vue'

import CloseButton from '@/components/CloseButton.vue'
import { useAuthStore } from '@/store/auth'
import { useTeamStore } from '@/store/team'
import { useSidebarStore } from '@/store/sidebar'
import { UserScope } from '@/util/enums'
import { decorateUser } from '@/util/decorators'

export default defineComponent({
  components: {
    NavbarToggleButton,
    CloseButton,
    SidebarAccount,
    SidebarButtons,
    SidebarSelectAccount,
  },
  props: {
    logo: {
      type: String,
      default: '/img/brand/Logo-Main.svg',
      description: 'Sidebar app logo',
    },
    autoClose: {
      type: Boolean,
      default: true,
      description: 'Whether sidebar should autoclose on mobile when clicking an item',
    },
  },

  setup(props, context) {
    const authStore = useAuthStore()
    const teamStore = useTeamStore()
    const sidebarStore = useSidebarStore()

    onBeforeMount(async () => {
      const route = context.root.$route

      await sidebarStore.setupState(
        decorateUser(authStore.user),
        route.meta?.partnerScope ? UserScope.Partner : UserScope.Customer,
        route.params.id ? parseInt(route.params.id) : null,
        route.params.subscriptionID ? parseInt(route.params.subscriptionID) : null,
        route.params.engineID ? parseInt(route.params.engineID) : null,
        route.params.originID ? parseInt(route.params.originID) : null
      )
    })

    watch(
      () => context.root.$route,
      async (to, from) => {
        if (from.path !== to.path) {
          const userScopeFromRoute = to.meta?.partnerScope
            ? UserScope.Partner
            : UserScope.Customer
          const userScope = to.meta?.commonScope
            ? sidebarStore.current.userScope || userScopeFromRoute
            : userScopeFromRoute

          await sidebarStore.updateState(
            decorateUser(authStore.user),
            userScope,
            to.params.id ? parseInt(to.params.id) : null,
            to.params.subscriptionID ? parseInt(to.params.subscriptionID) : null,
            to.params.engineID ? parseInt(to.params.engineID) : null,
            to.params.originID ? parseInt(to.params.originID) : null
          )
        }
      }
    )

    onBeforeUnmount(() => {
      if (sidebarStore.showSidebar) {
        sidebarStore.showSidebar = false
      }
    })

    const closeSidebar = () => {
      sidebarStore.displaySidebar(false)
    }

    const showSidebar = () => {
      sidebarStore.displaySidebar(true)
    }

    const logout = () => {
      authStore.logout()
      context.root.$router.push('/login')
    }

    const changeUserScope = () => {
      if (sidebarStore.current.userScope === UserScope.Partner) {
        context.root.$router.push({ name: 'subscriptions' })
      } else {
        context.root.$router.push({ name: 'customers-dashboard' })
      }
    }

    const isUserScopeSwitchAvailable = computed(() => {
      return teamStore.team?.partner
    })

    return {
      sidebarStore,
      isUserScopeSwitchAvailable,
      UserScope,
      changeUserScope,
      closeSidebar,
      showSidebar,
      logout,
    }
  },
})
