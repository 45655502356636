/*!

=========================================================
* Vue Argon Dashboard - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'prismjs/themes/prism-tomorrow.css'
import router from './router'
import './registerServiceWorker'
import DashboardPlugin from './plugins/dashboard-plugin'
import registerInterceptors from './registerAxiosInterceptors'
import * as Sentry from '@sentry/browser'
import { Vue as VueIntegration } from '@sentry/integrations'

import { createPinia, PiniaVuePlugin } from 'pinia'
import VueCompositionAPI from '@vue/composition-api'

import { ValidationObserver, ValidationProvider, extend, localize } from 'vee-validate'

import en from 'vee-validate/dist/locale/en.json'
import * as rules from 'vee-validate/dist/rules'
import Router, { routerBeforeEach } from './routerPrototype'
import 'whatwg-fetch'
import VueApexCharts from 'vue-apexcharts'

// extend('credit_card', {
//   validate: (val) => isCreditCard(val),
//   message: 'Please enter a valid credit card',
// })
extend('month_year', {
  validate: (val) => {
    const matches = val.match(/^0?(\d+)\/0?(\d+)$/)
    if (!matches) {
      return false
    }
    const month = matches[1]
    const year = matches[2]
    return month > 0 && month <= 12 && year >= 0 && year < 100
  },
  message: 'Please enter a valid date',
})
// Install VeeValidate rules and localization
Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule])
})

localize('en', en)

// Install VeeValidate components globally
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('apexchart', VueApexCharts)

registerInterceptors()

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new VueIntegration({
        Vue,
        tracing: true,
      }),
    ],
    environment: process.env.VUE_APP_DEPLOY_ENV,
  })
}

Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(DashboardPlugin)
/* using Pinia state management */
Vue.use(VueCompositionAPI)
Vue.use(PiniaVuePlugin)
const pinia = createPinia()
Vue.use(Router)
Vue.use(VueApexCharts)

router.beforeEach((to, from, next) => routerBeforeEach(to, from, next, pinia))

new Vue({
  pinia,
  router,
  render: (h) => h(App),
}).$mount('#app')
