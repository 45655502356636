



























import { defineComponent, ref } from '@vue/composition-api'
export default defineComponent({
  name: 'BaseDropdown',
  props: {
    tag: {
      type: String,
      default: 'div',
      description: 'Dropdown html tag (e.g div, ul etc)',
    },
    titleTag: {
      type: String,
      default: 'button',
      description: 'Dropdown title (toggle) html tag',
    },
    title: {
      type: String,
      description: 'Dropdown title',
    },
    direction: {
      type: String,
      default: 'down', // up | down
      description: 'Dropdown menu direction (up|down)',
    },
    icon: {
      type: String,
      description: 'Dropdown icon',
    },
    titleClasses: {
      type: [String, Object, Array],
      description: 'Title css classes',
    },
    menuClasses: {
      type: [String, Object],
      description: 'Menu css classes',
    },
    menuOnRight: {
      type: Boolean,
      description: 'Whether menu should appear on the right',
    },
    hasToggle: {
      type: Boolean,
      description: 'Whether dropdown has arrow icon shown',
      default: true,
    },
  },
  setup(_, context) {
    const isOpen = ref(false)

    const toggleDropDown = () => {
      isOpen.value = !isOpen.value
      context.root.$emit('change', isOpen.value)
    }

    const closeDropDown = () => {
      isOpen.value = false
      context.root.$emit('change', false)
    }

    return {
      toggleDropDown,
      closeDropDown,
      isOpen,
    }
  },
})
