import { PaymentPlanEnum } from '@/services/ie-microservice-openapi/api'

export enum SubscriptionType {
  Trial = 'TRIAL',
  Subscription = 'SUBSCRIPTION',
}

export enum SubscriptionStatus {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Suspended = 'SUSPENDED',
  PendingPaymentInformation = 'PENDING_PAYMENT_INFORMATION',
}

export enum UserScope {
  Partner = 'PARTNER',
  Customer = 'CUSTOMER',
}

export enum SidebarWizardStep {
  Idle = 'IDLE',
  ResourceSelection = 'RESOURCE_SELECTION',
  Engine = 'ENGINE',
  Origin = 'ORIGIN',
  Subscription = 'SUBSCRIPTION',
  Trial = 'TRIAL',
  AccountSelection = 'ACCOUNT_SELECTION',
  AccountInvite = 'ACCOUNT_INVITE',
  AccountCreate = 'ACCOUNT_CREATE',
  AccountCreationComplete = 'ACCOUNT_CREATION_COMPLETE',
  TeamMember = 'TEAM_MEMBER',
}

export enum SignupType {
  Google = 'GOOGLE',
  GitHub = 'GITHUB',
  Password = 'PASSWORD',
}

export enum UserRole {
  Engineering = 'Engineering/DevOps',
  Sales = 'Sales/Marketing',
  Executive = 'Executive/Manager',
  Other = 'other',
}

export enum CmsType {
  Sitefinity = 'sitefinity',
  Sitecore = 'sitecore',
  Wordpress = 'wordpress',
  Magento = 'magento',
  Sanityio = 'sanityio',
  Contentful = 'contentful',
  Shopify = 'shopify',
  Bigcommerce = 'bigcommerce',
  Prestashop = 'prestashop',
  Gatsby = 'gatsby',
  Hugo = 'hugo',
  Cloudflare = 'cloudflare',
  Shopware = 'shopware',
}

export const CmsTypes = [
  {
    type: CmsType.Sitefinity,
    name: 'Sitefinity',
    integrationGuideUrl: 'https://support.imageengine.io/hc/en-us/articles/360059187192-Sitefinity',
  },
  {
    type: CmsType.Sitecore,
    name: 'Sitecore',
    integrationGuideUrl: 'https://support.imageengine.io/hc/en-us/articles/360059612591-Sitecore',
  },
  {
    type: CmsType.Wordpress,
    name: 'WordPress',
    integrationGuideUrl: 'https://support.imageengine.io/hc/en-us/articles/360059593591-WordPress',
  },
  {
    type: CmsType.Magento,
    name: 'Magento 2.x',
    integrationGuideUrl:
      'https://support.imageengine.io/hc/en-us/articles/360059128332-Magento-2-x',
  },
  {
    type: CmsType.Sanityio,
    name: 'Sanity.io',
    integrationGuideUrl:
      'https://support.imageengine.io/hc/en-us/articles/4415463696525-How-to-use-ImageEngine-and-Sanity-io-with-GatsbyJS',
  },
  {
    type: CmsType.Contentful,
    name: 'Contentful',
    integrationGuideUrl:
      'https://support.imageengine.io/hc/en-us/articles/4415478443405-How-to-use-ImageEngine-and-Contentful-with-GatsbyJS',
  },
  {
    type: CmsType.Shopify,
    name: 'Shopify',
    integrationGuideUrl: 'https://support.imageengine.io/hc/en-us/articles/360059010192-Shopify',
  },
  {
    type: CmsType.Bigcommerce,
    name: 'BigCommerce',
    integrationGuideUrl:
      'https://support.imageengine.io/hc/en-us/articles/360059650671-BigCommerce',
  },
  {
    type: CmsType.Prestashop,
    name: 'PrestaShop',
    integrationGuideUrl:
      'https://support.imageengine.io/hc/en-us/articles/360059820731-PrestaShop-CDN',
  },
  {
    type: CmsType.Gatsby,
    name: 'GatsbyJS',
    integrationGuideUrl:
      'https://support.imageengine.io/hc/en-us/articles/4415470516877-Getting-Started-With-ImageEngine-and-GatsbyJS',
  },
  {
    type: CmsType.Hugo,
    name: 'Hugo',
    integrationGuideUrl: 'https://gohugo.io/',
  },
  {
    type: CmsType.Cloudflare,
    name: 'Cloudflare',
    integrationGuideUrl:
      'https://support.imageengine.io/hc/en-us/articles/360059534012-How-to-use-Cloudflare-workers-to-rewrite-image-URLs',
  },
  {
    type: CmsType.Shopware,
    name: 'Shopware',
    integrationGuideUrl:
      'https://support.imageengine.io/hc/en-us/articles/7918912397325-Shopware-Plugin',
  },
]

export enum FrontendType {
  React = 'react',
  Angular = 'angular',
  Vue = 'vue',
  Nuxt = 'nuxt',
}

export const FrontedTypes = [
  {
    type: FrontendType.React,
    name: 'React',
    integrationGuideUrl:
      'https://support.imageengine.io/hc/en-us/articles/4404180769805-React-Integration-Guide',
  },
  {
    type: FrontendType.Angular,
    name: 'Angular',
    integrationGuideUrl:
      'https://support.imageengine.io/hc/en-us/articles/4408922345101-AngularJS-integration-guide',
  },
  {
    type: FrontendType.Vue,
    name: 'Vue.js',
    integrationGuideUrl:
      'https://support.imageengine.io/hc/en-us/articles/4404168229005-Vue-js-Integration-Guide',
  },
  {
    type: FrontendType.Nuxt,
    name: 'Nuxt',
    integrationGuideUrl:
      'https://support.imageengine.io/hc/en-us/articles/4443720110093-Nuxt-image-Integration-Guide',
  },
]

export interface SubscriptionTier {
  tier: PaymentPlanEnum
  name: string
  description: string
  price?: number
  smartBytes: number | string
  engines?: string
  teammembers?: string
}

export const NewSubscriptionTiers: Array<SubscriptionTier> = [
  {
    tier: PaymentPlanEnum.Starter,
    name: 'Starter',
    description: 'For smaller websites, blog',
    price: 39,
    smartBytes: 70,
    engines: '2',
    teammembers: '2',
  },
  {
    tier: PaymentPlanEnum.Growth,
    name: 'Growth',
    description: 'For e-commerce and medium traffic sites',
    price: 225,
    smartBytes: 400,
    engines: '5',
    teammembers: '5',
  },
  {
    tier: PaymentPlanEnum.BeastMode,
    name: 'Beast',
    description: 'For high traffic sites, eCommerce, and technology partners',
    price: 449,
    smartBytes: 800,
    engines: '10',
    teammembers: 'unlimited',
  },
  {
    tier: PaymentPlanEnum.Pro,
    name: 'Pro',
    description: 'For high traffic sites, eCommerce, and technology partners',
    smartBytes: 'Custom SmartByte amount per month',
    engines: 'unlimited',
    teammembers: 'unlimited',
  },
]

export const WithLegacyTiers: Array<SubscriptionTier> = [
  {
    tier: PaymentPlanEnum.Basic,
    name: 'Basic',
    description: 'For smaller websites, blog',
    price: 49,
    smartBytes: 100,
  },
  {
    tier: PaymentPlanEnum.Standard,
    name: 'Standard',
    description: 'For e-commerce and medium traffic sites',
    price: 99,
    smartBytes: 250,
  },
  {
    tier: PaymentPlanEnum.Starter,
    name: 'Starter',
    description: 'For smaller websites, blog',
    price: 39,
    smartBytes: 70,
    engines: '2',
    teammembers: '2',
  },
  {
    tier: PaymentPlanEnum.Growth,
    name: 'Growth',
    description: 'For e-commerce and medium traffic sites',
    price: 225,
    smartBytes: 400,
    engines: '5',
    teammembers: '5',
  },
  {
    tier: PaymentPlanEnum.BeastMode,
    name: 'Beast',
    description: 'For high traffic sites, eCommerce, and technology partners',
    price: 449,
    smartBytes: 800,
    engines: '10',
    teammembers: 'unlimited',
  },
  {
    tier: PaymentPlanEnum.Pro,
    name: 'Pro',
    description: 'For high traffic sites, eCommerce, and technology partners',
    smartBytes: 'Custom SmartByte amount per month',
    engines: 'unlimited',
    teammembers: 'unlimited',
  },
]
