






































































































































import { computed, defineComponent, PropType, ref } from '@vue/composition-api'
import IconDots from '@/components/Icons/IconDots.vue'
import IconMagnifierSmall from '@/components/Icons/IconMagnifierSmall.vue'
import { User } from '@/util/decorators'
import { useSidebarStore } from '@/store/sidebar'
import { useWizardStore } from '@/store/wizard'
import debounce from 'lodash/debounce'
import { Users } from '@/util/collections'
import { SidebarWizardStep } from '@/util/enums'

export default defineComponent({
  components: {
    IconDots,
    IconMagnifierSmall,
  },
  props: {
    account: {
      type: Object as PropType<User>,
      required: true,
    },
  },
  setup(props, context) {
    const sidebarStore = useSidebarStore()
    const wizardStore = useWizardStore()
    const searchTerm = ref<string>('')
    const searchResults = ref<Array<User> | null>(null)
    const accounts = computed(() => sidebarStore.getRecentlyViewedAccounts)

    const visible = computed(() => searchResults.value || accounts.value)

    const onSearch = debounce(() => {
      if (!searchTerm.value.length) {
        searchResults.value = null
        return
      }

      searchResults.value = new Users(sidebarStore.accounts).search(searchTerm.value).all()
    }, 50)

    const dropdownToggled = (isOpen: boolean) => {
      if (!isOpen) {
        searchTerm.value = ''
        searchResults.value = null
      }
    }

    const clickAccountName = (account: User) => {
      context.root.$router.push({
        name: 'customers-id-subscriptions',
        params: { id: account.id.toString() },
      })
    }

    const clickViewProfile = (account: User) => {
      context.root.$router.push({
        name: 'customers-id',
        params: { id: account.id.toString() },
      })
    }

    const addTrial = async () => {
      await wizardStore.showWizard()
      wizardStore.wizard.step = SidebarWizardStep.Trial
      setTimeout(() => wizardStore.selectWizardAccount(props.account), 500)
    }

    const addSubscription = async () => {
      await wizardStore.showWizard()
      wizardStore.wizard.step = SidebarWizardStep.Subscription
      setTimeout(() => wizardStore.selectWizardAccount(props.account), 500)
    }

    return {
      sidebarStore,
      searchTerm,
      onSearch,
      visible,
      dropdownToggled,
      clickAccountName,
      clickViewProfile,
      addTrial,
      addSubscription,
    }
  },
})
