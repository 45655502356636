import { defineStore } from 'pinia'
import { SubscriptionType, UserScope } from '@/util/enums'
import {
  EnginesApi,
  OriginsApi,
  SubscriptionsApi,
  SubscriptionsApiWrapper,
  TeamsApiWrapper,
} from '@/services/ie-microservice-future-api'
import { DetailedSubscription, Engine, Origin, User } from '@/util/decorators'
import { DetailedSubscriptions, Users } from '@/util/collections'
import { TempStorage } from '@/util'
import moment from 'moment'
import { logError } from '@/util/sentry'

interface ISidebarStore {
  showSidebar: boolean
  isMinimized: boolean
  isLoading: boolean
  breakpoint: number
  maxVisibleAccounts: number
  maxVisibleSubscriptions: number
  accounts: Array<User>
  subscriptions: Array<DetailedSubscription>
  current: {
    userScope: UserScope | null
    subscriptionType: SubscriptionType | null
    account: User | null
    subscription: DetailedSubscription | null
    trial: DetailedSubscription | null
  }
  requested: {
    engine: number | null
    origin: number | null
  }
}

export const useSidebarStore = defineStore({
  id: 'sidebar',
  state: (): ISidebarStore => ({
    showSidebar: false,
    isMinimized: false,
    isLoading: false,
    breakpoint: 1200,
    maxVisibleAccounts: 5,
    maxVisibleSubscriptions: 5,
    accounts: [],
    subscriptions: [],
    current: {
      userScope: null,
      subscriptionType: null,
      account: null,
      subscription: null,
      trial: null,
    },
    requested: {
      engine: null,
      origin: null,
    },
  }),
  actions: {
    async setupState(
      user: User,
      userScope: UserScope,
      account: number | null,
      subscription: number | null,
      engine: number | null,
      origin: number | null
    ) {
      this.isLoading = true

      await this.setUserScope(user, userScope)

      if (account) {
        await this.setAccount(user, new Users(this.accounts).find(account))
      } else {
        await this.setAccount(user)
      }

      if (subscription) {
        this.setSubscription(new DetailedSubscriptions(this.subscriptions).find(subscription))
      } else {
        this.setSubscription()
      }

      this.requested.engine = engine
      this.requested.origin = origin

      this.isLoading = false
    },
    async updateState(
      user: User,
      userScope: UserScope,
      account: number | null,
      subscription: number | null,
      engine: number | null,
      origin: number | null
    ) {
      this.isLoading = true

      const userScopeChangeRequested = this.current.userScope !== userScope
      const accountChangeRequested = account && this.current.account?.id !== account
      const subscriptionChangeRequested =
        subscription &&
        this.current.trial?.id !== subscription &&
        this.current.subscription?.id !== subscription

      if (userScopeChangeRequested) {
        await this.setUserScope(user, userScope)
      }

      if (accountChangeRequested) {
        await this.setAccount(user, new Users(this.accounts).find(account))
      } else if (userScopeChangeRequested) {
        await this.setAccount(user)
      }

      if (subscriptionChangeRequested) {
        this.setSubscription(new DetailedSubscriptions(this.subscriptions).find(subscription))
      } else if (userScopeChangeRequested || accountChangeRequested) {
        this.setSubscription()
      }

      this.requested.engine = engine
      this.requested.origin = origin

      this.isLoading = false
    },
    async loadAccounts(user: User) {
      if (this.accounts.length) {
        return
      }

      this.accounts = await new TeamsApiWrapper().loadAccountsForUser(user)
    },
    async loadSubscriptions(account: User) {
      this.subscriptions = []
      this.subscriptions = await new SubscriptionsApiWrapper().loadSubscriptionsForAccount(account)
    },
    async setUserScope(authUser: User, scope: UserScope) {
      this.subscriptions = []
      this.current.account = null
      this.current.subscriptionType = null
      this.current.subscription = null
      this.current.trial = null
      this.current.userScope = scope

      if (this.current.userScope === UserScope.Partner) {
        await this.loadAccounts(authUser)
      }
    },
    async setAccount(authUser: User, account?: User | null) {
      this.subscriptions = []
      this.current.subscriptionType = null
      this.current.subscription = null
      this.current.trial = null

      if (this.current.userScope === UserScope.Partner) {
        this.current.account = account
          ? account
          : new Users(this.accounts).findMostRecent() || authUser
      } else {
        this.current.account = authUser
      }

      this.updateAccountAccessDate(this.current.account.id)

      await this.loadSubscriptions(this.current.account)
    },
    setSubscription(subscription?: DetailedSubscription | null) {
      if (subscription) {
        this.updateSubscriptionAccessDate(subscription.id)

        if (subscription.is_trial) {
          this.current.trial = subscription
          this.current.subscription = new DetailedSubscriptions(this.subscriptions).findMostRecent(
            SubscriptionType.Subscription
          )
          this.current.subscriptionType = SubscriptionType.Trial
        } else {
          this.current.trial = new DetailedSubscriptions(this.subscriptions).findMostRecent(
            SubscriptionType.Trial
          )
          this.current.subscription = subscription
          this.current.subscriptionType = SubscriptionType.Subscription
        }
      } else {
        this.current.trial = new DetailedSubscriptions(this.subscriptions).findMostRecent(
          SubscriptionType.Trial
        )
        this.current.subscription = new DetailedSubscriptions(this.subscriptions).findMostRecent(
          SubscriptionType.Subscription
        )

        if (this.current.trial) {
          this.updateSubscriptionAccessDate(this.current.trial.id)
          this.current.subscriptionType = SubscriptionType.Trial
        } else if (this.current.subscription) {
          this.updateSubscriptionAccessDate(this.current.subscription.id)
          this.current.subscriptionType = SubscriptionType.Subscription
        } else {
          this.current.subscriptionType = null
        }
      }
    },
    setSubscriptionType(type: SubscriptionType): void {
      this.current.subscriptionType = type
    },
    async updateEngineFavoriteState(engine: Engine) {
      engine.is_favorite = !engine.is_favorite

      await new EnginesApi().updateEngine(engine.id, engine)
    },
    async updateOriginFavoriteState(origin: Origin) {
      await new OriginsApi().updateOriginFavoriteState(origin.id, !origin.is_favorite)

      origin.is_favorite = !origin.is_favorite
    },
    updateSubscriptionAccessDate(id: number) {
      const now = moment().format('YYYY-MM-DD HH:mm:ss')
      const subscription = this.subscriptions.filter((s) => s.id === id)[0]

      if (!subscription) {
        return
      }

      new TempStorage().storeSubscriptionDateAccessed(id, now)

      subscription.date_accessed = now
    },
    updateDefaultSubscription(subscription: DetailedSubscription) {
      const state = !subscription.is_default

      new TempStorage().storeSubscriptionDefault(
        subscription.user_id,
        state ? subscription.id : null
      )

      subscription.is_default = state

      this.subscriptions
        .filter((s) => s.id !== subscription.id)
        .forEach((s) => (s.is_default = false))
    },
    updateSubscriptionName(id: number, name: string) {
      const subscription = this.subscriptions.filter((s) => s.id === id)[0]

      if (!subscription) {
        return
      }

      subscription.account_name = name
    },
    deleteOrigin(id: number) {
      const subscriptionIndex = this.subscriptions.findIndex((s) =>
        s.origins?.find((o) => o.id === id)
      )
      const originIndex = this.subscriptions[subscriptionIndex]?.origins?.findIndex(
        (o) => o.id === id
      )

      if (originIndex === undefined || originIndex < 0) {
        return
      }

      this.subscriptions[subscriptionIndex]?.origins?.splice(originIndex, 1)
    },
    updateEngine(engine: Engine) {
      const subscriptionIndex = this.subscriptions.findIndex((s) =>
        s.engines?.find((e) => e.id === engine.id)
      )
      const engineIndex = this.subscriptions[subscriptionIndex]?.engines?.findIndex(
        (e) => e.id === engine.id
      )

      // @ts-ignore
      Object.assign(this.subscriptions[subscriptionIndex].engines[engineIndex], engine)
    },
    deleteEngine(id: number) {
      const subscriptionIndex = this.subscriptions.findIndex((s) =>
        s.engines?.find((e) => e.id === id)
      )
      const engineIndex = this.subscriptions[subscriptionIndex]?.engines?.findIndex(
        (e) => e.id === id
      )

      if (engineIndex === undefined || engineIndex < 0) {
        return
      }

      this.subscriptions[subscriptionIndex]?.engines?.splice(engineIndex, 1)
    },
    appendAccount(account: User) {
      this.accounts.push(account)
    },
    appendSubscription(subscription: DetailedSubscription) {
      this.subscriptions.push(subscription)
    },
    async updateSubscription(id: number) {
      try {
        const sub = await new SubscriptionsApi().getSubscription(id)

        if (sub.data) {
          const subscription = this.subscriptions[this.subscriptions.findIndex((s) => s.id === id)]
          subscription.engines = sub.data.engines
          subscription.origins = sub.data.origins
        }
      } catch (err) {
        logError('failed to load subscriptions', { extras: { error: err } })
      }
    },
    deleteSubscription(id: number) {
      const subscriptionIndex = this.subscriptions.findIndex((s) => s.id === id)

      if (subscriptionIndex === undefined || subscriptionIndex < 0) {
        return
      }

      this.subscriptions.splice(subscriptionIndex, 1)
      this.setSubscription()
    },
    updateAccountAccessDate(id: number) {
      const now = moment().format('YYYY-MM-DD HH:mm:ss')
      const account = this.accounts.filter((a) => a.id === id)[0]

      if (!account) {
        return
      }

      new TempStorage().storeAccountDateAccessed(id, now)

      account.date_accessed = now
    },
    displaySidebar(value: boolean) {
      if (window.innerWidth > this.breakpoint) {
        return
      }

      this.isMinimized = !value
      this.showSidebar = value

      const docClasses = document.body.classList

      if (value) {
        docClasses.add('g-sidenav-pinned')
        docClasses.add('g-sidenav-show')
        docClasses.remove('g-sidenav-hidden')
      } else {
        docClasses.add('g-sidenav-hidden')
        docClasses.remove('g-sidenav-pinned')
        docClasses.remove('g-sidenav-show')
      }
    },
  },
  getters: {
    getRecentlyViewedSubscriptions: (state) => {
      return (type: SubscriptionType): Array<DetailedSubscription> => {
        return new DetailedSubscriptions(state.subscriptions)
          .filterByType(type)
          .sortByAccessedOrStartedDate()
          .sortByDefaultState()
          .take(state.maxVisibleSubscriptions)
      }
    },
    getCurrentlyActiveSubscription: (state) => {
      return (type: SubscriptionType): DetailedSubscription | undefined => {
        if (type === SubscriptionType.Subscription && state.current.subscription) {
          return state.current.subscription
        }

        if (type === SubscriptionType.Trial && state.current.trial) {
          return state.current.trial
        }
      }
    },
    getRecentlyViewedAccounts: (state) => {
      return new Users(state.accounts).sortByAccessedDate().take(state.maxVisibleAccounts)
    },
    getAccountRoutePrefix: (state): string => {
      return state.current.userScope === UserScope.Partner && state.current.account
        ? `/customers/${state.current.account.id}`
        : ''
    },
  },
})
