





















































































import { defineComponent, computed } from '@vue/composition-api'
import DocumentationButton from '@/components/Navbar/Documentation/DocumentationButton.vue'
import SearchButton from '@/components/Navbar/Search/SearchButton.vue'
import SupportButton from '@/components/Navbar/Support/SupportButton.vue'
import { useAuthStore } from '@/store/auth'
import { toColor, isProd } from '@/util/helpers'
import IconDiscord from '@/components/Icons/IconDiscord.vue'

export default defineComponent({
  name: 'DashboardNavbar',
  components: {
    DocumentationButton,
    SearchButton,
    SupportButton,
    IconDiscord,
  },
  setup(_, context) {
    const authStore = useAuthStore()

    const devDiscord = computed(() => {
      //if account's only sub is a dev tier then navbar displays discord link
      //instead of traditional support resources
      return (
        authStore.subscriptions.length == 1 &&
        authStore.subscriptions[0].payment_plan === 'IMAGEENGINE_DEV'
      )
    })
    const logout = () => {
      authStore.logout()
      context.root.$router.push('/login')
    }

    const openDiscord = () => {
      window.open('https://discord.gg/shCaRuGC2c', '_blank')
    }

    return {
      authStore,
      toColor,
      logout,
      isProd: isProd(),
      devDiscord,
      openDiscord,
    }
  },
})
