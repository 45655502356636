











































































































































































































































































































































































































import { computed, defineComponent, ref, watch } from '@vue/composition-api'
import { useAuthStore } from '@/store/auth'
import { useSidebarStore } from '@/store/sidebar'
import { useWizardStore } from '@/store/wizard'
//@ts-ignore
import Modal from '@/components/Modal.vue'
import DevModal from '@/components/DevModal.vue'
import { SidebarWizardStep, SubscriptionType, UserScope } from '@/util/enums'
import {
  decorateDetailedSubscription,
  decorateTrialLink,
  decorateUser,
  DetailedSubscription,
  Engine,
  TrialLink,
  User,
} from '@/util/decorators'
import {
  TeamsApi,
  TrialLink as BaseTrialLink,
  UsersApi,
  PaymentTypeEnum,
} from '@/services/ie-microservice-openapi'
import { createProSubscription, getGeoLocation, siteUrl } from '@/util'
import { alertFailure, alertSuccess } from '@/mixins/v3/alerts'
import { logError } from '@/util/sentry'
import capitalize from 'lodash/capitalize'
import { charsAll, cryptoRandomString } from '@/modules/helpers'
import { isNewTier, getTeamHighestTier } from '@/util/helpers'
import TierRestrictModal from '@/components/TierRestrictModal.vue'
import { SubscriptionsApi } from '@/services/ie-microservice-openapi'
import { TempStorage } from '@/util'

export default defineComponent({
  name: 'SidebarWizard',
  components: {
    Modal,
    DevModal,
    TierRestrictModal,
  },
  setup(_, context) {
    const query = '?global=true'
    const authStore = useAuthStore()
    const sidebarStore = useSidebarStore()
    const wizardStore = useWizardStore()
    const showDevModal = ref(false)
    const showRestrictModal = ref(false)
    const highestTier = ref<{ tier: string; limit: string; memberCount: number } | false>(false)
    const restrictTeam = ref(false)
    const userSub = ref({}) as any
    const addPayment = ref(false)
    const addon = ref(false)
    const modalTitle = ref('')

    const teamId = ref(authStore.user.team_id)

    const show = computed(() => wizardStore.wizard.step !== SidebarWizardStep.Idle)

    const getUserSub = async () => {
      let subs = []
      try {
        subs = await new SubscriptionsApi()
          .getSubscriptionsByUserId(authStore.user.id)
          .then((res) => res.data)
        const matchingSub = subs.find((sub) => sub.id === sidebarStore.current.subscription?.id)
        if (matchingSub) {
          userSub.value = matchingSub
        } else {
          userSub.value = subs[0]
        }
      } catch (err: any) {
        logError(err)
        return
      }
    }

    getUserSub()

    const getTeamMemberLimit = async () => {
      if (authStore.user.team_id) {
        highestTier.value = await getTeamHighestTier(authStore.user.team_id)
      }
      if (
        isTierValid(highestTier.value) &&
        highestTier.value.limit !== 'unlimited' &&
        highestTier.value.memberCount >= Number(highestTier.value.limit)
      ) {
        restrictTeam.value = true
      }
    }

    const isTierValid = (
      tier: any
    ): tier is { tier: string; limit: string; memberCount: number } => {
      return tier && typeof tier === 'object' && 'limit' in tier && 'memberCount' in tier
    }

    const disableAddTrial = computed(() => {
      return (
        wizardStore.wizard.step === SidebarWizardStep.AccountSelection &&
        selectedAccountCreationStep.value == 'TRIAL' &&
        wizardStore.wizard.form.trial.name === ''
      )
    })

    const disableAddSubscription = computed(() => {
      return (
        wizardStore.wizard.step === SidebarWizardStep.Subscription &&
        wizardStore.wizard.form.subscription.name === ''
      )
    })

    const userScopeClass = computed(() =>
      sidebarStore.current.userScope === UserScope.Partner ? 'indigo' : 'primary'
    )

    const model = ref({
      account: null as User | null,
      subscriptionType: null as SubscriptionType | null,
      subscription: null as DetailedSubscription | null,
      engine: null as Engine | null,
    })

    const selectedAccountCreationStep = ref(SidebarWizardStep.AccountCreate)

    const isSubmitting = ref(false)

    watch(
      () => wizardStore.wizard.isLoading,
      (value, oldValue) => {
        if (!value && oldValue) {
          if (wizardStore.wizard.subscriptions.length) {
            model.value.subscriptionType = SubscriptionType.Subscription
          } else if (wizardStore.wizard.trials.length) {
            model.value.subscriptionType = SubscriptionType.Trial
          }
        } else if (value && !oldValue) {
          model.value.subscriptionType = null
        }
      }
    )

    watch(
      () => model.value.subscriptionType,
      (value) => {
        //TODO: Prefer to hydrate with getsub call not sesstionStorage
        if (
          new TempStorage().getPaymentAddedStatus() ||
          context.root.$route.query.paymentAdded === 'true'
        ) {
          addPayment.value = false
        }
        model.value.subscription = null

        if (authStore.user.team_id) {
          getTeamMemberLimit()
        }

        getUserSub()

        if (value === SubscriptionType.Subscription) {
          //Prevent Developer Account as an option in dropdown to add
          //engines/origins unless the dev account is on /getting-started page
          //with no engines
          if (context.root.$route.path !== '/getting-started') {
            wizardStore.wizard.subscriptions = wizardStore.wizard.subscriptions.filter(
              (sub) => sub.payment_plan !== 'IMAGEENGINE_DEV'
            )
          }

          model.value.subscription = wizardStore.wizard.subscriptions[0]
        } else if (value === SubscriptionType.Trial) {
          model.value.subscription = wizardStore.wizard.trials[0]
        }

        wizardStore.selectWizardSubscriptionType(model.value.subscriptionType)
      }
    )

    watch(
      () => model.value.subscription,
      () => wizardStore.selectWizardSubscription(model.value.subscription)
    )
    watch(
      () => model.value.engine,
      () => wizardStore.selectWizardEngine(model.value.engine)
    )
    watch(
      () => wizardStore.wizard.selected.account,
      () => (model.value.account = wizardStore.wizard.selected.account)
    )

    const selectAccount = (account: User) => {
      model.value.subscriptionType = null
      model.value.subscription = null
      wizardStore.selectWizardAccount(account)
    }

    const subscriptionInputLabel = computed(() => {
      if (model.value.subscriptionType === SubscriptionType.Subscription)
        return 'Please select subscription'
      if (model.value.subscriptionType === SubscriptionType.Trial) return 'Please select trial'

      return 'Please select subscription'
    })

    const subscriptionInputDefaultOption = computed(() => {
      if (model.value.subscriptionType === SubscriptionType.Subscription) return 'Subscription'
      if (model.value.subscriptionType === SubscriptionType.Trial) return 'Trial'

      return 'Subscription'
    })

    const accountRoutePrefix = computed(() =>
      sidebarStore.current.userScope === UserScope.Partner
        ? `/customers/${wizardStore.wizard.selected.account?.id}`
        : ''
    )

    const isDevTier = computed(() => {
      return (
        sidebarStore.current.subscriptionType === 'SUBSCRIPTION' &&
        sidebarStore.current.subscription?.payment_plan === 'IMAGEENGINE_DEV'
      )
    })

    const next = async () => {
      if (isSubmitting.value) {
        return
      }
      if (!wizardStore.isWizardNextStepAvailable) {
        return
      }

      if (wizardStore.wizard.step === SidebarWizardStep.AccountSelection) {
        if (selectedAccountCreationStep.value == 'TRIAL') {
          await createSubOrTrial()
          window.dataLayer.push({ event: 'gladd_new_trial_existing_acc' })
          cancel()
          return
        }
        setStep(selectedAccountCreationStep.value)
        return
      }

      if (wizardStore.wizard.step === SidebarWizardStep.AccountCreate) {
        await createAccount()
        window.dataLayer.push({ event: 'gladd_new_trial_new_acc' })
        return
      }

      switch (wizardStore.wizard.step) {
        case SidebarWizardStep.Engine:
          context.root.$router.push(
            `${accountRoutePrefix.value}/subscriptions/${wizardStore.wizard.selected.subscription?.id}/engines/create${query}`
          )
          break
        case SidebarWizardStep.Origin:
          if (!wizardStore.wizard.selected.engine) {
            context.root.$router.push(
              `${accountRoutePrefix.value}/subscriptions/${wizardStore.wizard.selected.subscription?.id}/origins/create${query}`
            )
          } else {
            context.root.$router.push(
              `${accountRoutePrefix.value}/subscriptions/${wizardStore.wizard.selected.subscription?.id}/engines/${wizardStore.wizard.selected.engine.id}/edit${query}`
            )
          }
          break
        case SidebarWizardStep.Trial:
          await createSubOrTrial()
          break
        case SidebarWizardStep.Subscription:
          await createSubOrTrial()
          break
        case SidebarWizardStep.Idle:
        default:
          return
      }

      cancel()
    }

    const accountSaveAndNew = async () => {
      await createAccount(true)
    }

    const cancel = () => {
      if (isSubmitting.value) {
        return
      }

      wizardStore.hideWizard()
      wizardStore.wizard.form.subscription.name = ''
      model.value.account = null
      model.value.subscriptionType = null
      model.value.subscription = null
      model.value.engine = null
      selectedAccountCreationStep.value = SidebarWizardStep.AccountCreate
      invitationLink.value = null
    }

    const title = computed(() => {
      switch (wizardStore.wizard.step) {
        case SidebarWizardStep.Engine:
          return 'Add Engine'
        case SidebarWizardStep.Origin:
          return 'Add Origin'
        case SidebarWizardStep.Subscription:
          return 'Add New Subscription'
        case SidebarWizardStep.Trial:
          return 'Add New Trial'
        case SidebarWizardStep.AccountSelection:
          return 'Add new trial for new account'
        case SidebarWizardStep.AccountInvite:
          return 'Invite customer'
        case SidebarWizardStep.AccountCreate:
          return 'Create a new account'
        case SidebarWizardStep.AccountCreationComplete:
          return 'Account created'
        case SidebarWizardStep.Idle:
        default:
          return 'Add'
      }
    })

    const nextButtonLabel = computed(() => {
      if (selectedAccountCreationStep.value == 'TRIAL') return 'Add trial'
      switch (wizardStore.wizard.step) {
        case SidebarWizardStep.Trial:
          return 'Add trial'
        case SidebarWizardStep.Subscription:
          return 'Add subscription'
        case SidebarWizardStep.AccountCreate:
          return 'Create new trial'
        default:
          return 'Next'
      }
    })

    const cancelButtonLabel = computed(() => {
      switch (wizardStore.wizard.step) {
        case SidebarWizardStep.AccountInvite:
        case SidebarWizardStep.AccountCreationComplete:
          return 'Close'
        case SidebarWizardStep.Subscription:
          return 'Cancel'
        default:
          return 'Cancel'
      }
    })

    const isStep = (step: SidebarWizardStep) => wizardStore.wizard.step === step
    const setStep = (step: SidebarWizardStep) => {
      if (step === 'ENGINE' && isDevTier.value && sidebarStore.current.subscription?.engines) {
        showDevModal.value = true
        return
      }

      if (step === 'ENGINE' && model.value.subscription && isNewTier(model.value.subscription)) {
        if (
          userSub.value.engines?.length &&
          userSub.value.tier?.engines &&
          userSub.value.engines?.length >= userSub.value.tier?.engines
        ) {
          if (userSub.value.status !== 'ACTIVE') {
            addPayment.value = true
            addon.value = true
            modalTitle.value = 'Payment Method Required'
            showRestrictModal.value = true
            return
          }
          modalTitle.value = 'Additional Engine'
          showRestrictModal.value = true
          return
        }
      }

      if (
        step === 'TEAM_MEMBER' &&
        model.value.subscription &&
        isNewTier(model.value.subscription)
      ) {
        if (restrictTeam.value) {
          showRestrictModal.value = true
          return
        }
      }

      if (step === 'ORIGIN' && isDevTier.value && sidebarStore.current.subscription?.origins) {
        showDevModal.value = true
        return
      }
      wizardStore.wizard.step = step

      //partner level w/ customer id check if in customer acct to pre-fill
      if (context.root.$route.params.id) {
        model.value.account = wizardStore.wizard?.accounts?.filter(
          (acct) => acct.id === Number(context.root.$route.params.id)
        )[0]
        selectAccount(model.value.account)
      }
      //Non partner mode check if in subscription/trial to prefil
      if (context.root.$route.params.subscriptionID) {
        //if in trial
        const trialIDs = wizardStore.wizard?.trials?.map((trial) => trial.id)
        if (trialIDs.includes(Number(context.root.$route.params.subscriptionID))) {
          model.value.subscriptionType = SubscriptionType.Trial
          setTimeout(() => {
            model.value.subscription = wizardStore.wizard?.trials?.filter(
              (trial) => trial.id === Number(context.root.$route.params.subscriptionID)
            )[0]
          }, 100)
        } else {
          //if in subscription
          setTimeout(() => {
            model.value.subscription = wizardStore.wizard?.subscriptions?.filter(
              (sub) => sub.id === Number(context.root.$route.params.subscriptionID)
            )[0]
          }, 100)
        }
      }
    }

    const createSubOrTrial = async () => {
      const createSub = wizardStore.wizard.step === SidebarWizardStep.Subscription
      const entity = createSub ? 'Subscription' : 'Trial'

      if (!wizardStore.wizard.selected.account) {
        return
      }

      if (!createSub && !wizardStore.wizard.form.trial.name.length) {
        return
      }

      if (createSub && !wizardStore.wizard.form.subscription.name.length) {
        return
      }

      isSubmitting.value = true

      try {
        // @ts-ignore
        const { data: subscription } = await createProSubscription(
          wizardStore.wizard.selected.account.id,
          createSub
            ? wizardStore.wizard.form.subscription.name
            : wizardStore.wizard.form.trial.name,
          createSub ? PaymentTypeEnum.External : PaymentTypeEnum.Trial
        )

        sidebarStore.appendSubscription(decorateDetailedSubscription(subscription))

        context.root.$router.push(
          `${accountRoutePrefix.value}/subscriptions/${subscription.id}/engines/create${query}`
        )

        alertSuccess(context, `Successfully added a new ${entity}`)
      } catch (err) {
        logError(`failed to add ${entity}`, { extras: { error: err } })
        alertFailure(context, `Failed to add a new ${entity}, please contact support`)
      }

      isSubmitting.value = false
    }

    const createdAccountLoginLink = ref('')

    const createAccount = async (thenAddNew: boolean = false) => {
      if (!teamId.value) {
        return
      }
      if (
        !wizardStore.wizard.form.account.create.firstName ||
        !wizardStore.wizard.form.account.create.lastName ||
        !wizardStore.wizard.form.account.create.company ||
        !wizardStore.wizard.form.account.create.email
      ) {
        return
      }

      isSubmitting.value = true

      try {
        const password = cryptoRandomString(14, charsAll)
        const countryId = await getGeoLocation().then((geo) => geo.country_id)

        const { data: user } = await new UsersApi().createUser({
          first_name: wizardStore.wizard.form.account.create.firstName,
          last_name: wizardStore.wizard.form.account.create.lastName,
          company_name: wizardStore.wizard.form.account.create.company,
          email: wizardStore.wizard.form.account.create.email,
          username: wizardStore.wizard.form.account.create.email,
          password: password,
          country_id: countryId,
          confirmed: true,
          enabled: true,
          reseller_team_id: teamId.value,
        })

        // @ts-ignore
        const { data: subscription } = await createProSubscription(
          user.id,
          wizardStore.wizard.form.account.create.company,
          PaymentTypeEnum.Trial
        )

        createdAccountLoginLink.value = `${siteUrl}/login?showModal=true&email=${encodeURIComponent(
          wizardStore.wizard.form.account.create.email
        )}`

        sidebarStore.appendAccount(decorateUser(user))
        sidebarStore.appendSubscription(decorateDetailedSubscription(subscription))

        if (thenAddNew) {
          wizardStore.wizard.form.account.create = {
            firstName: '',
            lastName: '',
            company: '',
            email: '',
          }
          setStep(SidebarWizardStep.AccountSelection)
        } else {
          setStep(SidebarWizardStep.AccountCreationComplete)

          context.root.$router.push(
            `/customers/${user.id}/subscriptions/${subscription.id}/engines/create${query}`
          )
        }

        alertSuccess(context, `Successfully added a new account`)
      } catch (err: any) {
        if (err.response?.data?.message) {
          alertFailure(context, err.response.data.message)
        } else {
          logError('failed to add account', { extras: { error: err } })
          alertFailure(context, 'Failed to add a new account, please contact support')
        }
      }

      isSubmitting.value = false
    }

    const invitationLink = ref(null as TrialLink | null)

    const inviteCustomer = async () => {
      if (!teamId.value) {
        return
      }
      if (!wizardStore.wizard.form.account.invite.email.length) {
        return
      }

      isSubmitting.value = true

      try {
        const { data: data } = await new TeamsApi().createTeamTrialLink(teamId.value, {
          email: wizardStore.wizard.form.account.invite.email,
        })

        invitationLink.value = decorateTrialLink(data as BaseTrialLink)
        window.dataLayer.push({ event: 'gladd_generate_trial_link' })
      } catch (err) {
        logError('failed to create invitation link', { extras: { error: err } })
        alertFailure(context, 'Failed to generate the trial link, please contact support')
      }

      isSubmitting.value = false
    }

    const copyToClipboard = (text: string) => {
      navigator.clipboard.writeText(text).then(() => {
        alertSuccess(context, `Copied to clipboard.`)
      })
    }

    const addTeamMember = () => {
      setStep(SidebarWizardStep.TeamMember)
      if (!restrictTeam.value) {
        context.root.$router.push(`/team/${teamId.value}/members/add${query}`)
        cancel()
      }
    }

    const createTeamRoute = () => {
      context.root.$router.push('/createTeam')
      cancel()
    }

    //on created
    getTeamMemberLimit()

    return {
      steps: SidebarWizardStep,
      model,
      selectAccount,
      SubscriptionType,
      sidebarStore,
      wizardStore,
      UserScope,
      userScopeClass,
      capitalize,
      teamId,
      title,
      nextButtonLabel,
      cancelButtonLabel,
      subscriptionInputLabel,
      subscriptionInputDefaultOption,
      show,
      isStep,
      setStep,
      next,
      cancel,
      createSubOrTrial,
      accountSaveAndNew,
      isSubmitting,
      selectedAccountCreationStep,
      addTeamMember,
      inviteCustomer,
      invitationLink,
      copyToClipboard,
      createdAccountLoginLink,
      disableAddTrial,
      isDevTier,
      showDevModal,
      showRestrictModal,
      isNewTier,
      createTeamRoute,
      authStore,
      highestTier,
      restrictTeam,
      userSub,
      addPayment,
      addon,
      modalTitle,
      disableAddSubscription,
    }
  },
})
