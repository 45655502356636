























import { ref, defineComponent } from '@vue/composition-api'
import { PropType } from '@vue/composition-api/dist/vue-composition-api'
import { DetailedSubscription } from '@/util/decorators'
import Modal from '@/components/Modal.vue'

export default defineComponent({
  name: 'EnginesTable',
  components: {
    Modal,
  },
  props: {
    subscription: {
      type: Object as PropType<DetailedSubscription>,
    },
    showDevModal: {
      type: Boolean,
      required: true,
    },
    purgeEngine: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, context) {
    const bodyText = ref('')

    bodyText.value = props.purgeEngine
      ? `This feature is not available in free plans. Please create a new subscription to make
          use of all ImageEngine features.`
      : `Having multiple engines and origins is only supported in our paid tiers. Please create a
          new subscription to unlock this feature.`

    const closeDevModal = () => {
      context.emit('close')
    }

    const routeCreateSub = () => {
      closeDevModal()
      context.emit('route')
      props.subscription &&
        context.root.$router.push(`/subscriptions/${props.subscription.id}/details/devTier`)
    }

    return {
      routeCreateSub,
      closeDevModal,
      bodyText,
    }
  },
})
