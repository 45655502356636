import moment from 'moment'

const defaultDateFormat = 'YYYY-MM-DD HH:mm:ss'
// const dateAxisFormat = 'YYYY-MM-DD'
// const dateTimeAxisFormat = 'MM-DD HH:mm'
const dateAxisFormat = 'MMM D'
const dateTimeAxisFormat = 'MMM D, HH:mm'

/**
 * MomentDate formats a date provided as moment object using the default format
 * @param date The date to format
 * @returns The formatted date
 */
export function MomentDate(date: moment.Moment): string {
  return date.format(defaultDateFormat)
}

/**
 * UnixTimestampDate formats a date provided as UNIX timestamp using the default format
 * @param timestamp The date to format as UNIX timestamp
 * @returns The formatted date
 */
export function UnixTimestampDate(timestamp: number): string {
  return moment.unix(timestamp).format(defaultDateFormat)
}

/**
 * monthYearFormat formats a date provided to "MMM 'YY" example: "Dec '22"
 * @param date The date to format to "MMM 'YY"
 * @returns The formatted date
 */
export function monthYearFormat(date: string): string {
  return moment(date).format(`MMM 'YY`)
}

/**
 * yearMonthDayFormat formats UNIX timestamp provided to "YYYY-MM-DD" example: "2023-02-06"
 * @param timestamp The UNIX timestamp to format to "YYYY-MM-DD"
 * @returns The formatted date
 */
export function yearMonthDayFormat(timestamp: number): string {
  return moment.unix(timestamp).format('YYYY-MM-DD')
}

/**
 * DateAxisLabel produces date labels for a chart in the client's local timezone
 * @param datetime The date to format in a moment-parseable string
 * @returns the formatted date
 */
export function DateAxisLabel(datetime: string): string {
  return moment(datetime).format(dateAxisFormat)
}

/**
 * DateTimeAxisLabel produces date/time labels for a chart in the client's local timezone
 * @param datetime The date to format in a moment-parseable string
 * @returns the formatted date
 */
export function DateTimeAxisLabel(datetime: string): string {
  return moment(datetime).format(dateTimeAxisFormat)
}

/**
 * GB formats a number in gigabytes
 * Note: 1 GB is 1000³ bytes, as opposed to 1 GiB, which is 1024³
 * @param size The bytes to format
 * @param decimals The number of decimals to include
 * @returns the formatted date
 */
export function GB(size: number, decimals: number = 0): string {
  return (size / 1000000000).toFixed(decimals)
}

/**
 * Bytes formats bytes into human readable format
 * Note: 1 GB is 1000³ bytes, as opposed to 1 GiB, which is 1024³
 * @param size The bytes to format
 * @param decimals The number of decimals to include
 * @returns The formatted bytes
 */
export function Bytes(size: number, decimals: number = 0): string {
  const mod = 1000
  const units = ['B', 'kB', 'MB', 'GB', 'TB']
  let index = 0
  for (index; size > mod && index < units.length - 1; index++) {
    size /= mod
  }

  return (size.toFixed(decimals) + ' ' + units[index]).trimEnd()
}

/**
 * Weight formats weight into human readable format
 * @param size The weight to format
 * @param decimals The number of decimals to include
 * @returns The formatted weight
 */
export function Weight(size: number, decimals: number = 0): string {
  const mod = 1000
  const units = ['gram', 'kilogram', 'ton']
  let index = 0
  let isMultiple = false
  for (index; size >= mod && index < units.length - 1; index++) {
    size /= mod
  }
  if (+size.toFixed(decimals) > 1) isMultiple = true

  return (size.toFixed(decimals) + ' ' + units[index]).trimEnd() + `${isMultiple ? 's' : ''}`
}

/**
 * Number formats a number into human readable format (200000 => 200k)
 * @param input The number to format
 * @param decimals The number of decimals to include
 * @returns The formatted number
 */
export function NiceNumber(input: number, decimals: number = 0): string {
  const mod = 1000
  const units = ['', 'k', 'M', 'B']
  let index = 0
  for (index; input > mod && index < units.length - 1; index++) {
    input /= mod
  }

  return (input.toFixed(decimals) + ' ' + units[index]).trimEnd()
}

/**
 * Percent formats a number into human readable format, including the percent sign
 * @param input The number to format, example (0.824, 1) => "82.4%"
 * @param decimals The number of decimals to include
 * @returns The formatted percentage
 */
export function Percent(input: number, decimals: number = 0): string {
  return (input * 100.0).toFixed(decimals) + '%'
}

/**
 * EngineCname formats the engine cname based on its type: custom or not.
 * For non-custom CNAMEs, the suffix ".imgeng.in" is appended.
 *
 * @param engineCname The engine cname to format
 * @returns The formatted cname engine
 */
export function EngineCname(engineCname: string): string {
  if (!engineCname) {
    return ''
  }
  if (engineCname.endsWith('.cdn')) {
    return engineCname + '.imgeng.in'
  }
  // custom cname
  return engineCname
}

/**
 * EngineIEAddress formats the engine cname based on its type: custom or not.
 * The returned address will always end in ".imgeng.in" unless it is empty.
 *
 * @param engineCname The engine cname to format
 * @returns The formatted cname engine
 */
export function EngineIEAddress(engineCname: string): string {
  if (!engineCname) {
    return ''
  }
  if (!engineCname.endsWith('.imgeng.in')) {
    return engineCname + '.imgeng.in'
  }
  return engineCname
}

export function Round(value: number = 0, decimals: number = 0) {
  return Number(value).toFixed(decimals)
}

/**
 * Truncates strings to fit perfectly in UI elements
 * Example:  "this is a very long string" => "this is a very..."
 *
 */
export function TruncateString(
  string: string,
  maxCharacters: number = 20,
  concatWith: string = '...'
): string {
  if (string.length > maxCharacters) {
    return string.slice(0, maxCharacters).trim().concat(concatWith)
  }
  return string
}

export function Pluralize(count: number, noun: string, suffix = 's') {
  return `${count} ${noun}${count !== 1 ? suffix : ''}`
}

// for EST time returned by the backend and formats the date based on users timezone
export function estToClientTz(date: string): string {
  return moment.tz(date, 'America/New_York').local().format('YYYY-MM-DD')
}

export function formatDollarAmount(amount: number): string {
  return amount.toLocaleString('en-US')
}
