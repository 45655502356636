




















import { defineComponent, ref, onBeforeMount, computed } from '@vue/composition-api'
export default defineComponent({
  name: 'BaseCheckbox',
  model: {
    prop: 'checked',
  },
  props: {
    checked: {
      type: [Array, Boolean],
      description: 'Whether checkbox is checked',
    },
    disabled: {
      type: Boolean,
      description: 'Whether checkbox is disabled',
    },
    inline: {
      type: Boolean,
      description: 'Whether checkbox is inline',
    },
    inputClasses: {
      type: [String, Object, Array],
      description: 'Checkbox input classes',
    },
    type: {
      type: String,
      description: 'Checkbox type (e.g info, danger etc)',
    },
  },
  setup(props, context) {
    const cbId = ref('')
    const touched = ref(false)

    onBeforeMount(() => {
      cbId.value = Math.random().toString(16).slice(2)
    })

    const model = computed({
      get: () => props.checked,
      set: (check) => {
        if (!touched.value) {
          touched.value = true
        }
        context.root.$emit('update:checked', check)
      },
    })

    const inlineClass = computed(() => (props.inline ? 'form-check-inline' : ''))

    return {
      cbId,
      model,
      inlineClass,
    }
  },
})
