import Vue from 'vue'
import Router from 'vue-router'

import AuthLayout from '@/layout/AuthLayout'
import Dashboard from '@/layout/Dashboard'

/**
 * Casts props into proper data types.
 * Props ending in 'ID' and the prop 'id' are cast to Number automatically.
 * To cast other props or override the defaults, pass a mapping like this:
 * @example
 * // Truthy values like 'true', 'yes', 'on' and '1' are converted to Boolean(true)
 * {
 *  path: '/:isNice/:age/:hatSize',
 *  name: 'foo route',
 *  props: typedProps({ isNice: Boolean, age: Number, hatSize: Number}),
 * },
 * @param {Object} mapping
 * @returns
 */
const typedProps = (otherProps) => {
  let mapping = {}
  if (otherProps && 'mapping' in otherProps) {
    mapping = otherProps['mapping']
    delete otherProps['mapping']
  }

  return (route) => {
    let props = otherProps ? otherProps : {}
    for (let [prop, value] of Object.entries(route.params)) {
      if (prop in mapping) {
        if (mapping[prop] === Boolean) {
          value = ['true', '1', 'yes', 'on'].includes(value.toLowerCase())
        } else {
          value = mapping[prop](value)
        }
      } else if (prop === 'id' || prop.endsWith('ID')) {
        value = Number(value)
      }

      props[prop] = value
    }

    return props
  }
}

Vue.use(Router)

const routes = [
  {
    path: '/',
    redirect: '/login',
    component: AuthLayout,
    meta: {
      redirectIfAuth: true,
    },
    children: [
      {
        path: '/login',
        name: 'login',
        meta: {
          title: 'Login',
        },
        component: () => import(/* webpackChunkName: "public" */ './views/Login.vue'),
      },
      {
        path: '/team/:teamID/trial/:trialLink',
        name: 'register from partner invite',
        props: typedProps(),
        component: () =>
          import(
            /* webpackChunkName: "public" */ './views/SignUpFromPartnerInvite/CreateAccount.vue'
          ),
        meta: {
          title: 'Register from Parter Invite',
        },
      },
    ],
  },
  {
    // one off non authenticated routes
    // these components should redirect if required
    path: '/',
    component: AuthLayout,
    meta: {
      noAuthCheck: true,
    },
    children: [
      {
        path: '/impersonate/:id',
        name: 'impersonate',
        component: () => import(/* webpackChunkName: "public" */ './components/Impersonate.vue'),
      },
      {
        path: '/password/reset/:hash',
        component: () => import(/* webpackChunkName: "public" */ './views/PasswordReset.vue'),
      },
    ],
  },
  {
    path: '/register/add-payment',
    component: () => import(/* webpackChunkName: "public" */ './views/SignUp/AddPayment.vue'),
    meta: {
      title: 'Add Payment',
    },
  },
  // Redirect old /register to /register/starter'
  {
    path: '/register',
    redirect: '/register/starter',
  },
  {
    path: '/:flow(register|signup)/:subscription?/:interval?/:type(|starter|growth|beast|standard|basic|dev)',
    component: AuthLayout,
    meta: {
      redirectIfAuth: true,
      registerFlow: true,
    },
    children: [
      {
        path: '',
        props: (r) => r,
        meta: {
          title: 'Register',
        },
        component: () => import(/* webpackChunkName: "public" */ './views/SignUp/Website.vue'),
      },
      {
        path: 'website',
        redirect: '/register',
      },
      {
        path: 'create-account',
        component: () =>
          import(/* webpackChunkName: "public" */ './views/SignUp/CreateAccount.vue'),
        meta: {
          title: 'Create Account',
        },
      },
      {
        path: 'add-payment',
        component: () => import(/* webpackChunkName: "public" */ './views/SignUp/AddPayment.vue'),
        meta: {
          title: 'Add Payment',
        },
      },
      {
        path: 'signup-typeform',
        component: () =>
          import(/* webpackChunkName: "public" */ './views/SignUp/SignupTypeform.vue'),
        meta: {
          title: 'Signup Typeform',
        },
      },
      {
        path: 'create-engine',
        name: 'register create engine',
        props: typedProps({
          breadcrumbs: ['Register', 'Create Engine'],
          isRegistering: true,
        }),
        component: () => import(/* webpackChunkName: "public" */ './views/SignUp/CreateEngine.vue'),
        meta: {
          title: 'Create Engine',
        },
      },
      {
        path: 'select-origin',
        name: 'register select origin',
        props: typedProps({
          breadcrumbs: ['Register', 'Select Origin'],
          isRegistering: true,
        }),
        component: () => import(/* webpackChunkName: "public" */ './views/SignUp/SelectOrigin.vue'),
        meta: {
          title: 'Select Origin',
        },
      },
    ],
  },
  {
    // customers
    path: '/customers',
    component: Dashboard,
    meta: {
      requiresAuth: true,
      resellersOnly: true,
    },
    children: [
      {
        path: '',
        name: 'customers',
        meta: {
          title: 'Customers',
          partnerScope: true,
        },
        props: typedProps({
          breadcrumbs: ['Customers'],
        }),
        component: () => import(/* webpackChunkName: "customers" */ './views/CustomerOverview.vue'),
      },
      {
        path: 'dashboard',
        name: 'customers-dashboard',
        meta: {
          title: 'Customers Dashboard',
          partnerScope: true,
        },
        props: typedProps({
          breadcrumbs: ['Customers', 'Overview'],
        }),
        component: () =>
          import(/* webpackChunkName: "customers" */ './views/CustomerDashboard.vue'),
      },
      {
        path: 'create',
        meta: {
          title: 'Create Customer',
          partnerScope: true,
        },
        props: typedProps({
          breadcrumbs: ['Customers => customers', 'Create New Customer'],
        }),
        component: () =>
          import(/* webpackChunkName: "customers" */ './views/CreateCustomerAccount.vue'),
      },
      {
        path: 'invitations',
        name: 'invitations', // this is temporary
        meta: {
          title: 'Customer Invitations',
          partnerScope: true,
        },
        props: typedProps({
          breadcrumbs: ['Invitations'],
        }),
        component: () =>
          import(/* webpackChunkName: "customers" */ './views/InvitationsOverview.vue'),
      },
      {
        path: ':id',
        name: 'customers-id',
        meta: {
          title: 'Customer User Profile',
          partnerScope: true,
        },
        props: typedProps({
          breadcrumbs: ['$customer Profile'],
        }),
        component: () => import(/* webpackChunkName: "customers" */ './views/UserProfile.vue'),
      },
      {
        path: ':id/subscriptions',
        name: 'customers-id-subscriptions',
        meta: {
          title: 'Customer Subscriptions',
          partnerScope: true,
        },
        props: typedProps({
          breadcrumbs: ['$customer => customers', 'Subscriptions'],
        }),
        component: () => import(/* webpackChunkName: "customers" */ './views/Subscriptions.vue'),
      },
      {
        path: ':id/subscriptions/:subscriptionID/details',
        name: 'customers-id-subscriptions-id-details',
        meta: {
          title: 'Details',
          partnerScope: true,
        },
        props: typedProps({
          breadcrumbs: ['$customer => customers', '$subscription => customers-id-subscriptions'],
        }),
        component: () =>
          import(/* webpackChunkName: "customers" */ './views/SubscriptionDetails.vue'),
      },
      {
        path: ':id/subscriptions/:subscriptionID/analytics',
        name: 'customers-id-subscriptions-id-details',
        meta: {
          title: 'Analytics',
          partnerScope: true,
        },
        props: typedProps(),
        component: () =>
          import(/* webpackChunkName: "customers" */ './views/SubscriptionAnalytics.vue'),
      },
      {
        path: ':id/subscriptions/:subscriptionID/origins',
        name: 'customers-id-subscriptions-id-origins',
        meta: {
          title: 'Customer Origins',
          partnerScope: true,
        },
        props: typedProps({
          breadcrumbs: [
            '$customer => customers',
            '$subscription => customers-id-subscriptions',
            'Customer Origins',
          ],
        }),
        component: () => import(/* webpackChunkName: "customers" */ './views/Origins.vue'),
      },
      {
        path: ':id/subscriptions/:subscriptionID/engines/create',
        name: 'customers-id-subscriptions-id-engines-create',
        meta: {
          title: 'Create New Customer Engine',
          partnerScope: true,
        },
        props: typedProps({
          breadcrumbs: [
            '$customer => customers',
            '$subscription => customers-id-subscriptions',
            '$engine => customers-id-subscriptions-id-engines',
            'Create',
          ],
        }),
        component: () => import(/* webpackChunkName: "customers" */ './views/CreateEngine.vue'),
      },
      {
        path: ':id/subscriptions/:subscriptionID/origins/create',
        name: 'customers-id-subscriptions-id-origins-create',
        props: typedProps({
          breadcrumbs: [
            '$customer => customers',
            '$subscription => customers-id-subscriptions',
            '$origin => customers-id-subscriptions-id-origins',
            'Create Origin',
          ],
        }),
        component: () => import(/* webpackChunkName: "customers" */ './views/CreateOrigin.vue'),
        meta: { hideCustomerLink: true, title: 'Create Customer Origin', partnerScope: true },
      },
      {
        path: ':id/subscriptions/:subscriptionID/origins/:originID/edit',
        name: 'customers-id-subscriptions-id-origins-id-edit',
        props: typedProps({
          breadcrumbs: [
            '$customer => customers',
            '$subscription => customers-id-subscriptions',
            '$origin => customers-id-subscriptions-id-origins',
            'Edit Origin',
          ],
        }),
        component: () => import(/* webpackChunkName: "customers" */ './views/EditOrigin.vue'),
        meta: { hideCustomerLink: true, title: 'Customers Edit Origin', partnerScope: true },
      },
      {
        path: ':id/subscriptions/:subscriptionID/engines',
        name: 'customers-id-subscriptions-id-engines',
        meta: {
          title: 'Customer Engines',
          partnerScope: true,
        },
        props: typedProps({
          breadcrumbs: [
            '$customer => customers',
            '$subscription => customers-id-subscriptions',
            'Customer Engines',
          ],
        }),
        component: () => import(/* webpackChunkName: "customers" */ './views/Engines.vue'),
      },
      {
        path: ':id/subscriptions/:subscriptionID/engines/:engineID',
        name: 'customers-id-subscriptions-id-engines-id',
        meta: {
          title: 'Customer Engine Overview',
          partnerScope: true,
        },
        props: typedProps({
          breadcrumbs: [
            '$customer => customers',
            '$subscription => customers-id-subscriptions',
            '$engines => customers-id-subscriptions-id-engines',
            'Overview',
          ],
        }),
        component: () => import(/* webpackChunkName: "customers" */ './views/EngineOverview.vue'),
      },
      {
        path: ':id/subscriptions/:subscriptionID/engines/:engineID/setup-instructions',
        name: 'customers-id-subscriptions-id-engines-id-setup-instructions',
        meta: {
          title: 'Customer Engine Setup Instructions',
          partnerScope: true,
        },
        props: typedProps(),
        component: () =>
          import(/* webpackChunkName: "customers" */ './views/EngineSetupInstructions.vue'),
      },
      {
        path: ':id/subscriptions/:subscriptionID/engines/:engineID/edit',
        name: 'customers-id-subscriptions-id-engines-id-edit',
        meta: {
          title: 'Customer Edit Engine',
          partnerScope: true,
        },
        props: typedProps({
          breadcrumbs: [
            '$customer => customers',
            '$subscription => customers-id-subscriptions',
            '$engine => customers-id-subscriptions-id-engines',
            'Edit',
          ],
        }),
        component: () => import(/* webpackChunkName: "customers" */ './views/EditEngine.vue'),
      },
      {
        path: ':id/subscriptions/:subscriptionID/engines/:engineID/purge',
        name: 'customers-id-subscriptions-id-engines-id-purge',
        meta: {
          title: 'Customer Engine Purge Cache',
          partnerScope: true,
        },
        props: typedProps({
          breadcrumbs: [
            '$customer => customers',
            '$subscription => customers-id-subscriptions',
            '$engine => customers-id-subscriptions-id-engines',
            'Purge',
          ],
        }),
        component: () => import(/* webpackChunkName: "customers" */ './views/PurgeEngine.vue'),
      },
      {
        path: ':id/subscriptions/:subscriptionID/engines/:engineID/analytics',
        name: 'customers-id-subscriptions-id-engines-id-analytics',
        meta: {
          title: 'Customer Engine Analytics',
          partnerScope: true,
        },
        props: typedProps({
          breadcrumbs: [
            '$customer => customers',
            '$subscription => customers-id-subscriptions',
            '$engine => customers-id-subscriptions-id-engines',
            'Analytics',
          ],
        }),
        component: () => import(/* webpackChunkName: "customers" */ './views/EngineAnalytics.vue'),
      },
    ],
  },
  {
    path: '/subscriptions',
    component: Dashboard,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'subscriptions',
        meta: {
          title: 'Subscriptions',
          partnerScope: false,
        },
        props: typedProps({
          breadcrumbs: ['Subscriptions'],
        }),
        component: () => import(/* webpackChunkName: "personal" */ './views/Subscriptions.vue'),
      },
      {
        path: ':subscriptionID/details',
        name: 'subscriptions-id-details',
        meta: {
          title: 'Details',
        },
        props: typedProps({
          breadcrumbs: ['$subscription => subscriptions', 'Origins => origins', 'Origins'],
        }),
        component: () =>
          import(/* webpackChunkName: "personal" */ './views/SubscriptionDetails.vue'),
      },
      {
        path: ':subscriptionID/details/devTier',
        name: 'subscriptions-id-details-devTier',
        meta: {
          title: 'Details',
        },
        props: typedProps({
          breadcrumbs: ['$subscription => subscriptions', 'Origins => origins', 'Origins'],
        }),
        component: () => import(/* webpackChunkName: "personal" */ './views/DevTierCreateSub.vue'),
      },
      {
        path: ':subscriptionID/analytics',
        name: 'subscriptions-id-analytics',
        meta: {
          title: 'Analytics',
        },
        props: typedProps(),
        component: () =>
          import(/* webpackChunkName: "personal" */ './views/SubscriptionAnalytics.vue'),
      },
      {
        path: ':subscriptionID/origins',
        name: 'subscriptions-id-origins',
        meta: {
          title: 'Origins',
        },
        props: typedProps({
          breadcrumbs: ['$subscription => subscriptions', 'Origins => origins', 'Origins'],
        }),
        component: () => import(/* webpackChunkName: "personal" */ './views/Origins.vue'),
      },
      {
        path: ':subscriptionID/origins/create',
        name: 'subscriptions-id-origins-create',
        meta: {
          title: 'Create Origin',
        },
        props: typedProps({
          breadcrumbs: [
            '$subscription => subscriptions',
            'Engines => engines',
            'Origins => origins',
            'Create Origin',
          ],
        }),
        component: () => import(/* webpackChunkName: "personal" */ './views/CreateOrigin.vue'),
      },
      {
        path: ':subscriptionID/origins/:originID/edit',
        name: 'subscriptions-id-origins-id-edit',
        meta: {
          title: 'Edit Origin',
        },
        props: typedProps({
          breadcrumbs: ['$subscription => subscriptions', '$origin => origins', 'Edit Origin'],
        }),
        component: () => import(/* webpackChunkName: "personal" */ './views/EditOrigin.vue'),
      },
      {
        path: ':subscriptionID/engines',
        name: 'subscriptions-id-engines',
        meta: {
          title: 'Engines',
        },
        props: typedProps({
          breadcrumbs: ['$subscription => subscriptions', 'Engines'],
        }),
        component: () => import(/* webpackChunkName: "personal" */ './views/Engines.vue'),
      },
      {
        path: ':subscriptionID/engines/create',
        name: 'subscriptions-id-engines-create',
        meta: {
          title: 'Create New Engine',
        },
        props: typedProps({
          breadcrumbs: [
            '$subscription => subscriptions',
            '$engine => subscriptions-id-engines',
            'Create',
          ],
        }),
        component: () => import(/* webpackChunkName: "personal" */ './views/CreateEngine.vue'),
      },
      {
        path: ':subscriptionID/engines/:engineID',
        name: 'subscriptions-id-engines-id',
        meta: {
          title: 'Engine Overview',
        },
        props: typedProps({
          breadcrumbs: [
            '$subscription => subscriptions',
            '$engine => subscriptions-id-engines',
            'Overview',
          ],
        }),
        component: () => import(/* webpackChunkName: "personal" */ './views/EngineOverview.vue'),
      },
      {
        path: ':subscriptionID/engines/:engineID/setup-instructions',
        name: 'subscriptions-id-engines-id-setup-instructions',
        meta: {
          title: 'Engine Setup Instructions',
        },
        props: typedProps(),
        component: () =>
          import(/* webpackChunkName: "personal" */ './views/EngineSetupInstructions.vue'),
      },
      {
        path: ':subscriptionID/engines/:engineID/edit',
        name: 'subscriptions-id-engines-id-edit',
        meta: {
          title: 'Edit Engine',
        },
        props: typedProps({
          breadcrumbs: [
            '$subscription => subscriptions',
            '$engine => subscriptions-id-engines',
            'Edit Engine',
          ],
        }),
        component: () => import(/* webpackChunkName: "personal" */ './views/EditEngine.vue'),
      },
      {
        path: ':subscriptionID/engines/:engineID/purge',
        name: 'subscriptions-id-engines-id-purge',
        meta: {
          title: 'Engine Purge Cache',
        },
        props: typedProps({
          breadcrumbs: [
            '$subscription => subscriptions',
            '$engine => subscriptions-id-engines',
            'Purge Cache',
          ],
        }),
        component: () => import(/* webpackChunkName: "personal" */ './views/PurgeEngine.vue'),
      },
      {
        path: ':subscriptionID/engines/:engineID/analytics',
        name: 'subscriptions-id-engines-id-analytics',
        meta: {
          title: 'Engine Analytics',
        },
        props: typedProps({
          breadcrumbs: [
            '$subscription => subscriptions',
            '$engine => subscriptions-id-engines',
            'Analytics',
          ],
        }),
        component: () => import(/* webpackChunkName: "personal" */ './views/EngineAnalytics.vue'),
      },
      {
        path: ':subscriptionID/billing',
        name: 'subscriptions-id-billing',
        meta: {
          title: 'Billing Overview',
        },
        props: typedProps({
          breadcrumbs: ['$subscription => subscriptions', 'Billing'],
        }),
        component: () => import(/* webpackChunkName: "personal" */ './views/BillingOverview.vue'),
      },
      {
        path: ':subscriptionID/billing/history',
        name: 'subscriptions-id-billing-history',
        meta: {
          title: 'Billing History',
        },
        props: typedProps({
          breadcrumbs: ['$subscription => subscriptions', 'Billing History'],
        }),
        component: () => import(/* webpackChunkName: "personal" */ './views/BillingHistory.vue'),
      },
      {
        path: ':subscriptionID/billing/addCC',
        name: 'subscriptions-id-billing-addCC',
        meta: {
          title: 'Add Credit Card',
        },
        props: typedProps({
          breadcrumbs: ['$subscription => subscriptions', 'Add Card'],
        }),
        component: () => import(/* webpackChunkName: "personal" */ './views/AddCCOverview.vue'),
      },
      // This route should only be available for those who entered payment info
      {
        path: ':subscriptionID/billing/upgrade',
        name: 'subscriptions-id-billing-upgrade',
        meta: {
          title: 'Upgrade Plan',
        },
        component: () => import(/* webpackChunkName: "personal" */ './views/Upgrade.vue'),
        props: typedProps(),
      },
    ],
  },
  {
    path: '/team',
    component: Dashboard,
    meta: {
      requiresAuth: true,
      teamsOnly: true,
    },
    children: [
      {
        path: '/createTeam',
        name: 'create team',
        meta: {
          title: 'Create Team',
          commonScope: true,
        },
        props: typedProps({
          breadcrumbs: ['Create Team'],
        }),
        component: () => import(/* webpackChunkName: "team" */ './views/Forms/CreateTeam.vue'),
      },
      {
        path: ':teamID/members',
        name: 'team',
        meta: {
          title: 'Team',
          commonScope: true,
        },
        props: typedProps({
          breadcrumbs: ['Team Members'],
        }),
        component: () => import(/* webpackChunkName: "team" */ './views/TeamManagement.vue'),
      },
      {
        path: ':teamID/members/add',
        meta: {
          title: 'Add Team Member',
          commonScope: true,
        },
        props: typedProps({
          breadcrumbs: ['Team Members => team', 'Create Team Member'],
        }),
        component: () => import(/* webpackChunkName: "team" */ './views/CreateTeamMember.vue'),
      },
      {
        path: ':teamID/members/:id/subscriptions',
        name: 'team member subscriptions',
        meta: {
          title: 'Team Member Subscriptions',
          commonScope: true,
        },
        props: typedProps({
          breadcrumbs: ['$teamMember => team', 'Subscriptions'],
        }),
        component: () => import(/* webpackChunkName: "team" */ './views/Subscriptions.vue'),
      },
    ],
  },
  {
    // the one off authenticated routes
    path: '',
    component: Dashboard,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/traffic-report',
        name: 'traffic-report',
        meta: {
          title: 'Traffic Report',
          partnerScope: false,
        },
        props: typedProps({
          breadcrumbs: ['Reporting'],
        }),
        component: () => import(/* webpackChunkName: "personal" */ './views/TeamReporting.vue'),
      },
      {
        path: '/reporting/customer-usage',
        name: 'customer-reporting',
        meta: {
          title: 'Customer Traffic Report',
          partnerScope: true,
        },
        props: typedProps({
          breadcrumbs: ['Customer Reporting'],
          isCustomerReport: true,
        }),
        component: () => import(/* webpackChunkName: "personal" */ './views/TeamReporting.vue'),
      },
      {
        path: '/getting-started',
        meta: {
          title: 'Getting Started',
        },
        props: typedProps({
          breadcrumbs: ['Getting Started'],
        }),
        component: () =>
          import(/* webpackChunkName: "personal" */ './views/CreateYourFirstEngine.vue'),
      },
      {
        path: '/getting-started/select-origin',
        name: 'origin wizard',
        component: () =>
          import(/* webpackChunkName: "personal" */ './views/SelectOriginFromSuggested.vue'),
        meta: { doesNotReqEngines: true, title: 'Origin Wizard' },
        props: typedProps(),
      },
      {
        path: '/support',
        name: 'contact-support',
        meta: {
          title: 'Contact Support',
          doesNotReqEngines: true,
        },
        props: typedProps({
          breadcrumbs: ['Support'],
        }),
        component: () => import(/* webpackChunkName: "personal" */ './views/SupportPage.vue'),
      },
      {
        path: '/zendesk',
        meta: {
          title: 'Support Portal Login',
          doesNotReqEngines: true,
        },
        props: typedProps({
          breadcrumbs: ['Support Portal Login'],
        }),
        component: () => import(/* webpackChunkName: "personal" */ './views/Zendesk.vue'),
      },
    ],
  },
  {
    path: '/users',
    component: Dashboard,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: ':id',
        meta: {
          title: 'User Profile',
          doesNotReqEngines: true,
        },
        props: typedProps({
          breadcrumbs: ['User Profile'],
        }),
        component: () => import(/* webpackChunkName: "personal" */ './views/UserProfile.vue'),
      },
      {
        path: ':userID/apiKeys',
        name: 'api-keys',
        component: () => import(/* webpackChunkName: "personal" */ './views/ApiKeys.vue'),
      },
    ],
  },
  {
    path: '/_dev',
    component: () => import(/* webpackChunkName: "public" */ './views/Dev.vue'),
  },
  {
    path: '/not-found',
    component: () => import(/* webpackChunkName: "public" */ './views/NotFound.vue'),
  },
  {
    path: '*',
    redirect: '/not-found',
  },
]

export const router = new Router({
  linkExactActiveClass: 'active',
  mode: 'history',
  routes: [...routes],
})

export default router
