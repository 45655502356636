/* tslint:disable */
/* eslint-disable */
/**
 * ImageEngine Site Assessments
 * API to submit website assessment jobs and get their statuses and updates
 *
 * The version of the OpenAPI document: 2.0
 * Contact: andreas@scientiamobile.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration'
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common'
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base'

/**
 * Holds the results returned by the Google Page Speed Insight API. It can be `null` in case issues has occured on Google side. If so, please check the error field.
 * @export
 * @interface AnalysisResult
 */
export interface AnalysisResult {
  /**
   *
   * @type {number}
   * @memberof AnalysisResult
   */
  speed_index?: number
  /**
   *
   * @type {number}
   * @memberof AnalysisResult
   */
  visual_complete?: number
  /**
   *
   * @type {number}
   * @memberof AnalysisResult
   */
  image_payload?: number
  /**
   *
   * @type {number}
   * @memberof AnalysisResult
   */
  page_weight?: number
  /**
   *
   * @type {number}
   * @memberof AnalysisResult
   */
  page_load_time?: number
  /**
   *
   * @type {number}
   * @memberof AnalysisResult
   */
  elapsed_img_download_time?: number
  /**
   *
   * @type {number}
   * @memberof AnalysisResult
   */
  image_count?: number
  /**
   *
   * @type {number}
   * @memberof AnalysisResult
   */
  lcp?: number
  /**
   *
   * @type {number}
   * @memberof AnalysisResult
   */
  fcp?: number
  /**
   *
   * @type {number}
   * @memberof AnalysisResult
   */
  psi_performace_score?: number
  /**
   *
   * @type {AnalysisResultOpportunities}
   * @memberof AnalysisResult
   */
  opportunities?: AnalysisResultOpportunities
  /**
   *
   * @type {AnalysisResultFilmstrip}
   * @memberof AnalysisResult
   */
  filmstrip?: AnalysisResultFilmstrip
  /**
   *
   * @type {CarbonFootprintResults}
   * @memberof AnalysisResult
   */
  carboon_footprint?: CarbonFootprintResults
}
/**
 *
 * @export
 * @interface AnalysisResultFilmstrip
 */
export interface AnalysisResultFilmstrip {
  /**
   *
   * @type {AnalysisResultFilmstripDetails}
   * @memberof AnalysisResultFilmstrip
   */
  details?: AnalysisResultFilmstripDetails
}
/**
 *
 * @export
 * @interface AnalysisResultFilmstripDetails
 */
export interface AnalysisResultFilmstripDetails {
  /**
   *
   * @type {number}
   * @memberof AnalysisResultFilmstripDetails
   */
  scale?: number
  /**
   *
   * @type {Array<AnalysisResultFilmstripDetailsItems>}
   * @memberof AnalysisResultFilmstripDetails
   */
  items?: Array<AnalysisResultFilmstripDetailsItems>
}
/**
 *
 * @export
 * @interface AnalysisResultFilmstripDetailsItems
 */
export interface AnalysisResultFilmstripDetailsItems {
  /**
   *
   * @type {number}
   * @memberof AnalysisResultFilmstripDetailsItems
   */
  timing?: number
  /**
   *
   * @type {string}
   * @memberof AnalysisResultFilmstripDetailsItems
   */
  data?: string
  /**
   *
   * @type {number}
   * @memberof AnalysisResultFilmstripDetailsItems
   */
  timestamp?: number
}
/**
 *
 * @export
 * @interface AnalysisResultOpportunities
 */
export interface AnalysisResultOpportunities {
  /**
   *
   * @type {PageSpeedOpportunities}
   * @memberof AnalysisResultOpportunities
   */
  'modern-image-formats'?: PageSpeedOpportunities
  /**
   *
   * @type {PageSpeedOpportunities}
   * @memberof AnalysisResultOpportunities
   */
  'uses-optimized-images'?: PageSpeedOpportunities
  /**
   *
   * @type {PageSpeedOpportunities}
   * @memberof AnalysisResultOpportunities
   */
  'uses-responsive-images'?: PageSpeedOpportunities
}
/**
 * Result returned by the CarbonFootprint service
 * @export
 * @interface CarbonFootprintResults
 */
export interface CarbonFootprintResults {
  /**
   *
   * @type {string}
   * @memberof CarbonFootprintResults
   */
  summary?: string
  /**
   *
   * @type {number}
   * @memberof CarbonFootprintResults
   */
  carbonFootprintGrams?: number
  /**
   *
   * @type {number}
   * @memberof CarbonFootprintResults
   */
  carbonFootprintKilos?: number
  /**
   *
   * @type {number}
   * @memberof CarbonFootprintResults
   */
  carbonFootprintTonnes?: number
  /**
   *
   * @type {number}
   * @memberof CarbonFootprintResults
   */
  bytes?: number
  /**
   *
   * @type {number}
   * @memberof CarbonFootprintResults
   */
  gigabytes?: number
  /**
   *
   * @type {boolean}
   * @memberof CarbonFootprintResults
   */
  isGreenHosted?: boolean
  /**
   *
   * @type {string}
   * @memberof CarbonFootprintResults
   */
  model?: string
  /**
   *
   * @type {string}
   * @memberof CarbonFootprintResults
   */
  meta?: string
  /**
   *
   * @type {string}
   * @memberof CarbonFootprintResults
   */
  sameAs?: string
  /**
   *
   * @type {string}
   * @memberof CarbonFootprintResults
   */
  usage?: string
}
/**
 * Holds the information useful to run a demo site test.
 * @export
 * @interface DemoRequest
 */
export interface DemoRequest {
  /**
   * the url of the website to analyze
   * @type {string}
   * @memberof DemoRequest
   */
  url: string
  /**
   * set it to `true` if you need the site test to be performed on a desktop device
   * @type {boolean}
   * @memberof DemoRequest
   */
  desktop: boolean
  /**
   * set it to `true` if you need the site test to be performed on a mobile device
   * @type {boolean}
   * @memberof DemoRequest
   */
  mobile: boolean
  /**
   * ISO 3166 alpha-2 code representation of the country from where the site-test is being requested
   * @type {string}
   * @memberof DemoRequest
   */
  country?: string
  /**
   * the url of the website that is making the analysis request
   * @type {string}
   * @memberof DemoRequest
   */
  referrer?: string
  /**
   * object containing custom data in string format. Only one parameter key is reserved which is the `batch_id` key. The value for this parameter can be a number, a string, or a combination of both. Setting the same `batch_id` to a group of site tests will identify a batch of multiple requests.
   * @type {{ [key: string]: string; }}
   * @memberof DemoRequest
   */
  params?: { [key: string]: string }
  /**
   * url allowed to receive HTTP POST requests from this server when the site test is complete. The body of this request will be of the SiteTestResponse model.
   * @type {string}
   * @memberof DemoRequest
   */
  callback_url?: string
  /**
   * algorithm to use to calculate performance score.If not specified, default value is 1.
   * @type {number}
   * @memberof DemoRequest
   */
  lighthouse_algorithm?: number
}
/**
 * Holds the result of a demo test.
 * @export
 * @interface DemoResults
 */
export interface DemoResults {
  /**
   *
   * @type {DemoResultsOrigin}
   * @memberof DemoResults
   */
  origin?: DemoResultsOrigin
  /**
   *
   * @type {DeviceResult}
   * @memberof DemoResults
   */
  mobile?: DeviceResult
  /**
   *
   * @type {DeviceResult}
   * @memberof DemoResults
   */
  desktop?: DeviceResult
}
/**
 *
 * @export
 * @interface DemoResultsOrigin
 */
export interface DemoResultsOrigin {
  /**
   *
   * @type {number}
   * @memberof DemoResultsOrigin
   */
  est_pageviews_monthly?: number
  /**
   *
   * @type {string}
   * @memberof DemoResultsOrigin
   */
  origin_url?: string
  /**
   *
   * @type {Array<string>}
   * @memberof DemoResultsOrigin
   */
  frontend_tech?: Array<string>
  /**
   *
   * @type {Array<string>}
   * @memberof DemoResultsOrigin
   */
  cms?: Array<string>
  /**
   *
   * @type {number}
   * @memberof DemoResultsOrigin
   */
  est_smartbytes_monthly?: number
}
/**
 * Describes the status and progress of a demo site test.
 * @export
 * @interface DemoStatus
 */
export interface DemoStatus {
  /**
   *
   * @type {string}
   * @memberof DemoStatus
   */
  code?: DemoStatusCodeEnum
  /**
   *
   * @type {string}
   * @memberof DemoStatus
   */
  progress?: DemoStatusProgressEnum
  /**
   * seconds since the job was submitted
   * @type {number}
   * @memberof DemoStatus
   */
  elapsed?: number
  /**
   *
   * @type {string}
   * @memberof DemoStatus
   */
  message?: string
  /**
   *
   * @type {Array<DemoStatusDetails>}
   * @memberof DemoStatus
   */
  details?: Array<DemoStatusDetails>
}

/**
 * @export
 * @enum {string}
 */
export enum DemoStatusCodeEnum {
  Ok = 'OK',
  Ko = 'KO',
}
/**
 * @export
 * @enum {string}
 */
export enum DemoStatusProgressEnum {
  Processing = 'processing',
  Completed = 'completed',
  Failed = 'failed',
  Queued = 'queued',
  Unknown = 'unknown',
}

/**
 *
 * @export
 * @interface DemoStatusDetails
 */
export interface DemoStatusDetails {
  /**
   *
   * @type {string}
   * @memberof DemoStatusDetails
   */
  description?: string
  /**
   *
   * @type {string}
   * @memberof DemoStatusDetails
   */
  label?: DemoStatusDetailsLabelEnum
  /**
   *
   * @type {string}
   * @memberof DemoStatusDetails
   */
  status?: string
}

/**
 * @export
 * @enum {string}
 */
export enum DemoStatusDetailsLabelEnum {
  ImgengMobile = 'imgeng_mobile',
  OriginalMobile = 'original_mobile',
  SystemError = 'system_error',
}

/**
 *
 * @export
 * @interface DeviceResult
 */
export interface DeviceResult {
  /**
   *
   * @type {string}
   * @memberof DeviceResult
   */
  video_url?: string
  /**
   *
   * @type {AnalysisResult}
   * @memberof DeviceResult
   */
  original?: AnalysisResult
  /**
   *
   * @type {AnalysisResult}
   * @memberof DeviceResult
   */
  imgeng?: AnalysisResult
  /**
   *
   * @type {Array<ImageStatistics>}
   * @memberof DeviceResult
   */
  top_10_images?: Array<ImageStatistics>
  /**
   *
   * @type {number}
   * @memberof DeviceResult
   */
  speed_index_delta?: number
  /**
   *
   * @type {number}
   * @memberof DeviceResult
   */
  lcp_delta?: number
  /**
   *
   * @type {number}
   * @memberof DeviceResult
   */
  fcp_delta?: number
  /**
   *
   * @type {number}
   * @memberof DeviceResult
   */
  visual_complete_delta?: number
  /**
   *
   * @type {number}
   * @memberof DeviceResult
   */
  image_payload_delta?: number
  /**
   *
   * @type {number}
   * @memberof DeviceResult
   */
  image_payload_percentage_delta?: number
  /**
   *
   * @type {string}
   * @memberof DeviceResult
   */
  error?: string
}
/**
 * Describes an error.
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
  /**
   *
   * @type {string}
   * @memberof ErrorResponse
   */
  code?: string
  /**
   *
   * @type {string}
   * @memberof ErrorResponse
   */
  message?: string
  /**
   *
   * @type {Array<ErrorResponseDetails>}
   * @memberof ErrorResponse
   */
  details?: Array<ErrorResponseDetails>
}
/**
 *
 * @export
 * @interface ErrorResponseDetails
 */
export interface ErrorResponseDetails {
  /**
   *
   * @type {string}
   * @memberof ErrorResponseDetails
   */
  error?: string
  /**
   *
   * @type {string}
   * @memberof ErrorResponseDetails
   */
  description?: string
}
/**
 * Holds the information for an image found on a tested website.
 * @export
 * @interface ImageInfo
 */
export interface ImageInfo {
  /**
   *
   * @type {string}
   * @memberof ImageInfo
   */
  cdn_provider?: string
  /**
   *
   * @type {number}
   * @memberof ImageInfo
   */
  size?: number
  /**
   *
   * @type {string}
   * @memberof ImageInfo
   */
  file_type?: string
  /**
   *
   * @type {string}
   * @memberof ImageInfo
   */
  dimensions?: string
}
/**
 * Holds the statistic data for an image found on a tested website.
 * @export
 * @interface ImageStatistics
 */
export interface ImageStatistics {
  /**
   *
   * @type {string}
   * @memberof ImageStatistics
   */
  url?: string
  /**
   *
   * @type {number}
   * @memberof ImageStatistics
   */
  diff?: number
  /**
   *
   * @type {number}
   * @memberof ImageStatistics
   */
  percentage_diff?: number
  /**
   *
   * @type {number}
   * @memberof ImageStatistics
   */
  ssim?: number
  /**
   *
   * @type {string}
   * @memberof ImageStatistics
   */
  grade?: string
  /**
   *
   * @type {ImageInfo}
   * @memberof ImageStatistics
   */
  original?: ImageInfo
  /**
   *
   * @type {ImageInfo}
   * @memberof ImageStatistics
   */
  imgeng?: ImageInfo
}
/**
 * Holds the opportunities suggested by Google Page Speed Insight in order to improve the performance of a site.
 * @export
 * @interface PageSpeedOpportunities
 */
export interface PageSpeedOpportunities {
  /**
   *
   * @type {string}
   * @memberof PageSpeedOpportunities
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof PageSpeedOpportunities
   */
  title?: string
  /**
   *
   * @type {string}
   * @memberof PageSpeedOpportunities
   */
  description?: string
  /**
   *
   * @type {number}
   * @memberof PageSpeedOpportunities
   */
  score?: number
  /**
   *
   * @type {string}
   * @memberof PageSpeedOpportunities
   */
  scoreDisplayMode?: string
  /**
   *
   * @type {string}
   * @memberof PageSpeedOpportunities
   */
  displayValue?: string
  /**
   *
   * @type {PageSpeedOpportunitiesDetails}
   * @memberof PageSpeedOpportunities
   */
  details?: PageSpeedOpportunitiesDetails
  /**
   *
   * @type {Array<object>}
   * @memberof PageSpeedOpportunities
   */
  warnings?: Array<object>
  /**
   *
   * @type {number}
   * @memberof PageSpeedOpportunities
   */
  numericValue?: number
  /**
   *
   * @type {string}
   * @memberof PageSpeedOpportunities
   */
  numericUnit?: string
}
/**
 *
 * @export
 * @interface PageSpeedOpportunitiesDetails
 */
export interface PageSpeedOpportunitiesDetails {
  /**
   *
   * @type {Array<object>}
   * @memberof PageSpeedOpportunitiesDetails
   */
  items?: Array<object>
  /**
   *
   * @type {number}
   * @memberof PageSpeedOpportunitiesDetails
   */
  overallSavingsBytes?: number
  /**
   *
   * @type {Array<object>}
   * @memberof PageSpeedOpportunitiesDetails
   */
  headings?: Array<object>
  /**
   *
   * @type {string}
   * @memberof PageSpeedOpportunitiesDetails
   */
  type?: string
  /**
   *
   * @type {number}
   * @memberof PageSpeedOpportunitiesDetails
   */
  overallSavingsMs?: number
}
/**
 * Holds the information requested with a Users List request.
 * @export
 * @interface SearchResponse
 */
export interface SearchResponse {
  /**
   *
   * @type {Array<string>}
   * @memberof SearchResponse
   */
  site_tests_ids: Array<string>
  /**
   *
   * @type {ErrorResponse}
   * @memberof SearchResponse
   */
  error: ErrorResponse
}
/**
 * Describes the result of a single service
 * @export
 * @interface SingleServiceResult
 */
export interface SingleServiceResult {
  /**
   *
   * @type {string}
   * @memberof SingleServiceResult
   */
  service_name?: string
  /**
   *
   * @type {object}
   * @memberof SingleServiceResult
   */
  result?: object
}
/**
 * Holds the information of a specific site test.
 * @export
 * @interface SiteTestResponse
 */
export interface SiteTestResponse {
  /**
   *
   * @type {string}
   * @memberof SiteTestResponse
   */
  url: string
  /**
   *
   * @type {string}
   * @memberof SiteTestResponse
   */
  site_test_id: string
  /**
   *
   * @type {boolean}
   * @memberof SiteTestResponse
   */
  desktop: boolean
  /**
   *
   * @type {boolean}
   * @memberof SiteTestResponse
   */
  mobile: boolean
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof SiteTestResponse
   */
  params: { [key: string]: string }
  /**
   *
   * @type {number}
   * @memberof SiteTestResponse
   */
  lighthouse_algorithm: number
  /**
   *
   * @type {DemoStatus}
   * @memberof SiteTestResponse
   */
  status?: DemoStatus
  /**
   *
   * @type {DemoResults}
   * @memberof SiteTestResponse
   */
  results?: DemoResults
  /**
   *
   * @type {Array<SingleServiceResult>}
   * @memberof SiteTestResponse
   */
  services_results?: Array<SingleServiceResult>
  /**
   *
   * @type {ErrorResponse}
   * @memberof SiteTestResponse
   */
  error?: ErrorResponse
}

/**
 * SiteTestApi - axios parameter creator
 * @export
 */
export const SiteTestApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Deletes a site test that\'s still in the queue waiting to be processed.
     * @param {string} siteTestId the id of the site test you want information for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteImageengineDemoV2SiteTestsSiteTestId: async (
      siteTestId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'siteTestId' is not null or undefined
      assertParamExists('deleteImageengineDemoV2SiteTestsSiteTestId', 'siteTestId', siteTestId)
      const localVarPath = `/imageengine/demo/v2/site-tests/{site_test_id}`.replace(
        `{${'site_test_id'}}`,
        encodeURIComponent(String(siteTestId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Returns the information regarding a site test. In the case of a completed site test the `results` field will contain the results.
     * @summary Get Demo Site Test Info
     * @param {string} siteTestId the id of the site test you want information for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getV2TestInfo: async (
      siteTestId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'siteTestId' is not null or undefined
      assertParamExists('getV2TestInfo', 'siteTestId', siteTestId)
      const localVarPath = `/imageengine/demo/v2/site-tests/{site_test_id}`.replace(
        `{${'site_test_id'}}`,
        encodeURIComponent(String(siteTestId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Returns the list of site tests ids satisfying the search parameters. The results will be ordered by date creation from the most recent to the least recent. None of the parameter is mandatory but at least one must be provided. Dates must be in the form \'YYYY-MM-DD HH-mm-ss\'. Query parameters value **must** be url encoded. A site test, in order to be found through parameters, must be searched using **all** the parameters provided at creation (order does not matter).
     * @summary Search for site tests
     * @param {string} [params] list of ampersand (&amp;) separated key&#x3D;value pairs used to search site tests. Value **must** be url encoded. Please insert **all** the parameters provided at site test creation (order does not matter).
     * @param {string} [startDate] the site test searched should have been created after this date. Value **must** be url encoded. Date must be in the format &#x60;YYYY-mm-dd HH:MM:SS&#x60;.
     * @param {string} [endDate] the site test searched should have been created before this date. Value **must** be url encoded. Date must be in the format &#x60;YYYY-mm-dd HH:MM:SS&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getV2UserTests: async (
      params?: string,
      startDate?: string,
      endDate?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/imageengine/demo/v2/site-tests/search`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (params !== undefined) {
        localVarQueryParameter['params'] = params
      }

      if (startDate !== undefined) {
        localVarQueryParameter['start_date'] =
          (startDate as any) instanceof Date ? (startDate as any).toISOString() : startDate
      }

      if (endDate !== undefined) {
        localVarQueryParameter['end_date'] =
          (endDate as any) instanceof Date ? (endDate as any).toISOString() : endDate
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Submits a new demo site test.  The `params` field of the request body is a JSON object. This complete list of the exact same params can be used to do a search through the appropriate endpoint. The keys and the values of these parameters can be completely custom. Only one parameter key is reserved which is the `batch_id` key. The value for this parameter must be a JSON string. Setting the same `batch_id` to a group of site tests will identify a batch of multiple requests.  The `callback_url` is a URL to a functioning service that will accept a POST HTTP request from this server once the site test has completed it\'s execution. The body of this POST HTTP request will be of the SiteTestResponse model.
     * @summary Submit Demo Site Test
     * @param {DemoRequest} [demoRequest] Information about the demo to run
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postV2Analyze: async (
      demoRequest?: DemoRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/imageengine/demo/v2/analyze`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        demoRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * SiteTestApi - functional programming interface
 * @export
 */
export const SiteTestApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SiteTestApiAxiosParamCreator(configuration)
  return {
    /**
     * Deletes a site test that\'s still in the queue waiting to be processed.
     * @param {string} siteTestId the id of the site test you want information for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteImageengineDemoV2SiteTestsSiteTestId(
      siteTestId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteImageengineDemoV2SiteTestsSiteTestId(
          siteTestId,
          options
        )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Returns the information regarding a site test. In the case of a completed site test the `results` field will contain the results.
     * @summary Get Demo Site Test Info
     * @param {string} siteTestId the id of the site test you want information for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getV2TestInfo(
      siteTestId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SiteTestResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getV2TestInfo(siteTestId, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Returns the list of site tests ids satisfying the search parameters. The results will be ordered by date creation from the most recent to the least recent. None of the parameter is mandatory but at least one must be provided. Dates must be in the form \'YYYY-MM-DD HH-mm-ss\'. Query parameters value **must** be url encoded. A site test, in order to be found through parameters, must be searched using **all** the parameters provided at creation (order does not matter).
     * @summary Search for site tests
     * @param {string} [params] list of ampersand (&amp;) separated key&#x3D;value pairs used to search site tests. Value **must** be url encoded. Please insert **all** the parameters provided at site test creation (order does not matter).
     * @param {string} [startDate] the site test searched should have been created after this date. Value **must** be url encoded. Date must be in the format &#x60;YYYY-mm-dd HH:MM:SS&#x60;.
     * @param {string} [endDate] the site test searched should have been created before this date. Value **must** be url encoded. Date must be in the format &#x60;YYYY-mm-dd HH:MM:SS&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getV2UserTests(
      params?: string,
      startDate?: string,
      endDate?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getV2UserTests(
        params,
        startDate,
        endDate,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Submits a new demo site test.  The `params` field of the request body is a JSON object. This complete list of the exact same params can be used to do a search through the appropriate endpoint. The keys and the values of these parameters can be completely custom. Only one parameter key is reserved which is the `batch_id` key. The value for this parameter must be a JSON string. Setting the same `batch_id` to a group of site tests will identify a batch of multiple requests.  The `callback_url` is a URL to a functioning service that will accept a POST HTTP request from this server once the site test has completed it\'s execution. The body of this POST HTTP request will be of the SiteTestResponse model.
     * @summary Submit Demo Site Test
     * @param {DemoRequest} [demoRequest] Information about the demo to run
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postV2Analyze(
      demoRequest?: DemoRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SiteTestResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postV2Analyze(demoRequest, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * SiteTestApi - factory interface
 * @export
 */
export const SiteTestApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = SiteTestApiFp(configuration)
  return {
    /**
     * Deletes a site test that\'s still in the queue waiting to be processed.
     * @param {string} siteTestId the id of the site test you want information for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteImageengineDemoV2SiteTestsSiteTestId(
      siteTestId: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .deleteImageengineDemoV2SiteTestsSiteTestId(siteTestId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Returns the information regarding a site test. In the case of a completed site test the `results` field will contain the results.
     * @summary Get Demo Site Test Info
     * @param {string} siteTestId the id of the site test you want information for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getV2TestInfo(siteTestId: string, options?: any): AxiosPromise<SiteTestResponse> {
      return localVarFp
        .getV2TestInfo(siteTestId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Returns the list of site tests ids satisfying the search parameters. The results will be ordered by date creation from the most recent to the least recent. None of the parameter is mandatory but at least one must be provided. Dates must be in the form \'YYYY-MM-DD HH-mm-ss\'. Query parameters value **must** be url encoded. A site test, in order to be found through parameters, must be searched using **all** the parameters provided at creation (order does not matter).
     * @summary Search for site tests
     * @param {string} [params] list of ampersand (&amp;) separated key&#x3D;value pairs used to search site tests. Value **must** be url encoded. Please insert **all** the parameters provided at site test creation (order does not matter).
     * @param {string} [startDate] the site test searched should have been created after this date. Value **must** be url encoded. Date must be in the format &#x60;YYYY-mm-dd HH:MM:SS&#x60;.
     * @param {string} [endDate] the site test searched should have been created before this date. Value **must** be url encoded. Date must be in the format &#x60;YYYY-mm-dd HH:MM:SS&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getV2UserTests(
      params?: string,
      startDate?: string,
      endDate?: string,
      options?: any
    ): AxiosPromise<SearchResponse> {
      return localVarFp
        .getV2UserTests(params, startDate, endDate, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Submits a new demo site test.  The `params` field of the request body is a JSON object. This complete list of the exact same params can be used to do a search through the appropriate endpoint. The keys and the values of these parameters can be completely custom. Only one parameter key is reserved which is the `batch_id` key. The value for this parameter must be a JSON string. Setting the same `batch_id` to a group of site tests will identify a batch of multiple requests.  The `callback_url` is a URL to a functioning service that will accept a POST HTTP request from this server once the site test has completed it\'s execution. The body of this POST HTTP request will be of the SiteTestResponse model.
     * @summary Submit Demo Site Test
     * @param {DemoRequest} [demoRequest] Information about the demo to run
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postV2Analyze(demoRequest?: DemoRequest, options?: any): AxiosPromise<SiteTestResponse> {
      return localVarFp
        .postV2Analyze(demoRequest, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * SiteTestApi - object-oriented interface
 * @export
 * @class SiteTestApi
 * @extends {BaseAPI}
 */
export class SiteTestApi extends BaseAPI {
  /**
   * Deletes a site test that\'s still in the queue waiting to be processed.
   * @param {string} siteTestId the id of the site test you want information for
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SiteTestApi
   */
  public deleteImageengineDemoV2SiteTestsSiteTestId(
    siteTestId: string,
    options?: AxiosRequestConfig
  ) {
    return SiteTestApiFp(this.configuration)
      .deleteImageengineDemoV2SiteTestsSiteTestId(siteTestId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Returns the information regarding a site test. In the case of a completed site test the `results` field will contain the results.
   * @summary Get Demo Site Test Info
   * @param {string} siteTestId the id of the site test you want information for
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SiteTestApi
   */
  public getV2TestInfo(siteTestId: string, options?: AxiosRequestConfig) {
    return SiteTestApiFp(this.configuration)
      .getV2TestInfo(siteTestId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Returns the list of site tests ids satisfying the search parameters. The results will be ordered by date creation from the most recent to the least recent. None of the parameter is mandatory but at least one must be provided. Dates must be in the form \'YYYY-MM-DD HH-mm-ss\'. Query parameters value **must** be url encoded. A site test, in order to be found through parameters, must be searched using **all** the parameters provided at creation (order does not matter).
   * @summary Search for site tests
   * @param {string} [params] list of ampersand (&amp;) separated key&#x3D;value pairs used to search site tests. Value **must** be url encoded. Please insert **all** the parameters provided at site test creation (order does not matter).
   * @param {string} [startDate] the site test searched should have been created after this date. Value **must** be url encoded. Date must be in the format &#x60;YYYY-mm-dd HH:MM:SS&#x60;.
   * @param {string} [endDate] the site test searched should have been created before this date. Value **must** be url encoded. Date must be in the format &#x60;YYYY-mm-dd HH:MM:SS&#x60;.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SiteTestApi
   */
  public getV2UserTests(
    params?: string,
    startDate?: string,
    endDate?: string,
    options?: AxiosRequestConfig
  ) {
    return SiteTestApiFp(this.configuration)
      .getV2UserTests(params, startDate, endDate, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Submits a new demo site test.  The `params` field of the request body is a JSON object. This complete list of the exact same params can be used to do a search through the appropriate endpoint. The keys and the values of these parameters can be completely custom. Only one parameter key is reserved which is the `batch_id` key. The value for this parameter must be a JSON string. Setting the same `batch_id` to a group of site tests will identify a batch of multiple requests.  The `callback_url` is a URL to a functioning service that will accept a POST HTTP request from this server once the site test has completed it\'s execution. The body of this POST HTTP request will be of the SiteTestResponse model.
   * @summary Submit Demo Site Test
   * @param {DemoRequest} [demoRequest] Information about the demo to run
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SiteTestApi
   */
  public postV2Analyze(demoRequest?: DemoRequest, options?: AxiosRequestConfig) {
    return SiteTestApiFp(this.configuration)
      .postV2Analyze(demoRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }
}
