import { EnginesApi } from '@/services/ie-microservice-openapi'
import { TempStorage } from '.'
import { logError } from './sentry'
import PQueue from 'p-queue'
import onboardState from '@/store/onboardingTechnology'
const queue = new PQueue({ concurrency: 1 })

interface IResponse {
  status: string
  error?: string
  cms: Array<string>
  frontend: Array<string>
}

/* we want to add this in a queue because the lambda takes a while to run
its detection and this function could be triggered again which will cause
one of the PUT request to fail when adding it to engine preferences */
export const detectAndStoreCMSForEngine = async (
  url: string,
  engineId?: number
): Promise<undefined | IResponse> => {
  return await queue.add(async () => {
    if (!engineId) return
    let exist = false
    let frontend: Array<string> = [],
      cms: Array<string> = []
    const ids = {
      website: 0,
      frontend: 0,
      cms: 0,
    }
    const enginePrefResp = await new EnginesApi().getEnginePreferences(engineId)
    for (const pref of enginePrefResp.data) {
      const { key, value, id } = pref
      switch (key) {
        case 'website':
          ids.website = id
          if (value === url) {
            exist = true
          }
          break
        case 'frontend':
          ids.frontend = id
          frontend = value.split(',')
          break
        case 'cms':
          ids.cms = id
          cms = value.split(',')
          break
      }
    }

    if (exist) {
      return {
        status: 'success',
        cms,
        frontend,
      }
    }

    const response = await fetch(
      `https://oujog6aja2.execute-api.us-west-2.amazonaws.com/production?url=${url}`
    )
    if (response.ok) {
      const data = (await response.json()) as IResponse
      if (data.error) {
        logError(data.error)
        return
      }

      if (!engineId) {
        new TempStorage().storeCmsDetector(data)
        return
      }

      const payloads: Array<{ id: number; key: string; value: string; engine_id: number }> = []
      if (data.cms.length > 0) {
        const cms = data.cms.map((c) => c.replaceAll(',', ''))
        payloads.push({ id: ids.cms, engine_id: engineId, key: 'cms', value: cms.join(',') })
      }

      if (data.frontend.length > 0) {
        const frontend = data.frontend.map((c) => c.replaceAll(',', ''))
        payloads.push({
          id: ids.frontend,
          engine_id: engineId,
          key: 'frontend',
          value: frontend.join(','),
        })
      }
      // if user does not manually select a technology from Onboarding.vue modal
      // then store detected tech
      if (!onboardState.manualSelect) {
        new TempStorage().storeOnboardingTech(data.frontend[0] || data.cms[0])
        onboardState.onboardingTech = data.frontend[0] || data.cms[0]
      }

      payloads.push({
        id: ids.website,
        engine_id: engineId,
        key: 'website',
        value: url,
      })

      for (const payload of payloads) {
        // update if payload contains non zero ID
        if (payload.id !== 0) {
          await new EnginesApi().updateEnginePreference(engineId, payload.id, payload).catch((e) =>
            logError(`Failed to update engine preferences for cms detections`, {
              extras: { data, engineId, e },
            })
          )
        } else {
          await new EnginesApi().createEnginePreference(engineId, payload).catch((e) =>
            logError(`Failed to save engine preferences for cms detections`, {
              extras: { data, engineId, e },
            })
          )
        }
      }
      return data
    } else {
      logError('failed to check cms from cms-detector', { extras: { response } })
    }
    return
  })
}
