




































import { randomString } from '@/modules/helpers'
//@ts-ignore
import NavbarToggleButton from './NavbarToggleButton'
import { defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
  name: 'BaseNav',
  components: {
    NavbarToggleButton,
  },
  props: {
    type: {
      type: String,
      default: '',
      description: 'Navbar type (e.g default, primary etc)',
    },
    title: {
      type: String,
      default: '',
      description: 'Title of navbar',
    },
    contentId: {
      type: [String, Number],
      default: randomString(10),
      description: "Explicit id for the menu. By default it's a generated random number",
    },
    containerClasses: {
      type: [String, Object, Array],
      default: 'container-fluid',
    },
    transparent: {
      type: Boolean,
      default: false,
      description: 'Whether navbar is transparent',
    },
    expand: {
      type: Boolean,
      default: false,
      description: 'Whether navbar should contain `navbar-expand-lg` class',
    },
    showToggleButton: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const toggled = ref(false)

    const closeMenu = () => {
      toggled.value = false
    }

    return {
      toggled,
      closeMenu,
    }
  },
})
