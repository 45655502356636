



































//@ts-ignore
import { FadeTransition } from 'vue2-transitions'
import { defineComponent, ref } from '@vue/composition-api'
export default defineComponent({
  name: 'BaseAlert',
  components: {
    FadeTransition,
  },
  props: {
    type: {
      type: String,
      default: 'default',
      description: 'Alert type',
    },
    dismissible: {
      type: Boolean,
      default: false,
      description: 'Whether alert is dismissible (closeable)',
    },
    icon: {
      type: String,
      default: '',
      description: 'Alert icon to display',
    },
  },
  setup() {
    const visible = ref(true)

    const dismissAlert = () => {
      visible.value = false
    }
    return {
      visible,
      dismissAlert,
    }
  },
})
