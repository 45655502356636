













import { defineComponent, PropType } from '@vue/composition-api'
import { DetailedSubscription } from '@/util/decorators'
import { getSubscriptionStatusClass } from '@/util'

export default defineComponent({
  props: {
    fontScale: {
      type: Number,
      required: false,
      default: 0.8,
    },
    subscription: {
      type: Object as PropType<DetailedSubscription>,
      required: true,
    },
  },
  setup() {
    return {
      getSubscriptionStatusClass,
    }
  },
})
