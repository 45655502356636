











































































import { defineComponent, onMounted, PropType, ref, watch } from '@vue/composition-api'
import IconStarActive from '@/components/Icons/IconStarActive.vue'
import IconStarInactive from '@/components/Icons/IconStarInactive.vue'
import { DetailedSubscription, Engine } from '@/util/decorators'
import { useSidebarStore } from '@/store/sidebar'
import { scrollIntoViewIfNeeded } from '@/util'

export default defineComponent({
  components: {
    IconStarActive,
    IconStarInactive,
  },
  props: {
    subscription: {
      type: Object as PropType<DetailedSubscription>,
      required: true,
    },
    engine: {
      type: Object as PropType<Engine>,
      required: true,
    },
  },
  setup(props) {
    const sidebarStore = useSidebarStore()
    const toggled = ref<boolean>(false)
    const engineLink = ref<HTMLElement>()

    const handleEngineChange = () => {
      if (!sidebarStore.requested.engine) {
        return
      }

      if (sidebarStore.requested.engine !== props.engine.id) {
        return
      }

      toggled.value = true

      if (engineLink.value) {
        scrollIntoViewIfNeeded(engineLink.value, { behavior: 'smooth', block: 'center' })
      }
    }

    onMounted(handleEngineChange)
    watch(() => sidebarStore.requested.engine, handleEngineChange)

    return {
      sidebarStore,
      toggled,
      toggle: () => (toggled.value = !toggled.value),
      favorite: useSidebarStore().updateEngineFavoriteState,
      engineLink,
    }
  },
})
