















import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    width: {
      type: [Number, String],
      default: 10,
    },
    height: {
      type: [Number, String],
      default: 16,
    },
    viewBox: {
      type: String,
      default: '0 0 10 16',
    },
  },
})
