





































import { computed, defineComponent, ref } from '@vue/composition-api'
import { SubscriptionType } from '@/util/enums'
import { useSidebarStore } from '@/store/sidebar'
import SidebarSubscription from '@/components/SidebarPlugin/SidebarSubscription.vue'
import { SubscriptionsApi } from '@/services/ie-microservice-openapi'
import { logError } from '@/util/sentry'

export default defineComponent({
  components: { SidebarSubscription },
  setup(_, context) {
    const sidebarStore = useSidebarStore()
    const paymentAdded = ref(false)
    const trial = computed(() =>
      sidebarStore.getCurrentlyActiveSubscription(SubscriptionType.Trial)
    )
    const subscription = computed(() =>
      sidebarStore.getCurrentlyActiveSubscription(SubscriptionType.Subscription)
    )

    const paymentAddedUpdateSub = async () => {
      try {
        const { data } = await new SubscriptionsApi().getSubscription(
          Number(context.root.$route.params.subscriptionID)
        )
        //@ts-ignore
        sidebarStore.setSubscription(data)
      } catch (err: any) {
        logError(err)
      }
    }

    return {
      sidebarStore,
      trial,
      subscription,
      SubscriptionType,
      paymentAddedUpdateSub,
      paymentAdded,
    }
  },
  watch: {
    $route() {
      if (this.$route.query.paymentAdded) {
        this.paymentAddedUpdateSub()
        this.paymentAdded = true
      }
    },
  },
})
