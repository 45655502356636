import { defineStore } from 'pinia'
import * as api from '@/services/ie-microservice-openapi'
import { useTeamStore } from './team'
import { apiHelper, returnErrMsg } from './helpers'
import { setUser } from '@sentry/browser'
import { alertPartnerLogin, TempStorage } from '@/util'
import { load } from 'recaptcha-v3'
import { SignupType } from '@/util/enums'
import { useOnboardingStore } from '@/store/onboarding'

export interface LoginResp {
  expiration: number
  token: string
  user_id: number
  message?: string
}
type TokenResp = LoginResp

export interface IUser {
  company_name?: string
  confirmed?: boolean
  email?: string
  enabled?: boolean
  first_name?: string
  id?: number
  last_name?: string
  phone?: string
  reseller_team_id?: null | number
  team_id?: null | number
  username?: string
}

interface IRegister {
  username: string
  email: string
  country_id: number
  enabled: boolean
  password: string
  confirmed: boolean
  user_type?: number
  first_name?: string
  last_name?: string
  company_name?: string
  team_id?: number
  reseller_team_id?: number
}

interface AuthState {
  accessToken: null | string
  expiration: null | number
  user: api.User
  isRegistering: boolean
  isImpersonating: boolean
  engines: Array<IEngine>
  subscriptions: Array<api.DetailedSubscription>
  recentRefreshes: number
  maxRecentRefreshes: number
  recentTime: number
}

interface IEngine {
  id?: number
  cname?: string
  subscription_id?: number
}

const DUPLICATE_USER_ERROR =
  'Could not create a new user with these credentials. A user with this email or username already exists.'
// this is intentionally an object instead of undefined
// to make sure that components accessing properties of user don't error
const user = {} as api.User

export const useAuthStore = defineStore({
  id: 'auth',
  state: (): AuthState => ({
    accessToken: null,
    expiration: null,
    user: user,
    isRegistering: false,
    isImpersonating: false,
    engines: [],
    subscriptions: [],
    // Number of attempted token refreshes in the last recentTime milliseconds
    recentRefreshes: 0,
    maxRecentRefreshes: 10,
    recentTime: 10000,
  }),
  actions: {
    async login({
      username,
      password,
      token,
    }: {
      username: string
      password: string
      token?: string
    }) {
      return await apiHelper<LoginResp>(`${this.authUrl}/api/v1/login`, {
        username,
        password,
        token,
      })
        .then((res) => {
          return !res.token
            ? {
                ...res,
                success: false,
                niceMessage: getLoginErrorMessage(res.message),
              }
            : this.finishLogin(res)
        })
        .catch((e) => ({
          success: false,
          message: e,
          niceMessage: `An unknown error occurred while trying to login,
          please try again later or contact support@imageengine.io.`,
        }))
    },
    async register(payload: IRegister) {
      try {
        const user = await new api.UsersApi().createUser(payload).then((res) => res.data)
        this.isRegistering = true
        this.user = user

        useOnboardingStore().onboarding.is_recently_registered = true
        useOnboardingStore().pushState(this.user.id)

        const recaptcha = await load(process.env.VUE_APP_RECAPTCHA_SITE_KEY || '')
        const token = await recaptcha.execute('login')
        const loginRes = await this.login({
          username: user.username || '',
          password: payload.password,
          token,
        })
        // login res can is success or not we return the result
        return loginRes
      } catch (e) {
        const statusCode = (e as any).response.status as number
        if (statusCode === 409) {
          alertPartnerLogin(payload.email)
        }
        let msg = returnErrMsg(e as any)
        msg = msg.match(/user already exists/) ? DUPLICATE_USER_ERROR : msg

        return {
          success: false,
          message: msg,
        }
      }
    },
    async finishSSOAuth(): Promise<{ user?: IUser; success?: boolean; message?: string }> {
      try {
        const ssoData = await fetch(`${this.authUrl}/api/v1/sso/token`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          credentials: 'include', // this allows the required cookie to be included
        }).then((res) => res.json() as Promise<LoginResp>)
        if (!ssoData.token) {
          return {
            message: 'failed to authorize',
            success: false,
          }
        }
        return this.finishLogin(ssoData)
      } catch (err) {
        return {
          success: false,
          message: err as string,
        }
      }
    },
    async finishLogin(data: LoginResp) {
      // This refresh token can't be stored in state, so we will store it locally.
      // It can be used to refresh the access token
      localStorage.setItem('refreshToken', data.token)
      localStorage.setItem('refreshTokenExpiration', data.expiration.toString())
      localStorage.setItem('userID', data.user_id.toString())

      const res = await this.hydrateUser()

      if (!res.success) {
        return res
      }
      this.resetRefreshes()
      const team = useTeamStore()
      window.dataLayer.push({
        event: 'login',
        user_id: data.user_id.toString(),
        user_type: team.userType,
      })

      return {
        success: true,
        message: '',
        user: this.user,
      }
    },
    async impersonateUser(userID: number) {
      this.isImpersonating = true
      localStorage.setItem('userID', userID.toString())
      return this.hydrateUser()
    },
    containsValidRefresh() {
      const token = localStorage.getItem('refreshToken')
      const expirationTime = parseInt(localStorage.getItem('refreshTokenExpiration') || '0') - 120
      const unixSec = Date.now() / 1000
      return token && expirationTime > 0 && unixSec < expirationTime
    },
    async hydrateUser() {
      const token = localStorage.getItem('refreshToken')
      if (!token) return { success: false }
      if (this.recentRefreshes > this.maxRecentRefreshes) {
        // Too many reauth attempts, send the user to the login page
        this.logout()
      }

      this.incrementRefreshes()
      return apiHelper<TokenResp>(`${this.authUrl}/api/v1/token`, {
        grant_type: 'refresh_token',
        refresh_token: token,
      })
        .then(async (res) => {
          if (!res.token) {
            return {
              ...res,
              success: false,
            }
          }
          this.accessToken = res.token
          this.expiration = res.expiration

          const { success, user } = await this.fetchUser()
          if (!success || !user || !user.id) return { success: false }
          this.user = user
          const promises: Array<Promise<unknown>> = []
          promises.push(this.fetchUserEngines())
          promises.push(this.fetchSubscriptions())
          if (user.team_id) {
            const team = useTeamStore()
            user.team_id && promises.push(team.fetchTeam(user.team_id))
          }
          await Promise.all(promises)
          await useOnboardingStore().loadState()

          if (process.env.NODE_ENV === 'production') {
            setUser({ email: user.email, id: user.id?.toString() })
          }

          return {
            success: true,
            message: 'token refreshed',
          }
        })
        .catch((e) => ({ success: false, message: e }))
    },
    async fetchUser(): Promise<{ success: boolean; user?: api.User }> {
      const userID = localStorage.getItem('userID')
      if (!userID) throw Error('cannot find user')
      return await new api.UsersApi()
        .getUser(parseInt(userID))
        .then((res) => {
          this.user = res.data

          return { user: this.user, success: true }
        })
        .catch((e) => ({ success: false }))
    },
    async loginWithSSO({ signUp, type }: { signUp: boolean; type: SignupType }) {
      let redirectPath = '/login'
      const queryParams = new URLSearchParams()

      if (signUp) {
        redirectPath = window.location.pathname

        queryParams.append('new', 'true')

        if (type === SignupType.Google) {
          new TempStorage().storeSignupType(SignupType.Google)
          window.dataLayer.push({ event: 'signup_type_google' })
        } else {
          new TempStorage().storeSignupType(SignupType.GitHub)
          window.dataLayer.push({ event: 'signup_type_github' })
        }

        useOnboardingStore().onboarding.signup_type = type
      }

      const redirectUrl = `${process.env.VUE_APP_SITE_URL}${redirectPath}?sso=${type.toLowerCase()}`
      queryParams.append('redirect_url', redirectUrl)

      const url = `${this.authUrl}/api/v1/sso/${type.toLowerCase()}?${queryParams.toString()}`
      window.open(url, '_self')
    },
    logout() {
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('refreshTokenExpiration')
      localStorage.removeItem('userID')
      const teamStore = useTeamStore()
      teamStore.$reset()
      const onboardingStore = useOnboardingStore()
      onboardingStore.$reset()
      this.$reset()
    },
    async fetchSubscriptions() {
      const userID = this.user.id
      if (!userID) throw Error('user does not exist')
      if (!this.user.reseller_team_id && !this.user.team_id) {
        const result = await new api.SubscriptionsApi().getSubscriptionsByUserId(userID)
        if (result.data.length > 0) {
          const sub = result.data[0]
          this.subscriptions = result.data
          if (sub.status === 'SUSPENDED') {
            console.log('is SUSPENDED')
          }
        }
      }
    },
    async fetchUserEngines() {
      const userID = this.user.id
      if (!userID) throw Error('user does not exist')
      return await new api.SearchApi()
        .searchResourcesForUser(userID, undefined, ['engines'])
        .then((res) => {
          if (!res.data.engines) {
            this.engines = []
            return { success: true, engines: [] }
          }
          this.engines = res.data.engines

          return { success: true, engines: this.engines }
        })
        .catch((e) => ({ success: false }))
    },
    incrementRefreshes() {
      // Add one to the recent refreshes counter
      this.recentRefreshes++
      // Subtract one after the timeout
      setTimeout(() => {
        this.recentRefreshes--
      }, this.recentTime)
    },
    resetRefreshes() {
      this.recentRefreshes = 0
    },
  },
  getters: {
    isAuthenticated: (state): boolean => {
      const { accessToken, expiration, user } = state
      return !!(accessToken && user && expiration && expiration > Date.now() / 1000)
    },
    authUrl: (): string => {
      return process.env.VUE_APP_AUTH_API_URL || ''
    },
    getToken: ({ accessToken }): string => {
      return accessToken ?? ''
    },
    hasEngines: ({ engines }): boolean => {
      return engines.length > 0
    },
    requiredPayment: ({ subscriptions }): boolean => {
      if (subscriptions.length === 1 && subscriptions[0].status === 'SUSPENDED') return true
      return false
    },
    getName: ({ user }): string => {
      if (!user) return 'Unknown'
      const firstName = user.first_name ?? ''
      const lastName = user.last_name ?? ''
      const fullName = `${firstName} ${lastName}`
      return fullName.trim() ? fullName : 'Unknown'
    },
    isSelfServe({ user }): boolean {
      return !!(user.reseller_team_id || user.team_id)
    },
  },
})

function getLoginErrorMessage(message: string = ''): string {
  let niceMessage = ''
  switch (message) {
    case 'username and password are required':
      niceMessage = 'Username/email and password are required'
      break
    case 'not-found':
      niceMessage = 'Invalid username/email or password.'
      break
    case 'locked-account':
      niceMessage = 'Your account is locked, please contact support@imageengine.io.'
      break
    case 'unconfirmed-email':
      niceMessage =
        'You cannot login until you have verified your email address.  Please check your inbox for the verification email.'
      break
    case 'unknown':
    default:
      niceMessage =
        'An unknown error occurred while trying to login, please try again later or contact support@imageengine.io.'
      break
  }
  return niceMessage
}
