

























import { defineComponent, ref, computed, watch } from '@vue/composition-api'
export default defineComponent({
  name: 'BasePagination',
  props: {
    pageCount: {
      type: Number,
      default: 0,
      description: 'Pagination page count. This should be specified in combination with perPage',
    },
    perPage: {
      type: Number,
      default: 10,
      description: 'Pagination per page. Should be specified with total or pageCount',
    },
    total: {
      type: Number,
      default: 0,
      description:
        'Can be specified instead of pageCount. The page count in this case will be total/perPage',
    },
    value: {
      type: Number,
      default: 1,
      description: 'Pagination value',
    },
    size: {
      type: String,
      default: '',
      description: 'Pagination size',
    },
    align: {
      type: String,
      default: '',
      description: 'Pagination alignment (e.g center|start|end)',
    },
  },
  setup(props, context) {
    const defaultPagesToDisplay = ref(5)
    const val = ref(props.value)

    const totalPages = computed(() => {
      if (props.pageCount > 0) return props.pageCount
      if (props.total > 0) {
        return Math.ceil(props.total / props.perPage)
      }
      return 1
    })

    const pagesToDisplay = computed(() => {
      if (totalPages.value > 0 && totalPages.value < defaultPagesToDisplay.value) {
        return totalPages.value
      }
      return defaultPagesToDisplay.value
    })

    const minPage = computed(() => {
      if (val.value >= pagesToDisplay.value) {
        const pagesToAdd = Math.floor(pagesToDisplay.value / 2)
        const newMaxPage = pagesToAdd + val.value
        if (newMaxPage > totalPages.value) {
          return totalPages.value - pagesToDisplay.value + 1
        }
        return val.value - pagesToAdd
      } else {
        return 1
      }
    })

    const maxPage = computed(() => {
      if (val.value >= pagesToDisplay.value) {
        const pagesToAdd = Math.floor(pagesToDisplay.value / 2)
        const newMaxPage = pagesToAdd + val.value
        if (newMaxPage < totalPages.value) {
          return newMaxPage
        } else {
          return totalPages.value
        }
      } else {
        return pagesToDisplay.value
      }
    })

    const range = (min: number, max: number) => {
      let arr = []
      for (let i = min; i <= max; i++) {
        arr.push(i)
      }
      return arr
    }

    const changePage = (item: number) => {
      context.root.$emit('input', item)
    }

    const nextPage = () => {
      if (val.value < totalPages.value) {
        context.root.$emit('input', val.value + 1)
      }
    }

    const prevPage = () => {
      if (val.value > 1) {
        context.root.$emit('input', val.value - 1)
      }
    }

    watch(
      () => props.perPage,
      () => {
        context.root.$emit('input', 1)
      }
    )

    watch(
      () => props.total,
      () => {
        context.root.$emit('input', 1)
      }
    )

    return {
      totalPages,
      pagesToDisplay,
      minPage,
      maxPage,
      range,
      val,
      changePage,
      nextPage,
      prevPage,
    }
  },
})
