




























import { ref, defineComponent, watch } from '@vue/composition-api'
import { PropType } from '@vue/composition-api/dist/vue-composition-api'
import { DetailedSubscription } from '@/util/decorators'
//@ts-ignore
import Modal from '@/components/Modal.vue'

export default defineComponent({
  name: 'TierRestrictModal',
  components: {
    Modal,
  },
  props: {
    subscription: {
      type: Object as PropType<DetailedSubscription>,
    },
    showRestrictModal: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    team: {
      type: Boolean,
      required: true,
    },
    teamLimit: {
      type: String,
    },
    addPayment: {
      type: Boolean,
      required: true,
    },
    addon: {
      type: Boolean,
    },
    plan: {
      type: String,
    },
  },
  setup(props, context) {
    const bodyText = ref('')
    const buttonText = ref('')
    const engLimit = ref(2)
    const nextTeamTiers = ref('Growth or Beast tiers')
    watch(
      () => props.showRestrictModal,
      () => {
        if (props.subscription?.tier?.tier_name === 'ImageEngine Growth') {
          engLimit.value = 5
          nextTeamTiers.value = 'Beast Tier'
        }
        //Beast tier doesn't have team member limit
        if (props.subscription?.tier?.tier_name === 'ImageEngine Beast' && !props.team) {
          engLimit.value = 10
        }

        if (props.addPayment && props.addon) {
          bodyText.value =
            'Engine Limit Reached. Please add credit card information before adding more engines.'
          buttonText.value = 'Add payment method'
          return
        }
        if (props.addPayment) {
          bodyText.value = 'Please add credit card information before upgrading'
          buttonText.value = 'Add payment method'
          return
        }
        bodyText.value = props.team
          ? `Your current plan has a limit of ${props.teamLimit} team members. Upgrade plan to ${nextTeamTiers.value} to add more members.`
          : `Your current plan has a limit of ${engLimit.value} engines. You can add additional engines at $15/month each.`

        buttonText.value = props.team ? 'Upgrade' : 'Proceed to create additional engine'
      }
    )

    const closeDevModal = () => {
      context.emit('close')
    }

    const modalRoute = () => {
      closeDevModal()
      context.emit('route')
      if (props.addPayment) {
        context.root.$router.push({
          path: `/subscriptions/${props.subscription?.id}/details`,
          query: { addPayment: 'true', plan: props.plan },
        })
        return
      }
      props.team
        ? props.subscription &&
          context.root.$router.push(`/subscriptions/${props.subscription.id}/billing/upgrade`)
        : props.subscription &&
          context.root.$router.push(
            `/subscriptions/${props.subscription.id}/engines/create?addon=true`
          )
    }

    return {
      modalRoute,
      closeDevModal,
      bodyText,
      buttonText,
    }
  },
})
