













































import { defineComponent, computed } from '@vue/composition-api'
import { useSidebarStore } from '@/store/sidebar'
import { useWizardStore } from '@/store/wizard'
import IconChart from '@/components/Icons/IconChart.vue'
import IconDashboard from '@/components/Icons/IconDashboard.vue'
import IconPlus from '@/components/Icons/IconPlus.vue'
import SidebarButton from '@/components/SidebarPlugin/SidebarButton.vue'
import SidebarWizard from '@/components/SidebarPlugin/SidebarWizard.vue'
import { UserScope } from '@/util/enums'

export default defineComponent({
  components: {
    IconChart,
    IconDashboard,
    IconPlus,
    SidebarButton,
    SidebarWizard,
  },
  setup() {
    const sidebarStore = useSidebarStore()
    const wizardStore = useWizardStore()

    const isDevTier = computed(() => {
      return (
        sidebarStore.current.subscriptionType === 'SUBSCRIPTION' &&
        sidebarStore.current.subscription?.payment_plan === 'IMAGEENGINE_DEV'
      )
    })

    return {
      sidebarStore,
      wizardStore,
      UserScope,
      isDevTier,
    }
  },
})
