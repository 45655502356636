import { Country } from '@/services/ie-microservice-openapi'
import { UserOnboarding, UserOnboardingDefault } from '@/util/decorators'
import { CmsType, FrontendType, SignupType } from '@/util/enums'

enum KEYS {
  GEO_IP = 'geo-ip',
  DEMO_WEBSITES = 'demo-websites',
  DEMO_TEST_ID = 'demo-test-id',
  CACHE_KEYS = 'cache-keys',
  DEEP_LINK = 'deep-link',
  CUSTOM_DELIVERY_DEBUG = 'custom-delivery-debug',
  PAYMENT_ADDED_STATUS = 'payment-added-status',
  SUBSCRIPTION_DATE_ACCESSED = 'subscription-date-accessed',
  SUBSCRIPTION_DEFAULT = 'subscription-default',
  ENGINE_FAVORITE_FLAG = 'engine-favorite-flag',
  ENGINE_VERIFIED_FLAG = 'engine-verified-flag',
  ENGINE_NICKNAME = 'engine-nickname',
  ENGINE_SETUP_INSTRUCTIONS_DISMISSED = 'engine-setup-instructions-dismissed',
  ORIGIN_FAVORITE_FLAG = 'origin-favorite-flag',
  ORIGIN_DETECTED_CMS = 'origin-detected-cms',
  ORIGIN_DETECTED_FRONTEND = 'origin-detected-frontend',
  ORIGIN_DETECTION_COMPLETED = 'origin-detection-completed',
  ACCOUNT_DATE_ACCESSED = 'account-date-accessed',
  USER_ONBOARDING = 'user-onboarding',
  SIGNUP_TYPE = 'signup-type',
  USER_WEBSITE = 'user-website',
  SUGGESTED_ORIGINS = 'suggested-origins',
  CMS_DETECTOR = 'cms-detector',
  AFFILIATE_ID = 'affiliate_id',

  BETA_FLAG = 'beta-flag',
  ONBOARDING_TECH = 'onboarding-tech',
}

enum DEPRECATED_KEYS {
  UI_V2 = 'ui-v2',
  DEMO_TEST_ID_V2 = 'demo-test-id-v2',
}

export class TempStorage {
  static clearStorage() {
    for (const suit in KEYS) {
      const storageKey = KEYS[suit as keyof typeof KEYS]
      sessionStorage.removeItem(storageKey)
    }
  }

  constructor() {
    // we want to maintain this so it doesnt get out of hand
    for (const suit in DEPRECATED_KEYS) {
      const storageKey = DEPRECATED_KEYS[suit as keyof typeof DEPRECATED_KEYS]
      localStorage.removeItem(storageKey)
    }
  }

  /* Setters and Getters for GEOIP */
  storeGeoIP(country: Country) {
    sessionStorage.setItem(KEYS.GEO_IP, JSON.stringify(country))
  }

  getGeoIP(): Country | null {
    const res = sessionStorage.getItem(KEYS.GEO_IP)
    return !res ? null : (JSON.parse(res) as Country)
  }

  /* Setters and Getters for DEMO_WEBSITE */
  storeDemoWebsite(website: string) {
    const websites = this.getDemoWebsites()
    websites.push(website)
    sessionStorage.setItem(KEYS.DEMO_WEBSITES, JSON.stringify(websites))
  }

  getDemoWebsites(): Array<string> {
    const res = sessionStorage.getItem(KEYS.DEMO_WEBSITES)
    return !res ? [] : (JSON.parse(res) as Array<string>)
  }

  /* Setters and Getters for DEMO_TEST_ID */
  getDemoResults(): string {
    return sessionStorage.getItem(KEYS.DEMO_TEST_ID) ?? ''
  }

  storeDemoResults(id: string) {
    sessionStorage.setItem(KEYS.DEMO_TEST_ID, id)
  }

  getDeepLink(): string {
    return sessionStorage.getItem(KEYS.DEEP_LINK) ?? ''
  }

  storeDeepLink(link: string) {
    sessionStorage.setItem(KEYS.DEEP_LINK, link)
  }

  /* Below are localStorage implementations */

  storeCacheKey(k: string) {
    const keys = this.getCacheKeys()
    keys.push(k)
    this.overrideCacheKeys(keys)
  }

  getCacheKeys(): Array<string> {
    const stringKeys = localStorage.getItem(KEYS.CACHE_KEYS) ?? '[]'
    return JSON.parse(stringKeys)
  }

  overrideCacheKeys(keys: Array<string>) {
    localStorage.setItem(KEYS.CACHE_KEYS, JSON.stringify(keys))
  }

  getUserOnboarding(userId: number): UserOnboarding {
    return localStorage.getItem(`${KEYS.USER_ONBOARDING}-${userId}`)
      ? JSON.parse(localStorage.getItem(`${KEYS.USER_ONBOARDING}-${userId}`) ?? '{}')
      : { ...UserOnboardingDefault, is_skipped: true, is_technical: true }
  }

  storeUserOnboarding(userId: number, value: UserOnboarding) {
    localStorage.setItem(`${KEYS.USER_ONBOARDING}-${userId}`, JSON.stringify(value))
  }

  getCustomDeliveryDebugState(): boolean {
    const res = localStorage.getItem(KEYS.CUSTOM_DELIVERY_DEBUG)
    return res ? JSON.parse(res) : false
  }

  storeCustomDeliveryDebugState(state: boolean) {
    localStorage.setItem(KEYS.CUSTOM_DELIVERY_DEBUG, JSON.stringify(state))
  }

  storeSubscriptionDateAccessed(id: number, date: string) {
    localStorage.setItem(`${KEYS.SUBSCRIPTION_DATE_ACCESSED}-${id}`, date)
  }

  getSubscriptionDateAccessed(id: number): string {
    return localStorage.getItem(`${KEYS.SUBSCRIPTION_DATE_ACCESSED}-${id}`) || ''
  }

  storePaymentAddedStatus(state: boolean) {
    sessionStorage.setItem(KEYS.PAYMENT_ADDED_STATUS, JSON.stringify(state))
  }

  getPaymentAddedStatus(): boolean {
    const res = sessionStorage.getItem(KEYS.PAYMENT_ADDED_STATUS)
    return res ? JSON.parse(res) : false
  }

  storeSubscriptionDefault(accountId: number, subscriptionId: number | null) {
    if (subscriptionId) {
      localStorage.setItem(
        `${KEYS.SUBSCRIPTION_DEFAULT}-${accountId}`,
        JSON.stringify(subscriptionId)
      )
    } else {
      localStorage.removeItem(`${KEYS.SUBSCRIPTION_DEFAULT}-${accountId}`)
    }
  }

  getSubscriptionDefault(accountId: number, subscriptionId: number): boolean {
    let state: any = localStorage.getItem(`${KEYS.SUBSCRIPTION_DEFAULT}-${accountId}`)

    state = state ? parseInt(JSON.parse(state)) : false

    return state && state === subscriptionId
  }

  storeEngineFavoriteFlag(id: number, state: boolean) {
    localStorage.setItem(`${KEYS.ENGINE_FAVORITE_FLAG}-${id}`, JSON.stringify(state))
  }

  getEngineFavoriteFlag(id: number): boolean {
    const state = localStorage.getItem(`${KEYS.ENGINE_FAVORITE_FLAG}-${id}`)
    return state ? JSON.parse(state) : false
  }

  storeEngineVerifiedFlag(id: number, state: boolean) {
    localStorage.setItem(`${KEYS.ENGINE_VERIFIED_FLAG}-${id}`, JSON.stringify(state))
  }

  getEngineVerifiedFlag(id: number): boolean {
    const state = localStorage.getItem(`${KEYS.ENGINE_VERIFIED_FLAG}-${id}`)
    return state ? JSON.parse(state) : false
  }

  storeEngineNickname(id: number, nickname: string) {
    localStorage.setItem(`${KEYS.ENGINE_NICKNAME}-${id}`, nickname)
  }

  getEngineNickname(id: number): string {
    return localStorage.getItem(`${KEYS.ENGINE_NICKNAME}-${id}`) || ''
  }

  storeEngineSetupInstructionsDismissed(id: number, state: boolean) {
    localStorage.setItem(`${KEYS.ENGINE_SETUP_INSTRUCTIONS_DISMISSED}-${id}`, JSON.stringify(state))
  }

  getEngineSetupInstructionsDismissed(id: number): boolean {
    const state = localStorage.getItem(`${KEYS.ENGINE_SETUP_INSTRUCTIONS_DISMISSED}-${id}`)
    return state ? JSON.parse(state) : false
  }

  storeOriginFavoriteFlag(id: number, state: boolean) {
    localStorage.setItem(`${KEYS.ORIGIN_FAVORITE_FLAG}-${id}`, JSON.stringify(state))
  }

  getOriginFavoriteFlag(id: number): boolean {
    const state = localStorage.getItem(`${KEYS.ORIGIN_FAVORITE_FLAG}-${id}`)
    return state ? JSON.parse(state) : false
  }

  getOriginDetectedCms(id: number): Array<CmsType> {
    return JSON.parse(localStorage.getItem(`${KEYS.ORIGIN_DETECTED_CMS}-${id}`) ?? '[]')
  }

  storeOriginDetectedCms(id: number, value: Array<CmsType>) {
    localStorage.setItem(`${KEYS.ORIGIN_DETECTED_CMS}-${id}`, JSON.stringify(value))
  }

  getOriginDetectedFrontend(id: number): Array<FrontendType> {
    return JSON.parse(localStorage.getItem(`${KEYS.ORIGIN_DETECTED_FRONTEND}-${id}`) ?? '[]')
  }

  storeOriginDetectedFrontend(id: number, value: Array<FrontendType>) {
    localStorage.setItem(`${KEYS.ORIGIN_DETECTED_FRONTEND}-${id}`, JSON.stringify(value))
  }

  getOriginDetectionCompleted(id: number): boolean {
    const state = localStorage.getItem(`${KEYS.ORIGIN_DETECTION_COMPLETED}-${id}`)
    return state ? JSON.parse(state) : false
  }

  storeOriginDetectionCompleted(id: number, state: boolean) {
    localStorage.setItem(`${KEYS.ORIGIN_DETECTION_COMPLETED}-${id}`, JSON.stringify(state))
  }

  storeAccountDateAccessed(id: number, date: string) {
    localStorage.setItem(`${KEYS.ACCOUNT_DATE_ACCESSED}-${id}`, date)
  }

  getAccountDateAccessed(id: number): string {
    return localStorage.getItem(`${KEYS.ACCOUNT_DATE_ACCESSED}-${id}`) || ''
  }
  getSignupType(): string {
    return localStorage.getItem(KEYS.SIGNUP_TYPE) ?? ''
  }

  storeSignupType(type: SignupType.Google | SignupType.GitHub | SignupType.Password) {
    localStorage.setItem(KEYS.SIGNUP_TYPE, type)
  }

  storeOnboardingTech(technology: string) {
    localStorage.setItem(KEYS.ONBOARDING_TECH, technology || '')
  }

  getOnboardingTech(): string {
    return localStorage.getItem(KEYS.ONBOARDING_TECH) ?? ''
  }

  /* Setters and Getters for Website */
  storeWebsite(website: string) {
    sessionStorage.setItem(KEYS.USER_WEBSITE, website)
  }

  getWebsite(): string {
    return sessionStorage.getItem(KEYS.USER_WEBSITE) ?? ''
  }

  /* Setters and Getters for suggested origins */
  storeSuggestedOrigins(origins: Array<string>) {
    sessionStorage.setItem(KEYS.SUGGESTED_ORIGINS, JSON.stringify(origins))
  }

  getSuggestedOrigins(): Array<string> {
    const res = sessionStorage.getItem(KEYS.SUGGESTED_ORIGINS)
    return !res ? [] : (JSON.parse(res) as Array<string>)
  }

  getCmsDetector(): string {
    return JSON.parse(localStorage.getItem(KEYS.CMS_DETECTOR) ?? '')
  }

  storeCmsDetector(cms: object) {
    localStorage.setItem(KEYS.CMS_DETECTOR, JSON.stringify(cms))
  }

  getBetaFlag(): boolean {
    return JSON.parse(localStorage.getItem(KEYS.BETA_FLAG) || 'false')
  }

  storeBetaFlag(flag: boolean) {
    localStorage.setItem(KEYS.BETA_FLAG, JSON.stringify(flag))
  }

  getAffiliateId(): string {
    const affiliateStr = localStorage.getItem(KEYS.AFFILIATE_ID)
    if (!affiliateStr) return ''
    const affiliate = JSON.parse(affiliateStr) as { id: string; ts: number }
    const fiveMinutes = 1000 * 60 * 5
    const elapsed = Date.now() - affiliate.ts
    if (elapsed < fiveMinutes) {
      return affiliate.id
    }
    localStorage.removeItem(KEYS.AFFILIATE_ID)
    return ''
  }

  storeAffiliateId(id: string) {
    if (!id) return
    localStorage.setItem(KEYS.AFFILIATE_ID, JSON.stringify({ id, ts: Date.now() }))
  }
}
