





























import Card from './Card.vue'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'stats-card',
  components: {
    Card,
  },
  props: {
    type: {
      type: String,
      default: 'primary',
    },
    icon: String,
    title: String,
    subTitle: {
      type: String,
      required: false,
    },
    statisticsPeriod: {
      type: String,
      required: false,
    },
    iconClasses: {
      type: [String, Array],
      required: false,
    },
  },
})
