export function apiHelper<T>(url: string, body: unknown): Promise<T> {
  return fetch(url, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  }).then((res) => res.json())
}

export interface IError {
  response: {
    data: {
      success: true
      message: string
    }
  }
}

export function returnErrMsg(err: IError, defaultMsg: string = '') {
  if (err.response && err.response.data && err.response.data.message) {
    return err.response.data.message
  }
  return defaultMsg
}
